import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {LocationEditService} from "../../services/location-edit.service";

@Component({
  selector: 'app-modal-store-code',
  templateUrl: './modal-store-code.component.html',
  styleUrls: ['./modal-store-code.component.scss']
})
export class ModalStoreCodeComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ModalStoreCodeComponent>,
    private locationEditS: LocationEditService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

  hasAllCharacters() {
    return this.data.storeCode.length == 64;
  }

  apply() {
    this.locationEditS.locationEdit.storeCode = this.data.storeCode;
    this.locationEditS.setAndUpdate().toPromise().then(()=>{this.dialogRef.close();});
  }
}
