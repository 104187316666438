// dep
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AngularFireStorage } from '@angular/fire/storage';
import {Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

// app
import {InsightsService} from '../../services/insights.service';
import {Pageable} from '../../constants/pageable';
import {Pagination} from '../../constants/api-response';
import {DataPicker} from '../../constants/data-picker';
import {MULTIKEY_METRIC, MULTIKEY_METRIC_V2_NEW} from '../../constants/google/location-stats';
import {PdfService} from 'src/app/services/pdf.service';
import {Sort} from '../../constants/sort';
import {SnackbarService} from "../../services/snackbar.service";
import { LoadingService } from 'src/app/services/loading.service';
import { ReportService } from 'src/app/services/report.service';
import { LocationRef } from 'src/app/constants/firestore/location-object';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-table-multilocations',
  templateUrl: './table-multilocations.component.html',
  styleUrls: ['./table-multilocations.component.scss']
})
export class TableMultilocationsComponent extends BaseComponent implements OnInit, AfterViewInit, OnChanges {

  @Input('locations') externalLocations: LocationRef[];
  @Input('dataPicker') dataPicker: DataPicker;
  @Input('refresh') refresh: Observable<any>;
  @Input('disableIcons') disableIcons = false;
  @Input() reportType = '';
  @Input() reportId: string;
  @Input() reportName = '';

  public progress = true;
  public isEmpty = true;
  public showScrollbar = false;
  public isOverflowY = false;
  public move: any;
  public dialog: MatDialog;

  // data for table
  public displayedColumns: string[];
  public displayedMetricColumns: any;
  public dataSource: any;
  // custom pagination elements
  public manualPage: number;
  public errorMessage: boolean;
  public sort: Sort;
  public pagination: Pagination;
  public isProgressCSV = false;
  public viewModeChecked = 'legacy';
  
  private _pageable: { size: number; page: number } = {size: 10, page: 1};

  constructor(
    private _loadingService: LoadingService,
    private _detectStrategy: ChangeDetectorRef,
    private _insightS: InsightsService,
    private _pdfS: PdfService,
    private _afsStorage: AngularFireStorage,
    private _snack: SnackbarService,
    private _reportS: ReportService,
    private _sessionS : SessionService
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.refresh) {
      this._subscribeSafe(this.refresh, 
        result => {
        this.getData(this._pageable);
      });
    }

    this.getData(this._pageable);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataPicker?.currentValue) {
      this.dataPicker = changes.dataPicker?.currentValue;
    }

    if (changes.externalLocations?.currentValue) {
      this.externalLocations = changes.externalLocations?.currentValue;
    }

    this.getData(this._pageable);
  }

  public ngAfterViewInit(): void {
    this.manualPage = 1;
    this.errorMessage = false;
    this._detectStrategy.detectChanges();
  }

  public getData($event?): void {
    this.viewModeChecked = this.reportType.includes('performance') ? 'new' : 'legacy';
    
    if (this.externalLocations) {
      this.progress = true;

      if ($event) {
        this._pageable = $event;
      }
      let sort;
      if (this.sort && this.sort.sortBy) {
        sort = this.sort;
      }
      const metricsList = this.viewModeChecked == 'new' ? MULTIKEY_METRIC_V2_NEW : MULTIKEY_METRIC;
      let metrics = [];
      this.displayedMetricColumns = {};
      metricsList.forEach(metric => {
        metrics.push({id: _.camelCase(metric.name), name: metric.name});
      });
      if (this.reportType == 'performance-rollup' || this.reportType == 'performance-comparison') {
        metrics = [{id: 'location', name: 'Location'}, ...metrics];
      } else {
        metrics = [{id: 'location', name: 'Location'}, ..._.reject(metrics, {'id': 'averageStarRating'})];
      }
      metrics.forEach(metric => {
        this.displayedMetricColumns[metric.id] = metric.name;
      });
      this.displayedColumns = metrics.map(res => res.id);
      this.dataSource = new MatTableDataSource([]);
      if (this.viewModeChecked == 'new') {
        this._reportS.getTableData(this._sessionS.getSession().gid, this.reportType, this._pageable, this.sort, this.dataPicker)
        .subscribe(
          result => {
            const data = result?.data

            this.pagination = {
              items: data['items'],
              page: data['page'],
              pages: data['totalPages'],
              per_page: data['pageSize'],
              total: data['total'],
              hasNext: data['hasNext'],
              hasPrev: data['hasPrev']
            };
            this._loadingService.reportAdvanced(1, 'Insights Resume');
            this.removeFoodOrdersColumns(data['items']);
            this._updateTable(data['items'], this.displayedColumns);
            this.progress = false;
          },
          err => {
            this.progress = false;
          }
        )
      } else {
        if (!sort) {
          sort = {
            direction: 'ACTIONS_DRIVING_DIRECTIONS',
            sortBy: 'asc'
          }
        }
        this._insightS.resume(this.externalLocations, this._pageable, this.dataPicker, sort)
        .subscribe((result: Pagination) => {
          result.items = result.items.filter(r => 'locationName' in r)
          this.progress = false;
          this.pagination = result;
          this._loadingService.reportAdvanced(1, 'Insights Resume')
          this._updateTable(result.items, this.displayedColumns);
        },
        err => {
          this.progress = false;
        });
      }
    }
    this.showScrollbar = !this.checkScrollbar();
  }

  public removeFoodOrdersColumns(items): void {
    const propertiesToRemove = ['BOOKINGS', 'CONVERSATION', 'FOOD_MENU_CLICKS', 'FOOD_ORDERS'];

    for (const prop of propertiesToRemove) {
      if (!items[0]?.hasOwnProperty(prop)) {
        const field = prop == 'CONVERSATION' ? 'messages' : prop == 'FOOD_MENU_CLICKS' ? 'menu_views' : prop;
        const formattedProp = _.camelCase(field);
        const index = this.displayedColumns.findIndex(el => el == formattedProp);
        
        if(index > -1){
          this.displayedColumns.splice(index, 1);
        }
        delete this.displayedMetricColumns[formattedProp];
        
      }
    }
  }

  public locationUrl(accountId: string, locationId: string, destiny: string): string {
    return `/account/${accountId}/location/${locationId}/${destiny}`;
  }

  public checkScrollbar(): boolean {
    const scrollableElem = document.createElement('div');
    const innerElem = document.createElement('div');
    scrollableElem.style.width = '30px';
    scrollableElem.style.height = '30px';
    scrollableElem.style.overflow = 'scroll';
    scrollableElem.style.borderWidth = '0';
    innerElem.style.width = '30px';
    innerElem.style.height = '60px';
    scrollableElem.appendChild(innerElem);
    document.body.appendChild(scrollableElem);
    const diff = scrollableElem.offsetWidth - scrollableElem.clientWidth;
    document.body.removeChild(scrollableElem);
    return diff > 0;
  }

  // when table is scrolled right via button click
  public moveRight(): void {
    this._scrollTable('right');
  }

  // when table is scrolled left via button click
  public moveLeft(): void {
    this._scrollTable('left');
  }

  // when table is scrolled via the scrollbar
  public scrollTable(): void {
    this._scrollTable('');
  }
  
  public isOverflownVertically(element): boolean {
    if (element && element.length > 0) {
      const scroll = element[0].scrollHeight || 0
      const client = element[0].clientHeight || 0
      return scroll > client;
    }
    return false
  }
  
  public handleReload($event: Pageable): void {
    this.getData($event);
  }
  
  public exportPDF(): void {
    this._pdfS.exportTableToPDF('#table-location-container', 'Multilocations-PDF', 'Multi Profiles Table');
  }
  
  public exportCSV(): void {
    this.isProgressCSV = true;
    
    this._reportS.resumeExportCsv(this._sessionS.getSession().gid, this.reportType, this.sort, this.dataPicker, this.reportName)
    .pipe(
      switchMap(result => this._afsStorage.ref(result['data']).getDownloadURL())
    )
    .subscribe(
      filename => {
        this._insightS.openDownloadInNewTab(filename);
        this.isProgressCSV = false;
        this._snack.openSuccess("The file exported successfully")
    },
    err => {
      this.isProgressCSV = false;
      console.error(err);
      this._snack.openError("The file export failed. Please try again later.")
    });
    
  }
    
  public sortBy($event): void {
    this.sort = {
      sortBy: $event.active,
      direction: $event.direction,
    };
    if ($event.direction === '') 
      this.sort = null;
    
    this.handleReload({page: 1, size: this.pagination.per_page || 10})
    
  }
  
  public resetSort(): void {
    this.sort = null;
    this.getData();
  }

  private _updateTable(resume, columns): void {
    // data for table
    this.dataSource = new MatTableDataSource(resume);
    this.isEmpty = !(this.dataSource.data.length);

    if (!this.isEmpty) {
      const table = document.getElementsByClassName('js-table-scroll');
      this.isOverflowY = this.isOverflownVertically(table);
    }
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  // check visibility of the left and right scroll buttons while scrolling
  private _checkButtonsOnScroll(table, btnLeft, btnRight): void {
    btnLeft.style.display = (table.scrollLeft > 0) ? 'block' : 'none';
    btnRight.style.display = (table.scrollLeft < (table.scrollWidth - table.clientWidth)) ? 'block' : 'none';
  }
    
  private _scrollTable(direction): void {
    const table = document.getElementsByClassName('js-table-scroll')[0];
    const btnLeft  = document.getElementsByClassName('js-table-scroller-left')
    const btnRight = document.getElementsByClassName('js-table-scroller-right')
    if (direction === 'left') {
      this._scrollLeft(table, btnLeft[0], btnRight[0]);
    } else if (direction === 'right') {
      this._scrollRight(table, btnLeft[0], btnRight[0]);
    } else {
      this._checkButtonsOnScroll(table, btnLeft[0], btnRight[0]);
    }
  }

  // scroll the table left
  private _scrollLeft(table, btnLeft, btnRight): void {
    table.scrollLeft -= 150;
    this._checkButtonsOnScroll(table, btnLeft, btnRight);
  }

  // scroll the table right
  private _scrollRight(table, btnLeft, btnRight): void {
    table.scrollLeft += 150;
    this._checkButtonsOnScroll(table, btnLeft, btnRight);
  }
}
