<app-reports-filter
  [reportName]="reportName"
  [locations]="locations"
  [hasToggle]="true"
  [aggregation]="report?.aggregation"
  [lockDates]="lockDates"
  (export)="handleExport($event)"
  (statusCheck)="statusChecks($event)"
  [selectedDate]="selectDate"
  [type]="'performance-rollup'"
  [isShared]="shared"
  [clearChecks]="checks"
  [accountId]="accountId"
  [hasFoodOrdering]="hasFoodOrdering"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [stats]="stats"
  [multiChartSelected]="report?.multiChart"
  [subscribeToDatepicker]="isDatePickerFirstOpened"
  [showTooltipsOnDisabledDates]="showTooltipsOnDisabledDates"
  [hasDateLimitsInfo]="true"
  [isReport]="isReport"
  (dataPicker)="handleDataPicker($event)"
  (refresh)="handleRefresh()">
</app-reports-filter>

<mat-progress-bar mode="indeterminate" *ngIf="isProgressCSV"></mat-progress-bar><!-- //NOSONAR -->

<div id="charts-container" [class.view-without-padding]="!isReport" class="container--full container--padding-lg pb--0">
    <h2 *ngIf='reportName'><strong>{{reportName|uppercase}}</strong></h2>
    <app-skeleton-insights *ngIf="isProgress"></app-skeleton-insights>

    <div  *ngIf="!isProgress && stats?.length > 0" >
      <app-chart-multi [dataset]="multiChartDataSet" [commaNumbers]="true" [labels]="labels" [noDecimals]="true" *ngIf="multiChartDataSet?.length>0" (checks)="clearChecked($event)"></app-chart-multi>
      <ng-container *ngIf="stats">
        <ng-container *ngFor="let stat of stats">
          <app-chart-stats 
            *ngIf="stat?.totals > 0"
            [reportType]="typeReport"
            [stats]="stat"
            [labels]="stat?.labels"
            [dataPicker]="dataPicker"
            [showRightProgressBar]="true"
            [hasDonutGraph]="false"
            [centerLegend]="true"
          ></app-chart-stats>
        </ng-container>

        <div class="col-12 mb--30 p--15">
          <div class="box box--rounded box--shadow bg--white row">
            <div class="align-items-center justify-content-start p--20 col-12">
              <h2 class="txt--md m--0 mb--15"><strong>Total Views</strong></h2>
              <p class="d--inl-block m--0 txt--price fw--regular">{{viewsStats?.totals | number: '1.0-0'}}</p>
            </div>

            <div class="col-6" *ngFor="let stat of viewsStats?.items">
              <app-chart-stats 
                *ngIf="stat?.totals > 0"
                [reportType]="typeReport"
                [stats]="stat"
                [labels]="stat?.labels"
                [dataPicker]="dataPicker"
                [hasDonutGraph]="false"
                [centerLegend]="true"
                [hasMultipleChart]="true"
              ></app-chart-stats>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-12 col-lg-6 mb--30">
            <app-reviews-average-rating [dataPicker]="dataPicker" [locations]="locations" [averageData]="averageData" *ngIf="locations && dataPicker && averageData?.total > 0"></app-reviews-average-rating>
          </div>
          <div class="col-12 col-lg-6 mb--30">
            <app-reviews-number [dataPicker]="dataPicker" [locations]="locations" [reviewsData]="reviewsData" *ngIf="locations && dataPicker && reviewsData?.total > 0"></app-reviews-number>
          </div>
        </div>

      </ng-container>
    </div>

    <div class="box--height mb--30"  *ngIf="(stats?.length === 0 && !isProgress)">
      <div class="box box--rounded box--shadow bg--white">
        <div class="box__header d-flex align-items-center justify-content-center">
          <h2 class="txt--capitalize"><strong>{{hasNoDate()}}</strong></h2>
        </div>
      </div>
    </div>
</div>

<app-table-multilocations 
      *ngIf="isReport"
      [reportType]="'performance-rollup'"
      [reportId]="reportId"
      [reportName]="reportName"
      [dataPicker]="reportDataPicker"
      [locations]="reportDataPicker?.locations"
      [refresh]="refresh" 
      [disableIcons]="shared && showMultilocations"
    >
</app-table-multilocations>

<!-- view mode toggle -->
<!-- <div *ngIf="!isReport" class="container--full container--padding-lg pb--0">
  <div class="box box--rounded box--shadow bg--white p--15">
    <div class="row">
      <div class="col-8">
        <span class="d-inline-block mt--10 ml--10 txt--normal mr--15 mb--0">As of February 2023, Google deprecated the legacy insights on v4.9 via the Google Business Profile API but are still available via the legacy view for metrics recorded prior to December 31, 2022.</span>
      </div>
      <div class="col-4 txt--align--end">
        <span class="d-inline-block txt--normal ml--10 mr--15 mb--0">Insights View:</span>
        <mat-button-toggle-group [value]="viewModeChecked" appearance="legacy" name="viewMode" (change)="changedViewMode($event)">
          <mat-button-toggle value="new">
            New
          </mat-button-toggle>
          <mat-button-toggle value="legacy">
            Legacy
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
</div> -->


