import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';

import { Subject, Observable, BehaviorSubject } from 'rxjs';

import { IDateRange, ISelectedDateRange, Granularity, IPreset, Preset, IComparison, IComparisonItem } from './../../../constants/data-picker';
import { Injectable } from '@angular/core';


/* Definition of abstract service to be implemented by each report service
*
*/

@Injectable({
  providedIn: 'root'
})
export abstract class ReportsFilterConfigService {

  private _dateRestrictionSubject = new Subject<IDateRange>();
  protected _datePresetsSubject = new BehaviorSubject<IPreset[]>([]); 
  protected _dateComparisonSubject = new BehaviorSubject<{}>({});
  protected _comparisonPresetsSubject = new BehaviorSubject<IComparison[]>([]); 
  protected _granularitySubject = new Subject<Granularity>();
  protected _starterMinDate: Date;
  protected _starterMaxDate: Date;
  protected _presets: IPreset[] = [];
  protected _comparison: IComparison[] = [];


  constructor(
    protected _http: HttpClient
  ) { }

  get dateRestrictionSubject$(): Observable<IDateRange> {
    return this._dateRestrictionSubject.asObservable();
  }

  get datePresetsSubject$(): Observable<any> {
    return this._datePresetsSubject.asObservable();
  }

  get dateComparisonSubject$(): Observable<any> {
    return this._dateComparisonSubject.asObservable();
  }

  get comparisonPresetsSubject$(): Observable<any> {
    return this._comparisonPresetsSubject.asObservable();
  }

  get granularitySubject$(): Observable<Granularity> {
    return this._granularitySubject.asObservable();
  }

  initializeDateRestriction(dateRangeLimits: IDateRange, reportType: string): void {
    let newDateRangeLimits = {
      minDate: new Date(dateRangeLimits.minDate), 
      maxDate: new Date(dateRangeLimits.maxDate)
    };
    
    // definition of new date variables
    let newMaxDate: Date = null;
    let newMinDate: Date = null;

    // definition of calculation variables
    const today = new Date();
    const todayDay = today.getDate();
    const todayMonth = today.getMonth();

    const currentMaxDate = new Date(newDateRangeLimits.maxDate);
    const currentMaxDateDay = currentMaxDate.getDate();
    const currentMaxDateMonth = currentMaxDate.getMonth();
    const currentMaxDateYear = currentMaxDate.getFullYear();

    const currentMinDate = new Date(newDateRangeLimits.minDate);
    const currentMinDateDay = currentMinDate.getDate();
    const currentMinDateMonth = currentMinDate.getMonth();
    const currentMinDateYear = currentMinDate.getFullYear();

    // first convert currentMaxDate to be the last day of the previous month, if its not already the last day of its month
    if (currentMaxDateDay !== new Date(currentMaxDateYear, currentMaxDateMonth + 1, 0).getDate()) { // not the last day of the month
      newMaxDate = new Date(currentMaxDateYear, currentMaxDateMonth, 0); // last day of the previous month
      newDateRangeLimits = {...newDateRangeLimits, maxDate: newMaxDate}
    } else {
      newMaxDate = currentMaxDate;
    }
    
    // if today is between 1 and 6 of the month and currentMaxDateMonth is this month, we substract one month from newMaxDate
    // only applies for keywords and performance reports
    
    if ((reportType === 'keyword' || reportType === 'insights') 
      && todayDay >= 1 && todayDay <= 6 
      && ((newDateRangeLimits.maxDate.getMonth() === todayMonth) || (newDateRangeLimits.maxDate.getMonth() === todayMonth - 1))) {

      if (newDateRangeLimits.maxDate.getMonth() === 0) {
        newMaxDate = new Date(newMaxDate.getFullYear() - 1, 11, 31);
      } else {
        newMaxDate = new Date(newMaxDate.getFullYear(), newMaxDate.getMonth(), 0); // last day of the previous month
      }
      newDateRangeLimits = {...newDateRangeLimits, maxDate: newMaxDate}
    }

    // we convert the currentMinDate to be the first day of the next month, if its not already the first day of its month
    if (currentMinDateDay !== 1) { // not the first day of the month
      if (currentMinDateMonth === 11) { // last month
        newMinDate = new Date(currentMinDateYear + 1, 0, 1);
      } else {
        newMinDate = new Date(currentMinDateYear, currentMinDateMonth + 1, 1);
      }
      newDateRangeLimits = {...newDateRangeLimits, minDate: newMinDate}
    }

    // we could end up with newMaxDate or newMinDate being null, if validations were unsuccessfull
    // that is OK, it means there are no valid date ranges
    this._starterMinDate = newDateRangeLimits.minDate;
    this._starterMaxDate = newDateRangeLimits.maxDate;

    this._dateRestrictionSubject.next(newDateRangeLimits);
  }

  emitDateRestriction(dateRestriction: IDateRange): void {
    if (dateRestriction) {
      this._dateRestrictionSubject.next(dateRestriction);
    }
  }

  getDateValidation(reportType : string, accountIds : string[], gids : string[], locationIds : string[]): Observable<any> {
    const data = {
      "locationIds": locationIds,
      "accountIds": accountIds,
      "gids": gids,
      "type": reportType
      
      }
    return this._http.post(`${environment.apiUrl}/v2/locations/date-validation`, data)
  }

  /**
   * Builds the necessary presets for the datePicker. As of right now it is defined to work with keywords
   * In the future, the instance of this service could define which date presets should be available
   * After validation, it pushes the values through the datePresetsSubject
   */
  getDatePresets(): void {
    // Previous month
    // Previous 3 months
    // Previous 6 months
    // Previous 9 months
    // Previous 12 months
    // Previous 18 months
    // Year to date
    // Last year
    // All data

    // const currentDate = new Date(2025, 1, 7); // test
    this._presets = [];

    const currentDate = new Date();

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const firstDayOfCurrentYear = new Date(currentDate.getFullYear(), 0, 1);
    const offset = currentDay > 6 ? 0 : 1; // we subtract one more month when we are between 1 - 6
    const lastDayOfPreviousValidMonth = new Date(currentYear, currentMonth - offset, 0); 
    

    if (this._starterMinDate instanceof Date && this._starterMaxDate instanceof Date) {
        //test purposes
        // this._starterMinDate = new Date(2024, 0, 1);
        // this._starterMaxDate = new Date(2024, 11, 31);

        const minDate = new Date(this._starterMinDate);
        const maxDate = this._starterMaxDate >= lastDayOfPreviousValidMonth ? new Date(lastDayOfPreviousValidMonth) : new Date(this._starterMaxDate);

        // Previous Month
        const firstDayOfPreviousValidMonth = new Date(maxDate);
        firstDayOfPreviousValidMonth.setDate(1);

        if (minDate <= firstDayOfPreviousValidMonth) {
          this._buildPreset(Preset.LAST_MONTH, 'Previous Month', firstDayOfPreviousValidMonth, maxDate);
        }

        // Previous 3 months
        const threeMonthsAgo = new Date(maxDate);
        threeMonthsAgo.setDate(1);
        threeMonthsAgo.setMonth(maxDate.getMonth() - 2);

        if (minDate <= threeMonthsAgo) {
          this._buildPreset(Preset.LAST_3_MONTHS, 'Previous 3 months', threeMonthsAgo, maxDate);
        }

        // Previous 6 months
        const sixMonthsAgo = new Date(maxDate);
        sixMonthsAgo.setDate(1);
        sixMonthsAgo.setMonth(maxDate.getMonth() - 5);

        if (minDate <= sixMonthsAgo) {
          this._buildPreset(Preset.LAST_6_MONTHS, 'Previous 6 months', sixMonthsAgo, maxDate);
        }

        // Previous 9 months
        const nineMonthsAgo = new Date(maxDate);
        nineMonthsAgo.setDate(1);
        nineMonthsAgo.setMonth(maxDate.getMonth() - 8);

        if (minDate <= nineMonthsAgo) {
          this._buildPreset(Preset.LAST_9_MONTHS, 'Previous 9 months', nineMonthsAgo, maxDate);
        }

        // Previous 12 months
        const twelveMonthsAgo = new Date(maxDate);
        twelveMonthsAgo.setDate(1);
        twelveMonthsAgo.setMonth(maxDate.getMonth() + 1);
        twelveMonthsAgo.setFullYear(maxDate.getFullYear() - 1);

        if (minDate <= twelveMonthsAgo) {
          this._buildPreset(Preset.LAST_12_MONTHS, 'Previous 12 months', twelveMonthsAgo, maxDate);
        }

        // Previous 18 months
        const eighteenMonthsAgo = new Date(maxDate);
        eighteenMonthsAgo.setFullYear(maxDate.getFullYear() - 1);
        eighteenMonthsAgo.setDate(1);
        eighteenMonthsAgo.setMonth(maxDate.getMonth() - 5);

        if (minDate <= eighteenMonthsAgo) {
          this._buildPreset(Preset.LAST_18_MONTHS, 'Previous 18 months', eighteenMonthsAgo, maxDate);
        }


        // Last year
        const firstDayOfPreviousYear = new Date(maxDate);
        firstDayOfPreviousYear.setFullYear(currentYear - 1);
        firstDayOfPreviousYear.setDate(1);
        firstDayOfPreviousYear.setMonth(0);

        const lastDayOfPreviousYear = new Date(firstDayOfPreviousYear);
        lastDayOfPreviousYear.setMonth(11);
        lastDayOfPreviousYear.setDate(31);

        if (minDate <= firstDayOfPreviousYear && lastDayOfPreviousYear <= maxDate) {
          this._buildPreset(Preset.LAST_YEAR, 'Last year', firstDayOfPreviousYear, lastDayOfPreviousYear);
        }

        // YTD: we ensure that maxDate is in this year
        const maxYear = maxDate.getFullYear();

        if (currentYear === maxYear) { 
          const correctMinDate = minDate > firstDayOfCurrentYear ? minDate : firstDayOfCurrentYear;
          this._buildPreset(Preset.YEAR_TO_DATE, 'Year to Date', correctMinDate, maxDate);
        }

        // All data
        this._buildPreset(Preset.ALL_TIME, 'All data', minDate, maxDate);
    }
    
    this._datePresetsSubject.next(this._presets)

  }

  private _buildPreset(presetID: Preset, name: string, minDate: Date, maxDate: Date): void {
    if (minDate > maxDate) {
      return;
    }
    this._presets.push({
      presetID,
      name,
      dateRange: {maxDate, minDate }
    })
  }

  validateComparisonPresets(dates: IDateRange): void {
    this._comparison = [];
    let isEnabled = false;

    // Group headers
    const compareCurrentRangeTo: IComparisonItem[] = [];
    const quickComparison: IComparisonItem[] = [];

    // "None" option
    this._comparison.push({
      header: null,
      items: [{ comparisonID: null, name: 'None', enabled: true }]
    });

//  QUIZAS CONVIENE ESTO GUARDARLO EN UNA VARIABLE ASI NO LO REPETIMOS //
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const offset = currentDay > 6 ? 0 : 1;
    const lastDayOfPreviousValidMonth = new Date(currentYear, currentMonth - offset, 0);
    
    const minDate = new Date(this._starterMinDate);
    const maxDate = this._starterMaxDate >= lastDayOfPreviousValidMonth ? new Date(lastDayOfPreviousValidMonth) : new Date(this._starterMaxDate);

    const startDatepicker = dates.minDate;
    const endDatepicker = dates.maxDate;
    
    // Previous period
    const diff = endDatepicker.getTime() - startDatepicker.getTime();
    const previousStartDate = new Date(startDatepicker.getTime() - diff);
    const previousEndDate = new Date(endDatepicker.getTime() - diff);
    const isPreviousStartDateInRange = previousStartDate >= minDate && previousStartDate <= maxDate;
    const isPreviousEndDateInRange = previousEndDate >= minDate && previousEndDate <= maxDate;
    isEnabled = isPreviousStartDateInRange && isPreviousEndDateInRange

    this._comparison[0].items.push({ comparisonID: Preset.PREVIOUS_PERIOD, name: 'Previous Period', enabled: isEnabled })
    // this.buildComparisonPresets('Previous Period', isEnabled, Preset.PREVIOUS_PERIOD, compareCurrentRangeTo);

    // Previous month
    const previousMonthStartDate = new Date(startDatepicker.getFullYear(), startDatepicker.getMonth() - 1, startDatepicker.getDate());
    const previousMonthEndDate = new Date(endDatepicker.getFullYear(), endDatepicker.getMonth() - 1, endDatepicker.getDate());

    const isPreviousMonthStartDateInRange = previousMonthStartDate >= minDate && previousMonthStartDate <= maxDate;
    const isPreviousMonthEndDateInRange = previousMonthEndDate >= minDate && previousMonthEndDate <= maxDate;
    isEnabled = isPreviousMonthStartDateInRange && isPreviousMonthEndDateInRange;

    this._comparison[0].items.push({ comparisonID: Preset.MONTH_TO_MONTH, name: 'Previous Month', enabled: isEnabled })
    // this.buildComparisonPresets('Month to month', isEnabled, Preset.MONTH_TO_MONTH, quickComparison);
    
    // Previous year
    const previousYearStartDate = new Date(startDatepicker.getFullYear() - 1, startDatepicker.getMonth(), startDatepicker.getDate());
    const previousYearEndDate = new Date(endDatepicker.getFullYear() - 1, endDatepicker.getMonth(), endDatepicker.getDate());

    const isPreviousYearStartDateInRange = previousYearStartDate >= minDate && previousYearStartDate <= maxDate;
    const isPreviousYearEndDateInRange = previousYearEndDate >= minDate && previousYearEndDate <= maxDate;

    isEnabled = isPreviousYearStartDateInRange && isPreviousYearEndDateInRange;

    this._comparison[0].items.push({ comparisonID: Preset.YEAR_TO_YEAR, name: 'Previous Year', enabled: isEnabled })
    // this.buildComparisonPresets('Year to year', isEnabled, Preset.YEAR_TO_YEAR, quickComparison);

    // Add grouped data to _comparison
    // this._comparison.push({ header: 'Compare current range to', items: compareCurrentRangeTo });
    // this._comparison.push({ header: 'Quick Comparison', items: quickComparison });

    this._comparisonPresetsSubject.next(this._comparison)
  }

  buildComparisonPresets(name: string, enabled: boolean, comparisonID: Preset, group: any[]): void {
    group.push({
        comparisonID,
        name,
        enabled
    });
  }

  /**
   * Helper function to decide Granularity if the date range is less than 2 months 
   */
  buildGranularity(startDate: Date, endDate: Date): Granularity {
    if (!startDate || !endDate) {
      return Granularity.Month; // default in case of error
    }

    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();
    const monthsDifference = (endYear - startYear) * 12 + (endMonth - startMonth);

    const granularity = monthsDifference < 2 ? Granularity.Day : Granularity.Month;
    
    this._granularitySubject.next(granularity); // can be done better, but will work for now
    
    return granularity;
}

  /**
   * Required to initialize the filterConfig object
   * each instance can define its own requirements/transformations for this
   */
  abstract setInitialFilterConfigData(...args: any[]): void

  /**
   * Provides a method to update the filterConfig object
   * and call the associated reportData service to retrieve
   * new report data based on the new filter parameters
   */
  abstract updateFilterConfigAndGetReportData(...args: any[]): void
}

