import { Directive, ElementRef, HostListener, Input } from '@angular/core';

const PHONE_REGEX = /\b(\+?\d{1,3}(\s?\(\d{1,}\))?)?\s?\d{3,}[-.\s]?\d{3,}[-.\s]?\d{4}\b/g;
const EMAIL_REGEX = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;

@Directive({
  selector: '[appEmailPhoneValidation]'
})
export class EmailPhoneValidationDirective {
  @Input() appEmailPhoneValidation: string;

  constructor(private el: ElementRef) { }

  @HostListener('input') 
  onInput() : void {
    let text = this.el.nativeElement.value;
    text = text.replaceAll(/[\(\)"\s-]/g, "");
    const errorContainer = document.getElementById(this.appEmailPhoneValidation);

    if (EMAIL_REGEX.test(text) || PHONE_REGEX.test(text)) {
      errorContainer.textContent = 'Please refrain from entering phone numbers or email addresses. Remove any existing contact information.'
    } else {
      errorContainer.textContent = null;
    }
  }

}
