// See https://docs.datadoghq.com/real_user_monitoring/browser/#typescript
import '@datadog/browser-rum/bundle/datadog-rum'
import '@datadog/browser-logs/bundle/datadog-logs'

import './polyfills';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// app
import { AppModule } from './app/app.module';
import { environment } from '@environment';

const DATADOG_OPTS = {
  clientToken: 'pubd2fb0d8206d05a9ad5110272ea710668',
  site: 'datadoghq.com',
  env: 'production',
  service: 'map-labs',
} as const;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

const isProdContext = !window.location.hostname.endsWith(".dev") && !window.location.hostname.endsWith("localhost");

if (environment.production && isProdContext) {
  // @ts-ignore: TS2339
  window.DD_RUM.init({
    ... DATADOG_OPTS,
    applicationId: '9f685936-f530-48a4-9b57-9da85cc0f74b',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      {match: /https:\/\/(?!events\.).*\.maplabs\.com/, propagatorTypes: ["tracecontext"]},
    ],
    traceSampleRate: 20,
  });
  // @ts-ignore: TS2339
  window.DD_RUM.startSessionReplayRecording();

  // https://docs.datadoghq.com/logs/log_collection/javascript/
  // @ts-ignore: TS2339
  window.DD_LOGS.init({
    ...DATADOG_OPTS,
    forwardErrorsToLogs: true,
    forwardConsoleLogs : 'all',
    // sampleRate was renamed to sessionSampleRate, using both for 
    // version compatibility.
    sampleRate: 100, 
    sessionSampleRate: 100,
  });

}

console.log('\n------------------------------------------------------------------------------')
console.debug('Environment:', environment)

// Third-party domains cookies and HttpOnly cookies aren't shown
if( !isProdContext ) {
  console.debug('STORAGE: cookies (some): ' +       document.cookie.split('; ').map(c => c.split('=')[0]));
  console.debug("STORAGE: localStorage keys: " +    Object.keys(localStorage));
  console.debug("STORAGE: sessionStorage keys: " +  Object.keys(sessionStorage));
  (indexedDB as any).databases().then(dbs => 
    console.debug('STORAGE: indexedDB names: ' + dbs.map(db => db.name)));
}