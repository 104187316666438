// dep
import { Title } from '@angular/platform-browser';
import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable, of} from 'rxjs';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';

// app
import { ReportService } from '../../services/report.service';
import { DataPicker } from '../../constants/data-picker';
import { AuthService } from '../../services/auth.service';
import SavedLocation from '../../constants/firestore/saved-location';
import Report from '../../constants/firestore/report';
import { DataTransferService } from '../../services/data-transfer.service';
import { LocationService } from '../../services/location.service';
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE } from 'src/app/constants/firestore/account-location';
import { ValidateLocations } from 'src/app/shared/Auxiliary/validate-locations';
import AccountReport from '../../services/account-report';
import { LoadingService } from '../../services/loading.service';
import { DialogDeleteLocationsComponent } from 'src/app/components/dialog-delete-locations.component';
import { ModalService } from 'src/app/services/modal.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SessionService } from 'src/app/services/session.service';
import { LocationRef } from 'src/app/constants/firestore/location-object';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls:  ['./reports.component.scss']
})
export class ReportsComponent implements OnDestroy {
  public id: string;
  dataPicker: DataPicker;
  stats: any[];
  labels: string[];
  reportType: string;
  locations: SavedLocation[] = [];
  shared = false
  lockDates = false;
  sharedNotAllowed = false;
  report: Report;
  reportId: string;
  gid: string
  refresh = new Subject<boolean>();
  showMultilocations = true;
  contentClass: any;
  viewModeChecked = 'legacy';
  isProcessed = false;
  public minDate = null;
  public maxDate = null
  loadDataObservable$: Observable<{ total: number, completed: string }> = of({ total: 0, completed: null })

  loadingSpinner$ = this._spinnerS.loading$;

  constructor(
    private _router: Router,
    public route: ActivatedRoute,
    private reportS: ReportService,
    private _authS: AuthService,
    private _sessionS : SessionService,
    private titleService: Title,
    private dataTrasnfer: DataTransferService<string>,
    private locationService: LocationService,
    private validateLocation: ValidateLocations,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private modalService: ModalService,
    private _spinnerS: SpinnerService,
    private snackS: SnackbarService,
    private _navigationS: NavigationService,
    private _subscriptionS : SubscriptionService
  ) {
    this._spinnerS.loading$.next(true);
    this.reportType = this.route?.snapshot.params?.reportType;
    this.reportType = (
      this.reportType?.includes('-location') ? 
      this.reportType?.replace('-location', '') : 
      this.reportType?.includes('-report') ?
      this.reportType?.replace('-report', '') :  
      this.reportType
    );
    this.viewModeChecked = (this.reportType?.includes('performance') || 
                            this.reportType?.includes('review') || 
                            this.reportType?.includes('keyword') || 
                            this.reportType?.includes('qanda')) ? 'new' : 'legacy';
    
    console.debug(`reportType=${this.reportType} viewModeChecked=${this.viewModeChecked}`);
    
    this._checkAuthAndLoad();
  }

  private async _checkAuthAndLoad() : Promise<void> {
    if (this.route?.snapshot?.params?.hash) {
      await this._loadRouteWithHash(this.route.snapshot.params.hash);
    } else {
      const automatedReport = this.route?.snapshot?.params?.automatedReport;
      if (automatedReport && this.viewModeChecked == 'legacy') {
        await this._loadAutomatedData(automatedReport);
      } else {
        await this._loadData(automatedReport);
      }
    }
  }

  private async _loadRouteWithHash(hash : string) : Promise<void>  {
    try {
      const report = await this.reportS.fetchByHash(hash);
      this.id  = report.id;
      this.gid = report.gid;
      if(await this._ensureAnonymous())
        await this._router.navigate([`/report/${this.gid}/${this.id}/shared`])
    } catch(err) {
      console.error('error on:', err);
      this._spinnerS.loading$.next(false);
    }
  }

  private async _ensureAnonymous() {
   const auth = await this._authS.waitAuthSession();

    if (!auth) {
      await this._authS.signInAnonymously(this.gid); 
    } else if(auth.sessionType !== 'ANONYMOUS' || auth.gid !== this.gid) {
      await this._showNotSupportedModal();
      return false
    }
    return true
  }

  private async _loadAutomatedData(automated: string) : Promise<void>  {
    this.gid = this.route.snapshot?.params?.gid;
    this.id = automated;
    this.reportId = this.id;
    this.shared = true;
    this.lockDates = this.report?.lockDates || false;
    if(await this._ensureAnonymous())
      this.getAutomatedReport(this.gid, automated, true);
  }

  async AlloweReport(report: Report) : Promise<void> {
    if (_.isEmpty(report.accounts)) 
      return

    try {
      await this.locationService.basicLocations([{ accounts: report.accounts }])

      const sub = await this._subscriptionS.fetchSubscription(report.gid);

      if (sub.status == GROUP_SUBSCRIPTION_TYPE.TRIAL)
        return
        
      if (report.sharedOnly) {
        const {subscriptionType} = report.accounts[0].locationsBasics[0];
        if (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL || 
            subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE)
          await this._router.navigate(['/login']);
        
        return;
      }

      for (const account of report.accounts) {
        const hasFreeLocations      = !_.isEmpty(_.filter(account.locationsBasics, { subscriptionType: LOCATION_SUBSCRIPTION_TYPE.FREE }));
        const hasEssentialLocations = !_.isEmpty(_.filter(account.locationsBasics, { subscriptionType: LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL }));
        const hasBasicLocations     = !_.isEmpty(_.filter(account.locationsBasics, { subscriptionType: LOCATION_SUBSCRIPTION_TYPE.BASIC }));

        this._subscriptionS.fetchSubscription(account.gid).then(subscription => {
          if (subscription.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL &&
            (hasFreeLocations || hasEssentialLocations || hasBasicLocations)) {
            this._router.navigate(['/login'])
          }
        })

        const hasInvalidAccounts  = !_.isEmpty(_.filter(account.locations, { accountId: 'accounts' }));
        const hasInvalidLocations = !_.isEmpty(_.filter(account.locations, { locationId: 'locations' }));
        if (hasInvalidAccounts || hasInvalidLocations) {
          this._router.navigate(['/login'])
        }

        const hasDuplicated = _.size(_.uniqBy(account.locations, 'locationId')) < _.size(account.locations);
        if (hasDuplicated) {
          this._router.navigate(['/login'])
        }
      }
    } catch(err) {
      console.error(err)
      await this.modalService.openErrorLoadingModal(1);
      await this._navigationS.signOut()
    }
  }

  private async _loadData(automatedReport : string | undefined) : Promise<void> {
    let isAnonymous = false;
    this.id         = automatedReport || this.route.snapshot?.params?.id;
    this.gid        = this.route.snapshot?.params?.gid;
    this.reportId   = this.id;
    this.reportType = (this.reportType == 'performance-insights' ? 'performance-rollup' : this.reportType);

    if (this.route.snapshot?.url?.findIndex(segment => segment.toString() === 'shared') !== -1
        || !(await this._authS.waitAuthSession())) {
      this.shared = true;
      this.lockDates = this.report?.lockDates || false;
      if(!await this._ensureAnonymous())
        return;
      isAnonymous = true;
    }

    if(this.viewModeChecked == 'new') {
      this.getReportMongo(this.gid, this.id, isAnonymous, automatedReport); 
    } else {
      this._getReport(this.gid, this.id, isAnonymous);
    }    

  }

  private async _showNotSupportedModal() : Promise<void> {
    await this.modalService.openWarningModal('Alert',
                                            ('Opening a shared report while logged-in is not supported. '+
                                             'Please use an incognito session or log-out.'))
  }

  private getAutomatedReport(gid : string, reportId : string, isAnonymous = false) {
    this.reportS.getAutomated(gid, reportId)?.pipe(
      take(1)
    ).subscribe( report => {
      this._buildAutomatedReport(report, isAnonymous);
    }, error => {
      this._spinnerS.loading$.next(false);
      console.log(error);
    });
  }

  private _getReport(gid : string, reportId : string , isAnonymous = false) : void {
    this.reportS.get(gid, reportId)?.pipe(
      take(1)
    ).subscribe((report) => {
      this.report = report;
      this._verifyLocations();
      this.buildReport(gid, reportId, isAnonymous, report);
    }, error => {
      console.log(error);
      this.snackS.openError('There was an error while loading the data. Please try again or contact support')
      this._spinnerS.loading$.next(false);
    });
  }

  private async _verifyLocations() : Promise<void> {
    const hasLocations = this.report?.accounts.find(el => el?.locations?.length > 0);
    if (!hasLocations) {
      const reportType = this.reportType == 'keyword' ? 'keywords' : (this.reportType == 'qanda' ? 'qanda' : `${this.reportType}-report`);
      await this._router.navigateByUrl(`${reportType}`)
    }
  }

  private async _buildAutomatedReport(report : Report, isAnonymous : boolean) {
    this.report = report;
      const accounts = report.accounts || [
        {
          gid: this.gid,
          accountId: report?.locations[0]?.accountId,
          locations: report.locations
        }
      ];
      this.report.accounts = await this.validateLocation.validateArrayLocations(accounts);
      this._spinnerS.loading$.next(false);
      const waitingPopup = this._loadDataModal(
        this.report.accounts,
        report.reportType
      );

      if (isAnonymous) 
        await this.AlloweReport(report);

      if (!report) 
        return;

      let ReportTypeName = report.reportType.replace(/\b\w/g, l => l.toUpperCase());
      ReportTypeName = ReportTypeName.toLowerCase().includes('qanda') ? 'Q & A' : ReportTypeName;
      if (this.shared) {
        const {company_name} = this._sessionS.getDomain().branding
        this.titleService.setTitle(`${ReportTypeName} Report | ${company_name}`)
      } else {
        this.dataTrasnfer.setData(`${ReportTypeName} Report | ${report.reportName}`);
      }
      this.processReport(isAnonymous, report, true);
  }

  getReportMongo(gid : string, reportId : string, isAnonymous = false, automatedReport = null) : void { 
    this.reportS.getReportById(reportId, automatedReport)?.pipe(
      take(1)
    ).subscribe(async (report) => {
      if(report?.error) {
        console.log(report?.error);
        this.snackS.openError('There was an error while loading the data. Please try again or contact support');
        this._spinnerS.loading$.next(false);
      } else {
        const id = report?.id || report?._id;  // TODO: Redundant, what was the intention?

        //added it until the new version of back that handles the existence of locations and accounts is integrated
        report.accounts = report?.accounts.filter(r => r.locations.length);
        report.reportType = report?.reportType == 'performance-insights' ? 'performance-rollup' : report.reportType;
        report?.multiChart.forEach(m => m.checked = true)
        this.report = report;
        const accountIds = report.accounts.map(a => a.accountId);
        const locationIds = []; 
        report.accounts.forEach(a => a.locations.forEach(l => locationIds.push(l.locationId)));
        const dateValidations = await this.locationService.getDateValidations(this.reportType, accountIds, [gid], locationIds)
        const dates = this.locationService.dateValidation(dateValidations);
        this.minDate = dates.minDate;
        this.maxDate = dates.maxDate;
        this._verifyLocations();
        this.lockDates = this.report?.lockDates || false
        this.reportId = automatedReport ? report.parentReport : id;
        this.buildReport(gid, id, isAnonymous, report);
      }
    }, error => {
      console.log(error);
      this.snackS.openError('There was an error while loading the data. Please try again or contact support');
      this._spinnerS.loading$.next(false);
    });
  }

  async buildReport(gid : string, id : string, isAnonymous : boolean, report : Report) : Promise<void> {
    const accounts = report?.accounts || [
      {
        gid,
        accountId: report.locations[0].accountId,
        locations: report.locations
      }
    ];

    this.report.accounts = accounts;
    this._spinnerS.loading$.next(false);
    const waitingPopup = this._loadDataModal(this.report.accounts, report.reportType);

    if (await this.existAllLocations(report, id)) {
      this.report.accounts = await this.validateLocation.validateArrayLocations(accounts);
      if (isAnonymous) 
        await this.AlloweReport(report);

      if (!report)
        return;

      let ReportTypeName = report.reportType.replace(/\b\w/g, l => l.toUpperCase());
      ReportTypeName = ReportTypeName.toLowerCase().includes('qanda') ? 'Q & A' : ReportTypeName;
      if (this.shared) {
        const {company_name} = this._sessionS.getDomain().branding;
        this.titleService.setTitle(`${ReportTypeName} Report | ${company_name}`);
      } else {
        this.dataTrasnfer.setData(`${ReportTypeName} Report | ${report.reportName}`);
      }
      this.processReport(isAnonymous, report);
    } else {
      waitingPopup?.close();
    }
  }

  private async existAllLocations(report : Report, reportId : string) : Promise<boolean> {
    const locationsIds : (LocationRef & {gid : string})[] = [];

    for (const account of report.accounts) {
      if (account.locations && account.locations.length > 0) {
        account.locations.forEach(l => {
          locationsIds.push({
            'gid': account.gid,
            'accountId': account.accountId,
            'locationId': l.locationId
          });
        });
      } else {
        return false;
      }
    }

    const AllLocations = await this.locationService.fetchLocationsExistence(locationsIds)
    
    if (AllLocations.some(r => !r.exist)) {

      const DeletedLocations = AllLocations.filter(l => !l.exist)
      const nouns = DeletedLocations.length > 1 ? ['were', 'locations have'] : ['was', 'location has'];
      const textDescription = (`This report used to contain ${AllLocations.length} locations but ${DeletedLocations.length} ` +
                               `${nouns[0]} removed from the system. The missing ${nouns[1]} now been removed from this report.`)
      this.locationService.deleteReportLocation(DeletedLocations, DeletedLocations[0]['gid'], reportId)

      const dialogRef = this.dialog.open(DialogDeleteLocationsComponent, {
        width: '400px',
        data: {
          title: 'Heads Up',
          description: textDescription,
          DeletedLocations,
        }
      });

      dialogRef.disableClose = true;

      dialogRef.afterClosed().subscribe(() => {
        dialogRef.close();
        this.deleteLocations(DeletedLocations);
      });

      return false;
    }

    return true;
  }

  deleteLocations(deletedLocations : LocationRef[]) : void {
    this._spinnerS.loading$.next(true);

    deletedLocations.forEach(el => {
      const indexAccount = this.report?.accounts?.findIndex(a => a.accountId == el.accountId);
      const indexLoc = this.report.accounts[indexAccount].locations?.findIndex(l => l.locationId == el.locationId);
      this.report.accounts[indexAccount].locations?.splice(indexLoc, 1);
    });

    this.report.accounts = this.locationService.deleteServiceArea(this.report.accounts);
    
    if (this.viewModeChecked != 'new') {
      if(this.report?.id) {
        this.reportS.update(false, this.report?.gid, this.report?.id, this.report).then(
          res => {
            location.reload();
          },
        err => {
          this._handleDeleteError();
        });
      } else {
        location.reload();
      }
    } else {
      if (this.reportType == 'performance-comparison') {
        this.report.metrics = this.report.metricsName;
      } 

      this.reportS.updateReportsInMongo(this.report['_id'], this.report).subscribe(
        res => {
          if(res?.['error']) {
            this._handleDeleteError();
          }else {
            location.reload();
          }
        },
        err => {
          this._handleDeleteError();
        }
      );
    }
  }

  private async _handleDeleteError() : Promise<void> {
    this._spinnerS.loading$.next(false);
    await this.modalService.openErrorModal('Heads up', "There was an error while removing the locations.")
    window.location.reload();
  }

  private _loadDataModal(accounts: AccountReport[], type: string) {
    accounts = accounts.filter(Boolean)
    if (accounts.reduce((r, ac) => r += ac.locations.length, 0) <= 9) 
      return

    let steps = 0;
    let reportType = type.includes('-report') ? type.replace('-report', '') : type;
    reportType = reportType.includes('performance') ? reportType?.slice("performance-".length) : reportType

    switch (reportType) {
      case 'rollup':
        steps = 5;
        break;
      case 'qanda':
        steps = 1;
        break;
      case 'revenue':
        steps = 1;
        break;
      case 'review':
        steps = 3;
        break;
      case 'comparison':
        steps = 2;
        break;
      case 'grade':
        steps = 3;
        break;
      case 'Review Assistant':
        steps = 6;
        break;
      case 'keyword':
        steps = 1;
        break;
    }

    return this.loadingService.open(steps);
  }

  processReport(isAnonymous : boolean, report: Report, automatedReport = false) : void {
    // FIXME: 'isAnonymous' is unused.
    this._spinnerS.loading$.next(false);
    this.reportType = this.reportType?.includes('-location') ? this.reportType?.replace('-location', '') : this.reportType;
    this.dataPicker = this.reportS.reportToDataPicker(report, this.id, automatedReport, this.viewModeChecked);
    this.dataPicker.aggregation = report?.aggregation || this.dataPicker?.aggregation
    this.dataPicker.multiChart  = report?.multiChart  || this.dataPicker?.multiChart
    this.showMultilocations = _.has(report, 'dynamic');
    this.updateClass();
    this.isProcessed = true;
  }

  ngOnDestroy(): void {
    this.reportType = null;
    this._spinnerS.loading$.next(false);
  }

  handleDatapicker($event: any) : void {
    if ($event) {
      this.dataPicker = $event;
      this.refresh.next(true);
    }
  }

  private updateClass(): void {
    if (this.shared) {
      if(
        this.reportType.includes('rollup') ||
        this.reportType.includes('review') ||
        this.reportType.includes('comparison') ||
        this.reportType.includes('revenue') ||
        this.reportType.includes('keyword')
      ) {
        return;
      }

      const isGrade = (this.reportType === 'grade')
      this.contentClass = {
        'content content--padding'               : isGrade,
        'dashboard-content dashboard-content--sm': !isGrade
      };
    }

  }

}
