<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="showLoader">
  <mat-spinner></mat-spinner>
</div>
<app-modal-stepper
  [title]="[reportType + ' Report']"
  linear
  [finalAction]="'save'"
  [step]="step"
  [scan_action]="false"
  (completed)="completeSteeper()"
>
  <cdk-step [completed]="completeStep1">
    <form [formGroup]="form">
      <div
        class="dialog__content dialog__content--height pt--0"
        mat-dialog-content
      >
        <div class="dialog-row p-tb--0">
          <mat-label class="d-block mb--10">Report Name</mat-label>
          <mat-form-field
            floatLabel="never"
            class="input-group input-group--override input-group--override-label"
          >
            <input
              [formControl]="reportName"
              matInput
              required
              placeholder="Report Name"
              class="input-group__field"
            />
            <mat-error *ngIf="reportName.errors">
              <p>Report Name is required</p>
            </mat-error>
          </mat-form-field>
        </div>

        <ng-container *ngIf="reportType === 'rollup' || reportType === 'review' || reportType === 'comparison'  ">
          <div class="dialog-row p-tb--0" style="margin-top: 10px; margin-bottom: 20px;">
            <mat-slide-toggle labelPosition="before"  formControlName="scheduleReport" color="primary">Scheduled Report?</mat-slide-toggle>
          </div>
        </ng-container>

        <ng-container *ngIf="!scheduleReport.value;else DynamicDates">

          <ng-container *ngIf="!dynamic.value; else DynamicSelector">
            <div class="dialog-row p-tb--0" *ngIf="this.reportType !== 'Review Assistant'">
              <mat-label class="d-block mb--10"
                >Range <span *ngIf="reportType == 'comparison'">A</span></mat-label
              >
              <app-date-picker
                [dataPicker]="dataPicker"
                (dateSelected)="handleDateSelected($event)"
                [isForm]="true"
                [select]="selectDate"
                dropsChange="up"
              ></app-date-picker>
            </div>
          </ng-container>
          <ng-template #DynamicSelector>
            <div class="dialog-row pb--0">
              <mat-label class="d-block mb--10">Dynamic Range</mat-label>
              <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
                <mat-select
                  [formControl]="DynamicRangeControl"
                  class="input-group__field"
                  placeholder="Select one"
                >
                  <mat-option value="LastMonth">Last Month</mat-option>
                  <mat-option value="Last30">Last 30 Days</mat-option>
                  <mat-option value="Last90">Last 90 Days</mat-option>
                  <mat-option value="YTD">Year to Date</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="!DynamicRangeControl.valid">
                <p>Dynamic range is required</p>
              </mat-error>
            </div>
          </ng-template>

          <div class="dialog-row pb--0" *ngIf="reportType == 'comparison'">
            <mat-label class="d-block mb--10" *ngIf="reportType == 'comparison'"
              >Compare</mat-label
            >
            <mat-form-field
              floatLabel="never"
              class="input-group input-group--override input-group--override-label"
            >
              <mat-select
                (selectionChange)="periodChange($event)"
                [formControl]="period"
                class="input-group__field"
              >
                <mat-option [disabled]="true">Select Period</mat-option>
                <mat-option
                  value="{{ compare.value }}"
                  *ngFor="let compare of compareList"
                >
                  {{ compare.text }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <ng-container *ngIf="dataPicker?.rangeB">
            <div
              class="dialog-row pb--0"
              *ngIf="
                reportType == 'comparison' && dataPicker?.rangeB && period.value
              "
            >
              <p>Range B</p>
              <p
                *ngIf="
                  (period.value == 'period' || period.value == 'month' || period.value == 'year') &&
                  dataPicker?.rangeB
                "
              >
                {{ dataPicker?.rangeB.start | zuluToDate }} -
                {{ dataPicker?.rangeB.end | zuluToDate }}
              </p>
              <app-date-picker
                [dataPicker]="dataPicker"
                [rangeB]="true"
                [isForm]="true"
                [disabled]="rangeBDisabled"
                (dateSelected)="handleDateBSelected($event)"
                dropsChange="up"
                *ngIf="period.value == 'custom'"
              ></app-date-picker>
            </div>
          </ng-container>

          <!--checkbox buttons-->
          <div
            class="checkbox-group checkbox-group--margin checkbox-group--padding d-flex justify-content-start align-items-center mb--10"
          >
            <ng-container *ngIf="reportType !== 'comparison' && reportType !== 'revenue' && this.reportType !== 'Review Assistant'">
              <mat-checkbox
                [checked]="dynamic.value"
                (change)="changeDynamicDates($event)"
                class="d-flex align-items-center txt--sm fw--medium"
              >
                Dynamic Dates <i style="margin-left: 10px; color: #999ea2;" class="fas fa-info-circle"></i>
              </mat-checkbox>
            </ng-container>
            <mat-checkbox
              *ngIf="this.reportType !== 'Review Assistant'"
              class="d-flex align-items-center txt--sm fw--medium"
              [checked]="lockDates.value"
              matTooltip="By clicking lock dates, the dates of the report cannot be changed while viewing it"
              matTooltipPosition="right"
              matTooltipClass="tooltip tooltip--white"
              (change)="changeLockDates($event)"
            >
              Lock Dates <i style="margin-left: 10px; color: #999ea2;" class="fas fa-info-circle"></i>
            </mat-checkbox>
          </div>
        </ng-container>
        <ng-template #DynamicDates>

           <!--Frecuency-->
           <div class="dialog-row pb--0">
            <mat-label class="d-block mb--10">Frequency</mat-label>
            <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
              <mat-select
                [formControl]="FrecuencyControl"
                class="input-group__field"

              >
                <mat-option value="WeeklyOn">Weekly on (Day of Week)</mat-option>
                <mat-option value="MonthlyOn">Monthly on (Day of Month)</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error *ngIf="FrecuencyControl.errors">
              <p>Frequency is required</p>
            </mat-error>
          </div>

          <div class="dialog-row pb--0">
            <app-frecuency-selector-report [FrecuencySelect]="FrecuencyControl.value" [frecuencyEdit]="repeatOn" (FrecuencyOutput)="repeatOn = $event" ></app-frecuency-selector-report>
          </div>
          <ng-container [ngSwitch]="reportType">
            <ng-container *ngSwitchCase="'comparison'">
              <div class="dialog-row pb--0">
                <mat-label class="d-block mb--10">Dynamic Range</mat-label>
                <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
                  <mat-select
                    [formControl]="DynamicRangeControl"
                    class="input-group__field"
                    placeholder="Select one"
                  >
                    <mat-option value="LastMonth-PrevMonth">Last month vs Previous Month</mat-option>
                    <mat-option value="LastMonth-YearAgoMonth">Last Month vs Same Month Last Year</mat-option>
                    <mat-option value="Last90-PrevLast90">Last 90 days vs Previous 90 days</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error *ngIf="!DynamicRangeControl.valid">
                  <p>Dynamic range is required</p>
                </mat-error>
              </div>
            </ng-container>
            <div *ngSwitchDefault>
              <div class="dialog-row pb--0">
                <mat-label class="d-block mb--10">Dynamic Range</mat-label>
                <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
                  <mat-select
                    [formControl]="DynamicRangeControl"
                    class="input-group__field"
                    placeholder="Select one"
                  >
                    <mat-option value="LastMonth">Last Month</mat-option>
                    <mat-option value="Last30">Last 30 Days</mat-option>
                    <mat-option value="Last90">Last 90 Days</mat-option>
                    <mat-option value="YTD">Year to Date</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error *ngIf="!DynamicRangeControl.valid">
                  <p>Dynamic range is required</p>
                </mat-error>
              </div>
            </div>
          </ng-container>

          <div class="dialog-row pb--0">
            <mat-label class="d-block mb--10">Recipents (Email)</mat-label>
            <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
              <div class="row">
                <div class="col-10">
                  <input
                    [formControl]="RecipentsControl"
                    matInput
                    required
                    placeholder="recipent@mail.com"
                    class="input-group__field"
                  />
                </div>
                <div class="col-2" style="text-align: end;">
                  <span class="btn-add-recipent" (click)="addNewRecipent()"><i class="fas fa-plus"></i></span>
                </div>

              </div>


            </mat-form-field>
            <mat-error *ngIf="RecipentsEmails.length === 0">
              <p>Recipents are required</p>
            </mat-error>

            <mat-list>
              <mat-list-item class="selectable-list" (click)="deleteRecipent(email)" *ngFor="let email of RecipentsEmails">
                <h6 matLine> {{email}} </h6>
                <i matListIcon class="far fa-trash-alt"></i>
              </mat-list-item>
              <mat-divider></mat-divider>
            </mat-list>
          </div>

        </ng-template>

      </div>
    </form>
  </cdk-step>

  <cdk-step [completed]="completeStep2">
    <app-toggle-locations-in-account
      class="locations-no-padding"
      (selected)="selectedLocations($event)"
      [reportEdit]="reportEdit?.accounts"
      [filterProtocolLocations]="this.reportType == 'Review Assistant'"
    ></app-toggle-locations-in-account>
  </cdk-step>
  
  <cdk-step
    [editable]="false"
    *ngIf="reportType == 'comparison'"
    [completed]="completeStep3"
  >
    <app-check-metrics [metrics]='metricsChecked' (selected)="selectedMetrics($event)"></app-check-metrics>
  </cdk-step>
</app-modal-stepper>
