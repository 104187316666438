<!--Company Address-->
<div class="box__item box__item--editable" [class.align-items-start]="address?.addressLines">
  <div class="d-flex align-items-start w--100 justify-content-between">
    <div class="icon icon--rounded icon--gray mr--20">
      <i class="fas fa-map-marker-alt"></i>
    </div>

    <section class="w--100">
      <h4 class="w--100 txt--md fw--700 mb--10">Address</h4>
      <div class="w--100" [class.txt--medium-gray]="isUnderReview">
        <ng-container *ngIf="!isApple; else apple">

          <div class="d-flex">
            <gmb-address-outer [address]="address" *ngIf="address?.addressLines?.length > 0" [class.mb--5]="isUnderReview"></gmb-address-outer>
            <span *ngIf="lenguageCode !== ''">, {{lenguageCode}}</span>
          </div>
          
          <p class="mb--5" *ngIf="(!address || address?.addressLines?.length == 0) && isEdit">Add Address</p>
          
          <div *ngIf="address?.addressLines" class="w--100 mb--5">
            <ng-container *ngFor="let item of address?.addressLines; let i = index;">
              <p class="m--0 d--block"><span *ngIf="i > 0" [outerHTML]="item"></span></p>
            </ng-container>
          </div>
        </ng-container>
        
        <ng-template #apple>
          <div class="w--100 mb--5">
            <p class="m--0 d--block"><span>{{address || ''}}</span></p>
          </div>
        </ng-template>
      
        <span class="under-review" *ngIf="isUnderReview" role="alert">Under review</span>
      </div>
    </section>
  </div>
  <button mat-button (click)="openAddressDialog()" class="btn btn-icon btn-icon--gray" *ngIf="isEdit"><em class="fas fa-pencil"></em></button>
</div>

