import {Component, Inject, Input, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {CATEGORIES_DATA, GoogleMediaItem, ModalCategories} from '../../constants/google-media';
import {AngularFireStorageReference} from '@angular/fire/storage';
import AccountReport from 'src/app/services/account-report';
import { PhotosLocationComponent } from '../dashboard-location/photos-location/photos-location.component';

// const RESTAURANTS = 'RESTAURANTS'
// const HOTELS = 'HOTELS'


@Component({
  selector: 'app-modal-photos',
  templateUrl: './modal-photos.component.html',
  styleUrls: ['./modal-photos.component.scss']
})
export class ModalPhotosComponent implements OnInit {
  imageUrl: string[] = [];
  imageSelectedUrl: string = null;
  categories: any[];
  fileUrl: string;
  description: string;
  selectedFile: any;
  selectedFileName: any;
  listFiles = new ModalCategories();
  fileType: any;
  category: { name: string };
  isUploading: boolean;
  error: any;
  error_details: any;
  private media: GoogleMediaItem[];
  public percentUpload: number;
  private fileRef: AngularFireStorageReference;
  @Input() primaryCategory: any;
  isProgress = true;
  isProgressPublish = false;
  selectedCategory: {name: string, value: string} =  null;
  @Input() accountId: string = null;
  @Input() locationId: string = null;
  @ViewChild('photoComponent') photoComponent: PhotosLocationComponent;
  @Input() bulk = false;
  @Input() locationType: string = '';
  @Input() showOnlyMedia = false;
  @Input() accounts: AccountReport[] = null;
  @Output() validResult: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    public dialogRef: MatDialogRef<ModalPhotosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.categories = CATEGORIES_DATA;
    this.selectedCategory = this.categories[0];
    this.category = {name: 'CATEGORY_UNSPECIFIED'};
    if (data) {
      this.accountId = this.data.accountId;
      this.locationId = this.data.locationId;
      this.locationType = this.data.typeLocation;
      this.showOnlyMedia = this.data.showOnlyMedia;
      this.error = this.data.error
      this.error_details = this.data.error_details
    }
  }

  ngOnInit() {}

  getResult(): Partial<GoogleMediaItem>[]  {
    const result = this.photoComponent.getResult();

    if (result) {
      this.validResult.emit(true);
      return result;
    } else {
      this.validResult.emit(false);
      return result;
    }
  }

}
