<div class="dialog__content dialog__content--posts" mat-dialog-content>
  <div class="spinner-wrapper spinner-wrapper--bg spinner-wrapper--fixed" *ngIf="progress">
    <mat-spinner></mat-spinner>
  </div>
  <ng-container *ngIf="!locationShow && !validatePlaceholder">
    <mat-tab-group [(selectedIndex)]="selectedIndex" animationDuration="0" (selectedTabChange)="changeTab($event)">
      <mat-tab label="What's New" [disabled]="edit && isPosted && selectedIndex != 0">
        <div class="dialog__tab-content" *ngIf="selectedIndex == 0">
          <app-form-post #form 
                         [placeId]="locationId" 
                         postType="STANDARD"
                         [post]="data?.post" 
                         [isBulk]="bulkPost"
                         [recurring]="recurring"
                         (recurringChange)="handleRecurring($event)"
                         [publishRecurringNow]="publishNow"
                         (publishRecurringNowChange)="handlePublishNow($event)"
                         (data)="handleData($event)"
                         (handleIsRecurring)="changeRecurring($event)"
                         (isRecurringValid)="isRecurringValid = $event"
                         [touched]="touched" 
                         [savedData]="formData"
                         [isEditing]="edit">
          </app-form-post>
          <div class="notice mb--5" *ngIf="schedule">
            <p>
              Post Scheduled:
              <span class="txt--underline">{{schedule.date |  date}} -
                <span *ngIf="schedule.time">{{schedule.time |  hourAmPmSchedule}}</span>
                <a class="cursor--pointer" (click)="schedule = null"><i class="far fa-trash-alt ml--10"></i></a>
              </span>
            </p>
          </div>
          <div class="notice mb--5 px--10" *ngIf="recurring">
            <p>
              Recurring Post Dates:
              <app-calculate-recurring [postType]="'What\'s New'" 
                                       [recurringModel]="recurring" 
                                       [publishNow]="publishNow"
                                       [duplicateAccept]="duplicatePostAcceptance"
                                       (publishPlanDates)="planningDates$.next($event)"
                                       [isEdit]="edit">
              </app-calculate-recurring>
            </p>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Event" [disabled]="edit && isPosted && selectedIndex != 1">
        <div class="dialog__tab-content" *ngIf="selectedIndex == 1">
          <app-form-post #form 
                         [placeId]="locationId" 
                         postType="EVENT" 
                         [post]="data?.post" 
                         [isBulk]="bulkPost"
                         [scheduled]="schedule" 
                         [recurring]="recurring"
                         (recurringChange)="handleRecurring($event)"
                         [publishRecurringNow]="publishNow"
                         (publishRecurringNowChange)="handlePublishNow($event)"
                         (handleIsRecurring)="changeRecurring($event)"
                         (isRecurringValid)="isRecurringValid = $event"
                         (data)="handleData($event)"
                         [touched]="touched"
                         [savedData]="formData"
                         [planningDates$]="planningDates$.asObservable()"
                         [isEditing]="edit">
          </app-form-post>
          <div class="notice mb--5" *ngIf="schedule">
            <p>
              Post Scheduled:
              <span class="txt--underline">{{schedule.date |  date}} -
                <span *ngIf="schedule.time">{{schedule.time |  hourAmPmSchedule}}</span>
                <a class="cursor--pointer" (click)="schedule = null"><i class="far fa-trash-alt ml--10"></i></a>
              </span>
            </p>
          </div>

          <div class="notice mb--5 px--10" *ngIf="recurring">
            <p>
              Recurring Post Event Dates:
              <app-calculate-recurring [postType]="'Event'" 
                                       [recurringModel]="recurring" 
                                       [publishNow]="publishNow"
                                       [duplicateAccept]="duplicatePostAcceptance"
                                       (publishPlanDates)="planningDates$.next($event)"
                                       [isEdit]="edit">
              </app-calculate-recurring>
            </p>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Offer" [disabled]="edit && isPosted && selectedIndex != 2">
        <div class="dialog__tab-content" *ngIf="selectedIndex == 2">
          <app-form-post #form 
                         [placeId]="locationId" 
                         postType="OFFER" 
                         [post]="data?.post" 
                         [isBulk]="bulkPost"
                         [scheduled]="schedule" 
                         [recurring]="recurring"
                         (recurringChange)="handleRecurring($event)"
                         [publishRecurringNow]="publishNow"
                         (publishRecurringNowChange)="handlePublishNow($event)"
                         (handleIsRecurring)="changeRecurring($event)"
                         (isRecurringValid)="isRecurringValid = $event"
                         (data)="handleData($event)"
                         [touched]="touched"
                         [savedData]="formData"
                         [planningDates$]="planningDates$.asObservable()"
                         [isEditing]="edit">
          </app-form-post>
          <div class="notice mb--5" *ngIf="schedule">
            <p>
              Post Scheduled:
              <span class="txt--underline">{{schedule.date |  date}} -
                <span *ngIf="schedule.time">{{schedule.time |  hourAmPmSchedule}}</span>
                <a class="cursor--pointer" (click)="schedule = null"><i class="far fa-trash-alt ml--10"></i></a>
              </span>
            </p>
          </div>

          <div class="notice mb--5 px--10" *ngIf="recurring">
            <p>
              Recurring Post Event Dates:
              <app-calculate-recurring [postType]="'Offer'" 
                                       [recurringModel]="recurring" 
                                       [publishNow]="publishNow" 
                                       [duplicateAccept]="duplicatePostAcceptance" 
                                       (publishPlanDates)="planningDates$.next($event)"
                                       [isEdit]="edit">
              </app-calculate-recurring>
            </p>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="COVID-19" [disabled]="edit && isPosted && selectedIndex != 3 || edit && data?.post?.status != 'PUBLISH'" *ngIf="edit">
        <div class="dialog__tab-content" *ngIf="selectedIndex == 3">
          <app-form-post #form 
                         [placeId]="locationId"
                         postType="ALERT"
                         [post]="data?.post"
                         [isBulk]="bulkPost"
                         [recurring]="recurring"
                         (recurringChange)="handleRecurring($event)"
                         [publishRecurringNow]="publishNow"
                         (publishRecurringNowChange)="handlePublishNow($event)"
                         (handleIsRecurring)="changeRecurring($event)"
                         (isRecurringValid)="isRecurringValid = $event"
                         (data)="handleData($event)"
                         [touched]="touched"
                         [savedData]="formData"
                         [isEditing]="edit">
          </app-form-post>
          <div class="notice mb--5" *ngIf="schedule">
            <p>
              Post Scheduled:
              <span class="txt--underline">{{schedule.date |  date}} -
                <span *ngIf="schedule.time">{{schedule.time |  hourAmPmSchedule}}</span>
                <a class="cursor--pointer" (click)="schedule = null"><i class="far fa-trash-alt ml--10"></i></a>
              </span>
            </p>
          </div>
          <div class="notice mb--5 px--10" *ngIf="recurring">
            <p>
              Recurring Post Dates:
              <app-calculate-recurring [postType]="'COVID-19'" 
                                       [recurringModel]="recurring" 
                                       [publishNow]="publishNow"  
                                       [duplicateAccept]="duplicatePostAcceptance" 
                                       (publishPlanDates)="planningDates$.next($event)" 
                                       [isEdit]="edit">
              </app-calculate-recurring>
            </p>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <!--Recurring extension-->
    <div style="padding: 0px 30px;">
      <div class="notice mb--5 px--10" *ngIf="recurring">
        <p>
          Post will recur on:
          <span class="txt--underline">{{nextRecurringDate |  date}} -
            <span *ngIf="recurring.time">{{recurring.time |  hourAmPmSchedule}}</span>
            <a *ngIf="!edit" class="cursor--pointer" (click)="recurring = null"><i class="far fa-trash-alt ml--10"></i></a>
          </span>
        </p>
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="locationShow && !validatePlaceholder">
    <app-toggle-locations-in-account (selected)="handleLocations($event)"
                                     [isPost]="true"
                                     [reportEdit]="accountsSelected"></app-toggle-locations-in-account>
  </ng-container>
  <ng-container *ngIf="locationShow && validatePlaceholder">
    <div class="container--full container--padding-lg" >
      <h3>Some attributes are empty for the following locations. You may save this post as Draft until you complete those attributes.</h3>
      <div *ngFor="let location of locationsWithoutValidate" class="row mb--10">
        <div class="col-8">
          <div mat-line style="text-overflow: unset; white-space: unset;">
            <span style="font-weight: bold;">{{ location?.locationName }}</span>
            <gmb-address [address]="location?.address" style="word-break: break-all; overflow-wrap: break-word;"></gmb-address>
          </div>
        </div>
        <div class="col-4">
          <mat-chip-list *ngFor="let placeholder of location.placeholders" aria-label="Placholders">
            <mat-chip>{{placeholder}}</mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>

  </ng-container>
</div>
<div mat-dialog-actions class="dialog__footer dialog__footer--posts">
    <span class="alternate-theme d-inline-block mr--20">
      <ng-container [ngSwitch]="locationShow">
        <ng-container *ngSwitchCase="true">
          <button mat-button
            tabIndex="-1"
            color="primary"
            (click)="back()"
            class="btn btn-cancel">
            Back
          </button>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <button mat-button
            tabIndex="-1"
            color="primary"
            mat-dialog-close
            class="btn btn-cancel">
            Cancel
          </button>
        </ng-container>
      </ng-container>

    </span>

  <span class="d-flex justify-content-end">
  <span class="alternate-theme d-flex" *ngIf="edit && !isPosted || !edit">
      <button mat-flat-button tabIndex="-1"
              color="primary"
              class="btn btn--fluid btn--icon btn--md mr--10"
              [disabled]="!!recurring"
              (click)="openSchedule()">
            <i class="far fa-calendar-alt"></i> Schedule
      </button>
     <button mat-flat-button tabIndex="-1"
             color="primary"
             [disabled]="progress"
             class="btn btn--fluid btn--icon btn--md mr--20"
             (click)="apply('DRAFT')">
      <i *ngIf="progress" class="fas fa-spinner fa-pulse"></i>
      <i *ngIf="!progress" class="fal fa-newspaper"></i> To Draft
     </button>
    </span>
  <button mat-flat-button tabIndex="-1"
          color="accent"
          class="btn btn--icon btn--md"
          (click)="chooseLocations()"
          [disabled]="!validFormPost || (recurring && !isRecurringValid)"
          *ngIf="bulkPost && !locationShow && type !== null && !isPosted">
    <i class="fas fa-map-marker-alt"></i> Choose locations
  </button>
  <!--Only appears if there is in location select-->
  <button mat-flat-button tabIndex="-1" color="accent" class="btn btn--icon btn--md"
          (click)="apply()"
          [disabled]="!(bulkPost && accountsSelected?.length>0) || progress || disablePostingButton || (recurring && !isRecurringValid)"
          *ngIf="locationShow && !validatePlaceholder">
          <span *ngIf="edit && isPosted"><i *ngIf="progress" class="fas fa-spinner fa-pulse"></i> Update Post </span>
          <span *ngIf="!edit || edit && !isPosted"><i *ngIf="progress" class="fas fa-spinner fa-pulse"></i> {{ schedule ? 'Schedule Post' : 'Publish Post' }}</span>
  </button>

  <!--Only appears if there is in bulk post and doesn't pass placeholder validation-->
  <button mat-flat-button tabIndex="-1" color="accent" class="btn btn--icon btn--md"
          (click)="apply(null, true)"
          [disabled]="!(bulkPost && accountsSelected?.length>0) || progress || disablePostingButton || (recurring && !isRecurringValid)"
          *ngIf="locationShow && validatePlaceholder">
          <span *ngIf="edit && isPosted"><i *ngIf="progress" class="fas fa-spinner fa-pulse"></i> Update Anyway </span>
          <span *ngIf="!edit || edit && !isPosted"><i *ngIf="progress" class="fas fa-spinner fa-pulse"></i> {{ schedule ? 'Schedule Post' : 'Publish Anyway' }}</span>
  </button>

  <!--Only appears if there is edit a bulk posted post-->
  <button mat-flat-button tabIndex="-1" color="accent" class="btn btn--icon btn--md"
          (click)="apply()"
          [disabled]="!(bulkPost &&  accountsSelected?.length>0) || progress || disablePostingButton || (recurring && !isRecurringValid)"
          *ngIf="bulkPost && edit && isPosted">
          <span *ngIf="edit && isPosted"><i *ngIf="progress" class="fas fa-spinner fa-pulse"></i> Update Post </span>
          <span *ngIf="!edit || edit && !isPosted"><i *ngIf="progress" class="fas fa-spinner fa-pulse"></i> {{ schedule ? 'Schedule Post' : 'Publish Post' }}</span>
  </button>

  <!--Only shows if a local post-->
  <button mat-flat-button tabIndex="-1" color="accent" class="btn btn--icon btn--md"
          (click)="apply()"
          [disabled]="progress || disablePostingButton || !validFormPost || (recurring && !isRecurringValid)"
          *ngIf="!bulkPost">
          <span *ngIf="edit && isPosted"><i *ngIf="progress" class="fas fa-spinner fa-pulse"></i> Update Post</span>
          <span *ngIf="!edit || edit && !isPosted"><i *ngIf="progress" class="fas fa-spinner fa-pulse"></i> {{ schedule ? 'Schedule Post' : 'Publish Post' }}</span>
  </button>
    </span>
</div>
