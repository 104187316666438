<div class="dialog__header">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>Enable Listing Defender</strong></h2>
  </div>
  <div class="dialog__content" mat-dialog-content>
    <p>
        Google often updates your business profile without your knowledge or permission. <br>
        When the Listing Defender is on, it can prevent those changes.
    </p>
    <p>Note that when the feature is turned off, location will be automatically updated with the latest data.</p>

    <mat-checkbox class="txt--normal consent-checkbox" [(ngModel)]="consentAccepted"> 
        <span>By enabling the Listing Defender, I hereby grant specific and express consent to automatically edit my business profile</span>
    </mat-checkbox>
  </div>

  <div mat-dialog-actions class="dialog__footer">
    <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel d-inline-block mr--10">Cancel</button>
    <button tabIndex="-1" mat-flat-button class="btn btn--action padding-buttons" color="primary" 
        [disabled]="!consentAccepted"
        (click)="enableDefender()"
    >
        Enable Defender
    </button>
</div>