import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { LocationEditService } from 'src/app/services/location-edit.service';

@Component({
  selector: 'app-modal-service-area',
  templateUrl: './modal-service-area.component.html',
  styleUrls: ['./modal-service-area.component.scss']
})
export class ModalServiceAreaComponent implements OnInit {
  public serviceArea: any = {};
  public regionCode = "US";
  public newServiceArea;
  optionsGoogle: any;
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

  constructor(
    public dialogRef: MatDialogRef<ModalServiceAreaComponent>,
    public locationEditS: LocationEditService,
   @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit() {
    this.serviceArea = Object.keys(this.data?.serviceArea).length ? JSON.parse(JSON.stringify(this.data?.serviceArea)) : {places: {placeInfos: []}};
    this.regionCode = this.data?.regionCode;

    this.optionsGoogle = {
      types: [],
      componentRestrictions: { country: this.regionCode  }
    }
  }

  removePlace(place, index) {
    this.placeInfos.splice(index, 1);
  }
  
  handleAddressChange(place) {
    this.placeInfos.push({
      placeId: place?.place_id,
      placeName: place?.formatted_address
    });
    this.newServiceArea = null;
  }

  apply(): void {
    this.locationEditS.locationEdit.serviceArea = this.serviceArea;
    this.locationEditS.setAndUpdate().toPromise().then(()=>{
      this.dialogRef.close();
    });
  }

  get placeInfos() {
    return this.serviceArea?.places?.placeInfos
  }

}
