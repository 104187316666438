<div class="reports-filter-wrapper filter-dashboard" [class.is-impersonating]="(session$ | async).isImpersonating || (session$ | async).isTrial">
  <mat-progress-bar mode="indeterminate" class="progress-export-csv" *ngIf="isProgress"></mat-progress-bar>
    
  <div class="d-flex justify-content-start ml--15 ml--290" (click)="navigateBack()" data-html2canvas-ignore>
      <button mat-flat-button [style.color]="'#8c91a2'"
        class="btn btn--default btn--icon btn--md mr--5 cursor--pointer">
        <i class="fas fa-chevron-left mr--5"></i>
        Back To Profiles
      </button>
    </div>
    <div class="w--100 d-flex align-items-center justify-content-end" *ngIf="dateReady">
      <!-- Monthly Datepicker -->

      <div class="d-flex">
        <span class="label-datepicker d-inline-block txt--normal ml--10 mr--10 mb--0">Date Range</span>
        
        <ejs-daterangepicker
            class="d-inline-block"
            id="date-range-picker"
            #dateRangePickerMonthly 
            (change)="dateChanged($event)"
            (created)="datePickerCreation()"
            [start]="dateRangeObject?.granularity?.start"
            [startDate]="dateRangeObject?.dateRangeLimits?.minDate"
            [endDate]="dateRangeObject?.dateRangeLimits?.maxDate"
            [format]="'MMM yyyy'"
            [depth]="dateRangeObject?.granularity?.depth" 
            [min]="dateRangeObject?.dateRangeLimits?.minDate"
            [max]="dateRangeObject?.dateRangeLimits?.maxDate"
            [allowEdit]="false" 
            [showClearButton]="false"
            (open)="onOpen()"
            (click)="dateRange.show()">

            <!-- Presets -->
            <e-presets>
                <!-- Check possible ngIf cases for each preset (needs rework after IPreset rework) -->
                <ng-container *ngFor="let preset of dateRangeObject?.presetDateRanges">
                    <e-preset 
                        [label]="preset?.name" 
                        [start]="preset?.dateRange?.minDate" 
                        [end]="preset?.dateRange?.maxDate">              
                    </e-preset>
                </ng-container>
            </e-presets>
        </ejs-daterangepicker>

      </div>

      <!-- Comparion  -->
      <div *ngIf="comparisonAvailable">
        <span class="d-inline-block txt--normal ml--10 mr--10 mb--0">Compare to</span>

        <button mat-button [matMenuTriggerFor]="selectionComparison" class="filter__item__btn" [(ngModel)]="comparisonPeriod" ngDefaultControl>
          {{ comparisonPeriod.name }} <i class="fal fa-angle-down" data-html2canvas-ignore></i>
        </button>
            
        <mat-menu #selectionComparison="matMenu" [overlapTrigger]="false" xPosition="before">
          <ng-container *ngFor="let group of dateRangeObject?.comparisonPresets">
            <!-- Header -->
            <ng-container *ngIf="group.header">
              <span class="menu-header txt--14 txt--dark--gray">{{ group.header }}</span>
            </ng-container>
        
            <!-- Options -->
            <button
              mat-button *ngFor="let option of group.items"
              class="cursor--pointer menu-items"
              [class.menu-items-without-header]="!group.header"
              [class.menu-single-item]="group.items?.length === 1"
              color="accent"
              [class.txt-disabled]="!option.enabled"
              [disabled]="!option.enabled"
              (click)="onComparisonchage(option)"
            >
              <span class="txt--14">{{ option?.name }}</span>
            </button>
          </ng-container>
        </mat-menu>                            
      </div>

      <!--export button-->
      <div class="d-flex flex-gap-5 mr--10 ml--10 align-items-center" *ngIf="hasExportCSV || hasExportPDF" data-html2canvas-ignore>
        <button mat-flat-button color="primary" class="btn btn-icon btn-icon--lg" [matMenuTriggerFor]="exportMenu" gmbSubscriptionInTrial>
          <i class="fa fa-download"></i>
        </button>

        <mat-menu #exportMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="d-print-none">
          <button mat-button color="primary" *ngIf="hasExportPDF" (click)="exportPDF()">
            Download PDF
          </button>
          <button mat-button color="primary" *ngIf="hasExportCSV" (click)="exportCSV()">
            Download CSV
          </button>
        </mat-menu>
      </div>

      <!--share button-->
      <div class="d-flex flex-gap-5" *ngIf="hasShare" data-html2canvas-ignore>
        <button mat-flat-button color="primary" class="btn btn-icon btn-icon--lg filter__item__btn" (click)="handleShared()">
          <i class="fa fa-share-alt"></i>
        </button>
      </div>
  </div>
</div>
