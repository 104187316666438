import { Component, Inject, OnInit, ViewEncapsulation, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalServiceItemComponent } from '../modal-service-item/modal-service-item.component';
import { Section } from '../../constants/service';
import { LocationService } from '../../services/location.service';
import { ServiceData, ServiceList, Service, Category } from '../../constants/google/service-list';
import { ModalAddServiceComponent } from '../modal-add-service/modal-add-service.component';
import { GoogleService } from '../../services/google.service';
import { SnackbarService } from '../../services/snackbar.service';

export const SECTION_DATA: Section[] = [];

@Component({
  selector: 'app-modal-service',
  templateUrl: './modal-service.component.html',
  styleUrls: ['./modal-service.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalServiceComponent implements OnInit {
  @ViewChild('buttonGo') editbutton: ElementRef;

  @Input() bulk: boolean = false;
  @Input() serviceList: ServiceList[];
  @Input() primaryCategory: {categoryId: string, displayName: string,  primary: boolean, serviceTypes: {displayName: string, serviceTypeId: string}[]};
  @Input() aditionalCategories: {categoryId: string, displayName: string,  primary: boolean, serviceTypes: {displayName: string, serviceTypeId: string}[]}[] = [];
  
  @Output() validResult: EventEmitter<boolean> = new EventEmitter(false);

  public dataSource: ServiceData[] = [];
  public type: string;
  public loading: boolean = false;
  public showMoreMessage = false;
  public showMoreMessageIndex: number;
  public showOnly: boolean = false;
  public error = {};
  public error_details = [];
  
  private _combineCategories: {categoryId: string, displayName: string,  primary: boolean, serviceTypes: {displayName: string, serviceTypeId: string}[]}[] = [];
  private _locationId: string = '';
  private _accountId: string = '';


  constructor(
    public dialogRef: MatDialogRef<ModalServiceComponent>,
    public dialog: MatDialog,
    private _locationS: LocationService,
    private _googleS: GoogleService,
    private _snackS: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data) {
      this.type = this.data.type;
      this.serviceList = this.data.serviceList;
      this.primaryCategory = {...this.data.primaryCategory, primary: true};
      this.aditionalCategories = [...this.data.additionalCategories];
      this._combineCategories = [this.primaryCategory, ...this.aditionalCategories];
      this.dataSource = this._locationS.organizeServiceList(this.serviceList, this._combineCategories);
      this._accountId = this.data.accountId;
      this._locationId = this.data.locationId;
      this.showOnly = this.data.showOnly;
      this.error = this.data?.error;
      this.error_details = this.data?.error_details;

      //TODO: When the getRef service returns 'type', remove this logyc 
      this.addTypeToItems();
      //
    }
  }

  addTypeToItems() {
    this.serviceList.forEach(el => {
      if (el?.price) {
        el.price.type = 'fixed';
        if (Number(el.price.units) === 0 || (el.price.units == null && el.price.nanos == null)) {
          el.price.type = 'free';
        }
      }
    });
    
  }

  toggleMessage(i): void {
    this.showMoreMessage = !this.showMoreMessage;
    this.showMoreMessageIndex = i;
    return;
  }

  ngOnInit(): void {
    if (this.bulk) {
      if (this.aditionalCategories.length > 0) this._combineCategories = [this.primaryCategory, ...this.aditionalCategories];
      else this._combineCategories = [this.primaryCategory];
      this.dataSource = this._locationS.organizeServiceList(this.serviceList, this._combineCategories);
    }
  }

  getResult(): ServiceList[] {
    if (this.serviceList.length === 0) {
      this.validResult.emit(false);
      return
    }
    this.validResult.emit(true);
    return this.serviceList;
  }

  setDataService(service: ServiceList[], primaryCategory: any, aditionalCategories: any[]): void {
    this.serviceList = service;
    this.primaryCategory = {...primaryCategory, primary: true};
    this.aditionalCategories = [...aditionalCategories];
    this._combineCategories = [this.primaryCategory, ...this.aditionalCategories];
    this.dataSource = this._locationS.organizeServiceList(this.serviceList, this._combineCategories);
  }

  addItem(position: number): void {
    const dialogRef = this.dialog.open(ModalAddServiceComponent, {
      width: '480px',
      panelClass: 'overflow--visible',
      data: {
        category: this._combineCategories[position],
        addedServices: this.dataSource[position]?.services,
        position: this.dataSource[position]?.services?.length
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.serviceList.push(result.item);
        const data = this.dataSource.find(x => x.categoryId === result.category)
        if (data)  {
          this.dataSource = this._locationS.organizeServiceList(this.serviceList, this._combineCategories);
        }
      }
    });
  }

  editItem(service: Service, position: number, sectionIndex: number, serviceMaskIndex: number): void {
    if (!this.showOnly) {
      const dialogRef = this.dialog.open(ModalServiceItemComponent, {
        width: '480px',
        panelClass: 'overflow--visible',
        data: {
          structuredService: service,
          item: this.serviceList[position],
          position
        }
      });

      dialogRef.afterClosed().subscribe((result: {action: string, position: number, item?: ServiceList, structuredItem?: Service, category?: Category}) => {
        if (result) {
          if (result.action === 'removed') {
            this.serviceList.splice(position, 1);
            this.dataSource[sectionIndex].services.splice(serviceMaskIndex, 1);
          } else if (result.action === 'updated') {
            this.serviceList[position] = result.item;
            this.dataSource[sectionIndex].services[serviceMaskIndex] = result.structuredItem;
          }
        }
      });
    }
  }

  apply(): void {
    this.loading = true;
    this._googleS.updateServices(this._accountId, this._locationId, this.serviceList).toPromise().then( data => {
      if (data) {
        this.loading = false;
        this._snackS.openSuccess('The services was uploaded successfully', 2000)
        this.serviceList = data;
        this.dataSource = this._locationS.organizeServiceList(this.serviceList, this._combineCategories);
      }
    })
  }

}
