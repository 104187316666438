import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from 'src/app/components/base.component';
import { SessionService } from 'src/app/services/session.service';
import { AbcLocationService } from 'src/app/services/abc-location.service';
import { ILocationData, IPhoneNumber } from './../../../constants/abc-location-info';

@Component({
  selector: 'app-apple-location-info',
  templateUrl: './apple-location-info.component.html',
  styleUrls: ['./apple-location-info.component.scss']
})
export class AppleLocationInfoComponent extends BaseComponent implements OnInit {
  public readonly locationId: any;
  public readonly accountId: any;
  public locationData: ILocationData;
  public locationDataMock: any = {};
  public regionCode: string;
  public progress = false;
  public formattedAddress = '';
  public formattedDescription = '';
  public primaryCategory : {displayName: string} = null;
  public additionalCategories: {displayName: string}[] = [];
  public primaryPhone: IPhoneNumber = null;
  public additionalPhones: IPhoneNumber[] = []

  public isReport = false;
  public location$ = new BehaviorSubject(null);
  public isProgressPush = null;
  public fetchProgress = null;

  constructor(
    private _route: ActivatedRoute,
    private _sessionS: SessionService,
    private _abcLocS: AbcLocationService
  ) {
    super();

    this.locationId = this._route.snapshot.parent.params.locationId;
    this.accountId  = this._route.snapshot.parent.params.accountId;
  }

  ngOnInit() {
    const gid = this._sessionS.getSession().gid;
    const locationId = this._route?.snapshot?.parent?.params?.locationId;

    this.progress = true;
    // needs to be moved to its own service (or an openapi service)
    this._subscribeSafe(this._abcLocS.fetchLocationInfo(gid, locationId), res => {
      if (res) { 
        this.locationData = res;
        this.formatData();
      }
      
      this.progress = false;
    });
  }

  formatData(): void { // could be done in the correspondant reports data service
    this.formatAddress();
    this.formatDescription();
    this.formatCategories();
    this.formatPhones();
  }

  formatAddress(): void {
    this.formattedAddress = `
          ${(this.locationData?.geographicalinfo?.mainAddress?.structuredAddress?.fullThoroughfare + ', ') || ''}
          ${(this.locationData?.geographicalinfo?.mainAddress?.structuredAddress?.subLocality + ', ') || ''}
          ${(this.locationData?.geographicalinfo?.mainAddress?.structuredAddress?.locality + ', ') || ''}
          ${(this.locationData?.geographicalinfo?.mainAddress?.structuredAddress?.administrativeArea + ' ') || ''}
          ${this.locationData?.geographicalinfo?.mainAddress?.structuredAddress?.postCode || ''}
        `
  }

  formatDescription(): void {
    let descString = '';
    this.locationData?.locationDescriptions?.forEach(desc => {
      if (desc.type === 'ABOUT') {
        desc?.descriptions?.forEach(item => {
          descString += `${item.text}\n`
        })
      }
    })
    this.formattedDescription = descString;
  }

  formatCategories(): void {
    //replace all
    this.primaryCategory = { displayName: this.locationData?.categories[0].split('.')[2].replace(/_/g, ' ')};
    this.additionalCategories = this.locationData?.categories.slice(1)
                                .map(cat => {
                                  return { displayName: cat.split('.')[2].replace(/_/g, ' ')}
                                });
  }

  formatPhones(): void {
    this.locationData?.phoneNumbers?.forEach(phone => {
      if (phone.primary) {
        this.primaryPhone = phone;
      } else {
        this.additionalPhones.push(phone);
      }
    })
  }

  dispatchAction(event): void {
    return;
  }

  updateLocation(event): void {
  }

  getProcessedUrl(url: string): string {
    if (!url) return '';
    return url.startsWith('http') ? url : `https://${url}`;
  }
  
}
