// dep
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";

// app
import GoogleAccount from "../../constants/firestore/google-account";
import { Messages, string_message } from "../../constants/messages";
import { CREATE_DATA, NOTIFICATION_GENERAL, TYPE_LOG_LOCATION } from "../../constants/notifications";
import { AccountService } from "../../services/account.service";
import { SessionTraceService as SessionTraceService } from "../../services/session-trace.service";
import { GoogleLocationService } from "../../services/google-location.service";
import { LocationService } from "../../services/location.service";
import { NotificationService } from "../../services/notification.service";
import { SpinnerService } from "../../services/spinner.service";
import { SessionService } from "src/app/services/session.service";
import SavedLocation from "src/app/constants/firestore/saved-location";
import GoogleLocation from "src/app/constants/firestore/google-location";
import { BaseComponent } from "src/app/components/base.component";
import { locationNameToRef } from "src/app/constants/firestore/location-object";


/**
 * A Dialog to choose N unadded Profiles from a single Google Account to 
 * add them. 
 */
@Component({
    selector: 'app-dialog-locations',
    templateUrl: 'dialog-locations.component.html',
  })
export class DialogLocationsComponent extends BaseComponent implements OnInit {
    allChecked: boolean;
    googleLocations: any[] = [];
    private _googleAccounts$: Observable<GoogleAccount[]>;
    selectedAccountId: string;
    // onLocationsSaved = new EventEmitter(); // TODO: Unused, remove.
    search: string;
    resultSearchLocation : SavedLocation[];
    accountId: string;
    noDataFound$ = new BehaviorSubject(false);
    progressBar$ = new BehaviorSubject(false);
    selectedLocation: any[] = []
    private _debugMode = false
  
    constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<DialogLocationsComponent>,
      private _googleLocationService: GoogleLocationService,
      private _accountService: AccountService,
      private _spinnerService: SpinnerService,
      private _sessionS : SessionService,
      private _locationService: LocationService,
      private _notificationService: NotificationService,
      private _route: ActivatedRoute,
      private _sessionTracingService: SessionTraceService,

    ) {
      super();
      this.selectedAccountId = this.data.selectedAccountId;
      this._googleAccounts$  = this.data.googleAccounts;
      this.accountId         = this.selectedAccountId?.split('/')[1];
  
      this._googleLocationService.reset();
      this._googleLocationService.loadAll(this.selectedAccountId);

      this._addFinalizer(
        () => this._accountService.reset()
      );
    }
  
    ngOnInit() : void {
      this._subscribeSafe(this._route.queryParams,
                          params => this._debugMode = (params?.mode === 'debug'));

      this.progressBar$.next(true);
      this._subscribeSafe(this._googleLocationService.locations$,
          result => this._onLocationsChanged(result),
          () => {
            this.noDataFound$.next(true);
            this.progressBar$.next(false);
          });
    }

    private async _onLocationsChanged(result : GoogleLocation[]) : Promise<void> {
      try {
        if(!result) {
          this.noDataFound$.next(true);
        }

        const resultRequest = result.map(({address, locationName, name, serviceArea}) => ({address, locationName, name, serviceArea}));

        const {gid} = this._sessionS.getSession();
        const sl = await this._locationService.filterOutLocationsAlreadyAdded(gid, this.accountId, resultRequest);
        // TODO: This code was WRONG, gl.id was never existant so nothing/everything was filtered, remove.
        // const filterL              = result.filter(g => g !== undefined && !sl.some(gl => locationName(g.name).locationId === gl.id));
        // const alreadyExistLocation = result.filter(g => g !== undefined &&  sl.some(gl => locationName(g.name).locationId === gl.id));
        // this.googleLocations = filterL;
        // TODO: this had no effect as this.googleLocations was mutated here, but then it was overwritten by filterL, remove.
        // this._verificateLocations(this.googleLocations);
        this.googleLocations = sl;
        this.resultSearchLocation = sl as any;

        // TODO: As the filters before were wrong, using alreadyExistsLocation was also wrong, remove.
        // if (this.googleLocations.length == 0 && alreadyExistLocation.length == 0 || (sl && sl.length === 0)) {
        //   this.noDataFound$.next(true);
        // } else {
        //   this.noDataFound$.next(false);
        // }
        this.noDataFound$.next(!sl);
      } finally {
        this.progressBar$.next(false);
      }
    }


    locationCheckboxDisabled(location: any) : boolean {
      return !!(!this._debugMode && location?.exists);
    }
  
    allCheckedCheckboxDisabled(googleLocations) : boolean {
      let disabledReturn = true;
      if(this._debugMode) {
        disabledReturn = false;
      }
  
      googleLocations.forEach(element => {
        if(element?.exists !== true){
          disabledReturn = false;
        }
      });
  
      return disabledReturn;
    }
  
    private _verificateLocations(locations: any[]) : void {
      locations.forEach(location => {
        const ls = location.locationState;
        if (ls) {
          if (ls.isDisconnected) {
            ls.description = 'Unavailable';
            ls.tooltip = 'Location Disconnected';
            ls.fill = 'review-response--error';
            ls.txt = 'txt--orange';
          } else if (ls.hasPendingVerification) {
            ls.description = 'Unavailable';
            ls.tooltip = 'Location Pending verification';
            ls.fill = 'review-response--error';
            ls.txt = 'txt--orange';
          } else if (ls.isDisabled) {
            ls.description = 'Unavailable';
            ls.tooltip = 'Location Disabled';
            ls.fill = 'review-response--info';
            ls.txt = 'txt--blue';
          } else if (ls.needsReverification) {
            ls.description = 'Unavailable';
            ls.tooltip = 'Location Needs Reverification';
            ls.fill = 'review-response--error';
            ls.txt = 'txt--orange';
          } else if (ls.isSuspended) {
            ls.description = 'Unavailable';
            ls.tooltip = 'Location Suspended';
            ls.fill = 'review-response--info';
            ls.txt = 'txt--blue';
          } else if (ls.isDuplicate) {
            ls.description = 'Unavailable';
            ls.tooltip = 'Location Duplicate';
            ls.fill = 'review-response--error';
            ls.txt = 'txt--orange';
          } else if (!ls.isVerified) {
            ls.description = 'Unavailable';
            ls.tooltip = 'Location Unverified';
            ls.fill = 'review-response--error';
            ls.txt = 'txt--orange';
          }
        }
      });
    }
  
  
    filterLocation() : void {
      const search = this.search.toLowerCase();
      this.googleLocations = this.resultSearchLocation.filter(location =>
        location.locationName.toLowerCase().includes(search) ||
        this._locationService.formatAddress(location.address || location.location.address).toLowerCase().includes(search)
      );
    }
    
    async addLocation() : Promise<void> {
      // Need a cleaner way of removing accessory data so we can save a clean object and add address format
      const {gid, subscription} = this._sessionS.getSession();

      for(const loc of this.selectedLocation) {
        delete loc.isChecked;
        loc.formatAddress = this._locationService.formatAddress(loc.address);
        loc.gid           = gid;
      };
  
      try {
        this._spinnerService.loading$.next(true)

        //-- Saves the account previously selected, needed when the account doesn't exist
        // yet. TODO: This step can be optimized out when the account already exists.
        const googleAcc = (await this._googleAccounts$.pipe(take(1)).toPromise()).find(acc => acc.name === this.selectedAccountId)
        const [acc] = await this._accountService.saveAll([googleAcc]);

        //-- Add the list of selected locations 
        await this._locationService.addNewLocations(acc.accountId, 
                                                    this.selectedLocation.map(loc => ({locationName : loc.locationName,
                                                                                       locationId    : locationNameToRef(loc).locationId})));

        //-- Refresh
        await this._locationService.fetchAccountLocationsAndEmit(gid, acc.accountId);

        //-- Trace Profiles adding

        // Getting first location added date before saving locations
        if(!subscription.firstLocationAddedOn)
          this._sessionTracingService.onFirstLocationAdded();

        // this.onLocationsSaved.emit(true); // TODO: Unused, remove. 

        for (const sl of this.selectedLocation) {
          // Notification informative create location
          const l = { accountId: acc, 
                      locationId: locationNameToRef(sl).locationId, 
                      ...sl }
          const meta = this._notificationService.getMetaTypeLog(TYPE_LOG_LOCATION, l);
          this._notificationService.saveNotification(string_message(Messages.notifications.LOCATION_TOGGLE, [l.locationName, l.formatAddress, CREATE_DATA]), 
                                                     NOTIFICATION_GENERAL, TYPE_LOG_LOCATION, meta)
        }

        const {locationId} = locationNameToRef(this.selectedLocation[0]);
        this._sessionTracingService.onLocationAdded(acc.accountId, locationId, this.selectedLocation.length)
      } catch(err) {
        console.error('Error saving accounts', err);
      } finally {
        this._spinnerService.loading$.next(false);
        this.dialogRef.close(true);
      }
    }
  
    // check/uncheck all checkbox
    toggleCheckAll() : void {
      if (!this.allChecked) 
        this.selectedLocation = []

      this.googleLocations.forEach(location => {
        if(!location?.exists || this._debugMode){
          location.isChecked = this.allChecked;
          if (this.allChecked) {
            this.selectedLocation.push(location)
          }
        }
      });
    }
  
    // if every item is checked, check the Select All checkbox
  
    isAllChecked(location: any) : void {
      if (location.isChecked) {
        this.selectedLocation.push(location)
      } else {
        this.selectedLocation = this.selectedLocation.filter(l => location.name !== l.name)
      }
      // TODO: Code without any effects, intention was?, remove. 
      // this.googleLocations.every(item => {
      //   return item.isChecked === true;
      // });
    }
  
    // TODO: Getter called from template, slow
    get someCheck(): boolean {
      return this.selectedLocation.some(item => item.isChecked === true);
    }
  }
  