<!--Website-->
<div class="box__item box__item--editable" [class.align-items-start]="url" [class.pb--0]="isUnderReview">
  <div class="d-flex w--100" [ngClass]="url ? 'align-items-start' : 'align-items-center'">
    <div class="icon icon--rounded icon--gray mr--20 d-block"><i class="fas fa-globe-americas"></i></div>

    <div class="d-block" [class.txt--medium-gray]="isUnderReview">
      <h4 class="w--100 txt--md fw--700">Website URL</h4>
      <p class="w--100 wrap txt--md">
        <ng-container *ngIf="!isApple; else isAppleUrls">
          <span [outerHTML]="url ? url : isEdit ? 'Add Website' : '-'"></span>
        </ng-container>
        <ng-template #isAppleUrls>
          <ng-container *ngIf="homeUrl; else isAppleEmpty">
            <span>{{ homeUrl.url }}</span>
          </ng-container>
          <ng-template #isAppleEmpty>
            <span>{{ isEdit ? 'Add Website' : '-' }}</span>
          </ng-template>
        </ng-template>
      </p>
    </div>

  </div>
  <button mat-button (click)="openUrlDialog()" class="btn btn-icon" *ngIf="isEdit">
    <i class="fas fa-pencil m--0"></i>
  </button>
</div>
<div *ngIf="isUnderReview" class="txt-pendingMask">
  <span class="under-review" role="alert">Under review</span>
</div>
