import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../services/snackbar.service';
import { ServiceList, ServiceUpdateEvent } from '../../constants/google/service-list';
import { ServicesLocationComponent } from '../dashboard-location/services-location/services-location.component';
import { GoogleService } from '../../services/google.service';

type Category = {categoryId: string, 
                 displayName: string, 
                 primary: boolean, 
                 serviceTypes: {displayName: string, 
                               serviceTypeId: string}[]
                };

@Component({
  selector: 'app-dashboard-services',
  templateUrl: './dashboard-services.component.html',
  styleUrls: ['./dashboard-services.component.scss']
})
export class DashboardServicesComponent implements OnInit {
  @ViewChild('services') ServicesComponent: ServicesLocationComponent;
  progress   = false;
  accountId  = '';
  locationId = '';
  serviceList: ServiceList[] = [];
  primaryCategory: Category;
  aditionalCategories: Category[] = [];
  isUpdatedSave = false;
  constructor(
    private  activateRoute: ActivatedRoute,
    private googleS: GoogleService,
    private snackS: SnackbarService
  ) { }

  ngOnInit() {
    this.locationId = this.activateRoute.snapshot.parent.params.locationId;
    this.accountId = this.activateRoute.snapshot.parent.params.accountId;

  }

  dispatchAction(action: string) {
    if (action === 'updateService') {
      this.isUpdatedSave = true;
      this.serviceList = this.ServicesComponent.ServiceList;
      this.googleS.updateServices(this.accountId, this.locationId, this.serviceList).toPromise().then( m => {
        if (m) {
          this.snackS.openSuccess('The services was uploaded successfully', 2000);
          this.ServicesComponent.setServiceList(m)
        } else {
          this.snackS.openError('An error has occurred with google', 2000);
        }
        this.isUpdatedSave = false;
      }).catch( err => {
        this.isUpdatedSave = false;
        this.snackS.openError('An error has occurred with google', 2000)
      })
    }
  }

  updateServicesList(serviceEvent: ServiceUpdateEvent): void {
    this.ServicesComponent.refreshServices(serviceEvent);
  }



}
