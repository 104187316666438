import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {LocationEditService} from "../../services/location-edit.service";

@Component({
  selector: 'app-modal-labels',
  templateUrl: './modal-labels.component.html',
  styleUrls: ['./modal-labels.component.scss']
})
export class ModalLabelsComponent implements OnInit {

  labelList = [];
  @Input() bulk: boolean = false;
  @Output() validResult: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    public dialogRef: MatDialogRef<ModalLabelsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private locationEditS: LocationEditService
  ) {
    if (data) {
      if (data.list === null || data.list === undefined) {
        this.labelList = [];
      } else {
        data.list.forEach(item => {
          this.labelList.push(item);
        });
      }
    }
  }

  ngOnInit() {
  }

  // add label
  addNewLabel() {
    this.labelList.push('');
  }

  // remove label and reindex array
  removeLabel(index): void {
    if (index > -1) {
      this.labelList.splice(index, 1);
    }
  }

  apply(): void {
    this.locationEditS.locationEdit.labels = this.labelList.filter(el => el);
    this.locationEditS.setAndUpdate().toPromise().then(() => {
        this.dialogRef.close()
      }
    )
  }

  handleChange($event, i: number) {
    this.labelList[i] = $event.target.value
  }

  getResult(): { [k: string]: any[] } {
    if (this.labelList.length === 0) {
      this.validResult.emit(false);
      return
    }
    this.validResult.emit(true);
    return {labels: this.labelList.filter(el => el) }
  }
}
