<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="showLoader">
    <mat-spinner></mat-spinner>
</div>
<app-modal-stepper
  [title]="[getReportTitle(reportType)]"
  [linear]="true"
  [finalAction]="'save'"
  [scan_action]="false"
  [step]="step"
  [reportType]="reportType"
  [drawer]="drawer"
  [isSliderOpened]="isSliderOpened"
  [disabledStep]="disabledNextStep"
  [isSlider]="true"
  (doValidationMetrics)="doValidationMetrics($event)"
  (onStepChange)="onStepChange($event)"
  (backStep)="backStep($event)"
  (completed)="completeSteeper()"
  (selectionChange)="validateDates($event)"
  (destroyData)="ngOnDestroy()"
>

  <cdk-step [completed]="completeStep1">
    <app-toggle-locations-in-account
      [isSliderOpened]="isSliderOpened"
      class="locations-no-padding"
      (selected)="selectedLocations($event)"
      [reportEdit]="reportEdit?.accounts"
      [isSlider]="true"
      [filterProtocolLocations]="this.reportType == 'Review Assistant'">
    </app-toggle-locations-in-account>
  </cdk-step>

  <cdk-step [completed]="completeStep2">
    <form [formGroup]="form">
      <div class="dialog__content dialog__content--height pt--0" mat-dialog-content>
        <div class="dialog-row p-tb--0">
          <mat-label class="d-block mt--20 mb--20 txt--md fw--700">Report Name</mat-label>
          <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
            <input [formControl]="reportName" matInput required placeholder="Report Name" class="input-group__field"/>
            <mat-error *ngIf="reportName.errors">
              <p>Report Name is required</p>
            </mat-error>
          </mat-form-field>
        </div>

        <ng-container *ngIf="reportType === 'rollup' || reportType === 'review' || reportType === 'performance-review' || reportType === 'performance-rollup' || reportType === 'comparison' || reportType === 'performance-comparison'">
          <div class="dialog-row p-tb--0" style="margin-top: 10px; margin-bottom: 20px;">
            <mat-slide-toggle labelPosition="before"  formControlName="scheduleReport" color="primary" [disabled]="viewModeChecked == 'legacy'">Scheduled Report?</mat-slide-toggle>
          </div>
        </ng-container>
        
        <ng-container>
          <ng-container *ngIf="!scheduleReport.value; else DynamicDates">
  
            <ng-container *ngIf="!dynamic.value && reportType != 'qanda'">
              <div class="dialog-row p-tb--0" *ngIf="this.reportType !== 'Review Assistant'">
                <mat-label class="d-block mt--20 mb--20 txt--md fw--700">Range <span *ngIf="viewModeChecked != 'legacy'">A</span></mat-label>
                <app-date-picker
                  class="datepicker-rangeA"
                  *ngIf="reportType !== 'keyword' && !showLoader" 
                  [reportType]="reportType"
                  [dataPicker]="dataPicker"
                  [maxDate]="maxDate"
                  [minDateRange]="minDate"
                  (dateSelected)="handleDateSelected($event)"
                  [isForm]="true"
                  [select]="selectDate"
                  [hasDateLimitsInfo]="showTooltipsOnDisabledDates"
                  [showTooltipsOnDisabledDates]="showTooltipsOnDisabledDates">
                </app-date-picker>
                
                <ejs-daterangepicker 
                  *ngIf="reportType === 'keyword' && !showLoader" 
                  #dateRangePickerMonthly 
                  (change)="handleDateSelected($event)"
                  [start]="montPicker?.start"
                  [startDate]="reportData?.edit?.fromDate || montPicker?.lastYearStart"
                  [endDate]="reportData?.edit?.toDate || montPicker?.lastYearEnd"
                  [format]="montPicker?.format" 
                  [depth]="montPicker?.depth"
                  (created)="daterangepickerOpen()"
                  (click)="dateRange.show()"
                  [max]="maxDate"
                  [min]="minDate"
                  [allowEdit]="false"
                  [showClearButton]="false"
                >
                  <e-presets>
                    <e-preset label="Last Month" [start]="montPicker?.lastStart" [end]="montPicker?.lastEnd"></e-preset>
                    <e-preset label="YTD" *ngIf="!montPicker?.lastEnd?.toString()?.includes('Dec')"
                      [start]="montPicker?.thisYearStart" 
                      [end]="montPicker?.lastEnd">
                    </e-preset>
                  </e-presets>
                </ejs-daterangepicker>
              </div>
            </ng-container>

            <ng-container *ngIf="dynamic.value || reportType == 'qanda'">
              <div class="dialog-row pb--0">
                <mat-label class="d-block mt--20 mb--20 txt--md fw--700">
                  Dynamic Range
                  <span *ngIf="reportType == 'performance-rollup' || reportType == 'performance-comparison'"
                    matTooltip="Dynamic dates automatically update the time range of the report each time it is opened. Performance insights from Google are latent up to 7 days from the current date and data within the last week will be unavailable."
                    matTooltipPosition="above"
                    matTooltipClass="mat-tooltip--override"
                    class="mat-tooltip tooltip tooltip--info tooltip-dynamic-range">
                    <em class="fas fa-info"></em>
                  </span>
                </mat-label>
                <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
                  <mat-select [formControl]="DynamicRangeControl" class="input-group__field" placeholder="Select one" [disabled]="viewModeChecked == 'legacy'">
                    <mat-option *ngFor="let item of dynamicRangeOptions" [value]="item.value" [disabled]="isDynamicOptionEnabled(item.value)">{{item.displayName | titlecase}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error *ngIf="!DynamicRangeControl.valid">
                  <p>Dynamic range is required</p>
                </mat-error>
              </div>
            </ng-container>
  
            <div class="dialog-row pb--0" *ngIf="reportType == 'comparison' || reportType === 'performance-comparison' && !dynamic.value">
              <mat-label class="d-block mt--20 mb--20 txt--md fw--700" *ngIf="reportType == 'comparison' || reportType === 'performance-comparison'">Compare</mat-label>
              <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
                <mat-select (selectionChange)="periodChange($event)" [formControl]="period" class="input-group__field" [disabled]="viewModeChecked == 'legacy'">
                  <mat-option [disabled]="reportType == 'comparison'">Select Period</mat-option>
                  <mat-option value="{{ compare.value }}" [disabled]="isOptionEnabled(compare?.value)" *ngFor="let compare of compareList">{{ compare.text }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
  
            <ng-container *ngIf="dataPicker?.rangeB">
              <div class="dialog-row pb--0" *ngIf="(reportType == 'comparison' || reportType =='performance-comparison') && dataPicker?.rangeB && period.value && !dynamic.value">
                <p class="d-block mt--20 mb--20 txt--md fw--700">Range B</p>
                <p *ngIf="(period.value == 'period' || period.value == 'month' || period.value == 'year') && dataPicker?.rangeB">
                  {{ dataPicker?.rangeB.start | zuluToDate }} -
                  {{ dataPicker?.rangeB.end | zuluToDate }}
                </p>
                <app-date-picker
                  class="datepicker-rangeB"
                  [dataPicker]="dataPicker"
                  [reportType]="reportType"
                  [maxDate]="maxDate"
                  [minDateRange]="minDate"
                  [rangeB]="true"
                  [isForm]="true"
                  [disabled]="rangeBDisabled"
                  [select]="selectDateB"
                  [hasDateLimitsInfo]="showTooltipsOnDisabledDates"
                  (dateSelected)="handleDateBSelected($event)"
                  dropsChange="up"
                  [showTooltipsOnDisabledDates]="showTooltipsOnDisabledDates"
                  *ngIf="period.value == 'custom'">
                </app-date-picker>
              </div>
              <mat-error *ngIf="period?.value === 'custom' && rangeBPeriod != -1 && rangeAPeriod !== rangeBPeriod">
                <p>Periods for both ranges must contain the same amount of days</p>
                <p>Range A currently has <b>{{ rangeAPeriod }}</b> days, Range B currently has <b>{{ rangeBPeriod }}</b> days</p>
              </mat-error>
              <mat-error *ngIf="startDateTimeB?.errors || endDateTimeB?.errors">
                <p>Range B is required</p>
              </mat-error>
            </ng-container>
  
            <!--checkbox buttons-->
            <div class="checkbox-group checkbox-group--margin checkbox-group--padding d-flex justify-content-start align-items-center mb--10">
              <ng-container *ngIf="reportType !== 'revenue' && this.reportType !== 'Review Assistant' && reportType != 'qanda'">
                <mat-checkbox 
                  [checked]="dynamic.value"
                  (change)="changeDynamicDates($event)"
                  class="d-flex align-items-center txt--sm fw--medium"
                  [disabled]="viewModeChecked == 'legacy'"
                  matTooltip="Selecting dynamic dates will return results for the report automatically. For example “last month” will always show the previous month's data from the date of loading the report."
                  matTooltipPosition="right"
                  matTooltipClass="tooltip tooltip--white">
                  Dynamic Dates <em style="margin-left: 10px; color: #999ea2;" class="fas fa-info-circle"></em>
                </mat-checkbox>
              </ng-container>
              <mat-checkbox
                *ngIf="this.reportType !== 'Review Assistant' && reportType != 'qanda'"
                class="d-flex align-items-center txt--sm fw--medium"
                [checked]="lockDates.value"
                matTooltip="By clicking lock dates, the dates of the report cannot be changed while viewing it"
                matTooltipPosition="right"
                matTooltipClass="tooltip tooltip--white"
                (change)="changeLockDates($event)"
                [disabled]="viewModeChecked == 'legacy'">
                  Lock Dates <em style="margin-left: 10px; color: #999ea2;" class="fas fa-info-circle"></em>
              </mat-checkbox>
            </div>
          </ng-container>

          <ng-template #DynamicDates>
            <!--Frecuency-->
            <div class="dialog-row pb--0">
              <mat-label class="d-block mt--20 mb--20 txt--md fw--700">Frequency</mat-label>
              <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
                <mat-select [formControl]="FrecuencyControl" class="input-group__field" [disabled]="viewModeChecked == 'legacy'">
                  <mat-option value="WeeklyOn">Weekly on (Day of Week)</mat-option>
                  <mat-option value="MonthlyOn">Monthly on (Day of Month)</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-error *ngIf="FrecuencyControl.errors">
                <p>Frequency is required</p>
              </mat-error>
            </div>
  
            <div class="dialog-row pb--0">
              <app-frecuency-selector-report [disabled]="viewModeChecked == 'legacy'" [FrecuencySelect]="FrecuencyControl.value" [frecuencyEdit]="repeatOn" (FrecuencyOutput)="repeatOn = $event" ></app-frecuency-selector-report>
            </div>
            
            <ng-container>
              <ng-container>
                <div class="dialog-row pb--0">
                  <mat-label class="d-block mt--20 mb--20 txt--md fw--700">Dynamic Range</mat-label>
                  <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
                    <mat-select [formControl]="DynamicRangeControl" class="input-group__field" placeholder="Select one" [disabled]="viewModeChecked == 'legacy'">
                      <mat-option *ngFor="let item of dynamicRangeOptions" [value]="item.value">{{item.displayName | titlecase}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-error *ngIf="!DynamicRangeControl.valid">
                    <p>Dynamic range is required</p>
                  </mat-error>
                </div>
              </ng-container>
            </ng-container> 
  
            <div class="dialog-row pb--0">
              <mat-label class="d-block mt--20 mb--20 txt--md fw--700">Recipents (Email)</mat-label>
              <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
                <div class="row">
                  <div class="col-11" [class.col-12]="viewModeChecked != 'new'">
                    <input [formControl]="RecipentsControl" matInput required placeholder="recipent@mail.com" class="input-group__field" [readonly]="viewModeChecked == 'legacy'"/>
                  </div>
                  <button mat-flat-button color="primary" class="btn btn-icon btn-icon--lg bg--gray txt--align--end recipent-btn" *ngIf="viewModeChecked == 'new'" (click)="addNewRecipent()">
                    <em class="fas fa-plus"></em>
                  </button>
                </div>
              </mat-form-field>
              <mat-error *ngIf="RecipentsEmails.length === 0">
                <p>Recipents are required</p>
              </mat-error>
  
              <mat-list>
                <mat-list-item class="selectable-list" *ngFor="let email of RecipentsEmails">
                  <h6 matLine> {{email}} </h6>
                  <i matListIcon class="far fa-trash-alt cursor--pointer" *ngIf="viewModeChecked == 'new'" (click)="deleteRecipent(email)"></i>
                </mat-list-item>
                <mat-divider></mat-divider>
              </mat-list>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </form>
  </cdk-step>

  <cdk-step [editable]="false" *ngIf="reportType == 'comparison' || reportType === 'performance-comparison'" [completed]="completeStep3">
    <app-check-metrics
      [isEdit]="isEdit"
      [isSliderOpened]="isSliderOpened"
      [metrics]='metricsChecked'
      [viewMode]="viewModeChecked"
      [accountsSelected]="accountsReport"
      [reportType]="reportType"
      [metricVerification]="metricVerification"
      (changedMetricsValidation)="doValidationMetrics($event)"
      (selected)="selectedMetrics($event)" 
    >
    </app-check-metrics>
  </cdk-step>
</app-modal-stepper>
  