import {Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter} from '@angular/core';
import {ModalRegularHoursComponent} from "../../modal-regular-hours/modal-regular-hours.component";
import {MatDialog} from "@angular/material/dialog";
import {LocationService} from "../../../services/location.service";

@Component({
  selector: 'app-location-regular-hours',
  templateUrl: './location-regular-hours.component.html',
  styleUrls: ['./location-regular-hours.component.scss'],
})
export class LocationRegularHoursComponent implements OnInit, OnChanges {
  @Input() regularHours: any;
  @Input() placeId: any;
  @Input() isEdit =  true;
  @Input() isUnderReview = false;
  @Input() countSpecialHours = 0;
  @Input() countMoreHours = 0;
  @Input() isApple = false;
  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter();

  periods: any[];
  periodsByDay: any;
  weekDaysRegular: any;
  public sectionName = 'Regular Hours'

  constructor(private dialog: MatDialog, private locationS: LocationService) {
  }

  ngOnInit() {
    if (!this.regularHours) {
      this.regularHours = {periods: []};
    } else {
      this.periods = this.locationS.verifyOpen(this.regularHours.periods);
      this.periods = this.locationS.getContinuousHours(this.periods);
      this.periodsByDay = this.locationS.sortPeriodsByDay(this.periods);  
    }

    this.sectionName = this.isApple ? 'Hours' : 'Regular Hours';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.regularHours.firstChange) {
      this.periods = changes.regularHours.currentValue.periods;
      this.periods = this.locationS.verifyOpen(this.periods);
      this.periods = this.locationS.getContinuousHours(this.periods);
      this.periodsByDay = this.locationS.sortPeriodsByDay(this.periods);
    }
  }

  openRegularHoursDialog() {
    const dialogRef = this.dialog.open(ModalRegularHoursComponent, {
      width: '680px',
      data: {
        hasSpecialHours: this.countSpecialHours > 0 ? true : false,
        hasMoreHours: this.countMoreHours > 0 ? true : false,
        weekDays: this.periods,
        placeId: this.placeId
      }
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.weekDaysRegular = result.newList;
        this.onUpdate.emit(true);
      }
    });
  }

  originalOrder = (a, b) => 0;
}
