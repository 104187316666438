import { IInsightsReportData } from './../../../constants/insights-report';
import { map, catchError } from 'rxjs/operators';
import { forkJoin, Subject, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as ENV } from '@environment';

import { IReportFilterConfigData } from './../../../constants/report-filter-config-data';

@Injectable({
  providedIn: 'root'
})
export class InsightReportDataService {

  private _insightsReportDataSubject = new Subject<any>(); // define the correct type

  constructor(
    private _http: HttpClient
  ) { }

  get insightsReportDataSubject$() {
    return this._insightsReportDataSubject.asObservable();
  }

  getInsightsData(filterConfig: IReportFilterConfigData) {
    const data = {
      ...filterConfig,
      viewBy: filterConfig.granularity.toLowerCase()
    };

    const insights$ = this._http.post(`${ENV.apiUrl}/v2/locations/search-insights`, data);
    const ratings$ = this._http.post(`${ENV.apiUrl}/v2/reviews/ratings`, data);
    const views$ = this._http.post(`${ENV.apiUrl}/v2/locations/views`, data);

    const observables = [insights$, ratings$, views$];

    forkJoin(observables)
    .pipe(map((res: any) => {
      if (!res) {
        return of(null);
      }
      const primaryActions = { ...res[0].primaryActions, labels: res[0].labels };
      const secondaryActions = { ...res[0].secondaryActions, labels: res[0].labels };
      const searchImpressions = res[0].searchImpressions ? { ...res[0].searchImpressions, labels: res[0].labels } : null;

      const reportData: IInsightsReportData = {
        granularity: filterConfig.granularity,
        labels: res[0].labels,
        stats: [
          primaryActions,
          secondaryActions,
          searchImpressions
        ],
        reviewsData: {
          data:   res[1].reviews,
          labels: res[1].dates,
          total:  res[1].total_reviews
        },
        averageData: {
          data:   res[1].avg_rating,
          labels: res[1].dates,
          total:  res[1].total_avg
        },
        viewsData: {
          items: res[2].items,
          labels: res[2].labels,
          title: res[2].title,
          totals: res[2].totals
        }
      }
      this._insightsReportDataSubject.next(reportData);
    })).subscribe();
  }
}
