import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-brand',
  templateUrl: './location-brand.component.html',
  styleUrls: ['./location-brand.component.scss']
})
export class LocationBrandComponent implements OnInit {
  @Input() isUnderReview = false;
  @Input() brand = null;

  public sectionName = 'Brand';
  

  constructor() { }

  ngOnInit() {
  }

  getName(): string {
    return this.brand.brandDetails.displayNames.find(el => el.primary)?.name
  }

  getProcessedUrl(): string {
    if (!this.brand.brandLogo) return '';
    return this.brand.brandLogo.startsWith('http') ? this.brand.brandLogo : `https://${this.brand.brandLogo}`;
  }

}
