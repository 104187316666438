import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PriceList } from '../../../constants/google/price-list-section';
import { GoogleService } from '../../../services/google.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { defaultMenu, FoodMenu, FoodMenuSection, convertCusine, FoodMenuItem, MenuUpdateEvent } from '../../../constants/google/menuList';
import { ModalFormItemComponent } from './modal-form-item/modal-form-item.component';
import { ModalFormSectionComponent } from './modal-form-section/modal-form-section.component';
import { ModalFormMenuComponent } from './modal-form-menu/modal-form-menu.component';

@Component({
  selector: 'app-menu-location',
  templateUrl: './menu-location.component.html',
  styleUrls: ['./menu-location.component.scss']
})
export class MenuLocationComponent implements OnInit {
  @Input() foodMenuList: FoodMenu[] = Object.assign([], defaultMenu);
  sections: any;
  priceList: PriceList;
  @Input() type: string;
  @Input() accountId: string;
  @Input() locationId: string;
  @Input() onlyShow: boolean = false;
  @Input() bulk: boolean = false;
  @Output() validResult: EventEmitter<boolean> = new EventEmitter(false);

  private index: number;
  loading: boolean = false;

  showMoreDescriptionMessage = false;
  showMoreMessage = false;
  showMoreMessageIndex: string;

  constructor(
    public dialog: MatDialog,
    private googleS: GoogleService,
    private snackS: SnackbarService
  ) { }

  ngOnInit() {}

  addSection(foodMenuIndex: number = 0, sectionIndex: number = null) {
    const data: any = {
      type: this.type,
      priceIndex: this.index,
      foodMenuIndex
    };

    if (sectionIndex !== undefined) {
      data.section = this.foodMenuList[foodMenuIndex]?.sections[sectionIndex];
      data.sectionIndex = sectionIndex;
    }
    const dialogRef = this.dialog.open(ModalFormSectionComponent, {
      width: '680px',
      panelClass: 'overflow--visible',
      data
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().take(1).subscribe( (result: {action: string, item: FoodMenuSection}) => {
      if (result) {
        if (result.action === 'added') {
          this.foodMenuList[foodMenuIndex].sections.push(result.item)
        } else if (result.action === 'update') {
          this.foodMenuList[foodMenuIndex].sections[sectionIndex].labels = result.item.labels;
        } else if (result.action === 'remove') {
          this.foodMenuList[foodMenuIndex].sections.splice(sectionIndex, 1)
        }
      }
    })

  }

  setDataMenu(foodMenu: FoodMenu[]) {
    this.foodMenuList = Object.assign([], foodMenu);
  }

  getResult(): FoodMenu[] {
    if (this.foodMenuList.length === 0 || this.foodMenuList[0].sections.length === 0) {
      this.validResult.emit(false);
      return
    }
    this.validResult.emit(true);
    return this.foodMenuList;
  }

  convertKeysCuisines(array: string[]): string[] {
    return array.map( c => convertCusine(c))
  }

  refreshMenu(menuEvent: MenuUpdateEvent): void {
    this.foodMenuList = menuEvent.menu;
    if (menuEvent.error) {
      this.snackS.openError('Failed to fetch', 2000);
    } else {  
      this.snackS.openSuccess('The menu has been updated successfully', 2000);
    }
  } 

  addItem(foodMenuIndex: number = 0, sectionIndex: number, itemIndex: number = null): void {
    if (this.onlyShow) {
      return;
    }
    const data: any = {
      foodMenuIndex,
      sectionIndex,
      typePrice: 'fixed'
    };
    if (itemIndex !== null) {
      const menuItem = this.foodMenuList[foodMenuIndex]?.sections[sectionIndex].items[itemIndex];
      data.menuItems = [menuItem];
      if  (menuItem.attributes) {
        if (menuItem.attributes.price) {
          if (Number(menuItem.attributes.price.units) === 0) data.typePrice = 'free'
        } else {
          data.typePrice = 'noPrice'
        }
      } else {
        data.typePrice = 'noPrice'
      }
    }

    const dialogRef = this.dialog.open(ModalFormItemComponent, {
      width: '680px',
      panelClass: 'overflow--visible',
      data
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe((result: {action: string, item: FoodMenuItem[]}) => {
      if (result) {
        if (result.action === 'added') {
          this.foodMenuList[foodMenuIndex].sections[sectionIndex].items.push(result.item[0])
        } else if (result.action === 'update') {
          this.foodMenuList[foodMenuIndex].sections[sectionIndex].items[itemIndex] = result.item[0];
        } else if (result.action === 'remove') {
          this.foodMenuList[foodMenuIndex].sections[sectionIndex].items.splice(itemIndex, 1)
        }
      }
    });
  }

  editMenu(foodMenuIndex: number = 0) {
    const foodMenu: FoodMenu = this.foodMenuList[foodMenuIndex];
    const dialogRef = this.dialog.open(ModalFormMenuComponent, {
      width: '680px',
      panelClass: 'overflow--visible',
      data: {
        foodMenuIndex,
        foodMenu
      }
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().take(1).subscribe((result: {action: string, item: FoodMenu}) => {
      if (result) {
        if (result.action === 'updated') {
          this.foodMenuList[foodMenuIndex].labels = result.item.labels;
          this.foodMenuList[foodMenuIndex].cuisines = result.item.cuisines;
        }
      }
    });
  }

  parseMoney(units, nanos) : string {
    if (units === undefined && nanos === undefined) {
      return '0';
    } else if (units !== undefined && nanos === undefined) {
      return `${units}.0`;
    } else if (units === undefined && nanos !== undefined) {
      return `0.${nanos}`
    } else {
      return `${units}.${nanos}`
    }
  }

  get MenuList(): FoodMenu[] {
    return this.foodMenuList
  }

  toggleMessage(i): void {
    this.showMoreMessage = !this.showMoreMessage;
    this.showMoreMessageIndex = i;
    return;
  }

  async apply(): Promise<void> {
    this.loading = true;
    await this.googleS.updateMenuList(this.accountId, this.locationId, this.priceList)
      .then( (data) =>{
        if (data.success) this.snackS.openSuccess(data.message, 2000);
        else this.snackS.openError(data.message, 2000);
        this.loading = false
      })
      .catch( () => this.loading = false );
  }

}
