// dep
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

// app
import {ModalCompanyAddressComponent} from '../../modal-company-address/modal-company-address.component';

@Component({
  selector: 'app-location-address',
  templateUrl: './location-address.component.html',
  styleUrls: ['./location-address.component.scss']
})
export class LocationAddressComponent {
  @Input() address: any;
  @Input() isEdit = true;
  @Input() lenguageCode = "";
  @Input() isUnderReview = false;
  @Input() isApple = false;
  @Output() onUpdate = new EventEmitter<boolean>();

  constructor(
    private dialog: MatDialog) {
  }

  // edit address
  public openAddressDialog() {
    const dialogRef = this.dialog.open(ModalCompanyAddressComponent, {
      width: '680px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res != '') this.onUpdate.emit(true);
    });
  }

}
