import { IDayTime, ISpecialHours } from './../../../constants/abc-location-info';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-location-abc-hours',
  templateUrl: './location-abc-hours.component.html',
  styleUrls: ['./location-abc-hours.component.scss']
})
export class LocationAbcHoursComponent implements OnInit {
  @Input() regularHours: IDayTime[] = [];
  @Input() specialHours: ISpecialHours[] = [];
  @Input() isEdit = false;
  constructor() { }

  ngOnInit() {
  }

}
