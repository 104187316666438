import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-defender',
  templateUrl: './modal-defender.component.html',
  styleUrls: ['./modal-defender.component.scss']
})
export class ModalDefenderComponent implements OnInit {
  public consentAccepted = false;

  constructor(
    public dialogRef: MatDialogRef<ModalDefenderComponent>,
  ) { }

  ngOnInit() {
  }

  enableDefender() {
    this.dialogRef.close(true);
  }

}
