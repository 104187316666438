import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DatePickerComponent } from './date-picker.component';
@NgModule({
    declarations: [DatePickerComponent],
    imports: [
        CommonModule,
        FormsModule,
        NgxDaterangepickerMd
    ],
    exports: [DatePickerComponent]
})
export class DatePickerModule { }