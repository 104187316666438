// dep
import {Injectable} from '@angular/core';
import {MatSnackBar } from '@angular/material/snack-bar';

// app
import {SnackBarComponent} from '../modules/snack-bar/snack-bar.component';

enum SnackType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

const DEFAULT_DURATION = 4000

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private snackBar: MatSnackBar
  ) {}

  openSuccess(message: string, duration = DEFAULT_DURATION, skipCapitalizeFirstLetter: Boolean = false) : void {
    this._open(message, duration, SnackType.SUCCESS, skipCapitalizeFirstLetter);
  }

  openError(message: string, duration = DEFAULT_DURATION, skipCapitalizeFirstLetter: Boolean = false) : void {
    this._open(message, duration, SnackType.ERROR, skipCapitalizeFirstLetter);
  }

  openInfo(message: string, duration = DEFAULT_DURATION, skipCapitalizeFirstLetter: Boolean = false) : void {
    this._open(message, duration, SnackType.INFO, skipCapitalizeFirstLetter);
  }

  openWarning(message: string, duration = DEFAULT_DURATION, skipCapitalizeFirstLetter: Boolean = false) : void {
    this._open(message, duration, SnackType.WARNING, skipCapitalizeFirstLetter);
  }

  private _open(message: string, duration: number, panelClass: SnackType, skipCapitalizeFirstLetter: Boolean = false) : void {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        message,
        panelClass,
        skipCapitalizeFirstLetter
      },
      panelClass,
      duration,
      horizontalPosition : 'right',
      verticalPosition   : 'top',
    });
  }
}
