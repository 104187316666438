<!--FILTER-->
<div class="mat-row--filter mat-row--border-bottom d-flex justify-content-between align-items-center" *ngIf="!isClear" [style.display]="progress ? 'none' : 'block'">

  <form class="search" *ngIf="locations?.length == 0">
    <div class="input-field input-field--search">
      <div class="table__icon table__icon--lg">
        <i class="fas fa-search"></i>
      </div>
      <input #searchInput type="text" matInput placeholder="Search" (input)="handleChangeSearch(searchInput.value)"
          [matAutocomplete]="auto" [formControl]="selectedSearch" [value]="locationName" [readonly]="progress">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected()"  class="mat-autocomplete-panel--dimensions">
        <mat-option *ngFor="let option of resultLocationName | async" [value]="option" class="search-result box">
          <div class="d-flex box__item box__item--md border-bottom">
            <div class="profile__img mr--10">
              <span class="circle circle--lg circle--green">{{option?.locationName | slice:0:1 }} </span>
            </div>
            <div>
              <p class="txt--lightgray m--0" *ngIf="option?.locationName">
                {{option?.locationName}}
                <gmb-address [address]="option?.locationAddress"></gmb-address>
              </p>
            </div>
          </div>
        </mat-option>
      </mat-autocomplete>
    </div>
  </form>

  <!--radio buttons-->
  <mat-radio-group class="d-flex justify-content-center align-items-center" 
                   [selected]="null"
                   (change)="applyFilterStatus($event)" 
                   [disabled]="progress">
    <mat-radio-button [value]="false" color="primary">
      <div class="review-response review-response--xs review-response--success">
        <i class="fas fa-check-circle" data-html2canvas-ignore="true"></i>
        <p class="m--0 txt--green txt--uppercase"><strong>Complete</strong></p>
      </div>
    </mat-radio-button>
    <mat-radio-button [value]="true" color="primary">
      <div class="review-response review-response--xs review-response--error">
        <i class="fas fa-times-circle" data-html2canvas-ignore="true"></i>
        <p class="m--0 txt--red txt--uppercase"><strong>Failed</strong></p>
      </div>
    </mat-radio-button>
  </mat-radio-group>

  <!--action buttons-->
  <div class="d-flex p--lr-10" data-html2canvas-ignore="true">
    <span class="d-flex alternate-theme">
        <button mat-flat-button color="primary" class="btn btn--xs btn--xs--limited"
                (click)="clearFilters()">Clear</button>
    </span>
  </div>

  <span class="flex-grow-1"></span>

  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" *ngIf="!shared" (change)="applyFilterView($event)" [value]="selectedView" [disabled]="progress">
    <mat-button-toggle [value]="null" [checked]="true">All</mat-button-toggle>
    <mat-button-toggle [value]="true">Read</mat-button-toggle>
    <mat-button-toggle [value]="false">Unread</mat-button-toggle>
  </mat-button-toggle-group>

</div>
<app-skeleton-protocol-log *ngIf="progress || !dataSource"></app-skeleton-protocol-log>
<ng-container *ngIf="!progress && dataSource.data?.length > 0">

  <table mat-table matSort [dataSource]="dataSource" class="table table--lg table--last-row-border">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef><strong>Date</strong></th>
      <td mat-cell *matCellDef="let element" style="width: 15%;">
        <div class="cell__content flex-column justify-content-center align-items-start">
          <p class="txt--black m--0">Date of Review</p>
          <p class="txt--lightgray m--0">{{ element?.createTime | date : 'MMM d, yyyy' }}</p>
          <p class="txt--black m--0">Date of Reply</p>
          <p class="txt--lightgray m--0">{{ element?.reviewReply?.updateTime | date : 'MMM d, yyyy' }}</p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef><strong>Protocol Name</strong></th>
      <td mat-cell *matCellDef="let element" style="width: 20%;">
        <div class="cell__content flex-column align-items-start justify-content-center">
          <p class="m--0 txt--black txt--md"><strong>{{element?.locationName}}</strong></p>
          <p class="txt--lightgray">
            <gmb-address [address]='element?.locationAddress'></gmb-address>
          </p>

          <div class="mt--5 justify-content-center" style="text-align: center;" *ngIf="!shared">
            <a [routerLink]="locationUrl(element,'insights')" target="_blank" matTooltip="View Insights" matTooltipClass="tooltip tooltip--blue"
               matTooltipPosition="above"
               class="d-inline-block btn btn--blue btn-icon mr--5"><img
              src="/assets/images/icons/graph.png"
              alt="Graph Icon"></a>
            <a [routerLink]="locationUrl(element,'reviews')" target="_blank" matTooltip="See Reviews" matTooltipClass="tooltip tooltip--orange"
               matTooltipPosition="above"
               class="d-inline-block btn btn--orange btn-icon mr--5"><img
              src="/assets/images/icons/star-empty.png"
              alt="Star Icon"></a>
            <a [href]="element?.locationMap" target="_blank" matTooltip="See Location on Map" matTooltipClass="tooltip tooltip--green"
               matTooltipPosition="above" class="d-inline-block btn btn--green btn-icon"><img
              src="/assets/images/icons/pin-green.png" alt="Pin Icon"></a>
          </div>

          <p class="txt--black mt--15">{{ element?.protocol }}</p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef><strong>Review/Reply</strong></th>
      <td mat-cell *matCellDef="let element; index as i">
        <div class="cell__content flex-column justify-content-center align-items-start">
          <div class="review mb--10">
            <div class="d-flex">
              <app-starts [starts]="element?.starRatingNumber" class="stars--xs"></app-starts>
              <p class="m--0 txt--lightgray">by {{ element?.reviewer?.displayName }}</p>
            </div>
            <p class="txt--black mb--10">
              Review: {{ (showMoreMessage && showMoreMessageIndex === i) ? element?.comment : (element?.comment | limitTo: '150')}}
              <a (click)="toggleMessage(i)" class="btn txt--underline txt--lightblue"
                 *ngIf="element?.comment?.length >= 150">{{ showMoreMessage && showMoreMessageIndex === i ? 'read less' : 'read more'}}</a>
            </p>
          </div>
          <div class="reply">
            <ng-container *ngIf="element?.reviewReply?.comment; else NormalReply">
              <p class="txt--black m--0">
                Reply: {{ (showMoreReply && showMoreReplyIndex === i) ? element?.reviewReply.comment : (element?.reviewReply.comment | limitTo: '150') }}
                <a (click)="toggleReply(i)" class="btn txt--underline txt--lightblue"
                   *ngIf="element?.reviewReply?.comment?.length > 150">{{ showMoreReply && showMoreReplyIndex === i ? 'read less' : 'read more'}}</a>
              </p>
            </ng-container>
            <ng-template #NormalReply>
              <p class="txt--black m--0">
                Reply: {{ (showMoreReply && showMoreReplyIndex === i) ? element?.reviewReply : (element?.reviewReply | limitTo: '150') }}
                <a (click)="toggleReply(i)" class="btn txt--underline txt--lightblue"
                   *ngIf="element?.reviewReply?.length > 150">{{ showMoreReply && showMoreReplyIndex === i ? 'read less' : 'read more'}}</a>
              </p>
            </ng-template>

          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef><strong>Status</strong></th>
      <td mat-cell *matCellDef="let element" style="width: 10%;">
        <div class="cell__content">
          <ng-container *ngIf="element?.isDeleted; else NormalError">
            <button mat-flat-button color="warn"
                    matTooltip="This review was removed / deleted by GBP"
							      matTooltipPosition="above"
                    class="btn btn--badge btn--badge--sm txt--uppercase txt--xs">GBP DELETED</button>
          </ng-container>
          <ng-template #NormalError>
            <button mat-flat-button [color]="element?.error ? 'warn' : 'accent'" (click)="openError(element)"
                    class="btn btn--badge btn--badge--sm txt--uppercase txt--xs">{{ element?.error ? 'Failed' : 'Completed' }}</button>
          </ng-template>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="action-btn">
      <th mat-header-cell *matHeaderCellDef class="txt--right"><strong>Actions</strong></th>
      <td mat-cell *matCellDef="let element" class="txt--right column--sm" style="width: 8%;">
        <div class="cell__content">
          <button mat-flat-button color="primary" class="btn btn-icon mr--10" (click)="edit(element)">
            <i class="fas fa-pen"></i>
          </button>
          <span class="alternate-theme d-flex">
            <button mat-flat-button [color]="element?.viewed ? 'warn': 'primary'" class="btn btn-icon mr--10"
            [matTooltip]="element?.viewed ? 'Viewed': 'Not viewed'"
            matTooltipPosition="above"
            aria-label="Show if viewed"
            (click)="changeViewed(element)">
        <i class="far fa-check" *ngIf="element?.viewed"></i>
        <i class="far fa-check" *ngIf="!element?.viewed"></i>
    </button>
          </span>

          <span class="alternate-theme d-flex">
              <button (click)="delete(element)" mat-flat-button color="primary" class="btn btn-icon">
                 <i class="far fa-trash-alt"></i>
              </button>
          </span>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns" class="with-padding"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;" class="with-padding"></tr>
  </table>

  <app-paginator *ngIf="dataSource.data?.length > 0" [pagination]="pagination" (reload)="handleReload($event)"></app-paginator>
</ng-container>
<app-no-data [type]="locations?.length > 0 ? 'report': 'box--shadow'" *ngIf="dataSource.data?.length==0 && !isFiltered && !progress"></app-no-data>
