// dep
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';

// app
import {ACCOUNTS, GROUPS, LOCATIONS, WIDGET_INFO} from '../../constants/firestore/collections';
import {LocationService} from '../../services/location.service';
import {GoogleService} from '../../services/google.service';
import {SnackbarService} from '../../services/snackbar.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss']
})
export class DashboardWidgetComponent extends BaseComponent implements OnInit {

  accountId : string
  location = null;
  frameSource;
  locationId: string
  reviews: any[];
  url: string;
  color: any;
  backgroundColor: any;
  buttonColor: any;
  textColor: any;
  buttonTextColor: any;
  form: FormGroup;
  selectedStarts: number[] = [];
  average: number;
  eventsSubject = new Subject<void>()
  masterReset = 0;

  constructor(
    private _sessionS : SessionService,
    private locationS: LocationService,
    private route: ActivatedRoute,
    private googleS: GoogleService,
    private fb: FormBuilder,
    private snack: SnackbarService,
    private afs: AngularFirestore,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    this.locationId = this.route.snapshot.parent.params.locationId;
    this.accountId  = this.route.parent.snapshot.params.accountId;
    this.initForm();
  }

  ngOnInit() : void {
    this.googleS.dynamicUrl(this.locationId).subscribe(url => {
      this.url = url.shortLink;
    });
    this.cdr.detectChanges();
  }

  private get _generateFrameSource() : string {
    const {uid,gid} = this._sessionS.getSession();
    const {domainNameWithPort} = this._sessionS.getDomain()
    const order = this.order.value;

    // if (domain === 'localhost') {
    //   domain = domain + ':4200';
    // }

    let source = `https://${domainNameWithPort}/widget/${gid}/${this.accountId}/${this.locationId}/review?&uid=${uid}`;

    if (order) {
      source += `&order=${order}`;
    }

    if (this.backgroundColor) {
      source += `&back_color=${this.backgroundColor.replace('#', '')}`;
    }

    if (this.buttonColor) {
      source += `&btn_color=${this.buttonColor.replace('#', '')}`;
    }

    if (this.buttonTextColor) {
      source += `&btn_txt_color=${this.buttonTextColor.replace('#', '')}`;
    }

    if (this.textColor) {
      source += `&text_color=${this.textColor.replace('#', '')}`;
    }

    if (this.selectedStarts) {
      source += `&filter=${this.joinStars}`;
    }

    let newWidth;
    if (this.width) {
      newWidth = this.width.value + this.measure.value;
    }
    return `<iframe width="${newWidth}" height="100%" id="gmb-container-1" src="${source}" ></iframe>`;
  }

  get joinStars() {
    if (this.selectedStarts) {
      return this.selectedStarts.join();
    }
  }


  /**
   * handle method submit form and generate source url by iframe html
   */
  submit() {
    if (this.width.invalid) {
      return;
    }
    this.frameSource = this._generateFrameSource;
    this.eventsSubject.next();
  }

  getDataWidget() {
    const data: any = {};
    const order = this.order.value;
    if (order) {
      data.order = order;
    }

    data.responses = this.responses.value;

    if (this.backgroundColor) {
      data.backgroundColor = this.backgroundColor;
    }

    if (this.buttonColor) {
      data.buttonColor = this.buttonColor;
    }

    if (this.buttonTextColor) {
      data.buttonTextColor = this.buttonTextColor;
    }

    if (this.textColor) {
      data.textColor = this.textColor;
    }

    if (this.selectedStarts) {
      data.selectedStarts = this.selectedStarts;
    }
    if (this.width) {
      data.measure = this.measure.value;
      data.width = this.width.value;
    }
    return data;
  }


  /**
   * manage change selected  starts
   */
  handleStarts($event, num: number) {
    if (!this.selectedStarts) {
      this.selectedStarts = [];
    }

    if ($event.checked === true) {
      this.selectedStarts.push(num);
    }

    if ($event.checked === false) {
      const index = this.selectedStarts.findIndex(value => value === num);
      this.selectedStarts.splice(index, 1);
    }

    if (this.selectedStarts.length > 0) {
      this.selectedStarts.sort();
    }
  }

  selectedBackground($event) {
    this.backgroundColor = $event;
  }

  selectedButton($event: any) {
    this.buttonColor = $event;
  }

  selectedButtonText($event: any) {
    this.buttonTextColor = $event;
  }

  selectedText($event: any) {
    this.textColor = $event;
  }

  /**
   * this method set backgroundColor to undefined
   */
  clearedBackground() {
    this.backgroundColor = undefined;
  }


  /**
   * this method set buttonColor to undefined
   */
  clearedButtonColor() {
    this.buttonColor = undefined;
  }


  /**
   * this method set buttonTextColor to undefined
   */
  clearedButtonText() {
    this.buttonTextColor = undefined;
  }


  /**
   * this method clear text color
   */
  clearedText() {
    this.textColor = undefined;
  }


  // form initialize and getters
  initForm() {
    const {gid} = this._sessionS.getSession()

    this._subscribeSafe(
      this.afs.collection(GROUPS).doc(gid).collection(ACCOUNTS).doc(this.accountId)
              .collection(LOCATIONS).doc(this.locationId).collection(WIDGET_INFO).doc(this.locationId)
              .valueChanges(),
      (res: any) => {
        if (res) {
          this.selectedStarts = [...res.selectedStarts];
          this.backgroundColor = res.backgroundColor;
          this.textColor = res.textColor;
          this.buttonTextColor = res.buttonTextColor;
          this.buttonColor = res.buttonColor;
          this.form = this.fb.group({
            width: [res.width],
            measure: [res.measure],
            order: [res.order],
            responses: [res.responses],
          });
          this.submit();
        } else {
          this.form = this.fb.group({
            width: ['100'],
            measure: ['%'],
            order: ['createTime'],
            responses: [true],
          });
          this.submit();
        }
        this.frameSource = this._generateFrameSource;
      });
  }

  get width(): FormControl {
    return this.form?.get('width') as FormControl;
  }

  get order(): FormControl {
    return this.form?.get('order') as FormControl;
  }

  get measure(): FormControl {
    return this.form?.get('measure') as FormControl;
  }

  get responses(): FormControl {
    return this.form?.get('responses') as FormControl;
  }

  /**
   * this method copies the iframe content to clipboard
   */

  copyIframe(content) {
    content.select();
    document.execCommand('copy');
    content.setSelectionRange(0, 0);
    this.snack.openSuccess('The widget code has been copied to your clipboard!')
  }

  /**
   * this method reset the filters
   */
  resetOptions() {

    this.masterReset += 1;
    this.selectedStarts = [];
    this.clearedBackground();
    this.clearedButtonColor();
    this.clearedButtonText();
    this.clearedText();
    this.initForm();

  }

  async save() : Promise<void> {
    const {gid} = this._sessionS.getSession()
    try {
      await this.locationS.deleteWidget(gid, this.accountId, this.locationId)
      await this.locationS.saveWidget  (gid, this.accountId, this.locationId, this.getDataWidget())    
      this.snack.openSuccess('The widget has been saved')
    } catch(err) {
      console.error(err);
      this.snack.openError('There was an error saving the widget')
    }
  }


}
