// dep
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// app
import { SessionService } from './session.service';
import { DashboardPerformanceInsightComponent } from '../modules/dashboard-performance-insight/dashboard-performance-insight.component';
import { PerformanceInsightComponent } from '../modules/reporting/insight/performance-insight.component';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagResolver implements Resolve<any> {

    constructor(
        private _sessionS : SessionService
    ) {}
      
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): (
      typeof DashboardPerformanceInsightComponent 
      | typeof PerformanceInsightComponent
    ) {

      const featurePermissions = this._sessionS.getSession().features.final.updatedDatePicker;
      const componentTitle = route.data.title as string;

      // a switch to use in case we need to reutilize the resolver with other routes, at the same time
      switch (componentTitle) {
        case 'Insights':
          return (
            featurePermissions ? PerformanceInsightComponent : DashboardPerformanceInsightComponent
          );
        default:
          break;
      }
    }
}
