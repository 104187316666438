
<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="isSpinner">
  <mat-spinner></mat-spinner>
</div>

<div class="alert alert-warning w--100 mt--20" role="alert">
  <p>
    <em class="fas fa-exclamation-triangle"></em>
    Heads up. Today, we released a new version of Posts / Bulk Posts. 
    Your old scheduled and recurring Posts will be available until July 25th, 
    at which time they will stop publishing. Please re-create your scheduled 
    and recurring Posts and Bulk Posts in the New version by July 31st. 
    Be sure to delete your Posts from the old version so you don't end up with 
    duplicates.
  </p>
</div>

<div class="content__header content__header--up d-flex justify-content-between align-items-center mt--5">

  <h1><strong>Post Management</strong></h1>
  <button mat-flat-button color="accent" class="btn btn--icon btn--md" (click)="create()">
    <i class="far fa-plus"></i> Create Post
  </button>
</div>

<div class="content__body">
  <div class="table--shadow mb--30">
    <div class="mat-row mat-row--padding mat-row--border d-flex justify-content-between align-items-center bg--white">
      <mat-tab-group #tabGroup [selectedIndex]="selected.value" (selectedIndexChange)="indexChange($event)" (selectedTabChange)="tabChange($event)" class="w--100">
        <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab">
          <ng-container *ngIf="tab === 'Posted'">

            <app-post-list #tabPosted
              [displayedColumns]="displayedColumns" post_type="Posted" [dashboardType]="dashboardType"
              [accountId]="accountId" [locationId]="locationId" (isSpinner)="isSpinner = $event" (selected)="selected.setValue($event)"
            ></app-post-list>

          </ng-container>
          <ng-container *ngIf="tab === 'Pending'">
            <app-post-list #tabPending
              [displayedColumns]="displayedPendingColumns" post_type="Pending" [dashboardType]="dashboardType" [focus]="focusTab$"
              [accountId]="accountId" [locationId]="locationId" (isSpinner)="isSpinner = $event" (selected)="selected.setValue($event)"
            ></app-post-list>
          </ng-container>
          <ng-container *ngIf="tab === 'Drafts'">
            <app-post-list #tabDraft
              [displayedColumns]="displayedDraftColumns" post_type="Drafts" [dashboardType]="dashboardType"
              [accountId]="accountId" [locationId]="locationId" (isSpinner)="isSpinner = $event" (selected)="selected.setValue($event)"
            ></app-post-list>
          </ng-container>
          <ng-container *ngIf="tab === 'Archive'">

            <app-post-list #tabArchive
              [displayedColumns]="displayedColumns" post_type="Archive" [dashboardType]="dashboardType"
              [accountId]="accountId" [locationId]="locationId" (isSpinner)="isSpinner = $event" (selected)="selected.setValue($event)"
            ></app-post-list>
          </ng-container>

        </mat-tab>
      </mat-tab-group>
    </div>

  </div>

</div>

