// dep
import {Injectable} from '@angular/core'
import {HttpClient, HttpParams} from '@angular/common/http'
import {map} from 'rxjs/operators'
import {Observable} from 'rxjs'
import moment from 'moment'

// app
import {ApiResponse, ApiResponse2, Pagination} from '../constants/api-response'
import { IComparisonResponse } from '../constants/comparison'
import {DataPicker} from '../constants/data-picker'
import {Pageable} from '../constants/pageable'
import {environment as ENV} from '@environment'
import {Sort} from '../constants/sort'
import { Queue } from '../constants/firestore/enqueue'
import { LocationRef, locationRefsToPaths } from '../constants/firestore/location-object'
import { SessionService } from './session.service'

@Injectable({
  providedIn: 'root'
})
export class InsightsService {
  
  constructor(private _sessionS : SessionService,
              private http: HttpClient) {}

  /**
   * this method return metrics top to mongo db
   * @param accountId
   * @param placeId is id to google locations in firebase and mongo
   */
  getTopMetrics(locationId : string) {
    return this.http.get<ApiResponse>(`${ENV.apiUrl}/v2/locations/${locationId}/topMetrics`).pipe(map(value => value.data));
  }


  async getInsights(locations: LocationRef[], dataPicker: DataPicker, version = 'legacy') {
    const params = new HttpParams()
      .set('endDateTime', dataPicker.range.end)
      .set('startDateTime', dataPicker.range.start)
      .set('aggregate', dataPicker.aggregation?.toLowerCase())
      .set('version', version);

    const r = await this.http.post<ApiResponse>(`${ENV.apiUrl}/v2/insights/`, 
                                                { locations : locationRefsToPaths(locations) }, 
                                                { params }).toPromise()
    const d = r?.data
    return { labels: d?.labels,
             stats: [d?.actionStats, d?.viewStats, d?.searchStats, d?.localPostStats, d?.photoViews, d?.photoCount],
             popularTime: d?.popularTime
            }
  }

  getPerformanceInsights(accountId: string, gid: string, locationId: any, dataPicker: DataPicker) {
    const body = {
      startDate: moment(dataPicker.range.start, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      endDate:   moment(dataPicker.range.end).format('YYYY-MM-DD'),
      viewBy:    dataPicker.aggregation?.toLowerCase(),
      accountId,
      gid,
      locationId
    }
    
    return this.http.post<any>(`${ENV.apiUrl}/v2/locations/search-insights`, body).pipe(map(value => {
        return {
          labels: value?.labels,
          stats: [value?.primaryActions, 
                  value?.secondaryActions, 
                  value?.searchImpressions],
        };
      }));
  }

  async saveLocationStats(gid : string, accountId : string, locationId : string, startDate : string, endDate : string) : Promise<void> {
    const body = {
      startDate,
      endDate
    }

    await this.http.post(`${ENV.apiUrl}/v2/locations/retrieve-impressions/gid/${gid}/account/${accountId}/location/${locationId}`, body).toPromise()
  }


  comparison(
    locations: LocationRef[], 
    dataPicker: DataPicker, 
    metrics?: any[], 
    type = 'report', 
    isExternal = false, 
    reportId: string = null, 
    viewModeChecked = null
    ) : Observable<[] | Partial<IComparisonResponse> | IComparisonResponse> {

    let params = new HttpParams()
      .set('startDateA', dataPicker.range.start)
      .set('endDateA', dataPicker.range.end)
      .set('aggregate', dataPicker.aggregation)
      .set('type', type);


    if (dataPicker?.rangeB) {
      if (dataPicker.rangeB.start !== undefined && dataPicker.rangeB.end !== undefined) {
        params = params.append('startDateB',dataPicker.rangeB.start);
        params = params.append('endDateB',  dataPicker.rangeB.end);
      }
    }

    if (dataPicker.locations && !locations) {
      locations = dataPicker.locations;
    }

    const body : any = { locations: locationRefsToPaths(locations || []) }

    if (metrics) {
      metrics?.forEach(l => {
        params = params.append('metrics', l);
      });
    }

    // const headers = {gid: !isExternal ? this.auth.session.gid : this.auth.externalSession.gid}
    const headers = {gid: this._sessionS.getSession().gid };

    if(viewModeChecked == 'legacy' || !reportId){
      return this.http.post<ApiResponse2<Partial<IComparisonResponse>>>(`${ENV.apiUrl}/v2/locationStats/comparison`, body, {params, headers})
        .pipe(map(value => value.data ? value.data : []));
    } else {
      body.locations = undefined;
      body.locationId = (locations && locations.length === 1 ? locations[0]?.locationId : undefined);
      body.reportId = reportId;
      body.startDateA = dataPicker.range.start?.substring(0,10)
      body.endDateA   = dataPicker.range.end?.substring(0,10)
      body.viewBy     = dataPicker.aggregation?.toLowerCase();
      body.startDateB = dataPicker.rangeB?.start?.substring(0,10)
      body.endDateB   = dataPicker.rangeB?.end?.substring(0,10)

      return this.http.post<ApiResponse2<IComparisonResponse>>(`${ENV.apiUrl}/v3/reports/comparison`, body, { headers})
        .pipe(map(value => value.data || []));
    }
  }

  getGradeComparison(locationId: string, dataPicker: DataPicker) {

    const body = {
      'startDateA': dataPicker.range.start,
      'endDateA':   dataPicker.range.end,
      'viewBy':     dataPicker.aggregation.toLocaleLowerCase(),
      'startDateB': dataPicker?.rangeB?.start || null,
      'endDateB':   dataPicker?.rangeB?.end || null,
    }

    return this.http.post<ApiResponse>(`${ENV.apiUrl}/v3/reports/comparison/${locationId}/grader`, body)
  }

// TODO: Unused, remove?
//   gradeMetricsReports(locations: string[]) {
// 
//     const now = new Date(Date.now());
//     const startDateA = new Date(Date.now());
//     // const endDateA = new Date(Date.now());
//     const lastMonth = now.getMonth() - 1;
//     const lastYear = startDateA.getFullYear() - 1;
// 
//     // range A
//     startDateA.setDate(1);
//     startDateA.setMonth(lastMonth);
//     const endDateA = new Date(startDateA.getFullYear(), startDateA.getMonth() + 1, 0);
// 
//     // range B
// 
//     const startDateB = new Date(startDateA);
//     startDateB.setFullYear(lastYear);
//     const endDateB = new Date(startDateB.getFullYear(), startDateB.getMonth() + 1, 0);
// 
//     let params = new HttpParams()
//       .set('startDateA', startDateA.toISOString())
//       .set('endDateA', endDateA.toISOString())
//       .set('startDateB', startDateB.toISOString())
//       .set('endDateB', endDateB.toISOString())
//       .set('aggregate', 'week');
// 
//     if (locations) {
//       locations?.forEach(l => {
//         params = params.append('locations', l);
//       });
//     }
// 
// 
//     return this.http.get<ApiResponse>(`${ENV.apiUrl}/v2/locationStats/comparison/report`, {params}).pipe(map(value => value.data));
//   }

  resume(locations: LocationRef[], pageable: Pageable, dataPicker: DataPicker, sort: Sort): Observable<Pagination> {
    dataPicker.range.start = dataPicker.range.start.includes('T') ? dataPicker.range.start : `${dataPicker.range.start}T00:00:00.00Z`;
    dataPicker.range.end   = dataPicker.range.end.includes('T')   ? dataPicker.range.end   : `${dataPicker.range.end}T00:00:00.00Z`;

    let params = new HttpParams()
      .set('endDateTime', dataPicker.range.end)
      .set('startDateTime', dataPicker.range.start);
    
    if (sort) {
      params = params.append('sortBy', sort.sortBy.toString());
      params = params.append('direction', sort.direction.toString());
    }

    params = params.append('page', pageable.page.toString());
    params = params.append('size', pageable.size.toString());

    const body = { locations: locationRefsToPaths(locations || []) }

    return this.http.post<ApiResponse>(`${ENV.apiUrl}/v2/locationStats/resume`, body, {
      params,
    }).pipe(map(value => value.data));
  }


  /**
   * Export insights and generate file in api server
   */
  getExportFile(locations: LocationRef[], startDate: string, endDate: string, aggregate: any, format: string, reportName?) {
    // FIXME: Harcoded uid/gid ?
    let params = new HttpParams()
      .set('endDateTime', endDate)
      .set('startDateTime', startDate)
      .set('aggregate', aggregate.toLowerCase())
      .set('uid', 'D8QGdcMtDUcvi76IIsEfsDg1OZy2')
      .set('gid', 'uwxfwK6rKveXqdVXaJEF').set('format', format);

    if (reportName) {
      params = params.append('reportName', reportName);
    }

    const body = { locations: locationRefsToPaths(locations || []) }

    return this.http.post<ApiResponse>(`${ENV.apiUrl}/v2/insights/export`, body, { params })
  }

  openDownloadInNewTab(filename : string) : void {
    window.open(filename, '_blank');
  }

  saveInsights(accountId : string, placeId : string) {
    let params = new HttpParams();
    if (ENV.queuesEnabled) {
      params = params.append('enqueue', Queue.COMBINED_EXPRESS);
    }

    return this.http.post(`${ENV.apiUrl}/v2/insights/${accountId}/${placeId}`, {}, {params});
  }

  hasMoreThan24hs(date) : boolean {
    const createdData = moment(date);
    const today       = moment(new Date());

    return (today.diff(createdData, 'day') >= 1)
  }
}
