import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {ModalDescriptionComponent} from '../../modal-description/modal-description.component';

@Component({
  selector: 'app-location-description',
  templateUrl: './location-description.component.html',
  styleUrls: ['./location-description.component.scss']
})
export class LocationDescriptionComponent {
  @Input() description;
  @Input() isEdit = true;
  @Input() isApple = false;
  @Output() onUpdate = new EventEmitter<boolean>();

  constructor(private modalService: ModalService) {
  }

  // edit website
  openDialog() : void {
    this.modalService.openGenericModal(ModalDescriptionComponent, {description: this.description}, 
      res => {
      if (res != '') {
        this.onUpdate.emit(true);
      }
    });
  }
}
