import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
// import { MatDialog } from '@angular/material/dialog';
// import { ModalLocalPostsComponent } from '../modal-local-posts/modal-local-posts.component';
import { Post, PostManagement } from '../../constants/post';
import { PostService } from '../../services/post.service';
import { FormControl } from '@angular/forms';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { PostListComponent } from './post-list/post-list.component';
import { BehaviorSubject } from 'rxjs';
import { SnackbarService } from '../../services/snackbar.service';
import { LocationService } from '../../services/location.service';
import { BaseComponent } from 'src/app/components/base.component';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-local-posts',
  templateUrl: './local-posts.component.html',
  styleUrls: ['./local-posts.component.scss'],
})

export class LocalPostsComponent extends BaseComponent implements OnInit {

  displayedColumns: string[] = ['img', 'info', 'date', 'dateUpdate', 'actions'];
  displayedPendingColumns: string[] = ['img', 'info', 'date', 'status', 'progress', 'actions'];
  displayedDraftColumns: string[] = ['img', 'info', 'date', 'status', 'actions'];
  dataSource = new MatTableDataSource<Post>([]);
  dataArchiveSource = new MatTableDataSource<Post>([]);
  dataSourceNew = new MatTableDataSource<PostManagement>([]);
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChild('tabPosted') tabPosted: PostListComponent;
  @ViewChild('tabPending') tabPending: PostListComponent;
  @ViewChild('tabArchive') tabArchive: PostListComponent;
  @ViewChild('tabDraft') tabDraft: PostListComponent;
  postsArchiveExist = false;
  postsExist = false;
  pendingPostsExist = false;
  focusTab$ = new BehaviorSubject(0)

  direction: string;

  isSpinner = false;
  @Input() dashboardType: string;
  public progressPost = true;
  public progressArchivePost = true;
  bulkPost: boolean;
  readonly accountId: string;
  readonly locationId: string;
  tabs = ['Posted', 'Pending', 'Drafts', 'Archive'];
  selected = new FormControl(0);

  constructor(
    // private dialog: MatDialog,
    private postS: PostService,
    private route: ActivatedRoute,
    private _sessionS: SessionService,
    private snack: SnackbarService,
    private locationService: LocationService,
    private _router: Router,
  ) {
    super();
    this.locationId = this.route.parent.snapshot.params.locationId;
    this.accountId  = this.route.parent.snapshot.params.accountId;
  }


  ngOnInit(): void {
    this._subscribeSafe(this.postS.posts$, 
     newPost => {
      if (newPost) {
        switch (newPost.tab) {
          case 'Posted':
            if (!this.tabPosted) 
              break;

            if (this.selected.value !== 0) this.selected.setValue(0)
            this.tabPosted.refresh();
            break;
          case 'Pending':
            if (!this.tabPending) 
              break;

            if (this.selected.value !== 1) 
              this.selected.setValue(1)
            this.tabPending.refresh();
            break;
          case 'Drafts':
            if (!this.tabDraft) 
              break;

            if (this.selected.value !== 2) 
              this.selected.setValue(2)
            this.tabDraft.refresh();
            break;
          default:
            // TODO: Suspcious "!== undefined comparison", check if ok.
            if (!this.tabArchive || this.tabArchive !== undefined) 
              break;

            if (this.selected.value !== 3) 
              this.selected.setValue(3)
            this.tabArchive.refresh();
            break;
        }
      }
    })

    if (this.dashboardType === 'LOCAL_POST') {
      //this.tabs = ['Posted', 'Pending', 'Archive'];
      this.bulkPost = false;
    } else if (this.dashboardType === 'BULK_POST') {
      this.bulkPost = true;
    }
  }

  async refresh(): Promise<void> {
    const {gid} = this._sessionS.getSession();
    const locationId = this.route.parent.snapshot.params.locationId;
    const accountId  = this.route.parent.snapshot.params.accountId;
    this.snack.openSuccess('Refresh request sent!');

    await this.locationService.checkLocation(gid, accountId, locationId).toPromise()

    await this.postS.saveV3All(gid, accountId, locationId, 'enqueue');
    const selectedTabIndex = this.selected.value;
    this.refreshPostsByIndex(selectedTabIndex);
  }

  async purge(): Promise<void> {
    const {gid} = this._sessionS.getSession();
    const locationId = this.route.parent.snapshot.params.locationId;
    const accountId  = this.route.parent.snapshot.params.accountId;
    this.snack.openSuccess('Purge request sent!');

    await this.locationService.checkLocation(gid, accountId, locationId).toPromise()

    await this.postS.purgeGmbDeleted(gid, accountId, locationId);
    const selectedTabIndex = this.selected.value;
    this.refreshPostsByIndex(selectedTabIndex);
  }

  create(): void {
    const url = this.dashboardType === 'BULK_POST' ? `/posts-management` : `/account/${this.accountId}/location/${this.locationId}/post-management`;
    this._router.navigate([url])

    // let tabSelected: 'Posted' | 'Pending' | 'Drafts' | 'Archive' = 'Posted'
    // if (this.selected.value === 1) tabSelected = 'Pending'
    // if (this.selected.value === 2) tabSelected = 'Drafts'
    // if (this.selected.value === 3) tabSelected = 'Archive'
    // this.dialog.open(ModalLocalPostsComponent, {
    //   width: '700px',
    //   data: {posts: this.bulkPost, locationId: this.locationId, accountId: this.accountId, post_type: tabSelected}
    // });
  }


  tabChange($event: MatTabChangeEvent): void {
    this.refreshPostsByIndex($event.index);
  }

  refreshPostsByIndex(index:number){
    switch (index) {
      case 0:
        this.tabPosted.refresh();
        break;
      case 1:
        this.tabPending.refresh();
        break;
      case 2:
        this.tabDraft.refresh();
        break;
      default:
        this.tabArchive.refresh();
        break;
    }
  }

  indexChange($event: number): void {
    this.focusTab$.next($event)
    this.selected.setValue($event)
  }

}
