// dep
import {Component, Input, } from "@angular/core";
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'gmb-spinner',
  template: `
    <mat-progress-spinner
      [mode]="mode"
      [value]="value">
    </mat-progress-spinner>
    <div class="spinner-message">
      {{ message }}
    </div>
  `
})
export class SpinnerComponent {
  @Input() message = '';
  @Input() mode : ProgressSpinnerMode = 'indeterminate';
  @Input() value = null;

}
