// dep
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

// app
import { NavigationService } from '../services/navigation.service';
import { ThemePalette } from '@angular/material/core';


export class ModalAlertData {
  public title: string;
  public content: string;
  public alertType: AlertType;
  public closeButtonLabel: string;
  public disableCancelButton?: boolean
  public colorCloseBtn?: ThemePalette;
  public hideCancel?: boolean;
  public hideContactSupport?: boolean

  constructor(data) {
    if (data) {
      this.title = data?.title;
      this.content = data?.content;
      this.alertType = data?.alertType;
      this.closeButtonLabel = data?.closeButtonLabel;
      this.colorCloseBtn = data?.colorCloseBtn || 'primary';
      this.hideCancel = data?.hideCancel;
      this.hideContactSupport = data?.hideContactSupport
    }
  }
}

@Component({
  selector: 'app-alert',
  template: `
	  <div class="dialog__header txt--center dialog__header--warn"
		   [ngClass]="{'dialog__header--warn': data.alertType == 1, 
                   'dialog__header--info': data.alertType == 0, 
                   'dialog__header--error': data.alertType == 2}">
		  <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{ data.title | titlecase}}</strong></h2>
	  </div>

	  <div class="dialog__content" mat-dialog-content>
		  <div class="dialog-row">
			  <div class="txt--lg txt--center m--0" [innerHTML]="data.content"></div>
		  </div>
	  </div>

	  <div *ngIf="!data.disableCancelButton" mat-dialog-actions class="dialog__footer">
      <div>
        <span class="alternate-theme d-inline-block mr--10" [ngClass]="{'d--none': data?.hideCancel}">
          <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
        </span>
        
        <span *ngIf="!data.hideContactSupport" class="alternate-theme d-inline-block mr--10" [ngClass]="{'d--none': data?.hideContactSupport}">
          <button *ngIf="!data.hideContactSupport" 
                  mat-button tabIndex="-1" 
                  color="primary" 
                  class="btn btn-cancel" 
                  (click)="navigationS.goToContactSupport()">
             Contact Support
           </button>
        </span>
      </div>

      <button tabIndex="-1" 
              mat-flat-button 
              [color]="data?.colorCloseBtn" 
              [mat-dialog-close]="data?.title" 
              class="btn btn--action padding-buttons">
          {{ data?.closeButtonLabel }}
      </button>
	  </div>
  `,
})
export class AlertComponent {
  static readonly OPTS_DEFAULT = { config : { width: '680px'} }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ModalAlertData,
    public navigationS : NavigationService
  ) {}

  // TODO: Unused, remove.
  // getAlertIcon() {
  //   switch (this.data.alertType) {
  //     case AlertType.INFO:
  //       return 'info';
  //     case AlertType.WARNING:
  //       return 'warning';
  //     case AlertType.ERROR:
  //       return 'error';
  //   }
  // }
}

export enum AlertType {
  INFO, WARNING, ERROR
}
