import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';

import { AppChartsModule } from './../app-charts/app-charts.module';
import { SharedModule } from 'src/app/shared/shared-modules';
import { SkeletonsModule } from './../skeletons/skeletons.module';
import { ReviewsSharedModule } from './../reviews-shared/reviews-shared.module';
import { ReportsFilterComponent } from './reports-filter/reports-filter.component';
import { DatepickerFeatureflagWrapperComponent } from './datepicker-featureflag-wrapper/datepicker-featureflag-wrapper.component';
import { SearchKeywordsComponent } from 'src/app/modules/reporting/keywords/search-keywords.component';
import { ApplePerformanceInsightsComponent } from './abc-reporting/apple-performance-insights/apple-performance-insights.component';
import { ReportLoaderComponent } from './report-loader/report-loader.component';
import { PerformanceInsightComponent } from './insight/performance-insight.component';




@NgModule({
  declarations: [
    ApplePerformanceInsightsComponent,
    SearchKeywordsComponent, 
    DatepickerFeatureflagWrapperComponent, 
    ReportsFilterComponent, 
    ReportLoaderComponent, 
    PerformanceInsightComponent,
  ],
  imports: [
    AppChartsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatChipsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatTableModule,
    MatMenuModule,
    MatDividerModule,
    MatSortModule,
    SkeletonsModule,
    DateRangePickerModule,
    SharedModule,
    ReviewsSharedModule
  ],
  entryComponents: [
    DatepickerFeatureflagWrapperComponent,
    ReportLoaderComponent,
    SearchKeywordsComponent,
    PerformanceInsightComponent,
  ],
  providers: [
  ],
})
export class ReportingModule { }
