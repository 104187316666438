import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { Observable, timer } from 'rxjs';

@Pipe({
  name: 'oTimeAgo'
})
export class OTimeAgoPipe implements PipeTransform {
  
  transform(value: Date, future: boolean = false): string {
    var today: Date = new Date();
    let diffMs = Math.abs(value.getTime() - today.getTime()); // milliseconds between now 
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffDays > 0) {
      return future ? `${diffDays} days` : `${diffDays} days ago`;
    } else if (diffHrs > 0) {
      return future ? `${diffHrs} hours` : `${diffHrs} hours ago`;
    } else {
      return future ? `${diffMins} minutes` : `${diffMins} minutes ago`;
    }
  }

}
