<div>
    <!-- <section *ngIf="isRecurring">
        <h2 mat-dialog-title class="txt--14 fw--700 ">Select what to delete</h2>
        <mat-radio-group color="warn" [(ngModel)]="deleteWholeSeries" [disabled]="isProgress">
            <mat-radio-button [value]="false" class="txt--14 p--0 pr--50">Current post</mat-radio-button>
            <mat-radio-button [value]="true" class="txt--14 p--0 pr--50">Whole series</mat-radio-button>
        </mat-radio-group>
    </section> -->

    <section class="mt--35">
        <h2 mat-dialog-title class="txt--14 fw--700 ">When to delete</h2>
        <mat-radio-group class="display--block" color="warn" [(ngModel)]="whenToDelete" [disabled]="isProgress" (change)="whenDeletedChanged()">
            <mat-radio-button [value]="'deleteNow'" class="txt--14 p--0 pr--50">Delete now</mat-radio-button>
            <mat-radio-button [value]="'scheduleDelete'" class="txt--14 p--0 pr--50">Schedule delete</mat-radio-button>
        </mat-radio-group>
        
        <section class="row col-12" *ngIf="whenToDelete !== 'deleteNow'">
            <input matInput 
                   class="input-group__field input--calendar input-icon--right col-5 m--0 mt--20 p--15" 
                   [min]="minDate" 
                   [(ngModel)]="deleteDate" 
                   [matDatepicker]="whenToDeleteDatePicker" 
                   (click)="whenToDeleteDatePicker.open()" 
                   [disabled]="isProgress"/>
            <mat-datepicker #whenToDeleteDatePicker ></mat-datepicker>

            <div class="col-6 mt--20 ml--10">
                <mat-form-field class="input-group__field input-group--override-label time-autocomplete bg--white">
                    <input type="text"
                           aria-label="Start time"
                           matInput
                           (keydown)="validateInput($event)"
                           [(ngModel)]="deleteTime"
                           [matAutocomplete]="auto"
                           [disabled]="isProgress"/>
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let hour of hoursListOptions | async" [value]="hour">
                        {{ hour }}
                    </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </section>
    
    </section>

    <section class="mt--35">
        <button mat-flat-button 
                color="warn" 
                class="btn btn--icon btn--md w--100"
                [disabled]="isProgress || (whenToDelete === 'scheduleDelete' && (!deleteDate || !scheduleDateTime))"
                (click)="deletePosts()">
            <p class="m--0">
                {{deleteButtonLabel}}
                <span *ngIf="whenToDelete === 'scheduleDelete'" class="txt--xs display--block">{{scheduleDateTime}}</span>
            </p>
        </button>
        <div class="table__buttons alternate-theme">
            <button mat-flat-button color="primary" class="btn btn--icon btn--md w--100 mt--10"  [disabled]="isProgress" mat-dialog-close>Cancel</button>
        </div>
    </section>
</div>