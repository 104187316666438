// dep
import { ChangeDetectorRef, Component, Input, OnInit, ViewRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { forkJoin } from 'rxjs';

// app
import { Pagination } from 'src/app/constants/api-response';
import { DataPicker } from 'src/app/constants/data-picker';
import { Pageable } from 'src/app/constants/pageable';
// import { LocationService } from 'src/app/services/location.service';
import { QuestionsAnswerService } from 'src/app/services/questions-answer.service';
import { ReportService } from 'src/app/services/report.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SessionService } from "src/app/services/session.service";
import { Sort } from 'src/app/constants/sort';
import { LocationRef } from 'src/app/constants/firestore/location-object';

@Component({
  selector: 'app-questions-answer',
  templateUrl: './questions-answer.component.html',
  styleUrls: ['./questions-answer.component.scss']
})
export class QuestionsAnswerComponent implements OnInit {
  public accountId: string;
  public locationId: string;
  public location: LocationRef[];
  public locations = [];
  public updatingData = false;
  public loading = true;
  public totalsData: any = {};
  public questionsData: any = {};
  public paginate = {
    page: 1,
    size: 6
  }
  public isSliderOpened = false;
  public questionSlider: any;
  public answers: any;
  public startDate = null;
  public endDate = null;
  public accounts;
  public locationsOptions = [];
  public questionType;
  public accountsFiltered = []
  public displayedColumnsMultLoc = ['location', 'total_questions_for_location', 'total_questions_with_answers', 'total_questions_without_answers'];
  public fieldsColumnsMultiLoc = [
    { displayName:'locationName', fieldSort: 'locationName' }, 
    { displayName:'totalQuestions', fieldSort: 'totalQuestions' }, 
    { displayName:'totalAnswerCount', fieldSort: 'totalAnswerCount' }, 
    { displayName:'totalNotAnswerCount', fieldSort: 'totalNotAnswerCount' }
  ];
  public dataSourceMultiLoc = new MatTableDataSource([]); 
  public paginationMultiloc: Pagination = {
    items: [],
    per_page: this.paginate.size,
    page: 1,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  public paginateMultiloc: Pageable = {size: 10, page: 1};
  public sortMultiloc : Sort = {
    sortBy: 'locationName',
    direction: 'asc'
  };
  public keywordSearchText = '';
  public tableLoading = true;
  
  @Input() isReport = false;
  @Input() report;
  @Input() reportId;
  @Input() periodType = null;
  @Input() isShared = false;
  @Input() dataPicker: DataPicker = {range: {start: null, end: null}};


  session$ = this._sessionS.session$

  constructor(
    public questionAnswerS: QuestionsAnswerService,
    public route: ActivatedRoute,
    public cdRef: ChangeDetectorRef,
    public snackS: SnackbarService,
    private reportS: ReportService,
    // private locationS: LocationService
    private _sessionS : SessionService
  ) { }

  ngOnInit() {
    this.accountId = this.route.parent.snapshot.params.accountId;
    this.locationId = this.route.parent.snapshot.params.locationId;
    this.location = [{ accountId: this.accountId, locationId: this.locationId }];
    this.questionType = this.report?.questionsType || null;
    this.periodType = this.periodType || 'allTime';
    this.accounts = this.report ? this.report?.accounts : [];
    this.accountsFiltered = this.accounts ? [...this.accounts] : []
    this.startDate = this.report?.startDatetime?.split(/[T ]/)[0];
    this.endDate = this.report?.endDatetime?.split(/[T ]/)[0];
    this.buildData();
    this.getMultilocationTable();
  }
  
  buildStatus() {
    this.questionsData?.items?.forEach(item => {
      if (Object.keys(item?.answers || []).length == 0 ) {
        item.status = 'No Answer';
      } else {
        item.hasOwner = item?.answers?.find(el => (el.author.type).toLowerCase() == 'merchant') ? true : false;
        item.status = ! item.hasOwner ? 'No Owner Answer' : 'Answered By Owner';
      }
    });
  }
  
  buildData() {
    if (!this.isReport) {
      this.getData();
    } else {
      if (this.report?.accounts?.length > 0) {
        this.accounts.forEach(acc => acc.locations.forEach(l => this.locations.push(l)));
      }
      this.getDataReport();
    }
  }

  getData() {
    if(!this.keywordSearchText) {
      const gid = this._sessionS.getSession().gid
      forkJoin([
        this.questionAnswerS.getTotals(gid, this.accountId, this.locationId, this.questionType, this.startDate, this.endDate),
        this.questionAnswerS.getQuestions(gid, this.accountId, this.locationId, this.paginate, this.questionType, this.startDate, this.endDate)
      ]).subscribe(
        result => {
          this.totalsData = result[0];
          this.questionsData = result[1];
          this.buildStatus();
          this.loading = false;
          this.updatingData = false;
          this.detectChanges();
        },
        err => {
          this.loading = false;
          this.updatingData = false;
        }
      )
    } else {

      const questionType = {
        "withoutAnswer": "without_answers",
        "withAnswer": "with_answers"
      };

      forkJoin([
        this.questionAnswerS.getQuestionsAtlasSearch(
          [{
              gid: this._sessionS.getSession().gid, 
              accountId: this.accountId,
              locationId: this.locationId
          }],
          this.keywordSearchText,
          questionType[this.questionType] || 'all',
          this.startDate || undefined, 
          this.endDate || undefined,
          this.paginate?.size
        )
      ]).subscribe(
        result => {
          this.questionsData = result[0];
          this.loading = false;
          this.updatingData = false;
          this.detectChanges();
        },
        err => {
          this.loading = false;
          this.updatingData = false;
        }
      )
    }
  }

  detectChanges () : void {
    if(!(this.cdRef as ViewRef).destroyed)
       this.cdRef.detectChanges();
  }

  formattedDate(date) : string {
    const split = date?.split('-');
    split[1] = split[1]?.padStart(2, '0');
    split[2] = split[2]?.padStart(2, '0');
    return split?.join('-');
  }

  getMultilocationTable() {
    this.dataSourceMultiLoc = new MatTableDataSource([]); 
    this.tableLoading = true;
    const isAllTime = this.periodType == 'allTime';
    this.dataPicker.range = {
      start: isAllTime ? null : this.formattedDate(this.startDate),
      end: isAllTime ? null : this.formattedDate(this.endDate)
    }
    this.dataPicker.locations = [];
    this.accountsFiltered.forEach(a => this.dataPicker.locations.push(...a.locations));

    this.reportS.getTableData(this._sessionS.getSession().gid, 'qanda', this.paginateMultiloc ,this.sortMultiloc, this.dataPicker).subscribe(
      res => {
        this.buildMultiLocTable(res?.data);
        this.loading = false;
        this.updatingData = false;
        this.tableLoading = false;
      },
      err => {
        this.loading = false;
        this.updatingData = false;
        this.tableLoading = false;
      }
    )
  }

  getDataReport() {
    if(!this.keywordSearchText) {
      const isAllTime = this.periodType == 'allTime';
      this.dataPicker.range = {
        start: this.startDate,
        end: this.endDate
      }

      this.questionAnswerS.getReportData(this.reportId, this.paginate, this.startDate, this.endDate, this.accountsFiltered, this.questionType, isAllTime).subscribe(
        res => {
          this.totalsData = res['metrics'];
          this.questionsData = { 
            items: res['items'],
            total:res['total']
          };
          this.buildStatus();
          this.getLocationInfo();
          this.loading = false;
          this.updatingData = false;
          this.detectChanges();
        },
        err => {
          this.loading = false;
          this.updatingData = false;
        }
      );
      } else {

      const questionType = {
        "withoutAnswer": "without_answers",
        "withAnswer": "with_answers"
      };

      const accountsArray = this.getAccountsArray(this.accountsFiltered);
      this.questionAnswerS.getQuestionsAtlasSearch(
        accountsArray,
        this.keywordSearchText,
        questionType[this.questionType] || 'all',
        this.startDate || undefined, 
        this.endDate || undefined,
        this.paginate?.size
      ).subscribe(
        res => {
          // this.totalsData = res[0]['metrics'];
          this.questionsData = { 
            items: res[0]['items'],
            total: res[0]['total']
          };
          this.buildStatus();
          this.getLocationInfo();
          this.getMultilocationTable();
          this.loading = false;
          this.updatingData = false;
          this.detectChanges();
        },
        err => {
          this.loading = false;
          this.updatingData = false;
        }
      );
    }
  }


  getAccountsArray(accounts){
    const result = [];
    accounts.forEach(obj => {
      const accountId = obj.accountId;
      const gid = obj.gid;
      obj.locations.forEach(location => {
        const locationId = location.locationId;
        result.push({ accountId, gid, locationId });
      });
    });
  
    return result;
  }

  buildMultiLocTable(data) {
    this.dataSourceMultiLoc = new MatTableDataSource(data.items);
    this.paginationMultiloc = data;
    this.paginationMultiloc = {
      items: data['items'],
      page: data['page'],
      pages: data['totalPages'],
      per_page: data['pageSize'],
      total: data['total'],
      hasNext: data['hasNext'],
      hasPrev: data['hasPrev']
    };
  }

  getLocationInfo() {
    this.questionsData.items.forEach(loc => {
      const account = this.accounts.find(acc => acc.accountId == loc.accountId);
      const location = account.locations.find(l => l.locationId == loc.locationId);
      if(location?.locationName) { loc.locationName = location.locationName; }
      if(location?.serviceArea) { loc.serviceArea = location.serviceArea; }
      if(location?.address?.addressLines?.length) { 
        loc.address = location.address;
      }else {
        delete location.address
      }
    })
  }

  loadMoreQuestions() {
    this.updatingData = true;
    this.paginate = {
      page: 1,
      size: this.paginate.size + 6
    }

    this.buildData();
  }

  keywordSearch(event){
    this.updatingData = true;
    this.keywordSearchText = event;
    this.getMultilocationTable();
    this.buildData();
  }

  questionTypeChanged(event) {
    this.updatingData = true;
    this.questionType = event.value;
    this.buildData();
  }

  questionPeriodChanged(event) {
    this.updatingData = true;
    this.periodType = event.value;
    const dates = this.questionAnswerS.buildPeriodDate(event.value);
    this.startDate = dates.startDatetime;
    this.endDate = dates.endDatetime;
    this.getMultilocationTable();
    this.buildData();
  }

  locationsFilteredChanged(event) {
    this.updatingData = true;
    this.accountsFiltered = event.length > 0 ? event : this.accounts;
    this.getDataReport();
    this.getMultilocationTable();
  }

  sendNewAnswer(item, answer = null, errorId = null) {
    answer = (!answer ?
    document.getElementById(item?.questionId) ? document.getElementById(item?.questionId)['value'] : '' :
    answer);
    const accountId = this.accountId || item.accountId;
    const locationId = this.locationId || item.locationId;
    const hasError = !!document.getElementById(errorId).textContent
    answer = answer.trim();

    if (!answer || hasError) { return; }
    
    this.updatingData = true;

    const s = this._sessionS.getSession();
    this.questionAnswerS.addAnswer(s.gid, accountId, locationId, item?.questionId, s.user.displayName , answer).subscribe(
      res => {
        this.buildData();
        this.snackS.openSuccess(`The answer was added successfully`, 4000);
      },
      err => {
        this.updatingData = false
        this.snackS.openError(`There was an error while adding the answer`, 4000);
      }
    )
  }

  openQuestions(slider, question) {
    // const backdrop = document.getElementsByClassName('mat-drawer-backdrop') || [];
    // backdrop[0].className += ' mat-drawer-shown'
    this.isSliderOpened = true;
    this.questionSlider = question;
    slider.open();
  }

  sliderClosed() {
    this.isSliderOpened = false;
    this.buildData()
  }

  handleMultilocSort(event) {
    this.updatingData = true;
    this.sortMultiloc = {sortBy: event?.active, direction: event?.direction};
    this.paginateMultiloc = {size: this.paginateMultiloc.size, page: 1}
    this.getMultilocationTable();
  }

  handleMultilocPaginate(event) {
    this.updatingData = true;
    this.paginateMultiloc = event
    this.getMultilocationTable();
  }

  hasSeveralLocations() {
    return this.accountsFiltered.length > 1 || this.accountsFiltered[0].locations.length > 1;
  }

  deleteQuestions(question) {
    this.updatingData = true;
    const accountId = this.accountId || question.accountId;
    const locationId = this.locationId || question.locationId;
    const answer = this.getOwnerAnswer(question);

    this.questionAnswerS.deleteAnswer(this._sessionS.getSession().gid, accountId, locationId, question?.questionId, answer?.name).subscribe(
      res => {
        this.buildData();
        this.snackS.openSuccess(`The request has been sent to google please check again in some minutes`, 4000);
      },
      err => {
        this.updatingData = false
        this.snackS.openError(`There was an error while deleting the answer`, 4000);
      }
    )
  }

  getOwnerAnswer(item) {
    return item?.answers && item?.hasOwner ? item?.answers?.find(el => (el.author.type).toLowerCase() == 'merchant') : {};
  }
  
  getFullDate(date) {
    return date ? moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null;
  }

  getErrorId(id) {
    return `errorContainer-${id}`
  }

}
