<mat-accordion class="reports-accordion" >
  <mat-expansion-panel expanded="true" hideToggle>

    <mat-expansion-panel-header>
      <mat-panel-title>
          <h2 class="txt--capitalize"><strong>Review Activity</strong></h2>
      </mat-panel-title>
      <mat-panel-description class="align-items-center justify-content-end">
        <mat-icon><span class="mat-expansion-panel-icon"></span></mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div>
      <div class="box__content">
        <!-- FILTER -->
        <div class="mat-row--filter mat-row--border-bottom d-flex align-items-center">

          <!-- Keywords -->
          <div class="input-field-wrapper--search p--lr-10" data-html2canvas-ignore="true">
            <div class="input-field input-field--search input-field--header border--solid border--gray">
              <input #inputSearch class="pt--0 pb--0" (keyup)="search($event)" [(ngModel)]="searchText" placeholder="Search by reviewer or keyword">
            </div>
          </div>
    
          <!-- Filter Button -->
          <button mat-button class="filter__item__btn txt--white bg--green p--0 pr--100 w--165-px" [matMenuTriggerFor]="filterMenu" [disabled]="isLoading || showSpinner">
            Filter <em class="fal fa-angle-down"></em>
          </button>
          <mat-menu #filterMenu="matMenu" class="mat-menu--lg mat-menu--430height mat-menu--430MaxHeight" [overlapTrigger]="false">
            <div class="col-12 row m--0 pt--15 pb--15">
              <section class="col-6">
                <p class="mb--10 fw--extrabold">Star Rating</p>
                <ng-container *ngFor="let item of checkboxComponent.slice().reverse()">
                  <mat-checkbox
                    class="d-flex align-items-center checkbox--star checkbox--star-md mb--5"
                    value="{{ item.id }}"
                    [(ngModel)]="item.isChecked"
                    (click)="$event.stopPropagation();">
                    <app-starts class="d-flex stars--sm" [starts]="item.value" [showEmpty]="false" [showLabel]="true"></app-starts>
                  </mat-checkbox>
                </ng-container>
              </section>
    
              <section class="col-6">
                <p class="mb--10 fw--extrabold">Review Status</p>
                <ng-container *ngFor="let option of statusOptions">
                  <mat-checkbox class="d-flex align-items-center checkbox--star checkbox--star-md mb--5" 
                                name="checked" 
                                [(ngModel)]="option.checked" 
                                (click)="$event.stopPropagation();" 
                                [value]="option.value">
                    <p class="m--0" *ngIf="option.displayName">{{option.displayName}}</p>
                  </mat-checkbox>
                </ng-container>
              </section>
    
              <section class="col-6 mt--20">
                <p class="mb--10 fw--extrabold">Reply Status</p>
                <ng-container *ngFor="let option of respondedOptions">
                  <mat-checkbox class="d-flex align-items-center checkbox--star checkbox--star-md mb--5" 
                                name="checked" 
                                [(ngModel)]="option.checked" 
                                (click)="$event.stopPropagation();" 
                                [value]="option.value.toString()">
                    <p class="m--0" *ngIf="option.displayName">{{option.displayName}}</p>
                  </mat-checkbox>
                </ng-container>
              </section>
    
              <section class="col-6 mt--20">
                <p class="mb--10 fw--extrabold">Comments</p>
                <ng-container *ngFor="let option of commentsOptions">
                  <mat-checkbox class="d-flex align-items-center checkbox--star checkbox--star-md mb--5" 
                                name="checked" 
                                [(ngModel)]="option.checked" 
                                (click)="$event.stopPropagation();" 
                                [value]="option.value.toString()">
                    <p class="m--0" *ngIf="option.displayName">{{option.displayName}}</p>
                  </mat-checkbox>
                </ng-container>
              </section>
    
              <section class="col-12 mt--20 txt--align--end m--0">
                <button mat-flat-button color="accent" class="col-4 mr--10" (click)="applyChanges()">Apply</button>
              </section>
    
            </div>
          </mat-menu>
    
          <!-- Clear -->
          <button mat-flat-button [style.color]="'#8c91a2'" class="btn--default pl--40 pr--40 ml--10" (click)="resetFilter()" [disabled]="isLoading || showSpinner">Clear</button>
    
          <!-- Export -->
          <div class="txt--align--end w--55">
            <button mat-flat-button [style.color]="'#8c91a2'" class="btn btn--xs btn--default" (click)="handleExport()" [disabled]="isLoading || showSpinner || !dataSource?.data?.length">Export CSV</button>
          </div>
    
        </div>

        <!--TABLE-->
        <app-skeleton-reviews type="FILTERS" [isSingle]="isSingleReview" *ngIf="isLoading || searchTextProgress"></app-skeleton-reviews>

        <table mat-table 
              [dataSource]="dataSource" 
              matSort 
              [matSortActive]="sort && sort.sortBy"
              [matSortDirection]="sort && sort.direction" 
              class="table table--bordered" 
              *ngIf="!isLoading && dataSource"
              (matSortChange)="sortBy($event)">

          <!-- Company -->
          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="company.title"> Location</th>
            <td mat-cell *matCellDef="let row" style="width: 20%;">
              <div class="cell__content flex-column align-items-start">
                <p class="m--0 txt--black txt--md"><strong>{{ row?.locationName }}</strong></p>
                <p class="txt--lightgray">
                  <gmb-address
                    [address]='row?.locationAddress'
                    [serviceAreas]='row?.serviceArea?.places?.placeInfos'>
                  </gmb-address>
                </p>

                <div class="mt--5" *ngIf="!isShared">
                  <a [routerLink]="locationUrl(row,'insights')" target="_blank" matTooltip="View Insights"
                    matTooltipClass="tooltip tooltip--blue"
                    matTooltipPosition="above"
                    aria-disabled="true"
                    class="d-inline-block btn btn--blue btn-icon mr--5 border-box">
                    <img src="/assets/images/icons/graph.png" alt="Graph Icon">
                  </a>
                  <a [routerLink]="locationUrl(row,'reviews')" target="_blank" matTooltip="See Reviews"
                    matTooltipClass="tooltip tooltip--orange"
                    matTooltipPosition="above"
                    aria-disabled="true"
                    class="d-inline-block btn btn--orange btn-icon mr--5 border-box">
                    <img src="/assets/images/icons/star-empty.png" alt="Star Icon">
                  </a>
                  <a [href]="row.mapsUrl" target="_blank" matTooltip="See Location on Map"
                    matTooltipClass="tooltip tooltip--green"
                    aria-disabled="true"
                    matTooltipPosition="above" class="d-inline-block btn btn--green btn-icon border-box">
                    <img src="/assets/images/icons/pin-green.png" alt="Pin Icon">
                  </a>
                </div>
              </div>
            </td>

          </ng-container>

          <!-- Star Rating -->
          <ng-container matColumnDef="rating">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="starRatingNumber" class="txt--center"> Star Rating</th>
              <td mat-cell *matCellDef="let row" [style.width]="!isSingleReview ? '20%' : '12%'">
                <div class="cell__content d-flex flex-column justify-content-center  align-items-center">
                  <p class="txt--lg fw--medium txt--black mb--10">{{row.starRatingNumber}}</p>
                  <app-starts [starts]="row.starRatingNumber" class="stars--xs"></app-starts>
                </div>
              </td>
          </ng-container>

          <!-- Date-->
          <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="createTime" class="txt--center"> Date</th>
              <td mat-cell *matCellDef="let row" class="cdk-column-date--resp">
                <div class="cell__content justify-content-center">
                  <p class="m--0 fw--medium">{{row.createTime|date :'mediumDate'}}</p>
                </div>
              </td>
            </ng-container>

          <!-- Has response -->
          <ng-container matColumnDef="response">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="reviewReply" class="txt--center"> Response</th>
              <td mat-cell *matCellDef="let row" [style.width]="isSingleReview ? '15%' : ''">
                <div class="cell__content justify-content-center">
                  <p class="m--0 fw--medium" [ngClass]="row.reviewReply ? 'txt--green' : 'txt--red'">
                    <em class="far" [ngClass]="(row.reviewReply) ? 'fa-check-circle txt--green' : 'fa-times-circle txt--red'"
                       data-html2canvas-ignore></em>
                       {{row.reviewReply ? 'Yes' : 'No'}}
                  </p>
                </div>
              </td>
            </ng-container>

          <!-- status -->
          <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="status" class="txt--center"> Status</th>
              <td mat-cell *matCellDef="let row" [style.width]="isSingleReview ? '15%' : ''">
              <div class="cell__content justify-content-center min--height--0 min--width--100" 
                   [ngClass]="{'btn--green': row?.status == 'ACTIVE', 
                               'btn--red': row?.status == 'DELETED', 
                               'btn--orange': row?.status == 'UPDATED'}">
                  <p class="m--0 fw--medium">{{ formattedStatus(row.status) | titlecase}}</p>
              </div>
              </td>
          </ng-container>

          <!-- Review -->
          <ng-container matColumnDef="reviewer">
              <th width="35%" mat-header-cell *matHeaderCellDef mat-sort-header="reviewer.displayName" class="txt--center">Reviewer / Review</th>
              <td mat-cell *matCellDef="let row">
                <div class="cell__content flex-column align-items-start justify-content-center">
                  <p class="ml--30 mr--25 fw--medium txt--left">
                    <strong class="d-flex align-content-center align-items-center">
                      {{row.reviewer?.displayName}}
                    </strong>
                  </p>
                  <p class="ml--30 mr--25 mt--10 fw--medium txt--justify" *ngIf="row?.comment">
                    <read-more *ngIf="!isExporting" [text]="row?.comment" [maxLength]="200" [showToggleButton]="true"></read-more>
                    <span *ngIf="isExporting">{{row?.comment}}</span>
                  </p>
                </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="reply">
              <th width="30%" mat-header-cell *matHeaderCellDef mat-sort-header class="txt--center">Reply</th>
              <td mat-cell *matCellDef="let row">
                <div class="cell__content">
                  <p class="ml--10 fw--medium">
                    <read-more [text]="row.reviewReply?.comment" [maxLength]="100" [showToggleButton]="true"></read-more>
                  </p>
                </div>
              </td>
          </ng-container>

          <!-- Actions -->
          <ng-container matColumnDef="actions" >
              <th mat-header-cell *matHeaderCellDef class="txt--center column--sm" data-html2canvas-ignore="true"> Actions</th>
              <td mat-cell *matCellDef="let row" class="column--sm" data-html2canvas-ignore="true">
                <div class="cell__content justify-content-center" [ngClass]="{'isDisabled': isShared}">

                  <a (click)="openModalReply(row)" class="d-flex align-items-center btn  btn--xs btn--table-img txt--white"
                  aria-disabled="true" [ngClass]="{'bg--yellow': row?.reviewReply, 'bg--red': !row?.reviewReply}" *ngIf="row?.status != 'DELETED'">
                 <ng-container *ngIf="row?.reviewReply">
                   <em class="fas fa-edit d-inline-block mr--10 cursor--pointer"></em>
                   <span>Edit</span>
                 </ng-container>
   
                 <ng-container *ngIf="!row?.reviewReply">
                   <em class="fas fa-reply d-inline-block mr--10 cursor--pointer"></em>
                   <span>Reply</span>
                 </ng-container>
               </a>
   
               <a (click)="openUpdatedModal(row)" *ngIf="row?.status == 'UPDATED'"
               class="d-flex align-items-center btn btn--xs btn--table-img bg--blue txt--white ml--10 w--165-px" aria-disabled="true">
                 <ng-container>
                   <em class="fas fa-eye d-inline-block mr--10 cursor--pointer"></em>
                   <span>View Changes</span>
                 </ng-container>
               </a>
   
               <a (click)="handleExport(row?.reviewId)" *ngIf="row?.status == 'DELETED'"
               class="d-flex align-items-center btn btn--xs btn--table-img bg--green txt--white" aria-disabled="true">
                 <ng-container>
                   <em class="fa fa-angle-double-down d-inline-block mr--10 cursor--pointer"></em>
                   <span>Download</span>
                 </ng-container>
               </a>

              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="no-hover"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="no-hover border--full"></tr>
        </table>

        <app-no-data [message]="'No matches found'" *ngIf="!isLoading && dataSource?.data?.length === 0"></app-no-data>

        <!-- PAGINATION -->
        <div class="mat-row mat-row--border-top mat-row--padding-md d-flex justify-content-start align-items-center"
            *ngIf="pagination">
          <div class="pagination" data-html2canvas-ignore>
            <button (click)="onPrev()" class="pagination__item" [disabled]="!pagination.hasPrev"><i
              class="fas fa-angle-left"></i></button>
            <button (click)="onNext()" class="pagination__item" [disabled]="!pagination.hasNext"><i
              class="fas fa-angle-right"></i></button>
          </div>
          <div class="input-field input-field--pagination" data-html2canvas-ignore>
            <label>Go to:</label>
            <input type="text" matInput [(ngModel)]="this.pagination.page" (ngModelChange)="onChangePage($event)"/>
            <mat-error *ngIf="errorMessage" class="mat-error--absolute">
              <p>Enter valid number</p>
            </mat-error>
          </div>
          <mat-paginator data-html2canvas-ignore 
                         (page)="onChangePage($event)" 
                         [length]="pagination.total"
                         [pageSize]="pagination.per_page"
                         [pageSizeOptions]="sizeOption" 
                         [pageIndex]="pagination.page-1"
                         *ngIf="pagination"></mat-paginator>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>