export const MyBusinessErrors = {
    businessInformation: {
        ERROR_CODE_UNSPECIFIED: 'Missing error code',
        INVALID_ATTRIBUTE_NAME: 'One of the attributes provided cannot be applied to this location',
        ASSOCIATE_OPERATION_ON_VERIFIED_LOCATION: 'Try to associate a location or clear a location association on a location that is in verified state',
        ASSOCIATE_LOCATION_INVALID_PLACE_ID: 'The place_id provided in the associateLocation request is not a match for the location or is invalid',
        LAT_LNG_UPDATES_NOT_PERMITTED: 'This project is not allowed to update the lat/lng field on the Location',
        PO_BOX_IN_ADDRESS_NOT_ALLOWED: 'PO box cannot be used in address',
        BLOCKED_REGION: 'Google are unable to accept businesses from this region',
        MISSING_BOTH_PHONE_AND_WEBSITE: 'Either a phone or a website is required for CUSTOMER_LOCATION_ONLY businesses',
        MISSING_STOREFRONT_ADDRESS_OR_SAB: 'All Profiles must have at least a storefront address or a service area',
        LAT_LNG_TOO_FAR_FROM_ADDRESS: 'The latitude/longitude and address pair are too far apart',
        LAT_LNG_REQUIRED: 'The specified address cannot be located',
        INVALID_CHARACTERS: 'Invalid characters are found',
        FORBIDDEN_WORDS: 'Forbidden words are found',
        INVALID_INTERCHANGE_CHARACTERS: 'Invalid characters are found',
        FIELDS_REQUIRED_FOR_CATEGORY: 'Additional fields are required for this category of location',
        STOREFRONT_REQUIRED_FOR_CATEGORY: 'Your business category requires a storefront location customers can visit',
        ADDRESS_MISSING_REGION_CODE: 'Address is missing required region_code',
        ADDRESS_EDIT_CHANGES_COUNTRY: 'The address cannot be moved to different country',
        SPECIAL_HOURS_SET_WITHOUT_REGULAR_HOURS: 'Special hours can only be specified if the location has regular business hours',
        INVALID_TIME_SCHEDULE: 'Invalid time schedule, overlapped or end time is earlier than start time',
        INVALID_HOURS_VALUE: 'Invalid hours format or value',
        OVERLAPPED_SPECIAL_HOURS: 'Special_hours cannot overlap',
        INCOMPATIBLE_MORE_HOURS_TYPE_FOR_CATEGORY: 'Your business primary category doesn\'t support this hours type',
        DUPLICATE_CHILDREN_LOCATIONS: 'Duplicate children_locations in relationship_data',
        INCOMPATIBLE_SERVICE_AREA_AND_CATEGORY: 'A service area business cannot have the selected primary category',
        INVALID_SERVICE_AREA_PLACE_ID: 'Invalid place_id in service_area',
        INVALID_AREA_TYPE_FOR_SERVICE_AREA: 'Invalid area type for service_area',
        OPENING_DATE_TOO_FAR_IN_THE_FUTURE: 'Enter an opening date that\'s within a year',
        OPENING_DATE_MISSING_YEAR_OR_MONTH: 'Opening date must have a year or a month specified',
        OPENING_DATE_BEFORE_1AD: 'Opening date cannot be before 1 AD',
        TOO_MANY_ENTRIES: 'Too many entries for the field',
        INVALID_PHONE_NUMBER: 'Phone number input is not recognized',
        INVALID_PHONE_NUMBER_FOR_REGION: 'Invalid phone number for region',
        MISSING_PRIMARY_PHONE_NUMBER: 'Missing primary phone number',
        THROTTLED: 'The field cannot be updated at this time',
        UNSUPPORTED_POINT_RADIUS_SERVICE_AREA: 'Point radius service areas are no longer supported',
        INVALID_CATEGORY: 'Invalid category id',
        CANNOT_REOPEN: 'The business cannot reopen',
        INVALID_BUSINESS_OPENING_DATE: 'Invalid business opening date',
        INVALID_LATLNG: 'Invalid latlng',
        PROFILE_DESCRIPTION_CONTAINS_URL: 'Business description should not contain URL',
        LODGING_CANNOT_EDIT_PROFILE_DESCRIPTION: 'Lodging location\'s profile description can\'t be edited',
        INVALID_URL: 'Invalid link',
        INVALID_ADDRESS: 'Invalid address',
        PARENT_CHAIN_CANNOT_BE_THE_LOCATION_ITSELF: 'ParentChain cannot be the location itself',
        RELATION_CANNOT_BE_THE_LOCATION_ITSELF: 'Relation cannot be the location itself',
        MISSING_ADDRESS_COMPONENTS: 'Missing value for address components',
        READ_ONLY_ADDRESS_COMPONENTS: 'Can\'t edit readonly address components',
        STRING_TOO_LONG: 'The string is too long',
        STRING_TOO_SHORT: 'The string is too short',
        REQUIRED_FIELD_MISSING_VALUE: 'Missing value for required field',
        ATTRIBUTE_PROVIDER_URL_NOT_ALLOWED: 'URL for a provider cannot be added or edited',
        ATTRIBUTE_INVALID_ENUM_VALUE: 'Unknown value for enum attribute',
        ATTRIBUTE_NOT_AVAILABLE: 'Scalable attribute not valid for this location',
        ATTRIBUTE_CANNOT_BE_REPEATED: 'Scalable attribute only be specified once',
        ATTRIBUTE_TYPE_NOT_COMPATIBLE_FOR_CATEGORY: 'Scalable attribute is not compatible with the categories set on the location',
        ADDRESS_REMOVAL_NOT_ALLOWED: 'Address removal is not allowed for your business',
        AMBIGUOUS_TITLE: 'Best name is ambiguous for a language',
        INVALID_CATEGORY_FOR_SAB: 'A pure SAB cannot have gcids that are subtypes of gcid:establishment_poi',
        RELATION_ENDPOINTS_TOO_FAR: 'Relation endpoints are too far from each other',
        INVALID_SERVICE_ITEM: 'Neither structured_service_item nor free_form_service_item is set',
        SERVICE_ITEM_LABEL_NO_DISPLAY_NAME: 'Label is missing display name',
        SERVICE_ITEM_LABEL_DUPLICATE_DISPLAY_NAME: 'Display name is not unique for all labels across all price lists',
        SERVICE_ITEM_LABEL_INVALID_UTF8: 'Label contains invalid UTF-8 symbols',
        FREE_FORM_SERVICE_ITEM_WITH_NO_CATEGORY_ID: 'Missing category_id field in free_form_service_item',
        FREE_FORM_SERVICE_ITEM_WITH_NO_LABEL: 'Missing label in free_form_service_item',
        SERVICE_ITEM_WITH_NO_SERVICE_TYPE_ID: 'Missing service_type_id field in structured_service_item',
        INVALID_LANGUAGE: 'Language code is invalid',
        PRICE_CURRENCY_MISSING: 'Missing ISO 4217 currency code',
        PRICE_CURRENCY_INVALID: 'Provided currency code is not valid ISO 4217',
        SERVICE_TYPE_ID_DUPLICATE: 'Service type ids are not unique within the Location',
        PIN_DROP_REQUIRED: 'The specified address cannot be located. Please use the UI to provide a pin drop',
        STALE_DATA: 'One or more items were recently updated by Google',
  },
};
