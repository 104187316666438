import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatColumnDef, MatTable, MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {Observable} from 'rxjs';
import {Pagination} from '../../constants/api-response';
import {Pageable} from '../../constants/pageable';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class TableComponent implements OnInit, AfterContentInit, OnChanges {

  @Input() title: string;
  @Input() columns = [];
  @Input() data: (pageable?: Pageable) => Observable<Pagination>;
  @Input() actions = [];
  @Input() messageNoData: string;
  @Input() reload: string;
  @Input() filterPredicate: (data: any, filter: string) => boolean;


  columnShow = [];
  datasource = new MatTableDataSource<any>([]);
  selectionProtocolLog = new SelectionModel<any>(true, []);

  @ViewChild(MatTable) table: MatTable<any>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  view: any;
  pagination: Pagination;
  show: boolean;
  progress = true;

  ngOnInit() {
    this.datasource.data = [];
    this.pagination = {per_page: 0, items: [], page: 0, hasNext: false, hasPrev: false, pages: 0, total: 0};
    if (this.columns[0] !== 'id') {
      this.columns.unshift('id');
    }

    if (this.filterPredicate) {
      this.datasource.filterPredicate = this.filterPredicate;
    }

    if (this.data) {
      this.data().subscribe(result => {

        this.progress = false;
        if (result.items.length > 0) {
          this.pagination = result;
          this.columnShow = this.columns;
          this.datasource.data = result.items as any[];
        }

      }, error => {
        console.error(error);
      });
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reload.currentValue) {
      this.progress = true;
      this.ngOnInit();
    }

  }

  constructor() {
  }

  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
  }

  masterToggleProtocolLog() {
    this.isAllSelectedProtocolLog() ?
      this.selectionProtocolLog.clear() :
      this.datasource.data.forEach(row => this.selectionProtocolLog.select(row));
  }

  checkboxLabelProtocolLog(row?: any): string {
    if (!row) {
      return `${this.isAllSelectedProtocolLog() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionProtocolLog.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  isAllSelectedProtocolLog() {
    const numSelected = this.selectionProtocolLog.selected.length;
    const numRows = this.datasource.data.length;
    return numSelected === numRows;
  }


  handleView($event: any) {
    this.view = $event;
    switch ($event) {
      case 'read':
        this.datasource.filter = 'true';
        break;
      case 'unread':
        this.datasource.filter = 'false';
        break;
      default:
        this.datasource.filter = undefined;
        break;
    }
  }

  reloadPagination($event: Pageable) {
    this.data($event).subscribe();
  }
}
