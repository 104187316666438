import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from "@angular/material/dialog";
import { LocationService } from '../../../services/location.service';
import { LocationEditService } from '../../../services/location-edit.service';
import { ModalRegularHoursComponent } from '../../modal-regular-hours/modal-regular-hours.component';
import { DatesService } from 'src/app/services/dates.service';

@Component({
  selector: 'app-modal-more-hours',
  templateUrl: './modal-more-hours.component.html',
  styleUrls: ['./modal-more-hours.component.scss']
})
export class ModalMoreHoursComponent implements OnInit {
  public labels;
  public moreHours = [];
  public loading = true;
  public openPeriods = [];

  @Input() bulk = false;
  @Input() bulkLocationSelected;
  @Output() validResult: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ModalMoreHoursComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private locationService: LocationService,
    private locationEditS: LocationEditService,
    private dialog: MatDialog,
    private dateS: DatesService,
  ) { }

  ngOnInit() {
    if (this.bulk) {
      this.getLabels();
    } else {
      this.moreHours = JSON.parse(JSON.stringify(this.data?.moreHours || []));
      this.openPeriods = JSON.parse(JSON.stringify(this.moreHours));
      this.labels = this.buildLabels();
      this.loading = false;
    }
  }

  getLabels() {
    const { gid, accountId, locationId } = this.bulkLocationSelected[0];
    this.locationService.getRef(gid, accountId, locationId).subscribe(res => {
      this.labels = res.location?.primaryCategory.moreHoursTypes || [];
      res.location?.additionalCategories?.map(el => {
        el.moreHoursTypes.forEach(h => {
          const hasLabel =  this.labels.find(l => l.hoursTypeId == h.hoursTypeId) ? true : false;
          if (!hasLabel) { this.labels.push(h) }
        });
      });
      this.loading = false;
    });
  }

  buildLabels() {
    const labels = this.data?.labels ? [...this.data?.labels] : [...this.labels];
    const usedLabels = this.moreHours.map(el => el.hoursTypeId);
    usedLabels.forEach(el => {
      const index = labels.findIndex(i => (i.hoursTypeId).toLowerCase() == el.toLowerCase());
      if (index > -1) { labels.splice(index, 1) }
    });

    return labels;
  }

  apply() {
    const data = this.buildData();
    const hasChanges = this.hasChanges(data);
    
    if(hasChanges) {
      this.locationEditS.locationEdit.moreHours = data;
      this.locationEditS.setAndUpdate().toPromise().then(() => {
        this.dialogRef.close(data);
      });
    } else {
      this.dialogRef.close(data);
    }
  }

  hasChanges(data) {
    return JSON.stringify(this.openPeriods) != JSON.stringify(this.moreHours);
  }

  buildData() {
    const data = [];
    this.moreHours.forEach(el => {
      let periods = JSON.parse(JSON.stringify(el.formattedPeriods));
      periods = periods.filter(period => period.open === true);
      periods.map(period => {
        period.openTime = this.dateS.hours12To24(period.openTime);
        period.closeTime = this.dateS.hours12To24(period.closeTime);
        delete period.open;
        return period;
      });
      data.push({
        hoursTypeId: el.hoursTypeId,
        periods: periods
      });
    });

    return data;
  }


  selectLabel(label) {
    const item = {...label};
    item.periodsByDay = [];
    this.buildPeriods(item);
    this.openHoursDialog(item);
  }

  buildPeriods(item) {
    item.formattedPeriods = this.locationService.verifyOpen(item.formattedPeriods);
    item.periodsByDay = this.locationService.sortPeriodsByDay(item.formattedPeriods);
  }

  validate(e): void {
    this.validResult.emit(e !== '')
  }

  getResult() {
    const data = this.buildData();

    if (this.moreHours.length == 0) {
      this.validResult.emit(false);
      return
    }

    this.validResult.emit(true);
    return {moreHours: data }
  }

  openHoursDialog(item) {
    const dialogRef = this.dialog.open(ModalRegularHoursComponent, {
      width: '680px',
      data: {
        title: item.displayName,
        weekDays: item.formattedPeriods,
        save: false
      }
    });

    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        item.formattedPeriods = result;
        this.buildPeriods(item);
        const isClosed = item.formattedPeriods.find(i => i.open) ? false : true;
        const indexItems = this.moreHours.findIndex(el => el.hoursTypeId == item.hoursTypeId);
        if (isClosed && indexItems > -1) {
          this.moreHours.splice(indexItems, 1);
          this.moreHours = this.sortMoreHours();
          this.labels = this.buildLabels();
        }
        if (!isClosed && indexItems == -1) {
          this.moreHours.push(item);
          this.moreHours = this.sortMoreHours();
          this.labels = this.buildLabels();
        }
      }
    });
  }

  sortMoreHours() {
    return this.moreHours.sort((a,b) => { return a.hoursTypeId < b.hoursTypeId ? -1 : 1});
  }

  originalOrder = (a, b) => 0;
}
