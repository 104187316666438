<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="updatingData">
  <mat-spinner></mat-spinner>
</div>

<app-reports-filter 
  *ngIf="!loading" 
  [accountId]="accountId" 
  [locations]="isReport ? locations : location"
  [isShared]="isShared" 
  [selectedDate]="selectDate" 
  [hasPeriodSelect]="true" 
  [hasComparison]="isComparisonVisible" 
  [isComparisonVisible]="true" 
  [compareToValue]="dataRange"
  [type]="'keyword'" 
  [isReport]="isReport"
  [minDate]="minDate" 
  [maxDate]="maxDate"
  (export)="handleExport($event)" 
  (dataPicker)="handleDataPicker($event)" 
  (selectRange)="handleSelectRange($event)" 
  (changedViewComparison)="changedViewComparison($event)"
>
</app-reports-filter>

<mat-progress-bar mode="indeterminate" [class.mt--30]="!isReport" *ngIf="isProgressCSV"></mat-progress-bar><!-- //NOSONAR -->

<div [ngClass]="isReport || isShared ? 'ml--35 mr--35 pt--5' : ''">

  <app-skeleton-search-keywords *ngIf="loading"></app-skeleton-search-keywords>

  <section *ngIf="!loading">

    <div class="row box--height mb--30" [ngClass]="isReport || isShared ? 'mt--25' : 'mt--60'">
      <h2 class="col-12" *ngIf='report?.reportName'><strong>{{report?.reportName|uppercase}}</strong></h2>

      <!-- CARDS -->
      <div class="col-12 col-sm-4 col-lg-4">
        <section class="pl--20 pr--20 box box--shadow box--rounded bg--white pb--20 pt--20">
          <h2 class="txt--md m--0 mb--15"><strong>Unique Keywords</strong></h2>
          <section class="d-flex">
            <p class="d--inl-block m--0 txt--price fw--regular">{{searchKeywordsData?.comparison?.actual | number}}</p>
            <div class="d--inl-block chip--trend ml--10" *ngIf="isComparisonVisible && !updatingData" [ngClass]="getComparisonBoxClass('keywords')">
              <mat-chip-list>
                <mat-chip class="m--0 p--0 txt--xs line--height--14 fw--medium" disabled>
                  <i class="fa fa-arrow-up chip--trend--arrow"></i>
                  {{getChipValue(searchKeywordsData?.comparison?.trend) | buildPercentageFormat: false }}
                  <span *ngIf="searchKeywordsData?.comparison?.trend >= 1000">+</span>
                </mat-chip>
              </mat-chip-list>
            </div>
          </section>
          <p class="m--0 txt--sm"><strong>Keywords</strong></p>
          <p class="m--0 mt--15 txt--14 fw--regular">“Unique Keywords” count is the number of unique keywords your business was found on the first page for Google Maps and Search.</p>
        </section>
      </div>

      <div class="col-12 col-sm-4 col-lg-4">
        <section class="pl--20 pr--20 box box--shadow box--rounded bg--white pb--20 pt--20">
          <h2 class="txt--md m--0 mb--15"><strong>Branded Searches</strong></h2>
          <section class="d-flex">
            <p class="d--inl-block m--0 txt--price fw--regular">{{searchImpressionsData?.current?.direct?.percent}}%</p>
            <div class="d--inl-block chip--trend ml--10" *ngIf="isComparisonVisible && !updatingData" [ngClass]="getComparisonBoxClass('branded')">
              <mat-chip-list>
                <mat-chip class="m--0 p--0 txt--xs line--height--14 fw--medium" disabled>
                  <i class="fa fa-arrow-up chip--trend--arrow"></i>
                  {{getChipValue(searchImpressionsData?.trend?.direct) | buildPercentageFormat: false}}
                  <span *ngIf="searchImpressionsData?.trend?.direct >= 1000">+</span>
                </mat-chip>
              </mat-chip-list>
            </div>
          </section>
          <p class="m--0 txt--sm"><strong>{{searchImpressionsData?.current?.direct?.numeric | number}} Impressions</strong></p>
          <p class="m--0 mt--15 txt--14 fw--regular">Searches with keywords that include or are related to the name or address of your business.</p>
        </section>
      </div>

      <div class="col-12 col-sm-4 col-lg-4">
        <section class="pl--20 pr--20 box box--shadow box--rounded bg--white pb--20 pt--20">
          <h2 class="txt--md m--0 mb--15"><strong>Non-branded Searches</strong></h2>
          <section class="d-flex">
            <p class="d--inl-block m--0 txt--price fw--regular">{{searchImpressionsData?.current?.discovery?.percent}}%</p>
            <div class="d--inl-block chip--trend ml--10" *ngIf="isComparisonVisible && !updatingData" [ngClass]="getComparisonBoxClass('non-branded')">
              <mat-chip-list>
                <mat-chip class="m--0 p--0 txt--xs line--height--14 fw--medium" disabled>
                  <i class="fa fa-arrow-up chip--trend--arrow"></i>
                  {{getChipValue(searchImpressionsData?.trend?.discovery) | buildPercentageFormat: false}}
                  <span *ngIf="searchImpressionsData?.trend?.discovery >= 1000">+</span>
                </mat-chip>
              </mat-chip-list>
            </div>
          </section>
          <p class="m--0 txt--sm"><strong>{{searchImpressionsData?.current?.discovery?.numeric | number}} Impressions</strong></p>
          <p class="m--0 mt--15 txt--14 fw--regular">Searches with keywords related to the category, product or services your business offers.</p>
        </section>
      </div>
    </div>

    <!-- GRAPH -->
    <div class="col-12 mb--30 pl--20 pr--20 box box--shadow box--rounded bg--white pb--20 pt--20">
      <section class="d-flex justify-content-between mb--15">
        <h2 class="txt--md m--0 mb--15 p--0 col-6"><strong>{{currentVisibleGraph | titlecase}}</strong></h2>
        <section class="select-visible-graph col-6 txt--align--end">
          <mat-button-toggle-group
            *ngIf="graphIsVisible"
            name="graph"
            aria-label="Visible Graph"
            hideSingleSelectionIndicator="false"
            [value]="visibleGraph"
            (change)="changedVisibleGrapg($event)"
          >
            <mat-button-toggle value="total-impressions">Total Impressions</mat-button-toggle>
            <mat-button-toggle value="total-keywords">Total Keywords</mat-button-toggle>
          </mat-button-toggle-group>
        </section>
      </section>

      <section>
        <div>
          <section class="mb--15" *ngIf="visibleGraph === 'total-impressions'">
            <section class="d-flex">
              <p class="m--0 txt--price fw--regular">{{searchImpressionsData?.current?.total | number}}</p>
              <div class="d--inl-block chip--trend ml--10" *ngIf="isComparisonVisible && !updatingData" [ngClass]="getComparisonBoxClass('impressions')">
                <mat-chip-list>
                  <mat-chip class="m--0 p--0 txt--xs line--height--14 fw--medium" disabled>
                    <i class="fa fa-arrow-up chip--trend--arrow"></i>
                    {{getChipValue(searchImpressionsData?.trend?.total) | buildPercentageFormat: false}}
                    <span *ngIf="searchImpressionsData?.trend?.total >= 1000">+</span>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </section>
            <p class="m--0 txt--sm"><strong>Impressions</strong></p>
          </section>

          <section class="mb--15" *ngIf="visibleGraph === 'total-keywords'">
            <section class="d-flex">
              <p class="m--0 txt--price fw--regular">{{searchKeywordsData?.graph?.current?.total | number}}</p>
              <div class="d--inl-block chip--trend ml--10" *ngIf="isComparisonVisible && !updatingData" [ngClass]="getComparisonBoxClass('keywords')">
                <mat-chip-list>
                  <mat-chip class="m--0 p--0 txt--xs line--height--14 fw--medium" disabled>
                    <i class="fa fa-arrow-up chip--trend--arrow"></i>
                    {{getChipValue(searchKeywordsData?.graph?.trend?.total) | buildPercentageFormat: false}}
                    <span *ngIf="searchKeywordsData?.graph?.trend?.total >= 1000">+</span>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </section>
            <p class="m--0 txt--sm"><strong>Keywords</strong></p>
          </section>

          <app-chart-line
            *ngIf="graphIsVisible && dataSet?.length > 0"
            chartType="line"
            [commaNumbers]="false"
            [labels]="labelsGraph"
            [dataset]="dataSet"
            [aggregation]="dataPicker.aggregation"
            [colors]="colors">
          </app-chart-line>

          <section class="txt--gray" *ngIf="visibleGraph === 'total-impressions'">
            <p class="m--0">An "impression" refers to the number of times your business listing is displayed on a user's screen in Google Maps or Search:</p>
            <ul>
              <li class="m--0">Google Maps: when your business listing appears in the local search results, map views, or local pack results, and it is visible to the user.</li>
              <li class="m--0">Google Search: Each time a URL from your site appears in search results and is visible to the user</li>
            </ul>
            <p class="m--0">An impression does not necessarily mean that the user interacted with the result or listing; it simply means that the result or listing was displayed on their screen.</p>
          </section>

          <section class="txt--gray" *ngIf="visibleGraph === 'total-keywords'">
            <p class="m--0">“Total Keywords” count is the number of times per day per keywords your business was found on the first page of Google Maps and Search.</p>
            <p class="m--0">NOTE: A higher page one count signifies better search visibility and the potential for more converted customers</p>
          </section>
        </div>
      </section>
    </div>

    <!-- TABLES -->
    <div class="d-flex justify-content-between mb--30">
      <!-- Keywords -->
      <div class="w--50 mr--15 pl--20 box box--shadow box--rounded bg--white pb--20 pt--20">
        <ng-container>
          <section class="d-flex justify-content-between mb--10">
            <h2 class="txt--md m--0 p--0 col-7"><strong>Keywords</strong></h2>
            <!-- Keywords Atlas Search -->
            <div class="mr--20 w--50" [appUserFeature]="'searchKeywordsAtlas'">
              <div>
                <span
                  class="input-field input-field--search input-field--header border--solid border--gray keyword-search">
                  <input type="text" [formControl]='keywordSearchInput' matInput id="keywordsSearch"
                    placeholder="Search By Keyword" />
                </span>
              </div>
            </div>
            <div>
              <button mat-icon-button tabIndex="-1" class="btn btn-icon btn-icon--txt mr--10" 
                [matTooltip]="!keywordSearchInput.value ? 'Search for keywords first to download CSV' : 'Download CSV'"
                [disabled]="!keywordSearchInput.value" 
                (click)="handleExportCsvKeywordsSearch()" 
              >
                <i class="fas fa-file-csv"></i>
              </button>
            </div>
          </section>
  
          <div class="w--100 pr--20">
            <table class="table table--bordered" mat-table [dataSource]="keywordsDatasource" matSort [matSortActive]="sort.sortBy" [matSortDirection]="sort.sortDirection" (matSortChange)="sortChanged($event)">
              <ng-container matColumnDef="keywords">
                <th mat-header-cell *matHeaderCellDef class="txt--black w--50"><span>Keyword</span></th>
                <td mat-cell *matCellDef="let row">
                  <div class="cell__content flex-column align-items-start justify-content-center">
                    <p class="m--0 txt--md">{{ row?.keyword | titlecase }}</p>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="impressions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="txt--black txt--right p--0 w--10 header-float"><span class="mr--10">Impressions</span></th>
                <td mat-cell *matCellDef="let row" class="pr--10">
                  <div class="flex-column align-items-start justify-content-center mr--10">
                    <p class="m--0 txt--md txt--right mr--10">{{ getImpressionsValue(row?.numberOfImpressions) }}</p>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="differencePercentage">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="txt--black txt--right p--0 pr--10 w--20 header-float"><span class=" txt--right mr--10">Vs {{dataRange.displayName | titlecase}}</span></th>
                <td mat-cell *matCellDef="let row" class="txt--right mr--10 p--0">
                  <div class="flex-column align-items-start justify-content-center"
                    *ngIf="row?.prevComparison?.percentage === 0">
                    <p class="cursor--pointer" matTooltip="There is no comparison available"
                      matTooltipPosition="above" matTooltipClass="tooltip tooltip--black"> - </p>
                  </div>
                  <section class="box">
                    <div class="flex-column align-items-end p--0 chip--trend"
                      *ngIf="row?.prevComparison?.percentage != 0">
                      <div class="d--inl-block chip--trend ml--10" *ngIf="isComparisonVisible && !updatingData" [ngClass]="getComparisonBoxClass('keywords-table', row)">
                        <mat-chip-list>
                          <mat-chip class="m--0 p--0 txt--xs line--height--14 fw--medium" disabled>
                            <i class="fa fa-arrow-up chip--trend--arrow"></i>
                            {{getChipValue(row?.prevComparison?.percentage) | buildPercentageFormat: false}}
                            <span *ngIf="row?.prevComparison?.percentage >= 1000">+</span>
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                    </div>
                  </section>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsKeywords"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsKeywords"></tr>
            </table>

            <!-- No data when filter -->
            <div class="p--15" *ngIf="keywordsDatasource?.data?.length === 0 && keywordSearchInput?.value"> No data matching the filter. </div>
            
            <app-paginator [pagination]="pagination" (reload)="handleReload($event)" [options]="[10, 25,50,100,200,500]"></app-paginator>
          </div>
        </ng-container>

        <section class="txt--gray pr--20 pt--10">
          <p class="m--0">Keywords represent the unique search terms that your business profile appeared in Google Maps and Search results on desktop and mobile:</p>
          <ul>
            <li class="m--0">Impressions are displayed for reference to determine the number of times your business listing is displayed on a user's screen in Google Maps or Search results for a given keyword.</li>
            <li class="m--0">Multiple impressions by a unique user within a single day are counted as a single impression. </li>
          </ul>
          <p class="m--0">You can use this report to track changes in impressions over time to monitor changes of your business’s visibility on Maps and Search.</p>
        </section>
      </div>

      <!-- Topics -->
      <div class="w--50 ml--15 pl--20 box box--shadow box--rounded bg--white pb--20 pt--20">
        <h2 class="txt--md m--0 mb--15 p--0 col-9"><strong>Topics</strong></h2>
        <div class="mr--20">
          <table class="table table--bordered" mat-table [dataSource]="dataSourceTopics" aria-describedby="topic-keywords">
            <ng-container matColumnDef="rank">
              <th mat-header-cell *matHeaderCellDef class="txt--black txt--right p--0 w--10"><span class="mr--10">Rank</span></th>
              <td mat-cell *matCellDef="let row" class="p--0 txt--right">
                <div class="cell__content txt--right p--0">
                  <p class="m--0 txt--md mr--10">{{ row?.id }}</p>
                </div>
              </td>
            </ng-container>
    
            <ng-container matColumnDef="details">
              <th mat-header-cell *matHeaderCellDef class="txt--black pl--10"><span>Search Terms</span></th>
              <td mat-cell *matCellDef="let row">
                <div class="cell__content flex-column align-items-start justify-content-center">
                  <p class="m--0 txt--md txt--black txt--underline w--100">
                    {{ row?.topics | titlecase }}
                    <button mat-icon-button tabIndex="-1" class="btn btn-icon btn-icon--txt pull--right" (click)="handleExportCsvTopic(row)" matTooltip="Download CSV">
                      <i class="fas fa-file-csv"></i>
                    </button>
                  </p>
                  <p class="m--0 txt--md">{{ row?.low_estimate | number }} - {{ row?.high_estimate | number }} Impressions ({{row?.impressions_percentage}}%)</p>
                  <p class="m--0 txt--md">{{ row?.total_keywords | number }} Keywords ({{row?.keywords_percentage}}%)</p>
                </div>
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="fieldsColumnsTopics"></tr>
            <tr mat-row *matRowDef="let row; columns: fieldsColumnsTopics"></tr>
          </table>
        </div>
      
        <div class="p--15 pl--30" *ngIf="dataSourceTopics?.data?.length === 0"> No data available.</div>
        <app-paginator class="topics-paginator" [pagination]="topicPagination" (reload)="topicsHandleReload($event)" [options]="[10, 25,50,100,200,500]"></app-paginator>

        <section class="txt--gray pr--20 pt--10">
          <p class="m--0">Keyword Topics represent the most commonly used search terms that generated impressions for your business profile. You can export the CSV to view a detailed breakdown of keywords for each topic.</p>
        </section>
      </div>

    </div> 
  
    <!-- Multilocations table -->
    <app-multilocations-info-table *ngIf="isReport && report?.reportType != 'keyword-location'" [isLoading]="tableLoading"
      [reportType]="'keywords'" [reportName]="report?.reportName" [dataPicker]="dataPicker" [reportId]="reportId" [title]="'Multi Profiles Table'" [isShared]="isShared"
      [hasExportPDF]="true" [hasExportCSV]="true" [displayedColumns]="displayedColumnsMultLoc" [sort]="sortMultiloc"
      [fieldsColumns]="fieldsColumnsMultiLoc" [dataSource]="dataSourceMultiLoc" [pagination]="paginationMultiloc"
      (handleMultilocSort)="handleMultilocSort($event)" (handleMultilocPaginate)="handleMultilocPaginate($event)"
      [appUserFeature]="'searchTermsMultilocationTable'">
    </app-multilocations-info-table>
  </section>

  <!-- <section
    class="box--height pt--30 mb--30">
    <div class="box box--rounded box--shadow bg--white" style="margin-top: 50px;">
      <div class="box__header d-flex align-items-center justify-content-center">
        <h2 class="txt--capitalize"><strong>No keywords data for the {{dataRange?.displayName}}</strong></h2>
      </div>
    </div>
  </section> -->

</div>
