import {Component, Input, OnInit} from '@angular/core';

import { DataPicker } from '../../../constants/data-picker';

@Component({
  selector: 'app-chart-popular-time',
  templateUrl: './popular-time-chart.component.html',
  styleUrls: ['./popular-time-chart.component.scss']
})
export class PopularTimeChartComponent implements OnInit {

  @Input() popularStats = null;
  @Input() dataPicker: DataPicker;
  @Input() reportType: string = null;

  days: any = {data: []};
  hours: any = {data: []};

  ngOnInit() {
    if (this.popularStats) {
      this.days = this.popularStats?.days;
      this.hours = this.popularStats?.hours;
    }
  }

}
