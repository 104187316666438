// dep
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

// app
import { ProtocolService } from '../../services/protocol.service';
import { SnackbarService } from '../../services/snackbar.service';
import { Pageable } from '../../constants/pageable';
import { ModalService } from '../../services/modal.service';
import { ModalReplyReviewComponent } from '../reply-edit/reply-edit.component';
import { Pagination } from '../../constants/api-response';
import { AutoReplyReviewErrorComponent } from '../../components/auto-reply-review-error.component';
import { ReviewsService } from '../../services/reviews.service';
import { LoadingService } from '../../services/loading.service';
import { LocationProtocolLog } from '../../constants/protocol-log';
import { LocationRef } from '../../constants/firestore/location-object';


type Filter = { status: boolean, view: boolean, location: string[], accounts: string[]}

@Component({
  selector: 'app-protocol-log',
  templateUrl: './protocol-log.component.html',
  styleUrls: ['./protocol-log.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProtocolLogComponent implements OnInit {
  @Input() locations: LocationRef[] = [];
  @Input() shared  = false;
  paginate: Pageable = {page: 1, size: 10};
  previousPageable: { size: any; page: any };
  pagination: Pagination;
  columns = ['date', 'name', 'message', 'status', 'action-btn'];
  showMoreMessageIndex: number;
  showMoreReplyIndex: number;
  progress = true;
  selectedView: boolean = null;
  searchText = '';
  errorMessage = false;
  showMoreReply = false;
  showMoreMessage = false;
  filter = { status: null, view: null, location: [], accounts: []};
  dataSource = new MatTableDataSource<any>([]);
  dataSourceNew = new MatTableDataSource<any>([]);
  view: any;
  predicate: (data: any, filter: string) => boolean;
  reload: boolean;
  resultLocationName: Observable<LocationProtocolLog[]> = of([]);
  copyResultLocationName: Observable<LocationProtocolLog[]> = of([]);
  selectedSearch = new FormControl();
  locationName = '';
  isClear = true;

  constructor(
    private reviewS: ReviewsService,
    private protocolS: ProtocolService,
    private snackS: SnackbarService,
    private modalService: ModalService,
    private loadingService: LoadingService
  ) {  }

  get isFiltered(): boolean {
    return this.filter?.accounts?.length > 0 && this.filter?.location?.length > 0 && this.filter?.status && this.filter?.view;
  }

  ngOnInit() {
    this.progress = true;
    if (this.shared) {
      this.columns  = ['date', 'name', 'message', 'status'];
    }
    if (this.locations?.length > 0) {
      this.locations.forEach( l => {
        this.filter.location.push(l.locationId);
        this.filter.accounts.push(l.accountId);
      });
      this._getData(this.filter);
    } else {
      this._getData(this.filter);
      this.resultLocationName = this.copyResultLocationName = this.protocolS.getLocationNamesProtocolLog();
    }
    this.predicate = this.dataSourceNew.filterPredicate = (data: any, filter: string) => {
      return data.viewed.toString() === filter;
    };

  }

  private _getData(filter: { status: boolean, view: boolean, location: string[], accounts: string[]} = null) {
    this.progress = true;
    this.protocolS.getProtocolLog(this.paginate, filter).subscribe(result => {

      this.previousPageable = {size: result.per_page, page: result.page};
      this.pagination = result;
      this.dataSource.data = result.items as any[];
      this.progress = false;
      this.isClear = false;
      this.loadingService.reportAdvanced(1, "Logs")
    });
  }

  data = (pageable?: Pageable) => {
    return this.protocolS.getProtocolLog(pageable);
  };


  changeViewed(element: any) {
    if (!element.reviewId) {
      return;
    }
    if (!element?.viewed) {
      element.viewed = false;
    }
    this.reviewS.changeViewed(element.reviewId, !element.viewed).subscribe(result => {
      element.viewed = result.viewed;
      this._getData(this.filter)
      this.handleView(this.view);
    }, error => {
      this.snackS.openError('error to change viewed');
    });
  }

  handleView($event: any) : void {
    this.view = $event;
    switch ($event) {
      case 'read':
        this.dataSourceNew.filter = 'true';
        break;
      case 'unread':
        this.dataSourceNew.filter = 'false';
        break;
      default:
        this.dataSourceNew.filter = undefined;
        break;
    }
  }

  handleChangeSearch(inputSearchText: string) : void {
    this.resultLocationName = this.copyResultLocationName;
    if (inputSearchText && inputSearchText[inputSearchText.length-1] !== ' ') {
      const textSearch = inputSearchText.trim().toLowerCase();
      this.resultLocationName = this.resultLocationName.pipe(map(al => al.filter( l => l.locationName.trim().toLowerCase().indexOf(textSearch) > -1 ||
            l.locationAddress.locality.trim().toLowerCase().indexOf(textSearch) > -1 ||
            l.locationAddress.administrativeArea.trim().toLowerCase().indexOf(textSearch) > -1 ||
            l.locationAddress.postalCode.trim().toLowerCase().indexOf(textSearch) > -1 ||
            l.locationAddress.regionCode.trim().toLowerCase().indexOf(textSearch) > -1 ||
            l.locationAddress.addressLines.some( add => add.trim().toLowerCase().indexOf(textSearch) > -1))
      ))
    }
  }

  selected() : void {
    const value = this.selectedSearch.value;
    this.selectedSearch.setValue(value.locationName);
    this.locationName = value.locationName;
    this.filter.location.push(value.locationId);
    this.filter.accounts.push(value.accountId);
    this.paginate = {page: 1, size: 10};
    this._getData(this.filter)
  }

  toggleMessage(i): void {
    this.showMoreMessage = !this.showMoreMessage;
    this.showMoreMessageIndex = i;
  }

  toggleReply(i): void {
    this.showMoreReply = !this.showMoreReply;
    this.showMoreReplyIndex = i;
  }

  edit(element: any) {
    // todo use real messages
    this.modalService.openGenericModal(ModalReplyReviewComponent, element, result => {
      this.changeViewed(element)
    });
  }

  async delete(element: any) : Promise<void> {
    if(await this.modalService.openConfirmModal('Delete Reply', '')) {
      const accountId  = element.name.split('/')[1];
      const locationId = element.name.split('/')[3];
      await this.reviewS.replyDelete(accountId, locationId, element.reviewId).toPromise()
      this._getData(this.filter);
    }
  }

  handleReload($event: Pageable) {
    this.paginate = $event;
    this._getData(this.filter);
  }

  openError(element) {
    if (element?.error && !this.shared) {
      this.modalService.openGenericModal(AutoReplyReviewErrorComponent, {...element});
    }
  }

  locationUrl(log, destiny : string) : string {
    const url  = log.name.split('/');
    const accountId = url[1];
    const locationId = url[3];
    return `/account/${accountId}/location/${locationId}/${destiny}`;
  }

  applyFilterStatus($event) : void {
    this.filter.status = $event.value;
    this._getData(this.filter);
  }

  clearFilters() {
    this.isClear = true;
    this.filter.status = null;
    if (this.locations?.length == 0) {
      this.filter.location = [];
      this.filter.accounts = [];
      this.resultLocationName = this.copyResultLocationName;
    }
    this.locationName = '';
    this._getData(this.filter);
  }

  applyFilterView($event) : void {
    this.filter.view = $event.value;
    this._getData(this.filter);
  }

   /**
   * this method handle change text search keyword comment or reviewer
   * @param searchText
   */
  /* TODO: Unused, remove
  handleSearchText(searchText: string) {
    this.searchText = searchText;
    //this.getData();
  }
  */
}
