// dep
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// app
import { ISubscription } from 'src/app/constants/subscription';
import { NavigationService } from 'src/app/services/navigation.service';
import { ManagePlanService } from '../services/manage-plan.service';
// import { ModalService } from 'src/app/services/modal.service';
// import { ModalTrialEnded } from './../../modules/modal-trial-ended/modal-trial-ended.component';
import { IPricingObject } from './../constants/pricing';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit, OnDestroy {

  public firstLoad = true;
  public isPricingVisible = true;
  public subscription: ISubscription;
  public isLoading = true;
  public showAddonsModal = false;
  public modalTitle = '';
  public daysLeftInTrial = 0;
  public trialProgress = 0;
  public brandYellow = '#FBBC05';
  readonly listColumns = ['number', 'date', 'status', 'amount', 'actions'] as const
  // we create an IPricingObject object and populate it with the info we require to build the template
  public pricingObject: IPricingObject;

  private _ngUnsubscribe$ = new Subject<void>();

  constructor(
    private _mngPlanS: ManagePlanService,
    private _navigationS: NavigationService,
    // private _modalS: ModalService,
    ) {}
  
  ngOnInit(): void {
    this.firstLoad = false;
    this._mngPlanS.isLoading$
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe(value => this.isLoading = value);
    this._mngPlanS.pricingObject$
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe(data => {
        if (data) {
          this.pricingObject = data;
          this.isPricingVisible = !this.pricingObject.billingOverride;
        }
      });
    this._mngPlanS.loadPlan();
  }

  async onAddAddons(): Promise<void> {
    this.modalTitle = 'Manage Add-Ons';
    this.showAddonsModal = true; 
  }

  async onAddCard() : Promise<void> {
    this._mngPlanS.onAddCard();
  }

  async handleDefaultSource(card): Promise<void> {
    this._mngPlanS.handleDefaultSource(card);
  }

  async applyDefaultSource(card): Promise<void> {
    this._mngPlanS.applyDefaultSource(card);
  }

  async handleDeleteSource(card): Promise<void> {
    this._mngPlanS.handleDeleteSource(card);
  }

  updatePlanStatus(event: any): void {
    this.showAddonsModal = false
      this._mngPlanS.reloadPricingObject();
  }

  navigatePricingWeb(): void {
    this._navigationS.goToPricingWeb();
  }
  
  navigateProfServicesWeb(): void {
    this._navigationS.goToProfessionalServicesWeb();
  }

  onDownloadInvoicePDF(element): void {
    this._mngPlanS.onDownloadInvoicePDF(element)
  }
  
  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }

}