/**
 * Session Trace service
 * 
 */

// dep
import { Injectable } from '@angular/core';

// app
import { environment as ENV } from '@environment';

declare let gtag : any

const ZAPIER_URL = 'https://hooks.zapier.com/hooks/catch/13075898/23djv7q/'
  
interface IEventInfo {
  accountId?: string;
  domain?: string;
  gid?: string;
  name?: string;
  email?: string;
  created_at?: string;
  isTrial?: boolean;
  essentialListings?: number;
  basicListings?: number;
  ultimateListings?: number;
  totalListings?: number;
  totalLocations?: number;
  userId?: string;
  locationId?: string;
}
interface ITracker {
  identify( uid : string, info : IEventInfo ) : void;
  track (event : string, info? : IEventInfo ) : void;
}

class GTMTracker implements ITracker {
  identify(uid: string, info: IEventInfo) {
    console.debug("GTag identify (no-op)", uid, info)
  }
  track(event: string, info?: IEventInfo) {
    // Gtag event
    try {
      gtag('event', event)
    } catch(err) {
      console.error(err, info)
    }
  }
}

class ConsoleTracker implements ITracker {
  identify(uid: string, info: IEventInfo) {
    console.debug(`Console Tracker: Identify ${uid}`, info)
  }
  track(event: string, info?: IEventInfo) {
    console.debug(`Console Tracker: Event ${event} `, info)
  }
}

class JitsuTracker implements ITracker {
  private _analyticsQ() : any[] {
    const w = window as any
    w.jitsuQ = w.jitsuQ || []
    return w.jitsuQ
  }

  identify(uid: string, info: IEventInfo): void {
    // SEE https://docs.jitsu.com/sending-data/html#jitsu-processing-queue
    this._analyticsQ().push((jitsu : any) => jitsu.identify(uid, info))
  }
  track(event: string, info?: IEventInfo): void {
    // SEE https://docs.jitsu.com/sending-data/html#jitsu-processing-queue
    this._analyticsQ().push(function(jitsu) {
      jitsu.track(event, info)
    })   
  }
}

class DDTracker implements ITracker {
  identify(uid: string, info: IEventInfo): void {
    // Now loading via async so need to ensure we 'wait' via onReady
    const DD_RUM = (window as any).DD_RUM
    DD_RUM.onReady(() => {
      DD_RUM.setUser({
        'id': uid,
        'email': info.email
      });
    });
  }
  track(event: string, info?: IEventInfo): void {
    console.debug("DD track (no-op)", event, info)
  }
}

@Injectable({
  providedIn: 'root'
})
export class SessionTraceService {

  private _isGtagEnabled = ENV.ga4Enabled
  private _isUserGuidingEnabled = ENV.userGuidingEnabled 
  private _uid : string | null = null
  private _info : IEventInfo = null
  private _trackers: ITracker[] = []

  constructor() {
    // Only use real trackers in production mode
    if( ENV.production ) {
      if ( this._isGtagEnabled) {
        this._trackers.push(new GTMTracker)  
      }
      this._trackers.push(new DDTracker)
      this._trackers.push(new JitsuTracker)
    } else {
      /// For non-prod, just log to console
      this._trackers.push(new ConsoleTracker)
    }
  }

  private async _track(event : string, info? : IEventInfo ) {
    for (const t of this._trackers) {
      try {
        t.track(event, info) ;
      } catch(err) {
        console.error('Error: tracker.track()', err)
      }
    }
  }

  private async _identify(event : string, info? : IEventInfo ) {
    for (const t of this._trackers) {
      try {
        t.identify(event, info);
      } catch(err) {
        console.error('Error: tracker.identify()', err)
      }
    }
  }

  /**
   * Must be called when the user logs in. 
   */
  async onLogin(uid : string, info : IEventInfo) : Promise<void> {
    if(this._uid)
        // avoid double log-in miscalls
        return

    this._uid = uid
    this._info = info
    this._identify(uid, info)
  }

  /**
   * Must be called when the user logs out.
   */
  async onLogout() : Promise<void> {
    const uid = this._uid 
    this._uid = null
    this._info = null
    this._track("session logout", { userId : uid })
  }

  /**
   * Must be called when a new Location is added.
   */ 
  async onLocationAdded(accountId : string, locationId : string, totalLocations : number) : Promise<void> {
    this._track("location added", { userId : this._uid,
                                    accountId, 
                                    locationId,
                                    totalLocations })
  }

  async onFirstLocationAdded() : Promise<void> {
    /**
     * See MAP-2437: Jitsu>Hubspot wont work so we have to rely on Zapier hook
     * The most prudent way is to fire an event directly to to existing Zapier hook
     */
    try {
      const body = { data: {
          email: this._info['email'],
          gid: this._info['gid'],
          uid: this._uid,
          add_first_listing: "true"
        }
      }

      // When using Angular HTTPClient, a default Content-Type 'application/json' is
      // added. This rejected by Zapier CORS, see:
      // - https://help.zapier.com/hc/en-us/articles/8496291737485-Troubleshoot-webhooks-in-Zapier#h_01HCZE2Q4N14HEFS0CWTXZ833C
      // So we need to use plain fetch(), as the Angular HTTPClient doesn't seems to allow
      // not sending the Content-Type header.
      //
      // await this._http.post<any>(ZAPIER_URL, body, HEADERS_NO_AUTH).toPromise()
      await fetch(ZAPIER_URL, { method: 'POST', 
                                body:   JSON.stringify(body), 
                                mode:   'no-cors'})

      console.info("Event succeeded: Added first listing")
    } catch(err) {
      console.error('Error sending info to Zapier', err)
    }

    this._track('addFirstListing')
  }

  async onRegistration() : Promise<void> {
    this._track('completeSignup')
  }
}