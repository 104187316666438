// dep
import { Directive, ElementRef, /*Input,*/ OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// app
import { ModalService } from '../services/modal.service';
// import { GROUP_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { SubscriptionService } from '../services/subscription.service';
import { FeaturedComponent } from '../components/featured.component';
import { LocationRef } from '../constants/firestore/location-object';
import { ModalGetAddOn } from '../modules/modal-get-addon/modal-get-addon.component';
import { SessionService } from '../services/session.service';


/* TODO: Unused, remove. 
@Directive({
  selector: '[gmbGGActive]'
})
export class GroupGatedPaidDirective implements OnInit {
  @Input() gmbGGActive = () => { };

  session$ = this._sessionS.session$;

  constructor(
    private _modalService: ModalService,
    private _sessionS : SessionService,
    private _subscriptionService: SubscriptionService,
    private _route: ActivatedRoute,
    private _element: ElementRef,
  ) {
  }

  ngOnInit() : void {
    const sub = this._sessionS.getSession().subscription
    if (sub.status === GROUP_SUBSCRIPTION_TYPE.PAID) {
      this._element.nativeElement.removeAllListeners('click');
      this._element.nativeElement.addEventListener('click', this.handler.bind(this));
    }
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async handler() : Promise<void> {
    console.log('GroupGatedPaidDirective handler');
    if(await this._modalService.openModal(FeaturedComponent, null) && 
       await this._subscriptionService.flowChangeLocationsPlan([this._route.snapshot.parent.params as LocationRef]))
         console.log("upgrade successful - group-gated.directive")
  }
  
}
*/

@Directive({
  selector: '[gmbSubscriptionInTrial]'
})
export class GroupGatedTrialDirective implements OnInit {

  session$ = this._sessionS.session$;

  constructor(
    private _modalService: ModalService,
    private _sessionS: SessionService,
    private _subscriptionService: SubscriptionService,
    private _route: ActivatedRoute,
    private _element: ElementRef,
  ) {
  }

  ngOnInit() : void {
    const s = this._sessionS.getSession()

    if ((s.subscription.pricingVersion < 3) && s.isTrial) {
      this._element.nativeElement.removeAllListeners('click');
      this._element.nativeElement.addEventListener('click', this.handler.bind(this));
    }
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  async handler() : Promise<void> {
    console.log('GroupGatedTrialDirective handler');
    if(await this._modalService.openModal(FeaturedComponent, null) &&
       await this._subscriptionService.flowChangeLocationsPlan([this._route.snapshot.parent.params as LocationRef]))
         console.log("upgrade successful - group-gated.directive")
  }

}


@Directive({
  selector: '[gmbBulkUpdateAddOn]'
})
export class GroupGatedBulkUpdateAddOnDirective implements OnInit {

  session$ = this._sessionS.session$;

  constructor(
    private _modalService: ModalService,
    private _sessionS : SessionService,
    private _element: ElementRef,
  ) {
  }

  ngOnInit() : void {
    if (!this._sessionS.getSession().bulkActionsEnabled) {
      this._element.nativeElement.removeAllListeners('click');
      this._element.nativeElement.addEventListener('click', this.handler.bind(this));
    }
  }

  async handler() : Promise<void> {
    await this._modalService.openModal(ModalGetAddOn,
      {
        title: 'Add-On: Bulk Actions and Roll-Up Reports',
        message: '',
        buttons: {
          'cancel': 'Maybe Later',
          'accept': 'Get Add-On'
        }
      }, 
      { 
        config: { 
          width: '830px' 
        }
      }
    )
  }
}