import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-showcase',
  templateUrl: './location-showcase.component.html',
  styleUrls: ['./location-showcase.component.scss']
})
export class LocationShowcaseComponent implements OnInit {
  @Input() isUnderReview = false;

  public sectionName = 'Showcases';

  constructor() { }

  ngOnInit() {
  }

}
