<app-skeleton-comparison [columns]="3" type="report" *ngIf="progress && !(domain$ | async).hideComparisonInGrader">
</app-skeleton-comparison>

<ng-container *ngIf="!progress && (!(domain$ | async).hideComparisonInGrader || fromReportsScreen)">
  <ng-container *ngIf="comparison?.stats; else empty">
    <!-- dataSelected has missing data in any segment for any range -->
    <div class="alert alert-warning" role="alert" *ngIf="comparisonPartialData">
      <h4>
          Missing or incomplete data
          <span matTooltip="{{ comparisonPartialDataMessage }}"
              matTooltipPosition="above"
              matTooltipClass="mat-tooltip--override"
              class="mat-tooltip tooltip tooltip--info">
              <em class="fas fa-info"></em>
          </span>
      </h4>
    </div>
    <!-- dataSelected has no data in at least one segment for any range -->
    <div class="alert alert-warning" role="alert" *ngIf="comparisonEmptyData">
      <h4>
          Empty data
          <span matTooltip="{{ comparisonEmptyDataMessage }}"
              matTooltipPosition="above"
              matTooltipClass="mat-tooltip--override"
              class="mat-tooltip tooltip tooltip--info">
              <em class="fas fa-info"></em>
          </span>
      </h4>
    </div>
    <div
      class="box box--rounded {{ type !== 'report' ? 'box--shadow' : '' }} bg--white {{ columns !== 3 ? 'mb--30' : '' }}">
      <div class="box__header d-flex justify-content-between" *ngIf="type !== 'report'">
        <h2 class="txt--black d-flex align-items-center"><strong> Performance ({{comparison?.points}}/{{comparison?.max}})</strong>
          <span matTooltip="{{information}}"
                matTooltipPosition="above"
                matTooltipClass="mat-tooltip--override"
                class="mat-tooltip tooltip tooltip--info">
            <i class="fas fa-info"></i>
          </span>
        </h2>
        <div class="box-statistics"></div>
      </div>
      <div class="box__content grade-comparision {{ type !== 'report' ? 'box--padding-xs' : '' }}">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="row {{ columns == 3 ? 'chips-row--lg' : '' }}" >
              <ng-container *ngFor="let key of gradeKeys; let i = index;">
                <div class="col-6 {{ columns == 3 ? 'col-md-4' : 'col-md-6' }} mb--30"
                     *ngIf="key!='labels' && isShowMetric(stats[key]?.displayName)">
                  <button mat-button class="p--0 w--100" (click)="setSelectedItem(i,key)">
                    <div class="box box--shadow box--border-sm box--padding-xxxs" [ngClass]="{
                        'box--selected': selectedItem === i,
                         'box--flex box--success': stats[key]?.percent > 0,
                         'box--flex box--fail': stats[key]?.percent < 0,
                         'box--flex box--info': stats[key]?.percent== 0
                      }">
                        <h6 class="chip-title txt--capitalize mb--10">
                          <strong>{{ stats[key]?.displayName }}</strong>
                          <span *ngIf="showScoreOnCard">{{ tooltipContent(stats[key]) }}</span>
                        </h6>
                      <div class="d-flex justify-content-center align-items-center flex-column">
                        <mat-chip-list *ngIf="key !== 'AVERAGE_STAR_RATING' || (key == 'AVERAGE_STAR_RATING' && reportType != 'comparison')">
                          <ng-container
                            *ngIf="stats[key]?.totalA !== 0 || stats[key]?.totalB !== 0 || stats[key]?.difference !== 0 && stats[key]?.percent !== 0; else emptyChip">
                            <mat-chip disabled>{{stats[key]?.difference | number : '1.0-1'}}</mat-chip>
                            <svg-icon [src]="getGraphRiseImage(selectedItem, i, stats[key]?.difference)"></svg-icon>
                            <mat-chip *ngIf="stats[key]?.comparable == null || stats[key]?.comparable" disabled>{{stats[key]?.percent | number : '1.0-0'}}%</mat-chip>
                            <mat-chip *ngIf="stats[key]?.comparable != null && !stats[key]?.comparable" disabled>-</mat-chip>
                          </ng-container>
                          <ng-template #emptyChip>
                          <mat-chip disabled>N/A</mat-chip>
                          </ng-template>
                        </mat-chip-list>

                        <mat-chip-list *ngIf="key === 'AVERAGE_STAR_RATING' && reportType == 'comparison'">
                          <ng-container
                            *ngIf="stats[key]?.totalA !== 0 || stats[key]?.totalB !== 0 || stats[key]?.difference !== 0 && stats[key]?.percent !== 0; else emptyChip">
                            <mat-chip disabled>{{stats[key]?.totalA | number : '1.0'}}</mat-chip>
                            <svg-icon [src]="getGraphRiseImage(selectedItem, i, stats[key]?.difference)"></svg-icon>
                            <mat-chip disabled>{{stats[key]?.difference | number : '1.0'}}
                            </mat-chip>
                          </ng-container>
                          <ng-template #emptyChip>
                          <mat-chip disabled>N/A</mat-chip>
                          </ng-template>
                        </mat-chip-list>

                      </div>
                    </div>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-12 col-lg-6">

            <ng-container *ngIf="!comparisonEmptyData; else empty">
              <div class="d-flex justify-content-center align-items-center flex-column">
                <h2 class="txt--capitalize mb--15"><strong>{{dataSelected?.displayName}}</strong></h2>
                <h3 class="txt--capitalize m--10" >
                  <strong>
                    {{ startDate }} vs {{ endDate }}
                  </strong>

                </h3>
                <div class="box box--padding-xxxs" [ngClass]="{
                         'box--flex box--success': dataSelected?.percent > 0,
                         'box--flex box--fail': dataSelected?.percent < 0,
                         'box--flex box--info': dataSelected?.percent == 0
                      }">
                  <div class="d-flex justify-content-center align-items-center">
                    <mat-chip-list>
                      <ng-container
                        *ngIf="dataSelected?.totalA !== 0 || dataSelected?.totalB !== 0 || dataSelected?.difference !== 0 || dataSelected?.percent !== 0; else emptySubChip">
                        <mat-chip disabled>{{dataSelected?.difference | number : '1.0-0'}}</mat-chip>
                        <svg-icon [src]="getGraphRiseImage(selectedItem, -1, dataSelected?.difference)"></svg-icon>
                        <mat-chip *ngIf="dataSelected?.comparable == null || dataSelected?.comparable" disabled>{{dataSelected?.percent | number : '1.0-0'}} %</mat-chip>
                        <mat-chip *ngIf="dataSelected?.comparable != null && !dataSelected?.comparable" disabled>-</mat-chip>
                      </ng-container>
                      <ng-template #emptySubChip>
                        <mat-chip disabled>N/A</mat-chip>
                      </ng-template>
                    </mat-chip-list>
                  </div>
                </div>

                <div class="">
                  <div class="row align-items-end txt--center mb--20">
                    <div class="col-5 p--0">
                      <p class="mb--5">Total Range A</p>
                      <p *ngIf="dataSelected?.displayName != 'Average Star Rating'" [ngStyle]="{ 'color': primaryDataColor.full }" class="txt--md m--0"><strong>{{dataSelected?.totalA | number: '1.0-0'}}</strong></p>
                      <p *ngIf="dataSelected?.displayName == 'Average Star Rating'" [ngStyle]="{ 'color': primaryDataColor.full }" class="txt--md m--0"><strong>{{dataSelected?.totalA | number: '1.0-1'}}</strong></p>
                    </div>
                    <div class="col-2">
                      <p class="txt--lightgray m--0">vs</p>
                    </div>

                    <div class="col-5 p--0">
                      <p class="mb--5">Total Range B</p>
                      <p *ngIf="dataSelected?.displayName !== 'Average Star Rating'" [ngStyle]="{ 'color': secondaryDataColor.full }" class="txt--md m--0"><strong>{{dataSelected?.totalB | number: '1.0-0'}}</strong></p>
                      <p *ngIf="dataSelected?.displayName == 'Average Star Rating'" [ngStyle]="{ 'color': secondaryDataColor.full }" class="txt--md m--0"><strong>{{dataSelected?.totalB | number: '1.0-1'}}</strong></p>
                    </div>
                  </div>


                </div>
                <div class="w--100">
                  <app-chart-line
                    [dataset]="[{label: 'range A', data:dataSelected?.rangeA}, {label: 'range B', data:dataSelected?.rangeB}]"
                    [labels]="labels"
                    [subLabels]="labels_b"
                    [aggregation]="dataPicker.aggregation"
                    [centerLegend]="true"
                    [grade]="true"
                    [commaNumbers]="true"
                    [noDecimals]="true"></app-chart-line>
                </div>

              </div>
            </ng-container>
            <ng-template #empty>
              <div class="row box--height">
                <div class="col-12">
                  <div class="box box--rounded">
                    <div class="box__header d-flex align-items-center justify-content-center">
                      <h2 class="txt--capitalize txt--lightgray"><strong>No data</strong></h2>
                    </div>
                  </div>
                </div>
              </div>

            </ng-template>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
  <ng-template #empty>
    <app-no-data [type]="type" message="no comparison info"></app-no-data>
  </ng-template>

</ng-container>
