import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment as ENV } from '@environment';
import { Subject, forkJoin, of, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { IReportFilterConfigData, IPaginationData } from './../../../constants/report-filter-config-data';
import { IKeywordsReportData } from '../../../constants/keywords-report';

@Injectable({
    providedIn: 'root'
})
export class KeywordsReportDataService {

    public keywordsReportDataObject: IKeywordsReportData;
    
    private _keywordsReportDataSubject = new Subject<IKeywordsReportData>();

    constructor(
        private _http: HttpClient,
    ) {}

    get keywordsReportDataSubject$(): Observable<IKeywordsReportData> {
        return this._keywordsReportDataSubject.asObservable();
    }

    getKeywordsUrl(filterConfig: IReportFilterConfigData, keywordsBody) {
        const body = this.getAtlasBody(filterConfig, filterConfig.filteredKeyword || '');
        
        return (
            filterConfig.filteredKeyword ? 
            this._http.post(`${ENV.apiUrl}/v2/search/keyword-search`, body): 
            this._http.post(`${ENV.apiUrl}/v2/locations/search-keywords`, keywordsBody)
        )
    }

    getKeywordsResults(filterConfig: IReportFilterConfigData): void {        
        let sortBy = filterConfig?.sort?.sortBy.includes('Comparison') && !filterConfig?.period ? 'impressions' : filterConfig?.sort?.sortBy;
        let sortOrder = filterConfig?.sort?.sortBy.includes('Comparison') && !filterConfig?.period ? -1 : filterConfig?.sort?.sortOrder;
        
        if(filterConfig?.period) {
            sortBy = `${filterConfig.period}Comparison`;
            sortOrder = -1;  
        }

        filterConfig.sort = {
            sortBy: sortBy,
            sortOrder: sortOrder
        }
        const keywordsBody = {
            ...filterConfig,
            page: filterConfig?.page || 1,
            size: filterConfig?.size 
        };
        
        const atlasKeywords = this.getAtlasBody(filterConfig, filterConfig?.filteredKeyword || '')

        const atlasKeywords$ = this._http.post(`${ENV.apiUrl}/v2/search/keyword-search`, atlasKeywords)
        const keywords$ = this._http.post(`${ENV.apiUrl}/v2/locations/search-keywords`, keywordsBody);
        const keywordsBrand$ = this._http.post(`${ENV.apiUrl}/v2/locations/keyword-brands`, keywordsBody);
        const topics$ = this._http.post(`${ENV.apiUrl}/v2/locations/keyword-trends`, keywordsBody);

        const observables = [
            keywords$,
            keywordsBrand$,
            topics$
        ];

        if(filterConfig?.filteredKeyword) {
            observables.push(atlasKeywords$)
        }

        // data retrieving
        forkJoin(observables).pipe(
            map((result: any[]) => {                
                // build the report object

                // interface for reference
                this.keywordsReportDataObject = {
    
                    // structures taken form old keywords component
                    // used in both creating the cards and part of the graphs

                    // This interface needs optimization, this is the first step to
                    // understand what does what and where. 
                    // I will improve it to make more sense
                    searchKeywordsData: {
                        comparison: {
                            actual: result[0]?.comparison?.keywords?.actual,// number;
                            trend: result[0]?.comparison?.keywords?.trend,// number;
                        },
                        graph: {
                            trend: {
                                total: result[1]?.data?.comparison?.keywords?.trend?.total
                            },
                            current: {
                                total: result[1]?.data?.comparison?.keywords?.current?.total
                            }
                        }
                    },
                    searchImpressionsData: {
                        trend: {
                            direct: result[1]?.data?.comparison?.impressions?.trend?.direct, // number;
                            discovery: result[1]?.data?.comparison?.impressions?.trend?.discovery, // number;
                            total: result[1]?.data?.comparison?.impressions?.trend?.total, // number;
                        },
                        current: {
                            direct: {
                                numeric: result[1]?.data?.comparison?.impressions?.current?.direct?.numeric, // number; // or string?
                                percent: result[1]?.data?.comparison?.impressions?.current?.direct?.percent
                            },
                            discovery: {
                                numeric: result[1]?.data?.comparison?.impressions?.current?.discovery?.numeric, // number; // or string?
                                percent: result[1]?.data?.comparison?.impressions?.current?.discovery?.percent // number; // or string?
                            },
                            total: result[1]?.data?.comparison?.impressions?.current?.total, // number; // or string?
                        }
                    },
                    labelsGraph: result[1]?.data?.labels,
    
                    // used as datasets for app-chart-line
                    impressionsStats: result[1]?.data?.impressions, // any; // not sure about the type here
                    keywordsStats: result[1]?.data?.keywords, // any; // same as above
                    dataPicker: null, // any; // we need a better way to pass this info to app-chart-line
    
                    // used as datasources for the keywords/topics table
                    keywordsDataSource: result[3]?.data || result[0]?.items || [], //MatTableDataSource<any[]>;
                    filteredKeyword: filterConfig?.filteredKeyword || '',
                    keywordsPagination: {
                        total: result[0]?.comparison?.keywords?.actual || 0,
                        page: result[0]?.page,
                        pages: result[0]?.pages,
                        per_page: result[0]?.per_page,
                        sortBy: result[0]?.sortBy,
                        sortOrder: result[0]?.sortOrder,
                        hasNext: result[0]?.hasNext,
                        hasPrev: result[0]?.hasPrev
                    },
                    topicsDataSource: result[2].keyword_trends.items || [], //MatTableDataSource<any[]>;
                    topicsPagination: {
                        total: result[2]?.keyword_trends?.total || 0,
                        page: result[2]?.keyword_trends?.page,
                        pages: result[2]?.keyword_trends?.pages,
                        per_page: result[2]?.keyword_trends?.per_page,
                        hasNext: result[2]?.keyword_trends?.hasNext,
                        hasPrev: result[2]?.keyword_trends?.hasPrev
                    }
                }

                this._keywordsReportDataSubject.next(this.keywordsReportDataObject)

            }),
            catchError(error => { // handle errors in a generic way, we can replace with single error handling in each request
                console.error(error)
                return of({error: true, message: 'failed when requesting data'})
            })
        ).subscribe()
    }

    getKeywordsTableData(filterConfig: IReportFilterConfigData, keyword?: string): void {

        if (!filterConfig.filteredKeyword || filterConfig.filteredKeyword === '') {
            this._getKeywordsTableDataUnfiltered(filterConfig);
        } else {
            this._getKeywordsTableDataFiltered(filterConfig, filterConfig.filteredKeyword);
        }
    }

    getTableDataPaginated(filterConfig: IReportFilterConfigData, tableType: string): void {
        if (tableType === 'keywords') {
            this.getKeywordsTableData(filterConfig, filterConfig.filteredKeyword)
        } else if (tableType === 'topics') {
            this._getTopicsTableData(filterConfig);
        }
    }

    getTableDataSorted(filterConfig: IReportFilterConfigData): void {
        this.getKeywordsTableData(filterConfig);
    }

    private _getKeywordsTableDataUnfiltered(filterConfig: IReportFilterConfigData): void {
        const body = {
            ...filterConfig,
        };

        this._http.post(`${ENV.apiUrl}/v2/locations/search-keywords`, body)
        .subscribe((response: any) => {
            this.keywordsReportDataObject = {
                ...this.keywordsReportDataObject,
                keywordsDataSource: response.items,
                filteredKeyword: '',
                keywordsPagination: {
                    total: response.comparison.keywords.actual || 0,
                    page: response.page,
                    pages: response.pages,
                    per_page: response.per_page,
                    sortBy: response.sortBy,
                    sortOrder: response.sortOrder,
                    hasNext: response.hasNext,
                    hasPrev: response.hasPrev
                }
            }

            this._keywordsReportDataSubject.next(this.keywordsReportDataObject)
        })
    }

    private _getKeywordsTableDataFiltered(filterConfig: IReportFilterConfigData, keyword: string): void {
        // needs optimization
        const body = this.getAtlasBody(filterConfig, keyword);
        this._http.post(`${ENV.apiUrl}/v2/search/keyword-search`, body)
        .subscribe((response: any) => {            
            this.keywordsReportDataObject = {
                ...this.keywordsReportDataObject,
                keywordsDataSource: response?.data,
                filteredKeyword: keyword,
                keywordsPagination: {
                    ...this.keywordsReportDataObject.keywordsPagination,
                    total: response?.total,
                    hasNext: response?.hasNext,
                    hasPrev: response?.hasPrev,
                    page: filterConfig?.page,
                    per_page: filterConfig?.size,
                    sortBy: filterConfig?.sort?.sortBy,
                    sortOrder: filterConfig?.sort?.sortOrder,
                    pages: Math.ceil(response?.total / filterConfig?.size)
                }
            };             
            this._keywordsReportDataSubject.next(this.keywordsReportDataObject);
        })
    }

    getAtlasBody(filterConfig: IReportFilterConfigData, keyword: string) {

        return {
            endDate: filterConfig?.endDate,
            location_ids: [filterConfig?.locationId],
            page: filterConfig?.page || 1,
            period: filterConfig?.period || 'prevYear',
            size: 25,
            sortBy: filterConfig?.sort?.sortBy === 'impressions' ? 'numberOfImpressions' : filterConfig?.sort?.sortBy,
            sort_order: filterConfig?.sort?.sortOrder === -1 ? 'desc' : 'asc',
            startDate: filterConfig?.startDate,
            keyword,
        }
    }

    private _getTopicsTableData(filterConfig: IReportFilterConfigData): void {        
        const body = {
            ...filterConfig,
            // page: filterConfig?.paginate?.page,
            // size: filterConfig?.paginate?.size
        }        

        this._http.post(`${ENV.apiUrl}/v2/locations/keyword-trends`, body)
        .subscribe((response: any) => {
            this.keywordsReportDataObject = {
                ...this.keywordsReportDataObject,
                topicsDataSource: response?.keyword_trends?.items || [],
                topicsPagination: {
                    total: response?.keyword_trends?.total || 0,
                    page: response?.keyword_trends?.page,
                    pages: response?.keyword_trends?.pages,
                    per_page: response?.keyword_trends?.per_page,
                    hasNext: response?.keyword_trends?.hasNext,
                    hasPrev: response?.keyword_trends?.hasPrev
                }
            }

            this._keywordsReportDataSubject.next(this.keywordsReportDataObject);
        })

    }
}