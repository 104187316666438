import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from 'src/app/components/base.component';
import { IInsightsReportParams, IInsightsReportData } from 'src/app/constants/insights-report';
import { SessionService } from 'src/app/services/session.service';
import { LocationService } from './../../../services/location.service';
import { InsightReportDataService } from './../services/insight-report-data.service';
import { InsightFilterConfigService } from './../services/insight-filter-config.service';

@Component({
  selector: 'app-performance-insight',
  templateUrl: './performance-insight.component.html',
  styleUrls: ['./performance-insight.component.scss']
})
export class PerformanceInsightComponent extends BaseComponent implements OnInit {

  public gid = '';
  public locationId = '';
  public accountId = '';
  public insightsReportParams: IInsightsReportParams;
  public insightsReportData: IInsightsReportData;
  public reportName = ''; // might get moved into the reportData object if it makes sense to do so, or converted into an input
  public firstLoad = true;
  public updatingData = true;
  public isSingleMonth = null;
  public insightsDataMessage = '';

  // necessary for the template to render but may be removed after
  public typeReport = null; // will probably be an @Input() when we rework reports
  public isReport = null; // will probably be an @Input() when we rework reports


  constructor(
    private _routeS: ActivatedRoute,
    private _router: Router,
    private _sessionS: SessionService,
    private _insFilConfS: InsightFilterConfigService,
    private _insRepDataS: InsightReportDataService,
    private _locationS: LocationService
  ) {
    super()
  }

  async ngOnInit() {
    this.gid = this._sessionS.getSession().gid;
    this.locationId = await this._routeS.snapshot.parent.params.locationId;
    this.accountId  = await this._routeS.snapshot.parent.params.accountId;
    
    await this._buildNoDataMessage();
    
    this.insightsReportParams = {
      gid: this.gid,
      locationId: this.locationId,
      accountId: this.accountId
    }
    this._insFilConfS.setInitialFilterConfigData(this.insightsReportParams)
    this._initializeFilterConfig();

    this._subscribeSafe(this._insRepDataS.insightsReportDataSubject$,
      res => {
        if (res) {
          this.insightsReportData = res;
          this.firstLoad = false;
          this.updatingData = false;
        }
      });

    
  }

  goToLocations(): void {
    if (this.accountId) {
      this._router.navigate(['/accounts', this.accountId,  'locations'])
    }
  }

  private async _buildNoDataMessage() {
    const locationUpgraded = await this._locationS.fetchLocation(this.gid, this.accountId, this.locationId)
    const createdData = locationUpgraded ? new Date(locationUpgraded.createdAt?.$date) : null;
    const hasMoreThan24hs = (new Date().getTime() - createdData.getTime()) > 86400000
    this.insightsDataMessage = hasMoreThan24hs ? 'No performance insights data' : 'The performance insights for this business profile are still being imported, please refresh and check again in 10 minutes';    
  }

  private _initializeFilterConfig() {
    this.updatingData = true;
    
    this._subscribeSafe(this._insFilConfS.getDateValidation('rollup', [this.accountId], [this.gid], [this.locationId]), data => {
      if (data && data.minDate && data.maxDate) {
        this._insFilConfS.initializeDateRestriction({minDate: data.minDate, maxDate: data.maxDate}, 'insights');
      }
      this.updatingData = false;
    });
  }

}
