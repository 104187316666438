import {Pipe, PipeTransform} from '@angular/core';
import {JsonDate} from "../services/dates.service";
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'dateJson'
})
export class DateJsonPipe implements PipeTransform {

  transform(value: JsonDate, ...args: any[]): any {
    const date = new Date(value.year, value.month-1, value.day);
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date,'shortDate');
  }

}
