// dep
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

// app
// import { GROUPS, BULK_LOCATIONS_EDITS } from '../constants/firestore/collections'
import { BulkLocationInfo } from '../constants/location-info'
import { Pageable } from '../constants/pageable'
import {environment as ENV} from '@environment'
import { ApiResponse, Pagination } from '../constants/api-response'


export interface ResumeEdit {
  gid: string;
  account_id: string;
  location_id: string;
  location_name: string;
  location_address: any;
  log: any;
  error_details: any;
  service_area?: any;
}

@Injectable({
  providedIn: 'root'
})
export class BulkLocationsEditService {

  constructor(private http: HttpClient) {}

  // TODO: Firestore not used, remove
  // getRef(gid) {
  //   return this.afs.collection(GROUPS).doc(gid).collection(BULK_LOCATIONS_EDITS);
  // }

  getByGid(pageable: Pageable, gid: string, next, last): Observable<any>{
    return this.http.get<Pagination>(`${ENV.apiUrl}/v2/bulk_updates/${gid}/all?page=${pageable.page}&pageSize=${pageable.size}`);
/*     return this.fs.paginate<BulkLocationInfo>(`${GROUPS}/${gid}/${BULK_LOCATIONS_EDITS}`, 'createdAt', null
      , {
        size: pageable.size,
        page: pageable.page
      }, true, next, last, null, null , 'desc'); */
  }

  getBulkProgress(id: string): Observable<any>{
    return this.http.get(`${ENV.apiUrl}/v2/google/bulk_edit/${id}/status`)
  }

  create(gid: string, bulkEdit: BulkLocationInfo): Promise<ApiResponse> {
    return this.http.post<ApiResponse>(`${ENV.apiUrl}/v2/bulk_updates/${gid}/add`, bulkEdit).toPromise()
  }

  getResumeEdits(bulk_edit: string, page: number, deleted = false): Observable<{response: ResumeEdit[], pages: number}> {
    return this.http.post<ApiResponse>(`${ENV.apiUrl}/v2/google/resume_edits/edit/${bulk_edit}`, {page, deleted}).pipe(
      map( value => {
        return value.data || []
      })
    )
  }

  processBulkEdit( o: {location_edit: BulkLocationInfo, updates: any}): Promise<any>{
    return this.http.post(`${ENV.apiUrl}/v2/google/bulk_edit`, o).toPromise()
  }

  editBulk(gid: string, bulkId: string, updates: {'status': string}): Promise<any> {
    return this.http.post<ApiResponse>(`${ENV.apiUrl}/v2/bulk_updates/${gid}/${bulkId}/edit`, {updates}).pipe(
      map(_value => true)).toPromise()
  }

  retryFailedLocations(bulk_id : string): Observable<any> {
    return this.http.get(`${ENV.apiUrl}/v2/google/bulk_edit/${bulk_id}/retry`);
  }

  purgeFailedLocations(bulk_id : string): Observable<any> {
    return this.http.get(`${ENV.apiUrl}/v2/google/bulk_edit/${bulk_id}/purge`);
  }

  getchangeslogs(bulk_id : string): Observable<any> {
    return this.http.get(`${ENV.apiUrl}/v2/google/bulk_edit/${bulk_id}/changelog`);
  }

  getBulkPlaceActionLinks(gids, accountIds, locationIds): Observable<any> {
    const data = {
      gids: [gids],
      accountIds: accountIds,
      locationIds: locationIds
    }
    return this.http.post(`${ENV.apiUrl}/v2/google/bulkPlaceActionLinks`, data);
  }
}
