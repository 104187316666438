
import { CurrencyPipe } from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-products-item-edit',
  templateUrl: './modal-products-item-edit.component.html',
  styleUrls: ['./modal-products-item-edit.component.scss']
})
export class ModalProductsItemEditComponent implements OnInit {
  itemId: string;
  linkType: any[];
  removeItem: boolean;

  productName: string;
  productDescription: string;
  productImage: string;
  productNameInvalid: boolean;

  priceRange: boolean;
  productPriceToShow: string = "";
  productPrice: number;
  productPriceMax: number;
  currencyCode: string;

  selectedButton: string;
  buttonAdded: boolean;
  buttonType: string;
  buttonText: string;

  constructor( private currencyPipe: CurrencyPipe,
               public dialogRef: MatDialogRef<ModalProductsItemEditComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any
               ) {
    this.removeItem = false;
    this.linkType = ['Order Online', 'Buy', 'Learn More', 'Get offer'];
    this.productNameInvalid = false;
    this.itemId =                 data?.productItem?.itemId;
    this.productName =            data?.productItem?.labels[0]?.displayName ? data?.productItem?.labels[0]?.displayName : '';
    this.productImage =           data?.productItem?.image ? data?.productItem?.image : '';
    this.productDescription =     data?.productItem?.labels[0]?.description ? data?.productItem?.labels[0]?.description : '';
    this.priceRange =             (data?.productItem?.priceMax);
    this.productPrice =           data?.productItem?.price?.units ? data?.productItem?.price?.units : null;
    this.productPriceMax =        data?.productItem?.priceMax ? data?.productItem?.priceMax : '';
    this.currencyCode =           data?.productItem?.price?.currencyCode ? data?.productItem?.price?.currencyCode : null;
    this.selectedButton =         (data?.productItem?.link?.linkType) ? data?.productItem?.link?.linkType : '';
    this.buttonAdded =            (data?.productItem?.link?.linkType && data?.productItem?.link?.linkText);
    this.buttonType =             (data?.productItem?.link?.linkType) ? (data?.productItem?.link?.linkType) : '';
    this.buttonText =             (data?.productItem?.link?.linkText) ? (data?.productItem?.link?.linkText) : '';

    if (this.productPrice && this.currencyCode)  {
      this.productPriceToShow = this.currencyPipe.transform(this.productPrice, this.currencyCode)
    }
  }

  ngOnInit() {
  }

  fileChanged(val) {
    this.productImage = val;
  }

  showLinkText(type): void {
    this.buttonAdded = (type !== 'hideButton');
    this.buttonType = type;
  }

  changeCurrency(event: string) {
    if (this.currencyCode) {
      const price = Number(event.split("$")[1]);
      if (!isNaN(price)) {
        this.productPrice = price;
        this.productPriceToShow = this.currencyPipe.transform(price, this.currencyCode)
      } else {
        this.productPrice = 0;
        this.productPriceToShow = this.currencyPipe.transform(0, this.currencyCode)
      }
    } else {
      if (event !== '') {
        this.productPrice = Number(event);
        this.productPriceToShow = this.currencyPipe.transform(this.productPrice)
      } else {
        this.productPrice = 0;
        this.productPriceToShow = this.currencyPipe.transform(0)
      }
    }
    
  }

  apply(): void {
    this.removeItem = false;
    if ( this.productName ) {
      this.productNameInvalid = false;
      const productItem = {
        itemId: this.itemId,
        labels: [
          { 
            displayName: this.productName,
            description: this.productDescription ? this.productDescription : ''
          }
        ],
        price: {
          currencyCode: this.currencyCode,
          units: this.productPrice ? this.productPrice : ""
        }
      };
      const data = { productItem, removeItem: this.removeItem };
      this.dialogRef.close(data);
    } else {
      this.productNameInvalid = true;
    }
  }

  remove(): void {
    this.removeItem = true;
    const data = { removeItem: this.removeItem };
    this.dialogRef.close(data);
  }

}
