import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompassButtonComponent } from './compass-button/compass-button.component';
import { CompassTestBadgeComponent } from './compass-test-badge/compass-test-badge.component';

@NgModule({
    declarations: [CompassButtonComponent, CompassTestBadgeComponent],
    imports: [CommonModule, MatTooltipModule],
    exports: [CompassButtonComponent, CompassTestBadgeComponent]
})
export class CompassModule { }
