<!--Company Name-->
<div class="box__item box__item--sm box__item--editable pb--20">

  <ng-container *ngIf="!isApple else apple">
    <h2 class="txt--lg fw--medium m--0" [class.txt--medium-gray]="isUnderReview"><span [outerHTML]="locationName"></span></h2>
    <span class="under-review" *ngIf="isUnderReview" role="alert">
      <p>Under review</p>
    </span>
  </ng-container>

  <ng-template #apple>
    <div class="d-flex flex-column w--100 gap--10">
      <h2 class="txt--lg fw--medium m--0" [class.txt--medium-gray]="isUnderReview">{{locationName}}</h2>
      <!-- <span class="under-review" *ngIf="isUnderReview" role="alert">
        <p>Under review</p>
      </span> -->
      <div>
        <span>{{locationAddress}}</span>
      </div>
      <div>
        <span *ngIf="verified" class="selected-label"><i class="fas fa-badge-check mr--5"></i>Verified</span>
      </div>
    </div>
  </ng-template>

  <button mat-button (click)="openNameDialog()" class="btn btn-icon" *ngIf="isEdit">
    <i class="fas fa-pencil m--0"></i>
  </button>
</div>
