<span>
    <ul>
      <li *ngFor="let date of nextDates; let i = index" class="mb--0">
        <div class="txt--underline">
          {{date.start |  date }}:
        </div>
        <div class="ml--10 mb--5">
          {{postType}} 
          <span *ngIf="postType==='Event' || postType==='Offer'">{{nextDatesEvent[i].start |  date}} {{(recurringModel.eventTimeStart ? startTimeDate : timeDate) |  date:'hh:mm a'}} - <span >{{nextDatesEvent[i].end |  date:'MMM dd, yyyy hh:mm a'}}</span></span>
          <span *ngIf="postType!=='Event' && postType!=='Offer'"> post </span>
        </div>
      </li>
    </ul>
</span>


