<!--Apple App-->
<ng-container *ngIf="appUrl">

  <div class="box__item box__item--editable">
    <div class="d-flex w--100">
      <!-- <div class="icon icon--rounded icon--gray mr--20"><i class="far fa-mobile"></i></div> -->
      <div class="w--100" [class.txt--medium-gray]="isUnderReview">
        <h4 class="w--100 txt--md fw--700 mb--10">{{sectionName}}</h4>
        <p class="w--100 txt--md">{{ appUrl.url }}</p>
      </div>
    </div>
  </div>
</ng-container>