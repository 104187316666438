<!--average star rating-->
<div class="box box--rounded box--shadow bg--white">
  <div class="p--20">
    <h2 class="txt--capitalize align-items-center txt--md mb--15"><strong>average star rating</strong></h2>
    <ngx-skeleton-loader *ngIf="isProgressRating" count="1" [theme]="{ width: '25px',height: '25px','margin-bottom': '0', 'transform':'translateY(5px)'}"></ngx-skeleton-loader>
    <p class="d--inl-block m--0 txt--price fw--regular" *ngIf="!isProgressRating">{{avgRating | number: '1.0-1'}}</p>
    <span *ngIf="tooltip"
      [matTooltip]="tooltip"
      matTooltipPosition="above"
      matTooltipClass="mat-tooltip--override"
      class="mat-tooltip tooltip tooltip--info">
      <em class="fas fa-info"></em>
    </span>
  </div>
  <div class="box__content box--padding-xxs">
    <app-skeleton-reviews type="AVERAGE-STAR-RATING" *ngIf="isProgressRating"></app-skeleton-reviews>
    <app-chart-line
      [max]="5"
      [yDecimals]="1"
      [showLegend]="false"
      [labels]="labelsLineRating"
      [dataset]="datasetLineRating"
      [aggregation]="granularity || dataPicker.aggregation"
      *ngIf="!isProgressRating"
    ></app-chart-line>
  </div>
</div>
