import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SchedulePost} from '../../constants/post';
import * as _moment from 'moment';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSelectChange } from '@angular/material/select';
import { Recurring } from '../../v3.models/posts.models';
import { RecurringComponent } from './recurring/recurring.component';

@Component({
  selector: 'app-modal-schedule-post',
  templateUrl: './modal-schedule-post.component.html',
  styleUrls: ['./modal-schedule-post.component.scss']
})
export class ModalSchedulePostComponent implements OnInit {
  form: FormGroup;
  schedule: any;
  lessToNow: boolean;
  submitted: boolean;

  @ViewChild('formRecurring') formRecurring: RecurringComponent;

  // RECURRING
  recurringPost = false;
  recurringTime = '12:00';
  frecuency = "WeeklyOn";
  repeatOn = "0";
  durationEvent = 0;

  recurringModel: Recurring = null;

  isDeletePost = false;

  title = '';
  publishRecurringNow = false;

  // LIST POST TYPE
  listPostType = {
    0: "Standard",
    1: "Event",
    2: "Offer",
    3: "Alert",
  }

  postDateAvaible = false;
  postTypeName: string = null;


  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModalSchedulePostComponent>,
    @Inject(MAT_DIALOG_DATA) public config
  ) {
  }

  ngOnInit(): void {
    this.schedule = this.config.data.schedule || {'date': new Date().toISOString()};
    if (this.config.data.postType) {
      this.postTypeName = this.listPostType[this.config.data.postType];
      this.postDateAvaible = (this.postTypeName === 'Event' || this.postTypeName === 'Offer')
    }
    if (this.config.data.recurring) {
      this.recurringPost = true;
      this.recurringTime = this.config.data.recurring.time;
      this.frecuency = this.config.data.recurring.frecuency;
      this.repeatOn = this.config.data.recurring.repeatOn;
      this.recurringModel = this.config.data.recurring;
      this.publishRecurringNow = this.config.data.publishNow;
    }
    this.isDeletePost = this.config.data.isDeletePost ? this.config.data.isDeletePost : false;
    this.title = this.config.data.title ? this.config.data.title : 'Schedule';
    this.submitted = false;
    this.initForm();
  }


  initForm(): void {
    const parseZone = _moment(this.schedule.date).parseZone();
    const time =  _moment(parseZone['_d'].getTime()).format('HH:mm');

    this.form = this.fb.group({
      date: [this.schedule?.date ? parseZone.toDate() : new Date(), Validators.required],
      time: [this.schedule?.date ? time : '12:00', Validators.required],
    });

    this.form.valueChanges.subscribe(values => {
      this.checkChanges();
    });
  }

  checkChanges(): void {
    const date = new Date(this.date.value);
    if (this.time.value) {
      const split = this.time.value.split(':');
      date.setHours(split[0], split[1]);
    }
    this.lessToNow = date < new Date(Date.now());
  }

  get date(): FormControl {
    return this.form.get('date') as FormControl;
  }

  get time(): FormControl {
    return this.form.get('time') as FormControl;
  }

  frecuencyControl(event: MatSelectChange): void {
    event.value === 'MonthlyOn' ? this.repeatOn = "1" : this.repeatOn = "0"
    this.frecuency = event.value
  }

  recurringTimeControl(event: any): void {
    this.recurringTime = event.target.value
  }

  recurringDurationControl(event: any): void {
    this.recurringTime = event.target.value
  }

  apply(): void {
    this.outSchedule()
  }

  changeRecurring($event: MatSlideToggleChange): void {
    this.recurringPost = $event.checked
  }

  callBackRecurring(result: boolean, nextDate: Date): void {
    if (!result) {
      this.publishRecurringNow = false
      return
    }

    const recurring: Recurring = {
      frecuency: this.frecuency,
      repeatOn: this.repeatOn,
      time: this.recurringTime,
      nextDate: nextDate
    };
    this.dialogRef.close({recurring: true, data: recurring, publishNow: this.publishRecurringNow})
  }

  outSchedule(): void {
    if (!this.recurringPost) {
      this.checkChanges();
      if (this.form.invalid || this.lessToNow) {
        return;
      }
      const schedule = this.form.value as SchedulePost;
      const date = new Date(this.form.value.date)
      const splitTime = this.time.value.split(':')
      const hours = splitTime[0]
      const minutes = splitTime[1]
      date.setHours(hours, minutes);
      const utcDate = _moment.utc(date).toDate();
      schedule.date = utcDate
      this.dialogRef.close({recurring: false, data: schedule})
    } else {
      this.formRecurring.apply().subscribe(data => {
        const response: {data: Recurring, publishNow: boolean, duplicate: boolean} = data;
        if (response) this.dialogRef.close({recurring: true, data: response.data, publishNow: response.publishNow, duplicate: response.duplicate})
      });

    }


  }

  cancelDelete(): void {
    this.dialogRef.close({ cancel: true})
  }
}
