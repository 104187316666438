<div class="row box--height mt--50">
  <!-- Cards -->
  <div class="col-12 col-sm-4 col-lg-4 mb--30">
    <div class="box box--shadow box--rounded bg--white pb--20 pt--20">
      <section class="pl--20 pr--20 mb--25">
        <h2 class="txt--md"><strong>Page One Keyword</strong></h2>
        <ngx-skeleton-loader class="d--block" count="1" [theme]="{ width: '136px', height: '48px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="d--block" count="1" [theme]="{ width: '136px', height: '12px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="d--block mt--15" count="1" [theme]="{ width: '136px', height: '20px'}"></ngx-skeleton-loader>
      </section>
    </div>
  </div>

  <div class="col-12 col-sm-4 col-lg-4 mb--30">
    <div class="box box--shadow box--rounded bg--white pb--20 pt--20">
      <section class="pl--20 pr--20 mb--25">
        <h2 class="txt--md"><strong>Branded Searches</strong></h2>
        <ngx-skeleton-loader class="d--block" count="1" [theme]="{ width: '136px', height: '48px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="d--block" count="1" [theme]="{ width: '136px', height: '12px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="d--block mt--15" count="1" [theme]="{ width: '136px', height: '20px'}"></ngx-skeleton-loader>
      </section>
    </div>
  </div>

  <div class="col-12 col-sm-4 col-lg-4 mb--30">
    <div class="box box--shadow box--rounded bg--white pb--20 pt--20">
      <section class="pl--20 pr--20 mb--25">
        <h2 class="txt--md"><strong>Non-branded Searches</strong></h2>
        <ngx-skeleton-loader class="d--block" count="1" [theme]="{ width: '136px', height: '48px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="d--block" count="1" [theme]="{ width: '136px', height: '12px'}"></ngx-skeleton-loader>
        <ngx-skeleton-loader class="d--block mt--15" count="1" [theme]="{ width: '136px', height: '20px'}"></ngx-skeleton-loader>
      </section>
    </div>
  </div>

  <!-- GRAPH -->
  <div class="col-12 mb--30">
    <div class="box box--shadow box--rounded bg--white pl--20 pr--20 pb--20 pt--20">
      <section class="d-flex justify-content-between">
        <h2 class="txt--md"><strong>Total Impressions</strong></h2>
        <ngx-skeleton-loader count="1" [theme]="{ width: '400px', height: '45px'}"></ngx-skeleton-loader>
      </section>

      <ngx-skeleton-loader class="d--block" count="1" [theme]="{ width: '136px', height: '48px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader class="d--block" count="1" [theme]="{ width: '136px', height: '12px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader class="d--block mt--30 txt--center" count="1" [theme]="{ width: '70%', height: '250'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader class="d--block mt--15" count="1" [theme]="{ width: '100%', height: '100px'}"></ngx-skeleton-loader>
    </div>
  </div>

  <!-- Table -->
  <div class="d-flex justify-content-between mb--30 ml--20 mr--20 w--100">
    <div class="w--50 mr--15">
      <div class="box box--shadow box--rounded bg--white">
        <section class="p--20">
          <h2 class="m--0 mb--10"><strong>Keywords</strong></h2>
        </section>
        <table class="table table--sm table--last-row-border mt--10 p--20" aria-describedby="keywords">
          <thead>
            <tr>
              <th class="mat-header-cell txt--right w--10"><strong>Keywords</strong></th>
              <th class="mat-header-cell txt--center"><strong>Number of Impressions</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr class="mat-row" *ngFor="let i of [0,1,2]">
              <td class="mat-cell cdk-column-subscription txt--right">
                <ngx-skeleton-loader count="1" [theme]="{ width: '50px', height: '20px'}"></ngx-skeleton-loader>
              </td>
              <td class="mat-cell cdk-column-subscription txt--center">
                <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px'}"></ngx-skeleton-loader>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
    <div class="w--50 ml--15">
      <div class="box box--shadow box--rounded bg--white">
        <section class="p--20">
          <h2 class="m--0 mb--10"><strong>Topics</strong></h2>
        </section>
        <table class="table table--sm table--last-row-border mt--10 p--20" aria-describedby="keywords">
          <thead>
            <tr>
              <th class="mat-header-cell txt--right w--10"><strong>Rank</strong></th>
              <th class="mat-header-cell txt--center"><strong>Keywords Details</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr class="mat-row" *ngFor="let i of [0,1,2]">
              <td class="mat-cell cdk-column-subscription txt--right">
                <ngx-skeleton-loader count="1" [theme]="{ width: '50px', height: '20px'}"></ngx-skeleton-loader>
              </td>
              <td class="mat-cell cdk-column-subscription txt--center">
                <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px'}"></ngx-skeleton-loader>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Multi Profiles Table -->
  <div class="col-12 mb--30">
    <div class="box box--shadow box--rounded bg--white p--20">
      <h2 class="m--0 mb--10"><strong>Multi Profiles Table</strong></h2>
      <table class="table table--sm table--last-row-border mt--20" aria-describedby="keywords-multiloc">
        <thead>
          <tr>
            <th class="mat-header-cell txt--left"><strong>Location</strong></th>
            <th class="mat-header-cell txt--center"><strong>Impressions</strong></th>
            <th class="mat-header-cell txt--center"><strong>Keywords</strong></th>
            <th class="mat-header-cell txt--center"><strong>Branded</strong></th>
            <th class="mat-header-cell txt--center"><strong>Non-branded</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr class="mat-row" *ngFor="let i of [0,1,2]">
            <td class="mat-cell cdk-column-subscription">
              <ngx-skeleton-loader count="1" [theme]="{ width: '850px', height: '20px'}"></ngx-skeleton-loader>
            </td>
            <td class="mat-cell cdk-column-subscription txt--center">
              <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px'}"></ngx-skeleton-loader>
            </td>
            <td class="mat-cell cdk-column-subscription txt--center">
              <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px'}"></ngx-skeleton-loader>
            </td>
            <td class="mat-cell cdk-column-subscription txt--center">
              <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px'}"></ngx-skeleton-loader>
            </td>
            <td class="mat-cell cdk-column-subscription txt--center">
              <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '20px'}"></ngx-skeleton-loader>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>


  
