
export const Cusines: Array<{key: string, description}> = [
    {key: 'CUISINE_UNSPECIFIED', description: 'Cuisine unspecified'},
    {key: 'AMERICAN', description: 'American food'},
    {key: 'ASIAN', description: 'Asian food'},
    {key: 'BRAZILIAN', description: 'Brazilian food'},
    {key: 'BREAK_FAST', description: 'Breakfast'},
    {key: 'BRUNCH', description: 'Brunch'},
    {key: 'CHICKEN', description: 'Chicken'},
    {key: 'CHINESE', description: 'Chinese food'},
    {key: 'FAMILY', description: 'Family style cuisine'},
    {key: 'FAST_FOOD', description: 'Fast food'},
    {key: 'FRENCH', description: 'French food'},
    {key: 'GREEK', description: 'Greek food'},
    {key: 'GERMAN', description: 'German food'},
    {key: 'HAMBURGER', description: 'Hamburger'},
    {key: 'INDIAN', description: 'Indian food'},
    {key: 'INDONESIAN', description: 'Indonesian food'},
    {key: 'ITALIAN', description: 'Italian food'},
    {key: 'JAPANESE', description: 'Japanese food'},
    {key: 'KOREAN', description: 'Korean food'},
    {key: 'LATIN_AMERICAN', description: 'Latin American food'},
    {key: 'MEDITERRANEAN', description: 'Mediterranean food'},
    {key: 'MEXICAN', description: 'Mexican food'},
    {key: 'PAKISTANI', description: 'Pakistani food'},
    {key: 'PIZZA', description: 'Pizza'},
    {key: 'SEAFOOD', description: 'Seafood'},
    {key: 'SPANISH', description: 'Spanish food'},
    {key: 'SUSHI', description: 'Sushi'},
    {key: 'THAI', description: 'Thai food'},
    {key: 'TURKISH', description: 'Turkish food'},
    {key: 'VEGETARIAN', description: 'Vegetarian Food'},
    {key: 'VIETNAMESE', description: 'Vietnamese food'},
    {key: 'OTHER_CUISINE', description: 'Other cuisine'}
];



export interface MenuLabel {
    displayName: string;
    description?: string;
    languageCode?: string;
}

export interface FoodMenuItemAttributes {
    price?: {
        currencyCode: string,
        units: string,
        nanos: number
    }
    servesNumPeople: number;
    mediaKeys: string[]
}

export interface FoodMenuItem {
    labels: MenuLabel[];
    attributes: FoodMenuItemAttributes;
    options?
}

export interface FoodMenuSection {
    labels: MenuLabel[];
    items: FoodMenuItem[];
}

export interface FoodMenu {
    labels: MenuLabel[];
    sourceUrl?: string;
    sections: FoodMenuSection[];
    cuisines?: string[];
}

export interface MenuUpdateEvent {
    menu: FoodMenu[];
    error?: boolean;
}


export const convertCusine = (key): string => {
    return Cusines.find(c => c.key ===key).description
}

export const defaultMenu: FoodMenu[] = [
    {
      labels: [
        {
          displayName: 'Menu',
          description: '',
          languageCode: 'en' 
        }
      ],
      cuisines: [],
      sections: []
    }
  ];