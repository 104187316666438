// dep
import { Component, Input, OnInit, EventEmitter, Output, ChangeDetectorRef, ViewRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Sort as MatSort} from '@angular/material/sort';
import * as moment from 'moment';
import { interval, BehaviorSubject, Subscription } from 'rxjs';

// app
import { Pageable } from '../../../constants/pageable';
// import { PostManagement } from '../../../constants/post';
import { ModalService } from '../../../services/modal.service';
// import { PostManagementService } from '../../../services/post-management.service';
import { PostService } from '../../../services/post.service';
import { Sort } from '../../../constants/sort';
import { ModalLocalPostsComponent } from '../../modal-local-posts/modal-local-posts.component';
import { ModalSchedulePostComponent } from '../../modal-schedule-post/modal-schedule-post.component';
import { BulkPost, MetaPost, LocalPost } from '../../../v3.models/posts.models';
import { convertMetaToAccountsReports } from '../../../helpers/functions.helpers';
import { Pagination2 } from '../../../constants/api-response';
import { PostsErrorsComponent } from '../../../components/posts-errors';
import { BULK_POST_STATUS_PARTIAL_PUBLISH, SchedulePost, POST_STATUS_PENDING,
         POST_STATUS_PENDING_DELETE, POST_STATUS_PENDING_UPDATE, 
         POST_STATUS_SEND, POST_STATUS_RECURRING, POST_STATUS_SCHEDULED, 
         POST_STATUS_DRAFT } from '../../../constants/post';
import { Messages } from '../../../constants/messages';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss']
})
export class PostListComponent extends BaseComponent implements OnInit {

  @Input() displayedColumns: string[] = ['img', 'info', 'date', 'dateUpdate', 'views', 'actions_post', 'actions'];
  @Input() ColumnsSize: number[] = [5, 40, 10, 10, 5, 5, 10];
  @Input() post_type: 'Posted' | 'Pending' | 'Drafts' | 'Archive' = 'Posted';
  @Input() dashboardType: string;
  @Input() accountId: string;
  @Input() locationId: string;
  @Input() focus = new BehaviorSubject(0)

  @Output() isSpinner = new EventEmitter<boolean>()
  @Output() selected  = new EventEmitter<number>()

  // constanst
  partialPublishMessage = Messages.post.PARTIAL_PUBLISH
  partialDeleteMessage = Messages.post.PARTIAL_DELETE
  partialUpdateMessage = Messages.post.PARTIAL_UPDATE
  partialStatus = BULK_POST_STATUS_PARTIAL_PUBLISH
  pendingStatus = POST_STATUS_PENDING
  pendingDeleteStatus = POST_STATUS_PENDING_DELETE
  pendingUpdateStatus = POST_STATUS_PENDING_UPDATE
  sendStatus = POST_STATUS_SEND
  statusMessages = {
    [BULK_POST_STATUS_PARTIAL_PUBLISH]: Messages.post.PARTIAL_PUBLISH,
    [POST_STATUS_PENDING]: Messages.post.PARTIAL_PUBLISH,
    [POST_STATUS_PENDING_DELETE]: Messages.post.PARTIAL_DELETE,
    [POST_STATUS_PENDING_UPDATE]: Messages.post.PARTIAL_UPDATE,
  }

  redirectTo = {
    [BULK_POST_STATUS_PARTIAL_PUBLISH]: 'Pending',
    [POST_STATUS_PENDING]: 'Pending',
    [POST_STATUS_SCHEDULED]: 'Pending',
    [POST_STATUS_RECURRING]: 'Pending',
    [POST_STATUS_DRAFT]: 'Drafts'
  }
  //----------

  bulkPost: boolean;
  progressPost = true;
  postsExist = false;
  dataSource = new MatTableDataSource<LocalPost | BulkPost>([]);

  loadingLocationsRef = false;
  locationRef: {meta: MetaPost}[] = [];

  // Pagination
  paginate: Pageable = {page: 1, size: 10};
  previousPageable: { size: number; page: number };
  pagination: Pagination2<LocalPost | BulkPost>;
  sort: Sort = {
    sortBy: 'createdAt',
    direction: 'desc',
  };

  // --- UPDATE COUNTERS
  bulkPostList: BulkPost[] = []
  subscriptionUpdateCounter$: Subscription;

  public loading$ = this.postS.loading.asObservable()

  constructor(
    // private postManagementS: PostManagementService,
    private dialog: MatDialog,
    private modalS: ModalService,
    private cdRef: ChangeDetectorRef,
    private postS: PostService) {
      super();
    }


  ngOnInit(): void {
    this.progressPost = true;
    if (this.dashboardType === 'LOCAL_POST') {
      this.bulkPost = false;
      this.postData();
    } else if (this.dashboardType === 'BULK_POST') {
      this.bulkPost = true;
      if (this.post_type === 'Posted') {
        this.bulkData();
      }

      if (this.post_type === 'Pending') {
        this.ColumnsSize = [5, 35, 15, 15, 5, 5];

        this.focus.subscribe( (tabSelected: number) => {
          if (tabSelected === 1) {
            this.subscriptionUpdateCounter$ = this._subscribeSafe(interval(15000), () => {
              const arrayPromise: Promise<{id: string, posted: number}>[] = []
              this.bulkPostList.forEach( bl => {
                arrayPromise.push(this.postS.getBulkCounters(bl.id))
              })
              Promise.all(arrayPromise).then(values => {
                values = values.filter(Boolean);
                if (values.length === 0) return
                const copyData = this.dataSource.data as BulkPost[];
                copyData.forEach( (data) => {
                  const updateData = values.find(value => value.id === data.id)
                  if  (updateData) data.posted = updateData.posted
                })
                this.dataSource.data = copyData;
              });
            })
          } else {
            if (this.subscriptionUpdateCounter$) 
              this.subscriptionUpdateCounter$.unsubscribe()
          }
        })
      }

      if (this.post_type === 'Drafts') {
        this.ColumnsSize = [5, 35, 15, 20, 5];
      }
    }
  }

  progressValue(element: BulkPost): number {
    if (element.status === POST_STATUS_PENDING_DELETE) {
      return ( (element.planned - element.posted) / element.planned) * 100;
    } else {
      return (element.posted / element.planned) * 100;
    }
  }

  postData(): void {
    this.postS.getLocalPost(this.accountId, this.locationId, this.post_type.toUpperCase(), this.paginate, this.sort.sortBy, this.sort.direction).then((result) => {
      if (!result)  return
      this.postS.isLoading(true);
      this.previousPageable = {size: result.per_page, page: result.page};
      this.pagination = result;
      this.dataSource = new MatTableDataSource<LocalPost>(result.items);
      this.postsExist = this.dataSource?.data?.length > 0;
      this.progressPost = false;
    });
  }

  bulkData(): void {
      this.postS.getBulkPost(this.post_type.toUpperCase(), this.paginate).then( result => {
        this.postS.isLoading(true)
        this.previousPageable = {size: result.per_page, page: result.page};
        this.pagination = result;
        result.items.forEach(el => {
          el['missingLocations'] = el.planned - el.locationsRef?.length
        })
        this.dataSource = new MatTableDataSource<BulkPost>(result.items);
        this.postsExist = this.dataSource?.data?.length > 0;
        this.progressPost = false;
        if (this.post_type === 'Pending') {
          this.bulkPostList = result.items.filter(bl => (bl.status === BULK_POST_STATUS_PARTIAL_PUBLISH || 
                                                         bl.status === POST_STATUS_PENDING || 
                                                         bl.status === POST_STATUS_PENDING_DELETE || 
                                                         bl.status === POST_STATUS_PENDING_UPDATE) && !bl.hasError)
        }
      })

  }

  public sortByActions($event: MatSort): void {
    this.sort.sortBy = $event.active;
    this.sort.direction = $event.direction;
    this.ngOnInit();
  }

  isDisplayField(status: string): boolean  {
    return (status === POST_STATUS_PENDING_UPDATE || status === POST_STATUS_PENDING || status === POST_STATUS_PENDING_DELETE || status === BULK_POST_STATUS_PARTIAL_PUBLISH)
  }

  isPendingStatus(status: string): boolean {
    return (status === POST_STATUS_PENDING_UPDATE || status === POST_STATUS_PENDING || status === POST_STATUS_PENDING_DELETE || status === BULK_POST_STATUS_PARTIAL_PUBLISH)
  }

  getMessage(status: string): string {
    return this.statusMessages[status]
  }

  getDate(element: any): any{
    return moment(element.scheduled.date).parseZone().toDate()
  }

  getStatus(status: string): string {
    return status === POST_STATUS_PENDING_UPDATE || status === POST_STATUS_PENDING || status === POST_STATUS_PENDING_DELETE ? 'IN PROGRESS': status.toUpperCase()
  }

  /* TODO: Unused, remove?
  loadLocaLocations(bulk_id: string): void {
    this.loadingLocationsRef = true
    this.postS.getBulkLocationPost(bulk_id).then( data => {
      this.locationRef = data
      this.loadingLocationsRef = false
    })
  }
  */

  edit(element: LocalPost | BulkPost): void {
    const type      = element.status || null
    const schedule  = element.scheduled || null
    const recurring = element.recurring || null
    const dialog = this.dialog.open(ModalLocalPostsComponent, {
      width: '700px',
      data: {
        post: element,
        posts: this.bulkPost,
        accounts: this.bulkPost ? convertMetaToAccountsReports((element as BulkPost).locationsRef) : null,
        type,
        schedule,
        recurring,
        locationId: this.locationId,
        accountId: this.accountId,
        post_type: this.post_type
      }
    });

    dialog.afterClosed().subscribe(saved => {
      if (saved) {
        this.ngOnInit();
      }
    });
  }

  adjustScheduleTime(date: string): Date {
    return moment.utc(date).local().toDate()
  }

  getTime(rawTime: string): string{

    const time = rawTime.split(':');

    const hours = Number(time[0]);
    const minutes = Number(time[1]);

    let timeValue: string;

    if (hours > 0 && hours <= 12) {
      timeValue= "" + hours;
    } else if (hours > 12) {
      timeValue= "" + (hours - 12);
    } else if (hours == 0) {
      timeValue= "12";
    }
    
    timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;
    timeValue += (hours >= 12) ? " PM" : " AM";
    return timeValue
  }

  // TODO: Unused, remove
  // savePostManagement(postManagement: PostManagement):  void {
  //   if (this.locationId) {
  //     postManagement.placeId = this.locationId;
  //     postManagement.accountId = this.accountId;
  //   }
  //   this.postManagementS.save(this.auth.session.gid, this.accountId, this.locationId, postManagement).then(() => {
  //     this.isSpinner.emit(false);
  //     this.selected.emit(1);
  //   });
  // }



  async copy(postId: string): Promise<void>  {
    if (!postId || postId === "") return;
    const type = this.bulkPost ? 'BULK' : 'LOCAL';
    this.postS.copy(postId, type, 'Drafts').then()

  }

  delete(postId: string, status: string): void {
    this.modalS.openConfirmModal('Are you sure you want to delete this post?', 'This action cannot be undone!', result => {
      if (result) {
        this.isSpinner.emit(true)
        if (postId) {
          const type = this.bulkPost ? 'BULK' : 'LOCAL';
          const redirectTo = this.redirectTo[status];
          this.isSpinner.emit(false);
          this.postS.deleteV3(postId, type, redirectTo).then(() => {
            this.refresh()
          });
        }
      }
    }, 2);

  }

  disableAction(status: string): boolean {
    return (status === POST_STATUS_PENDING || status === POST_STATUS_PENDING_DELETE || status === POST_STATUS_PENDING_UPDATE)
  }

  disableDeleteAction(element: any): boolean {
    const date = this.adjustScheduleTime(element.createdAt);
    const hoursDiff = Math.floor((Math.abs(+new Date(date) - +new Date()) / 1000) / 3600) % 24
    if ((
      element.status === POST_STATUS_PENDING ||
      element.status === POST_STATUS_PENDING_DELETE ||
      element.status === POST_STATUS_PENDING_UPDATE
    ) && hoursDiff === 0) { return true }
    return false
  }

  getSizeColumn(name: string): string  {
    const index = this.displayedColumns.indexOf(name);
    if (index >= 0) {
      return `${this.ColumnsSize[index]}%`
    }
  }

  handleReload($event: Pageable): void {
    this.paginate = $event;
    this.progressPost = true;
    if (this.dashboardType === 'LOCAL_POST') {
      this.bulkPost = false;
      this.postData();
    } else if (this.dashboardType === 'BULK_POST') {
      this.bulkPost = true;
      this.bulkData();
    }
  }

  refresh(): void {
    this.paginate = {page: 1, size: 10};
    this.progressPost = true;
    if (this.dashboardType === 'LOCAL_POST') {
      this.bulkPost = false;
      this.postData();
    } else if (this.dashboardType === 'BULK_POST') {
      this.bulkPost = true;
      this.bulkData();
    }
  }

  openSchedule(element: LocalPost | BulkPost): void {
    let schedule: SchedulePost = null;

    if(element?.toScheduleDelete)  {
      schedule = element?.scheduled
    }

    this.modalS.openGenericModal(ModalSchedulePostComponent, {
      schedule,
      isDeletePost: true,
      title: 'Scheduled Delete'
    }, (result: {recurring: boolean, data: any, cancel: boolean}) => {
      if (result) {
        this.isSpinner.emit(true);
        const cancelSchedule = Object.prototype.hasOwnProperty.call(result, 'cancel');
        const type = this.bulkPost ? 'BULK' : 'LOCAL';
        const data =  result.data ?  result.data : null
        this.postS.scheduledDeleteV3(element.id, data, cancelSchedule, type, 'Posted').then(() => {
          this.progressPost = false;
          this.isSpinner.emit(false);
        }).catch( () => {
          this.progressPost = false;
          this.isSpinner.emit(false);
        });
      }
    }, 470);
  }

  ngAfterViewChecked(): void {
    this.detectChanges();
  }

  detectChanges () : void {
    if (!(this.cdRef as ViewRef).destroyed)
      this.cdRef.detectChanges();
  }

  openError(element: LocalPost | BulkPost): void {
    const dialog = this.modalS.openGenericModal(PostsErrorsComponent, {
      bulk: this.bulkPost,
      location: element,
      bulkId: element.id
    }, null, 1000);

    dialog.afterClosed().subscribe(saved => {
      if (saved) {
        this.refresh();
      }
    });
  }

  getCountTotalViews(info: {meta: MetaPost, totalViews: number, totalAction: number}[]): number {
    return info.reduce( (response, data) => response += data.totalViews , 0)
  }

  getCountTotalActions(info: {meta: MetaPost, totalViews: number, totalAction: number}[]): number {
    return info.reduce( (response, data) => response += data.totalAction , 0)
  }

}
