import { IInsightsReportParams } from './../../../../constants/insights-report';
import { AbcInsightsFilterConfigService } from './../../services/abc-insights-filter-config.service';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/base.component';
import { COLORS_CHART } from 'src/app/constants/chart-color';
import { AbcInsightsReportDataService } from 'src/app/modules/reporting/services/abc-insights-report-data.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-apple-performance-insights',
  templateUrl: './apple-performance-insights.component.html',
  styleUrls: ['./apple-performance-insights.component.scss']
})
export class ApplePerformanceInsightsComponent extends BaseComponent implements OnInit {

  public accountId: any;
  public gid: any;
  public locationId: any;

  public firstLoad = true;
  public updatingData = true;
  public dataPicker = null;
  public isProgress = false;
  public isReport = false;
  public stats = {above: [], below: []};
  public locations = null;

  public insightsReportParams: IInsightsReportParams;

  public reportName = null;
  public selectDate = null;
  public shared = null;
  public checks = null;
  public typeReport = null;
  public report = null;
  public displayedColumns = ['rank', 'zipCodes', 'percent'];
  public granularity = '';
  

  // Used for charts not currently present
  public searchLocationClicksData = new MatTableDataSource([]);
  public directionsRequestsLocationData = new MatTableDataSource([]);
  public photoMetrics = null;
  public lockDates: any;
  public isShared: any;
  public hasFoodOrdering: any;
  public multiChartSelected: any;
  public subscribeToDatepicker: any;
  public showTooltipsOnDisabledDates: any;

  constructor(
    private _routeS: ActivatedRoute,
    private _router: Router,
    private _sessionS : SessionService,
    private _insFilConfS: AbcInsightsFilterConfigService,
    private _insRepDataS: AbcInsightsReportDataService
  ) {
    super();
  }

  async ngOnInit() {
    this.gid = this._sessionS.getSession().gid;
    //Real data
    this.locationId = await this._routeS.snapshot.parent.params.locationId;
    this.accountId  = await this._routeS.snapshot.parent.params.accountId;
    
    this.insightsReportParams = {
      gid: this.gid,
      locationId: this.locationId,
    }
    this._insFilConfS.setInitialFilterConfigData(this.insightsReportParams)
    this._initializeFilterConfig();

    this._subscribeSafe(this._insRepDataS.insightsReportDataSubject$,
      res => {
        if (res['data']) {
          
          const data = res['data'];
          this.granularity = data.params.granularity;
          delete data.params; // <-- we need to review this

          this.stats.above = []; // this reset is required to populate both arrays with the updated data
          this.stats.below = []; // this reset is required to populate both arrays with the updated data

          // TODO: REVIEW THE WAY WE ARE BUILDING THE DATA, THIS SHOULD BE DONE IN THE REPORT DATA SERVICE INSTEAD

          Object.keys(data).forEach(el =>{
            this.stats.above.push(data[el]);
          })
          this.stats.above = this.sortByTitleOrder();
          this.formattingLabels(this.granularity)
          this.firstLoad = false;
          this.updatingData = false;
        }

      },
      error => {
        console.log(error);
        this.firstLoad = false;
      }
    )

    // this.getData();
  }

  formattingLabels(granularity: string) {
    if (granularity.toLocaleLowerCase() === 'month') { 
      Object.keys(this.stats).forEach(stat => {
        this.stats[stat].forEach(el => {
          el.labels = el.labels.map(l => new Date(l).toLocaleString('en-US', { month: 'short', year: '2-digit' }))
        })
      })
    }
  }

  // getData() {
  //   this._subscribeSafe(this._appleS.getInsightsData(this.gid, this.dataPicker?.range?.start, this.dataPicker?.range?.end, this.locationId, this.dataPicker?.aggregation),
  //     res => {
  //       if (res['data']) {
  //         const data = res['data'];
  //         delete data.params;
  //         Object.keys(data).forEach(el =>{
  //           this.stats.above.push(data[el])
  //         })
  //       }

  //       this.stats.above = this.sortByTitleOrder();
  //       this.updatingData = false;
  //     },
  //     error => console.log(error)
  //   )
  // }

  sortByTitleOrder(): any[] {
    const order = ["Primary Actions", "Secondary Actions","Views of Your Place Card"];

    return this.stats.above.sort((a, b) => {
      const indexA = order.indexOf(a.title);
      const indexB = order.indexOf(b.title);
      
      if (indexA === -1 && indexB === -1) return 0;
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
  
      return indexA - indexB;
    });
  }

  isDonutsGrpah(title): boolean {
    const acceptedGraphics = ['What people searche for before tapsping your location in search results']
    return acceptedGraphics.includes(title)
  }

  getColor(index): string {
    return COLORS_CHART[index].borderColor
  }

  private _initializeFilterConfig() {
    this.updatingData = true;
    
    this._subscribeSafe(this._insFilConfS.getDateValidation('abc-metrics', [this.accountId], [this.gid], [this.locationId]), data => {
      if (data && data.minDate && data.maxDate) {
        this._insFilConfS.initializeDateRestriction({minDate: data.minDate, maxDate: data.maxDate}, 'insights');
      }
      this.updatingData = false;
    });
  }

  goToLocations(): void {
    if (this.accountId) {
      this._router.navigate(['/accounts', this.accountId,  'locations'])
    }
  }

}
