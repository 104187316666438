import { NgModule } from '@angular/core';

import { ChartsModule } from 'ng2-charts';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SharedModule } from 'src/app/shared/shared-modules';
import { PaginatorComponent } from './paginator/paginator.component';
import { ChartStatsComponent } from './chart-stats/chart-stats.component';
import { ChartProgressComponent } from 'src/app/modules/app-charts/chart-progress/chart-progress.component';
import { ChartMultiComponent } from './chart-multi/chart-multi.component';
import { ChartLineComponent } from 'src/app/modules/app-charts/chart-line/chart-line.component';
import { ChartDonutsComponent } from 'src/app/modules/app-charts/chart-donut/chart-donut.component';
import { PopularTimeChartComponent } from './chart-bar/popular-time-chart.component';


@NgModule({
  declarations: [
    PopularTimeChartComponent,
    ChartDonutsComponent,
    ChartLineComponent,
    ChartMultiComponent,
    ChartProgressComponent,
    ChartStatsComponent,
    PaginatorComponent
  ],
  imports: [
    MatProgressBarModule,
    MatCheckboxModule,
    MatTooltipModule,
    SharedModule,
    ChartsModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatFormFieldModule,
  ],
  exports: [
    PopularTimeChartComponent,
    ChartDonutsComponent,
    ChartLineComponent,
    ChartMultiComponent,
    ChartProgressComponent,
    ChartStatsComponent,
    PaginatorComponent
  ]
})
export class AppChartsModule { }
