// dep
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

// app
import {GroupService} from '../services/group.service';
import { SpinnerService } from '../services/spinner.service';
import { SubscriptionService } from '../services/subscription.service';
import { LocationRef } from '../constants/firestore/location-object';
import { SessionService } from '../services/session.service';

@Component({
  selector: 'app-featured-datepicker',
  template: `
      <div class="dialog__content dialog--featured" mat-dialog-content>
          <img src="/assets/images/modal-star.png" alt="Image of a star" class="dialog__icon" width="128" height="128">
          <button mat-dialog-close="" class="btn btn-dialog-close btn--absolute"><i class="txt--lg fal fa-times"></i>
          </button>
          <div *ngIf="!(session$ | async).isMember">
            <div class="dialog-row txt--center">
                <h2 class="heading--xl mb--10 mt--10 txt--black"><strong>Just as a heads up...</strong></h2>
                <p class="heading-h2 mb--10">You will only be able to access the last 12 months of data. If you'd like to
                    see more, please upgrade.</p>
            </div>
            <div class="d-flex justify-content-center mb--10">
                <button mat-flat-button color="accent" class="btn btn--md txt--md" mat-dialog-close (click)="openUpgrade()">
                Upgrade Now
                </button>
            </div>
            <div class="d-flex justify-content-center">
                <a class="txt--lightblue cursor--pointer mt--5" (click)="dismissModal()" mat-dialog-close>
                Dismiss until tomorrow
            </a>
            </div>
          </div>
          <div *ngIf="!(session$ | async).isMember">
            <div class="dialog-row txt--center">
                <h2 class="heading--xl mb--10 mt--10 txt--black"><strong>Just as a heads up...</strong></h2>
                <p class="heading-h2 mb--10">You will only be able to access the last 12 months of data. If you'd like to
                    see more, please contact your account administrator.</p>
            </div>
          </div>
      </div>
  `,
})
export class FeaturedDatePickerComponent {
  static readonly OPTS_DEFAULT = { config : { width: '470px',
                                             height: '420px' }}

  session$ = this._sessionS.session$

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: LocationRef,
    public dialog: MatDialog,
    private _groupService: GroupService,
    private _sessionS : SessionService,
    private _spinnerService: SpinnerService,
    private _subscriptionService: SubscriptionService,
  ) {
    this._spinnerService.loading$.next(false);
  }

  async dismissModal() : Promise<void> {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);

    try {
        this._spinnerService.loading$.next(true);
        await this._groupService.updateGroup(this._sessionS.getSession().gid, {dismissModalDatePicker : tomorrow});
        await this._sessionS.refresh();
    } finally {
        this._spinnerService.loading$.next(false);
    }
  }

  async openUpgrade() : Promise<void> {
    await this._subscriptionService.flowChangeLocationsPlan([this.data])
  }

}
