import { Component, Input, OnInit } from '@angular/core';
import { ILocationAttribute } from './../../../constants/abc-location-info';

@Component({
  selector: 'app-location-good-to-know',
  templateUrl: './location-good-to-know.component.html',
  styleUrls: ['./location-good-to-know.component.scss']
})
export class LocationGoodToKnowComponent implements OnInit {
  @Input() isUnderReview = false;
  @Input() set attributes(atts: ILocationAttribute[]) {
    const trueAtts = atts.filter(att => att.value === 'true');

    const transformedAtts = trueAtts.map(att => {
      const parts = att.name.split('.');
      return {
        category: parts[1],
        name: parts[2].replace(/_/g, ' '),
      }
    })

    const groupedAtts = new Map<string, { name: string }[]>();
    transformedAtts.forEach(att => {
      if (!groupedAtts.has(att.category)) {
        groupedAtts.set(att.category, []);
      }
      groupedAtts.get(att.category)!.push({ name: att.name });
    })
    const groupedArray = Array.from(groupedAtts.entries()).map(([category, catAtts]) => ({
      catName: category,
      catAtts
    }));
    console.log('groupedAtts', groupedArray);
    this.attributesArray = groupedArray;
  };

  public sectionName = 'Good to Know';
  public attributesArray = [];

  constructor() { }

  ngOnInit() {
  }

}
