<div class="dialog__header">
    <h2 *ngIf="!showDeleted" mat-dialog-title class="m--0 txt--xl"><strong>Location Update Summary</strong></h2>
    <h2 *ngIf="showDeleted" mat-dialog-title class="m--0 txt--xl"><strong>Location Deleted Summary</strong></h2>
</div>

<div class="dialog__content" mat-dialog-content>
    <div class="spinner-wrapper" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="dialog-row pb--0" *ngIf="!loading">
        <ng-container [ngSwitch]="component">
            <ng-container *ngSwitchCase="'photos'">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item d-flex justify-content-between align-items-center row" *ngFor="let loc of dataSource">
                        <div>
                            <h6 style="margin-bottom: 0px;"><strong>{{loc.location_name}}</strong></h6>
                            <gmb-address 
                                class="text--muted"
                                *ngIf="loc?.location_address || loc?.service_area"
                                [address]="loc.location_address"
                                [serviceAreas]="loc.service_area?.places?.placeInfos">
                            </gmb-address>
                        </div>

                        <div class="row" style="width: min-content;">
                            <div class="col-12">
                                <button *ngIf="loc.log" mat-flat-button (click)="showMedia(loc.log.accountId, loc.log.locationId, loc)"
                                    class="btn btn--badge btn--badge--sm txt--uppercase txt--xs" [color]="loc.log.status === 'success' ?  'accent' : 'warn'">{{loc.log.status}}</button>
                            </div>
                            <div class="col-12">
                                <a class="link link--blue txt--normal" (click)="showMedia(loc.log.accountId, loc.log.locationId, loc)">View Changes</a>
                            </div>
                        </div>   
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngSwitchCase="'service'">
                <ul class="list-group list-group-flush">
                    <ng-container *ngIf="dataSource.length > 0">
                        <li class="list-group-item d-flex justify-content-between align-items-center mb--20" *ngFor="let loc of dataSource">
                            <div>
                                <h6 style="margin-bottom: 0px;"><strong>{{loc.location_name}}</strong></h6>
                                <gmb-address 
                                    class="text--muted"
                                    *ngIf="loc?.location_address || loc?.service_area"
                                    [address]="loc.location_address"
                                    [serviceAreas]="loc.service_area?.places?.placeInfos">
                                </gmb-address>
                            </div>
    
                            <div class="row" style="width: min-content;">
                                <div class="col-12">
                                    <button *ngIf="loc.log" mat-flat-button (click)="showServices(loc.log.accountId, loc.log.locationId, loc)"
                                        class="btn btn--badge btn--badge--sm txt--uppercase txt--xs" [color]="loc.log.status === 'success' ?  'accent' : 'warn'">{{loc.log.status}}</button>
                                </div>
                                <div class="col-12">
                                    <a class="link link--blue txt--normal" (click)="showServices(loc.log.accountId, loc.log.locationId, loc)">View Changes</a>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
            <ng-container *ngSwitchCase="'menu'">
                <ul class="list-group list-group-flush">
                    <ng-container *ngFor="let acc of accounts">
                        <li class="list-group-item d-flex justify-content-between align-items-center row" *ngFor="let loc of dataSource">
                            <div>
                                <h6 style="margin-bottom: 0px;"><strong>{{loc.location_name}}</strong></h6>
                                <gmb-address 
                                    class="text--muted"
                                    *ngIf="loc?.location_address || loc?.service_area"
                                    [address]="loc.location_address"
                                    [serviceAreas]="loc.service_area?.places?.placeInfos">
                                </gmb-address>
                            </div>
    
                            <div class="row" style="width: min-content;">
                                <div class="col-12">
                                    <button *ngIf="loc.log" 
                                             mat-flat-button 
                                             (click)="showMenu(loc.log.accountId, loc.log.locationId, loc)"
                                             class="btn btn--badge btn--badge--sm txt--uppercase txt--xs" 
                                             [color]="loc.log.status === 'success' ?  'accent' : 'warn'">
                                      {{loc.log.status}}
                                    </button>
                                </div>
                                <div class="col-12">
                                    <a class="link link--blue txt--normal" (click)="showMenu(loc.log.accountId, loc.log.locationId, loc)">View Changes</a>
                                </div>
                            </div>   
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <ul class="list-group list-group-flush" *ngIf="dataSource.length > 0" >
                    <li class="list-group-item d-flex justify-content-between align-items-center mb--20" *ngFor="let loc of dataSource">
                        <div>
                            <h6 style="margin-bottom: 0px;"><strong>{{loc.location_name}}</strong></h6>
                            <gmb-address 
                                class="text--muted"
                                *ngIf="loc?.location_address || loc?.service_area"
                                [address]="loc.location_address"
                                [serviceAreas]="loc.service_area?.places?.placeInfos">
                            </gmb-address>
                        </div>

                        <div class="row" style="width: min-content;">
                            <div class="col-12">
                                <button *ngIf="loc.log" mat-flat-button (click)="openFetchDialog(loc)"
                                    class="btn btn--badge btn--badge--sm txt--uppercase txt--xs" [color]="loc.log.status === 'success' ?  'accent' : 'warn'">{{loc.log.status}}</button>
                            </div>
                            <div class="col-12">
                                <a class="link link--blue txt--normal" (click)="openFetchDialog(loc)">View Changes</a>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="notification__item text-center a" (click)="handleReload($event); $event.stopPropagation()" *ngIf="page < pages" [style.backgroundColor]="loadingNoti ? '#ddd' : '#39b54a'">
                    <div class="w--100">
                        <h3 style="text-align: center; color: #fff;">Load more <i class="fas fa-redo-alt" [ngClass]="{'fa-spin': loadingNoti}"></i></h3>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        
        
    </div>

</div>
<div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Close</button>
    </span>

    <div *ngIf="(bulkStatus == 'error' || bulkStatus == 'partial') && component != 'photos'">
        <button mat-flat-button class="btn btn--badge btn--badge--sm txt--uppercase txt--xs" [color]="'primary'" (click)="retryFailedLocations()">Retry</button>
        <button mat-flat-button class="btn btn--badge btn--badge--sm txt--uppercase txt--xs" [color]="'warn'" (click)="purgeFailedLocations()">Purge</button>
    </div>
</div>
