import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalServiceAreaComponent } from '../../modal-service-area/modal-service-area.component';

@Component({
  selector: 'app-location-service-area',
  templateUrl: './location-service-area.component.html',
  styleUrls: ['./location-service-area.component.scss']
})
export class LocationServiceAreaComponent implements OnInit {
  serviceAreaPlaces = [];
  @Input() serviceArea: any = {}
  @Input() isUnderReview = false;
  @Input() isEdit = true;
  @Input() regionCode = 'US'
  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.serviceAreaPlaces = this.serviceArea?.places?.placeInfos || []
  }

  openAddressDialog() {
    const dialogRef = this.dialog.open(ModalServiceAreaComponent, {
      width: '680px',
      data: {
        'serviceArea': this.serviceArea,
        'regionCode': this.regionCode
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res != '') this.onUpdate.emit(true);
    });
  }

}
