import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environment';
import { ObservationsDTO } from '../constants/firestore/observation';
import { ApiResponse } from '../constants/api-response';
import { Queue } from '../constants/firestore/enqueue';

@Injectable({
  providedIn: 'root'
})
export class ObservationService {
  private _addAccount$ = new Subject<void>();
  public readonly addAccount$ = this._addAccount$.asObservable();

  constructor(private http: HttpClient) {
  }

  save(accountId: string, locationId: string) {
    let params = new HttpParams();
    if (environment.queuesEnabled) {
      params = params.append('enqueue', Queue.COMBINED_EXPRESS);
    }

    return this.http.post(`${environment.apiUrl}/v2/observations/${accountId}/${locationId}`, {}, { params });
  }

  get(accountId : string, locationId : string): Observable<ObservationsDTO> {
    return this.http.get<ApiResponse>(`${environment.apiUrl}/v2/observations/${accountId}/${locationId}`).pipe(
      map(value => value.data as ObservationsDTO)
    );
  }

  public sendAddAccount() : void {
    this._addAccount$.next();
  }

}
