<div class="col-12 m--0 p--0">
  <div class="box box--rounded box--shadow bg--white">
    <div class="box__content ">
      <ng-content></ng-content>
    </div>
  </div>
</div>





