// dep
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Location as AngularLocation } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject, timer } from 'rxjs';

// app
import { LocationService } from '../../services/location.service';
import SavedLocation from '../../constants/firestore/saved-location';
import { SubscriptionService } from '../../services/subscription.service';
import { AccountService } from '../../services/account.service';
import { SnackbarService } from '../../services/snackbar.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { ObservationService } from '../../services/observation.service';
import { ModalService } from '../../services/modal.service';
import { Pageable } from '../../constants/pageable';
import { Pagination } from '../../constants/api-response';
import * as constants_locations from '../../constants/firestore/account-location'
import * as constants_plans from '../../constants/firestore/plan'
import { PaginatorComponent } from '../app-charts/paginator/paginator.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import { AlertType } from 'src/app/components/alert.component';
import { LocationID, LocationRef } from 'src/app/constants/firestore/location-object';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
import { markedPositions } from 'src/app/helpers/utils.helpers';
import { ProfilesListView, ProfilesService } from 'src/app/services/core-api';
import { IStatusBadge } from 'src/app/constants/status-badge';


/**
 * Shows a List of Locations of a single account
 *
 * Also provides buttons to:
 *  - Change the selected locations plan (iff pricing < V3)
 *  - Delete the selected locations
 *  - Refresh the selected locations
 *  - Cancel the pending plan change of a single location
 *  - Navigate to location info / open it in new tab
 *  - Add a new location
 * 
 * Locations can be filtered by location name or address
 */
@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: [ './locations.component.scss']
})
export class LocationsComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('paginator') paginatorComponent: PaginatorComponent;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;

  public STATUS_CHANGE_PLAN         = constants_plans.STATUS_CHANGE_PLAN;
  public LOCATION_SUBSCRIPTION_TYPE = LOCATION_SUBSCRIPTION_TYPE;
  // public authOk           = constants_locations.VERIFICATION_OK;
  // public authUnauthorized = constants_locations.VERIFICATION_UNAUTHORIZED;
  // public authUnauthorized_2 = constants_locations.VERIFICATION_UNAUTHORIZED_2;
  // public authNotFound     = constants_locations.VERIFICATION_NOT_FOUND;
  // public authNotFound_2   = constants_locations.VERIFICATION_NOT_FOUND_2;

  // TODO: These two are never reassigned but read in the template on a switch:
  public VERIFICATION_ACCOUNT_ERROR = constants_locations.VERIFICATION_ACCOUNT_ERROR;
  public VERIFICATION_REQUIRED      = constants_locations.VERIFICATION_REQUIRED;

  private _filteredLocationIds: LocationID[] = [];
  // public filteredOptions: Observable<any>; // TODO: Unused, remove. 
  // public countLocations: number; // TODO: Unused, remove.
  
  
  /* List of locations shown on the frontend */
  public locations: (SavedLocation & 
                     { isChecked   : boolean 
                       locationAbc : ProfilesListView['profiles'][number]['locationAbc'] | null} )[] = [];

  public accountsOptions = [];
  // public selectedAccount: object // TODO: Unused, remove
  public labelAccountsFiltered: string;
  public filterAccountsControl = new FormControl('');
  public accountObjectId: string;
  public displayedColumns: string[];
  public dataSource: MatTableDataSource<SavedLocation>;
  public allLocationCheck = false;
  public allChecked = false;
  // public checkedLocations; // TODO: Unused, remove. 
  public listCheckLocations: (Pick<SavedLocation, 'locationId' | 'subscriptionType' | 
                                                  'pendingChange' | 'locationName' | 
                                                  'location'> & {checked : boolean})[] = [];
  // public upgradeCheckSelect = false; // TODO: Unused, remove
  // public downgradeCheckSelect = false; // TODO: Unused, remove
  // public manualPage: number; // TODO: Unused, remove
  // public errorMessage: boolean; // TODO: Unused, remove
  public textSearch = '';
  public pagination: Pagination = {
    items: [],
    per_page: 10,
    page: 1,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  public selectedSearch = new FormControl();
  public filtered = false;
  public searchText: string;
  public loadingTable$   = new BehaviorSubject(true);
  public accountEmpty$   = new BehaviorSubject(false);
  public NotFoundSearch$ = new BehaviorSubject(false);
  public errorAccount = false;
  public appleBusinessConnectDarkLaunch: boolean;

  private _applyFilterTimer: ReturnType<typeof setTimeout> | null = null;
  private _hasAccountsFilter = false;
  private _refreshTimerStarted = false;
  private _paginate : Pageable;
  // private _previousPageable: Pageable = null;

  session$ = this._sessionS.session$

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _changeDetector: ChangeDetectorRef,
    private _spinnerService: SpinnerService,
    private _snack: SnackbarService,
    private _locationService: LocationService,
    private _accountService: AccountService,
    private _apiSubscription: SubscriptionService,
    private _obsService: ObservationService,
    private _modalService: ModalService,
    private _angularLocation: AngularLocation,
    private _sessionS : SessionService,
    private _profilesS : ProfilesService    
  ) {
    super(); 

    this._subscribeSafe(this.session$, _ => this._updateTable());

    // Reload the locations table when we trigger a location plan change from this
    // component or the plan change is externally started by TrialMessageComponent
    let is_first_value = true
    this._subscribeSafe(this._locationService.someLocationChanged$, 
      _ => {
        if(is_first_value)
          // Avoid running _init() on component creation, as ngOnInit already
          // runs it.
          is_first_value = false
        else
          this._init()
      }
    )

    const {features} = this._sessionS.getSession();
    this.appleBusinessConnectDarkLaunch = features.userFeatures.appleBusinessConnect || false;
  }

  ngOnInit(): void {
    this._subscribeSafe(this.session$, s => {
      this.displayedColumns = ['id', 'company', 
                                ...(this.appleBusinessConnectDarkLaunch ? ['profile-status'] : []), 
                                // 'subscription' here means location's plan
                                ...(s.subscription.pricingVersion < 3 ? ['subscription'] : []), 
                                ...(s.isAdmin ? ['actions'] : []), 
                              ];
    })

    this._subscribeSafe(this._locationService.paginate$,
      res => this._paginate = res
    )

    this._init();

    this._subscribeSafe(this._router.events, event => {
      if (event instanceof NavigationEnd) {
        this._showLoading();
        this.accountObjectId = event.url.split('/')[2]
        this._filterAccountsByAccountId();
        this._init();
      }
    });
  }

  ngAfterViewInit(): void {
    // this.manualPage = 1;
    // this.errorMessage = false;
    this._changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this._locationService.reset();
    super.ngOnDestroy();
  }
  
  public async onAddNewLocation(): Promise<void> {
     await this._router.navigate(['accounts'])
     this._obsService.sendAddAccount()
   }

  public async openDeleteSingleLocationDialog(location : Pick<SavedLocation, 'locationId' | 'location' | 'locationName'>): Promise<void> {
    const domainCompany = this._sessionS.getDomain().branding.company_name;

    if(!await this._modalService.openConfirmModal(
      `Are you sure you want to disconnect ${location?.locationName} from ${domainCompany}?`, 
      `Note: this only disconnects the profile from ${domainCompany}; `+
      `it does not delete your business profile from Google Maps. `+
      `You can always re-add your business profile to ${domainCompany}.`)) {
      return;
    }
    
    const {gid} = this._sessionS.getSession();
     
    try {
      this._spinnerService.loading$.next(true);
      const accountWasDeleted = await this._locationService.deleteLocation(gid, this.accountObjectId, location);
      if(accountWasDeleted) {
        await this._router.navigate(['/accounts']);
      } else {
        this.listCheckLocations = [];
        this._init()
      }

    } catch(err) {
      console.error('Error deleting location', err);
    } finally {
      this._spinnerService.loading$.next(false);
    }
  }

  private _initPaginator(): void {
    this._paginate = { page: 1, size: 10 };
    this.paginatorComponent?.reset();
    // this._previousPageable = null;
  }

  private _setTableState(state: 'LOADING' | 'EMPTY' | 'NOT_FOUND' | 'SHOW'): void {
    this.loadingTable$.next(state === 'LOADING');
    this.accountEmpty$.next(state === 'EMPTY');
    this.NotFoundSearch$.next(state === 'NOT_FOUND');
  }
  
  // Methods to maintain semantic clarity
  private _showTable(): void {
    this._setTableState('SHOW');
  }
  
  private _showLoading(): void {
    this._setTableState('LOADING');
  }
  
  private _showEmpty(): void {
    this._setTableState('EMPTY');
  }
  
  private _showNotFound(): void {
    this._setTableState('NOT_FOUND');
  }

  // apply filter from search
  public applyFilter(searchText: string): void {

    // TODO: Why 350ms delay?
    clearTimeout(this._applyFilterTimer);
    this._applyFilterTimer = setTimeout(() => {

       if (!searchText) {
         this.filtered = false;
         this._filteredLocationIds = [];
         this._showLoading();
         this._initPaginator();
         this._getData(this._paginate);
       } else if (searchText[searchText.length - 1] !== ' ') {
          if (!this.filtered) 
            this._initPaginator();
          this.filtered = true;
          this._showLoading();
          this.searchText = searchText.toLowerCase();
          this._updateData(this._paginate, this.searchText)
        }

     }, 350);
  }


  /**
   * Fetchs a page of locations
   * TODO: Probably called multiple times in parallel, implement coalescing. 
   */
  private async _getData(page: Pageable, locationsIds : LocationID[] = []): Promise<void> {
    const {gid, isAdmin, user} = this._sessionS.getSession(); 
    if (!isAdmin) {
      // Restrict the locations to the ones on the selected account that the non-admin 
      // user has permission to access to.
      locationsIds = (user.accounts || []).find(acc => acc.accountId === this.accountObjectId)?.locations.map(loc => loc.locationId) || [];
    }

    try {

      const [r, pl] = await Promise.all([this._locationService.fetchAccountLocationsPaginated(gid, this.accountObjectId, page, locationsIds),
                                        // TODO: Must set the name on the pydantic spec instead of this horrible one
                                        this._profilesS.routeGetProfilesViewProfilesByAccountAccountIdViewGet(this.accountObjectId).toPromise()] as const);

      // this._previousPageable = { size: r['perPage'], 
      //                            page: r['currentPage'] };
      this.pagination = {
        items:    r['items'],
        per_page: r['perPage'],
        page:     r['currentPage'],
        hasNext:  r['currentPage'] < r['totalPages'],
        hasPrev:  r['currentPage'] > 1,
        pages:    r['totalPages'],
        total:    r['totalItems']
      };

      this.locations = this._formatData(r['items']) as any;
      r['items'] = this.locations;
      
      const profilesByLocationId = {}
      for(const p of pl.data.profiles) {
        profilesByLocationId[p.locationGbp.locationId] = p
      }

      for(const l of this.locations) {
        l.locationAbc = profilesByLocationId[l.locationId]?.locationAbc || null;
      }

      // this.countLocations = r['totalItems']; // TODO: Unused, remove.
      this._updateTable();
    } catch (err) {
      console.error(err);
    } finally {
      this._spinnerService.loading$.next(false);
    }

  }

  private async _updateData($event: Pageable, text: string): Promise<void> {
    const {gid} = this._sessionS.getSession(); 

    const response = await this._locationService.getLocationsIdsByStringQuery(gid, text);
    const filteredAccount = response?.accounts?.find(acc => acc.accountId === this.accountObjectId) || null
    this._filteredLocationIds = filteredAccount?.locations?.map(location => location.locationId) || []
    if (this._filteredLocationIds.length) {
      this._getData($event, this._filteredLocationIds)
    } else {
      this._showNotFound();
    }
  }

  // TODO: getter called from template, change to prop.
  public getLocationStatus(element : SavedLocation): string {
    if (element?.location?.locationState?.isVerified === false) {
      return this.VERIFICATION_REQUIRED;
    } 
    
    const s = element?.lastCheck?.status;
    if (s && s !== 'OK') {
      return (s === 'NOT AUTHORIZED' ? 'NOT_AUTHORIZED' :
              (s === 'NOT FOUND' ? 'NOT_FOUND' : 
              s));
    } else if (element?.errorLog) {
      return 'errorLog';
    } 
    return '';
  }

  // check for nested objects
  private _nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this._nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }


  private _listCheckLocationsPush(loc : SavedLocation, checked : boolean) : void {
    this.listCheckLocations.push({
      locationId:       loc.locationId,
      subscriptionType: loc.subscriptionType,
      pendingChange:    loc.pendingChange,
      locationName:     loc.locationName,
      location:         loc.location,
      checked
    });
  }

  public toggleCheckAll(check: MatCheckboxChange) : void {
    this.allChecked = check.checked;

    for(const location of this.locations) {
      if (this.allChecked) {
        const isChecked = this.listCheckLocations.find(l => l.locationId == location.locationId);
        if (!isChecked) {
          this._listCheckLocationsPush(location, true);
        }
      } else {
        this.listCheckLocations = [];
        this.allLocationCheck = false;
        // Uncheck location if it's checked.
        // this.listCheckLocations = this.listCheckLocations.filter(l => l.locationId !== location.locationId);
      }
      location.isChecked = this.allChecked
    }
    // this._isUpgradeDowngradeActions(); // TODO: Unused, remove.
  }


  public selectLocation(location : SavedLocation, check: MatCheckboxChange) : void {
    const isChecked = this.listCheckLocations.find(l => l.locationId == location.locationId);

    if (isChecked) {
      this.listCheckLocations = this.listCheckLocations.filter(l => l.locationId !== location.locationId);
    } else {
      this._listCheckLocationsPush(location, check.checked);
    }

    // this._isUpgradeDowngradeActions(); // TODO: Unused, remove. 
  }

  // TODO: Member vars setted are unused, remove
  // private _isUpgradeDowngradeActions() : void {
  //   if (this.listCheckLocations.length) {
  //     this.upgradeCheckSelect   = !!this.listCheckLocations.find(l => l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE)
  //     this.downgradeCheckSelect = !!this.listCheckLocations.find(l => l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC || 
  //                                                                     l.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ULTIMATE)
  //   } else {
  //     this.upgradeCheckSelect   = false
  //     this.downgradeCheckSelect = false
  //   }
  // }

  private _generateBulkSubscriptionErrorMessage(unverifiedLocations, canUpdateOthers : boolean): string {
    let message = `<div class='txt--left'>
    The following location(s) need to be verified in your Google Business Profile before you can update the subscription(s).
      <div>
      <br>
        <ul>`;

    unverifiedLocations.forEach((location) => {
      message += `<div>${location?.locationName}</div><li>${this._locationService.formatAddress(location?.location?.address)}</li>`;
    });
  
    message += `</ul>
      </div>`;

    if (canUpdateOthers){
      message += "Would you like to proceed with updating the other locations?"
    }
    message += "</div>"
  
    return message;
  }

  // Function change to plan locations
  // TODO: Should be removed after all Customers are moved to V3 pricing
  public async changePlan(locations : LocationsComponent['listCheckLocations'][0] | LocationsComponent['listCheckLocations']) : Promise<void> {
    if (this._sessionS.getSession().isMember) {
      this._modalService.openWarningModalNoPermissions();
      return;
    }

    let locationsChange : LocationRef[]= []
    const unverifiedLocations : typeof locations = []
    if (!Array.isArray(locations)) {
      const loc = locations;
      if (!this._validateVerifiedStatus(loc, true))
        return
    
      locationsChange = [{ locationId: loc.locationId, 
                            accountId: this.accountObjectId }];
    } else {
      for(const location of locations) {
        if (location?.location?.locationState?.isVerified == false){
          unverifiedLocations.push(location)
        } else {
          locationsChange.push({ locationId: location.locationId || location as any, 
                                 accountId:  this.accountObjectId })
        }
      }
    }

    if (unverifiedLocations.length){
      const canUpdateOthers = !!locationsChange.length;

      const r = await this._modalService[canUpdateOthers ? 'openConfirmModal' : 'openAlertModal'].bind(this._modalService)
                       ("Verification required in Google Business Profile",
                        this._generateBulkSubscriptionErrorMessage(unverifiedLocations, canUpdateOthers),
                        null,
                        AlertType.ERROR);
      
      if (!r || !canUpdateOthers) {
         return
      }
    }

    await this._apiSubscription.flowChangeLocationsPlan(locationsChange)
    // locationService.someLocationsChanged will fire if a plan change was made
  }

  public async refreshLocation(locationId: string): Promise<void> {
    const { gid } = this._sessionS.getSession();

    if (this.filtered) 
      this.applyFilter(this.searchText);

    try {
      await this._locationService.locationRefreshAllDeps(this.accountObjectId, locationId, gid)
    } finally {
      if (this.filtered) 
        this.applyFilter(this.searchText);
    }
  }

  public async refreshSelected(): Promise<void> {
    const {gid} = this._sessionS.getSession();

    this._snack.openInfo("The selected profiles are being refreshed. Please wait while this is completed.");
    let some_error = false;

    // TODO: Rewrite with Promise.allSettled when available
    await Promise.all(this.listCheckLocations.map(({locationId}) => (
      async () => {
        try {
          await this._locationService.locationRefreshAllDeps(this.accountObjectId, locationId, gid);
        } catch(err) {
          console.log(err)
          some_error = true;
        }  
      })()));

    if (some_error) {
      this._snack.openWarning("Some locations weren't updated");
    } else { 
      this._snack.openSuccess("The selected locations was updated");
      this.listCheckLocations = [];
    }

    if (this.filtered) 
      this.applyFilter(this.searchText);
  }

  public async deleteSelected() : Promise<void> {
    if (this.listCheckLocations.length === 1) {
      await this.openDeleteSingleLocationDialog(this.listCheckLocations[0]);
      return;
    } 

    const domainCompany = this._sessionS.getDomain().branding.company_name;

    if(!await this._modalService.openConfirmModal(
      `Are you sure you want to disconnect these business profiles from ${domainCompany}?`, 
      `Note: this only disconnects the profiles from ${domainCompany}; ` +
       `it does not delete your business profile from Google Maps. `+
      `You can always re-add your business profiles to ${domainCompany}.`)) {
        return;
    }

    try {
      this._spinnerService.loading$.next(true);
      const {gid} = this._sessionS.getSession();
      let accountWasDeleted = false
      for(const e of markedPositions(this.listCheckLocations)) {
        accountWasDeleted = await this._locationService.deleteLocation(gid, this.accountObjectId, e.value, 
                                                                       {notifyChange       : e.isLast, 
                                                                        deleteEmptyAccount : e.isLast});
      }

      this.locations = this.locations.filter(l => !this.listCheckLocations.find(lc => lc.locationId === l.locationId));
      this.listCheckLocations = []
      this.allChecked = false
      if(accountWasDeleted) 
        await this._router.navigate(['/accounts'])

      // this._init() will be called by someLocationsChanged subscription. 
    } finally {
      this._spinnerService.loading$.next(false);
    }

  }

  private async _getAccountsFilter() : Promise<void> {
    try {
      const r = await this._accountService.getAccountPaginate(this._sessionS.getSession().gid, {page: 1, size: 1000}, [])
      this.accountsOptions = [...r.items]
      this._filterAccountsByAccountId()
      this._hasAccountsFilter = true
    } catch (e) {
      console.error(e)
    } finally {
      this._spinnerService.loading$.next(false)
    }
  }

  public selectAll(): void {
    if (!this.allLocationCheck) {
      this.allLocationCheck = true;

      const accountLocations = this._locationService.getAccountLocationsCached()
      const filteredLocations = (this._filteredLocationIds.length ?
                                 accountLocations.filter(l => this._filteredLocationIds.includes(l.locationId)) :
                                 accountLocations)
  
      for(const location of filteredLocations) {
        if(!this.listCheckLocations.find(l => l.locationId == location.locationId)) {
          this._listCheckLocationsPush(location, this.allChecked);
        }
      }
      this.allChecked = true;
    } else {
      this.listCheckLocations = [];
      this.allLocationCheck = false;
      this.allChecked = false;
    }

    for(const location of this.locations) {
      location.isChecked = this.allChecked
    }

  }

  private _formatData(locations: SavedLocation[]): SavedLocation[] {
    const {isTrial} = this._sessionS.getSession();

    const existSelected = this.listCheckLocations.length > 0;
    locations = locations.filter(Boolean);
    for (const location of locations) {
        let status = '';
        if (location.subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE) {
          status = (isTrial ? 
                    LOCATION_SUBSCRIPTION_TYPE.ULTIMATE_TRIAL : 
                    LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL)
        } else {
          status = location.subscriptionType
        }
        
        location['subscriptionStatus'] = status;

        if(existSelected)
          location.isChecked = !!this.listCheckLocations.find(l => l.locationId === location.locationId)
    }

    // If there are no locations, show the empty message
    if (locations.length === 0) {
      this._showEmpty();
    } else {
      this._showTable();
    }
    
    return locations;
  }

  public handleReload($event: Pageable): void {
    this._showLoading();
    this._locationService.setPaginate($event);
    this._paginate = $event;
    if (this.filtered) {
      this.applyFilter(this.searchText);
    } else {
      this._getData($event);
    }
  }

  public goLocation(accountId: string, location: SavedLocation): void {
    // TODO: Don't verify here, disable goLocation link/button if not verified
    if (this._validateVerifiedStatus(location))
      this._router.navigate(['/account', accountId, 'location', location.locationId, 'insights'])
  }

  private _validateVerifiedStatus(location: Pick<SavedLocation, 'location'>, typeIsSubscription = false) : boolean {
    if (location?.location?.locationState?.isVerified == false){
      this._modalService.openErrorModal(
        "Verification required in Google Business Profile",
        `<div class='txt--left'>
        ${typeIsSubscription ? 
          'In order to update your profile subscription, you need to verify your location.' :
          'This location requires verification, which prevents access to your dashboard.' }
        <div>
          <br>Step 1: Log in to your Google Business Profile and verify your location.
          <br>Step 2: Once verified, return and click the refresh button.
        </div>
      </div>`
      );
      return false
    }

    return true
  }

  public goNewTab(accountId: string, location: Pick<SavedLocation, 'locationId' | 'location'>, event?: Event): void {
    if (event) 
      event.stopPropagation()
    
    if (!this._validateVerifiedStatus(location))
      return
    
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/account', accountId, 'location', location.locationId, 'insights'])
    );
    window.open(url, '_blank');
  }

  public goNewTabSelected(): void {
    for (const location of this.listCheckLocations) 
      this.goNewTab(this.accountObjectId, location)
  }

  public getNextDueDate(): string {
    const {nextDueDate} = this._sessionS.getSession().subscription;
    const d = this._locationService.formatDates(nextDueDate).split('-');
    return `${d[1]}/${d[2]}/${d[0]}`;
  }

  public async cancelChangePlan(location : LocationsComponent['listCheckLocations'][0]): Promise<void> {
    if(await this._modalService.openConfirmModal('Cancel Listing Plan Change',
                                                 (`This location is scheduled to change subscription plan to ${location.pendingChange.nextPlan} `+
                                                  `on ${this.getNextDueDate()}. `+
                                                  `Do you want to cancel this scheduled change and keep the location as ${location.subscriptionType}?`)))
      try {
        this._spinnerService.loading$.next(true)

        if(!await this._apiSubscription.applyChangePlan([{ locationId: location.locationId, 
                                                            accountId: this.accountObjectId }], 
                                                        location.subscriptionType))
          return

        await this._getData(this._paginate);
        await this._locationService.fetchAccountLocationsAndEmit(this._sessionS.getSession().gid, this.accountObjectId);
      } catch(err) {
        console.error(err);
      } finally {
        this._spinnerService.loading$.next(false)
      }
  }

  public filterChanged(account): void {
    this.listCheckLocations = [];
    this.allLocationCheck = true;
    this.selectAll();
    this._showLoading();
    this.accountObjectId = account[0].accountId;
    this._router.navigate([`accounts/${this.accountObjectId}/locations`]);

  } 

  private _filterAccountsByAccountId(): void {
    const matchingAccount = this.accountsOptions?.find((account) => account?.accountId === this.accountObjectId);
    // this.selectedAccount = matchingAccount || null // TODO: Unused, remove. 
    this.labelAccountsFiltered = matchingAccount?.account?.accountName || null
  }

  private _updateTable(): void {
    this.dataSource = new MatTableDataSource(this.locations);
    this.dataSource.filterPredicate = (data, filter: string) => {
      const accumulator = (currentTerm, key) => {
        return this._nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
  }

  public getABCStatus(status): IStatusBadge {
    return this._locationService.getABCStatusBadge(status)
  }

  
  private _init() {
    this.filtered = false;
    this.textSearch = '';
    const errorAccount = this._route.snapshot.queryParamMap.get("errorAccount")
    this.errorAccount = (errorAccount?.toLowerCase() === "true")

    if (errorAccount !== null) {
      const url = this._router.url.split('?')[0]
      this._angularLocation.go(url)
    }
    
    this.accountObjectId = this._route.snapshot.paramMap.get('accountObjectId');

    const {gid} = this._sessionS.getSession();

    // TODO: 5s forced delay, why? (single shot)
    if(!this._refreshTimerStarted) {
      this._refreshTimerStarted = true
      this._subscribeSafe(timer(5000), async () => {
        try {
          await this._getData(this._paginate)
          await this._locationService.fetchAccountLocationsAndEmit(gid, this.accountObjectId);
          this._updateTable()
        } finally {
          this._refreshTimerStarted = false
        }
      })
    }

    if (!this._hasAccountsFilter)
      this._getAccountsFilter()
  }
  
}
