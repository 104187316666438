// dep
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// app 
import { environment as ENV } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  constructor(
    private http: HttpClient
  ) { }

  search(query: string, gid: string): Observable<any> {
    return this.http.post(`${ENV.apiUrl}/v2/search/global-search/${gid}`, {query});
  }
}
