import { Component } from '@angular/core';

@Component({
    selector: 'compass-test-badge',
    template: `
        <div class="test-badge">
            TEST FEATURE
        </div>
    `,
    styles: [`
        .test-badge {
            background-color: #ecb21352;
            color: #975b20;
            padding: 5px 10px;
            border-radius: 15px;
            font-size: 12px;
            font-weight:500;
            width: 110px;
            text-align: center;
            margin-top:10px;
        }
    }
    `],
})
export class CompassTestBadgeComponent {

    constructor() {}

}