import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalFormMenuComponent } from '../modal-form-menu/modal-form-menu.component';
import { FoodMenuItem } from '../../../../constants/google/menuList';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-modal-form-item',
  templateUrl: './modal-form-item.component.html',
  styleUrls: ['./modal-form-item.component.scss']
})
export class ModalFormItemComponent implements OnInit {

  modelResponse: FoodMenuItem[] = []
  isEdit: boolean = false;
  form: FormGroup;
  typeFormPrice: string ='fixed';

  constructor(
    public dialogRef: MatDialogRef<ModalFormMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit() {
    this.isEdit = this.data.edit;
    this.initForm();

    if (this.data.menuItems) {
      this.isEdit = true;
      this.modelResponse = this.data.menuItems;
      let items = this.modelResponse[0];
      this.typeFormPrice = this.data.typePrice;
      let editAttributes;
      if (items.attributes && items.attributes.price) {
        const roundNanos = items.attributes.price.nanos ? items.attributes.price.nanos.toString().substr(0, 2) : '00';
        const units = items.attributes.price.units || '0';
        editAttributes = {
          currencyCode: items.attributes.price.currencyCode,
          units: `${units}.${(roundNanos === '0') ? '00' : roundNanos}`,
          type: this.typeFormPrice
        };
      } else {
        editAttributes = {
          currencyCode: 'USD',
          units: '',
          type: this.typeFormPrice
        }
      }

      this.getItemArray(0).setValue({
        displayName: items.labels[0].displayName || '',
        description: items.labels[0].description || '',
        languageCode: 'en',
        attributes: editAttributes
      })
    }
  }

  remove() {
    this.dialogRef.close({action: 'remove',item: this.modelResponse });
  }

  apply() {
    let FormResult = this.form.value;
    let items = FormResult.items[0]

    let units: string = '0';
    let nanos: number = 0;
    if (items.attributes.type !== 'free' && items.attributes.type !== 'noPrice') {
      const amount = items.attributes.units.toString().split('.');
      units = amount[0];
      nanos = Number(amount[1]);
    }

    this.modelResponse = [{
      labels: [
        {
          displayName: items.displayName,
          description: items.description,
          languageCode: items.languageCode
        }
      ],
      attributes: {
        price: {
          currencyCode: items.attributes.currencyCode,
          units,
          nanos
        },
        servesNumPeople: 1,
        mediaKeys: [],
      }
    }];

    if (items.attributes.type === 'noPrice') {
      delete this.modelResponse[0].attributes.price;
    }
    if (this.isEdit) {
      this.dialogRef.close({action: 'update',item: this.modelResponse });
    } else {
      this.dialogRef.close({action: 'added',item: this.modelResponse });
    }

  }


  get ItemsArray(): FormArray {
    return (this.form.get('items') as FormArray);
  }

  getItemArray(index): AbstractControl {
    return (this.form.get('items') as FormArray).at(index);
  }

  initForm() {
    this.form = new FormGroup({
      items: new FormArray([new FormGroup({
        displayName: new FormControl('', [Validators.required, Validators.maxLength(140)]),
        description: new FormControl('', [Validators.maxLength(1000)]),
        languageCode: new FormControl('en'),
        attributes: new FormGroup({
          currencyCode: new FormControl('USD', [Validators.required]),
          units: new FormControl('0' , [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{2})?'), Validators.min(0)]),
          type: new FormControl(this.typeFormPrice, Validators.required),
        })
      })])
    });
  
  }

  onChangePrice(index) {
    const formInput = this.getItemArray(index).get('attributes.units');
    const currency = this.decimalPipe.transform(formInput.value, '1.2-2');
    formInput.setValue(currency.replace(/,/g, ''));
  }
}
