
<div class="row">
  <div class="col-12 mb--30">
    <div class="box box--rounded box--shadow box--padding-xs bg--white">
      <div class="row">
        <div class="col-12 col-md-8 col--padding border-right">
          <div class="box">

            <div class="box__section border-bottom">
              <div class="box__item box__item--sm box__item--editable" *ngFor="let i of [0]">
                <ngx-skeleton-loader count="1" [theme]="{ width: '200px', height: '40px' }"></ngx-skeleton-loader>
              </div>
            </div>
            <div class="box__section">
              <div class="box__item box__item--sm" *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10]">
                <div class="d-flex align-items-center justify-content-between mb--20 border-bottom">
                  <div class="d-flex align-items-center">
                    <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '35px', height: '35px', 'margin-right': '30px' }"></ngx-skeleton-loader>
                    <ngx-skeleton-loader class="d-flex flex-column" [count]="i === 2 ? 5 : 1" [theme]="{ width: '270px', height: '20px' }"></ngx-skeleton-loader>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col--padding">
          <div class="box">

            <div class="box__section">
              <!--Your business is live on Apple-->
              <div class="box__item border-bottom">
                <h2 class="mb--20"><strong>Your business is live on Apple</strong></h2>

                <div class="box__item box__item--sm" *ngFor="let i of [0,1]">
                  <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '30px', height: '30px', 'margin-right': '20px' }"></ngx-skeleton-loader>
                  <ngx-skeleton-loader count="1" [theme]="{ width: '150px', height: '30px'}"></ngx-skeleton-loader>
                </div>

              </div>
            </div>
            <div class="box__section">
              <div class="border-bottom pt--20">
                <h2><strong>Cover Photo</strong></h2>

                <div class="box__item box__item--sm" *ngFor="let i of [0,1,2]">
                  <ngx-skeleton-loader count="2" [theme]="{ width: '35%', height: '30px', margin: '0 40px 0 0' }"></ngx-skeleton-loader>
                  <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '30px', height: '30px' }"></ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
