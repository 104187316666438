<table class="table table--sm table--last-row-border bg--white">
  <thead>
    <tr class="mat-row no-hover">
      <th class="mat-header-cell txt--center"><strong>Creation Datetime</strong></th>
      <th class="mat-header-cell txt--center"><strong>Update</strong></th>
      <th class="mat-header-cell txt--center"><strong>Profiles</strong></th>
      <th class="mat-header-cell txt--center"><strong>Changes</strong></th>
      <th class="mat-header-cell txt--center"><strong>Status</strong></th>
    </tr>
  </thead>
  <tbody>
    <tr class="mat-row no-hover" *ngFor="let i of [0,1,2]">
      <td class="mat-cell pb--5 cdk-column-date" style="width: 20%;">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px', 'border-radius': '4px', 'display':'block', 'margin-bottom': '5px' }"></ngx-skeleton-loader>
      </td>

      <td class="mat-cell" style="width: 20%;">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px', 'border-radius': '4px', 'display':'block'}"></ngx-skeleton-loader>
      </td>

      <td class="mat-cell" style="width: 20%;">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px', 'margin-bottom': '4px', 'display': 'block'}"></ngx-skeleton-loader>
      </td>

      <td class="mat-cell" style="width: 20%;">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px', 'margin-bottom': '4px', 'display': 'block'}"></ngx-skeleton-loader>
      </td>

      <td class="mat-cell" style="width: 20%;">
        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px', 'margin-bottom': '4px', 'display': 'block' }"></ngx-skeleton-loader>
      </td>
    </tr>
  </tbody>
</table>
