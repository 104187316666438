// dep
import {Component, EventEmitter, Input, OnInit, Output, AfterViewInit, Renderer2, ElementRef} from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment-timezone';

// app
import {DataPicker}  from '../../constants/data-picker';
import { DATE_PICKER_RANGES } from '../../constants/datePicker';
import {EventMessageService} from "../../services/event-message.service";
import { EventNames } from 'src/app/constants/event-names';
import { ReportService } from 'src/app/services/report.service';
import { DatesService } from 'src/app/services/dates.service';

export interface SelectedDatePicker {
  start: Moment;
  end: Moment;
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, AfterViewInit {
  id = '';
  @Output() dateSelected = new EventEmitter<DataPicker>()

  @Input() reportType: string;
  @Input() dataPicker: DataPicker;
  @Input() rangeB: boolean;
  @Input() disabled: boolean;
  @Input() isForm: boolean;
  @Input() select: SelectedDatePicker;
  @Input() dropsChange: string;
  @Input() minRange: boolean;
  @Input() maxDate;
  @Input() minDateRange;
  @Input() subscribeToDatepicker = false
  @Input() showTooltipsOnDisabledDates = false;
  @Input() isReadOnly = false;
  @Input() hasDateLimitsInfo = false;
  @Input() isAppleMockup = false;

  ranges = DATE_PICKER_RANGES;
  opens = 'left';
  drops = 'down';
  alwaysShowCalendars = true;
  // keepCalendarOpeningWithRange = true;
  locale = {
    applyLabel: 'Apply',
    format: 'MM/DD/YYYY',
    cancelLabel: 'Cancel',
    customRangeLabel: 'Custom Range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 0, // first day is sunday
  };
  isClosed = true;
  events: string[] = [];
  private tooltipsApplied = false;
  public isOpened = false;

  constructor(
    public em: EventMessageService,
    private reportS: ReportService,
    private renderer: Renderer2,
    private el: ElementRef,
    private dateS: DatesService
    ) {}

  ngOnInit() : void {
    console.log('mock data in date-picker.component, remove data and Input()');
    
    if (this.isAppleMockup) {
      this.select = {
        start: moment("2024-08-17T02:59:59.000Z"),
        end: moment("2025-01-01T02:59:59.999Z")
      }
    }

    const now = moment()

    if (this.rangeB) {
      this.id = 'B';
    }
    
    this.drops = this.dropsChange ||  'down';

    if (this.minRange) {
      const months = this.reportType == 'performance-rollup' || this.reportType == 'review' ? 13 : 12;
      const minDate = now.clone().subtract(moment.duration({'months': months}));
      this.minDateRange = this.minDateRange?.isAfter(minDate) ? this.minDateRange : minDate;
    }

    if (this.disabled)
      this.dateSelected.emit(this.dataPicker);
    
    this.setRanges();
  }

  applyTooltips() : void {
    if(this.tooltipsApplied)
      return
      
    const elementsWithTooltip = document.querySelectorAll('.off.disabled');

    if (elementsWithTooltip?.length && this.showTooltipsOnDisabledDates) {
      const date = this.maxDate?.set({'hours': 23, 'minute': 59, 'second': 59});
      elementsWithTooltip.forEach(element => {
        element.setAttribute(
          'title',
          (`Google Business Profile performance data is latent for up to 7 days from today's date. ` +
            `Please choose a date before ${date.format('MM/DD/YYYY')} to see the most recent ` +
            `performance data for your business profile.`)
          );
      });

      this.tooltipsApplied = true
    }
  }

  addDateLimitsInfo() : void {
    const element = document.getElementsByClassName('limits-info-txt')[0];
    const buttonsInput = this.el.nativeElement.querySelector('.buttons_input');
    if (element)
      this.renderer.removeChild(element.parentNode, element);

    if(this.minDateRange && this.maxDate && buttonsInput) {
      const minDate = this.dateS.getStringDate(this.minDateRange) || ''
      const maxDate = this.dateS.getStringDate(this.maxDate) || ''
      const txt  = this.renderer.createText(`Please select a date from ${minDate} to ${maxDate}`)
      const span = this.renderer.createElement('span');

      this.renderer.addClass(span, 'limits-info-txt');
      this.renderer.appendChild(span, txt);
      this.renderer.insertBefore(buttonsInput, span, buttonsInput.children[1]);
    }
  }

  ngAfterViewInit() : void {
    this.applyTooltips();
  }
  

  rangeClicked(range) : void {
    const r = {start: range.startDate, 
                 end: range.endDate };
    if (this.rangeB) {
      this.dataPicker.rangeB = r;
    } else {
      this.dataPicker.range  = r;
    }
  }

  handleClick($event) : void {
    this.isClosed = false
    if (this.hasDateLimitsInfo) {
      this.addDateLimitsInfo();
    }

    if (this.minRange) {
      this.em.emitMessageEvent(EventNames.EVENT_DATEPICKER_SUBSCRIPTION, true);
    }

    if (!this.minRange && this.subscribeToDatepicker) {
      this.em.emitMessageEvent(EventNames.EVENT_DATEPICKER_FIRST_TIME_SUBSCRIPTION, true);
    }

    if (!this.isOpened) {
      this.isOpened = true;
      this.setRanges();

      if (!this.tooltipsApplied) {
        setTimeout(() => {
          this.applyTooltips();
        }, 500);
      }
    }
  }

  setRanges() : void {
    if (this.reportType == 'performance-rollup' || this.reportType == 'performance-comparison') {
      const r = {}
      for(const [desc, selector] of [['Last 7 Days',  'Last7'], 
                                     ['Last 30 Days', 'Last30'],
                                     ['Last 90 Days', 'Last90'],
                                     ['This Month',   'ThisMonth'],
                                     ['Last Month',   'LastMonth'],
                                     ['Last Year',    'LastYear'],
                                     ['YTD',          'YTD']] as const) {
        const dates = this.reportS.getDates(selector, 'performance-rollup')
        r[desc] = [dates.startDatetime, 
                   dates.endDatetime] as const
      }
      this.ranges = r as any
    }
  }

  datesUpdated($event) : void {
    if ($event.startDate) {
      const r = {
        start: $event.startDate.utc(true).toISOString(),
          end:   $event.endDate.utc(true).toISOString()
      };

      if (this.rangeB) {
        this.dataPicker.rangeB = r;
      } else {
        this.dataPicker.range = r;
      }
    }
    this.dateSelected.emit(this.dataPicker);
  }

  datesChange($event) : void {
    if ($event.start) {
      const r = {
        start: $event.start.utc(true).toISOString(),
        end:     $event.end.utc(true).toISOString()
      }

      if (this.rangeB)
        this.dataPicker.rangeB = r
      else
        this.dataPicker.range = r
    }

    this.dateSelected.emit(this.dataPicker);
  }

  changeDateWrite(date: string, $event) : void {
    if($event.keyCode !== 13)
      return

    const arraydate = date.split('-');

    if (arraydate.length > 1) {
      const dateStart = moment(arraydate[0].trim());
      const dateEnd   = moment(arraydate[1].trim());
      
      if (!dateStart && !dateEnd) 
        return

      const start = !this.minDateRange ? dateStart : (dateStart.isAfter(this.minDateRange) ? dateStart : this.minDateRange)
      const end   = !this.maxDate      ? dateEnd   : (dateEnd.isBefore(this.maxDate)       ? dateEnd   : this.maxDate)

      this.select = {
        start: start || dateStart,
        end:     end || dateEnd
      }
      this.datesUpdated({
        startDate: start || dateStart,
        endDate:     end || dateEnd
      })
    }
  }

  getDates(d1, d2) : string {
    d1 = moment(d1).utc().format(this.locale.format);
    d2 = moment(d2).utc().format(this.locale.format);

    return `${d1} - ${d2}`;
  }

}
