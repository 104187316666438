// dep
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

// app
import { LocationService } from '../../services/location.service';
import { ModalService } from '../../services/modal.service';
import { GoogleService } from '../../services/google.service';
import { ReviewLinkGeneratorComponent } from '../../components/review-link-generator.component';
import * as constants_locations from '../../constants/firestore/account-location';
import { DataTransferService } from '../../services/data-transfer.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { SubscriptionService } from '../../services/subscription.service';
import { SessionService } from 'src/app/services/session.service';
import SavedLocation from 'src/app/constants/firestore/saved-location';
import { BaseComponent } from 'src/app/components/base.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { AbcLocationService } from 'src/app/services/abc-location.service';
import { IStatusBadge } from 'src/app/constants/status-badge';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls:  ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent implements OnInit {
  // navigationSubscription;
  public locationId: string;
  public accountId: string;
  public location: Observable<SavedLocation> = of({} as any);
  // public locationTitle;
  public isRevealPath = false;
  public currentLocation : SavedLocation;
  public isABCflagged = false;
  public abcStatus: string;
  private placeId: string


  c_auth_ok           = constants_locations.VERIFICATION_OK;
  c_auth_unauthorized = constants_locations.VERIFICATION_UNAUTHORIZED;
  c_auth_not_found    = constants_locations.VERIFICATION_NOT_FOUND;

  domain$  = this._sessionS.domain$;
  session$ = this._sessionS.session$;
  
  constructor(
    private _sessionS : SessionService,
    private _route: ActivatedRoute,
    private _locationS: LocationService,
    private _modalService: ModalService,
    private _googleService: GoogleService,
    private _dataTransfer: DataTransferService<string>,
    private _spinnerService: SpinnerService,
    private _router: Router,
    private _apiSubscription: SubscriptionService,
    private _snackBarS: SnackbarService,
    private _abcLocS: AbcLocationService
  ) {
    super();
  }

  ngOnInit() : void {
    this._loadLocation();

    /*this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.loadLocation();
      }
    });

    this.locationTitle = this.location.subscribe(location => {
      const title = location ? `${location.locationName} @ ${location.location.address.addressLines[0]}`: '';
      this.wl.setTitle(title);
    });*/
  }

  getStatus(status) {
    if(!status) { return; }
    return status.replace(/\_/g, " ")
  }

  private _loadLocation() : void {
    this.locationId = this._route.snapshot.params?.locationId;
    this.accountId  = this._route.snapshot.params?.accountId;

    const {gid} = this._sessionS.getSession()
    this.location = this._locationS.getRef(gid, this.accountId, this.locationId);
    
    this._subscribeSafe(this.location, location => {
      this.currentLocation = this.formData(location);
      this._dataTransfer.setData(location.locationName);
      this.placeId = location ? location.location.locationKey.placeId : null;
      const hasAppleBusinessConnect = this._sessionS.getSession().features.userFeatures.appleBusinessConnect || false;
      this.isABCflagged = hasAppleBusinessConnect && location.hasAbcConnection;

      if(this.isABCflagged) {
        this._subscribeSafe(this._abcLocS.fetchLocationInfo(gid, this.locationId), res => {
          if (res) { 
            this.abcStatus = res?.state;
          }
        });
      }
    });
  }

  formData(location : SavedLocation) : SavedLocation {
    if (location) {
      const {isTrial} = this._sessionS.getSession();
      const st = location.subscriptionType;
      location.subscriptionStatus = (st === LOCATION_SUBSCRIPTION_TYPE.FREE ?
                                     (isTrial ? LOCATION_SUBSCRIPTION_TYPE.ULTIMATE_TRIAL : 
                                                LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL) :
                                     st);
    }

    return location
  }

  // ngOnDestroy() : void {
  //   // if (this.navigationSubscription) {
  //   //   this.navigationSubscription.unsubscribe();
  //   // }
  // }


  async handleReviewLinkGenerator() : Promise<void> {
    if (!this.placeId) {
      return;
    }

    const url = await this._googleService.dynamicUrl(this.placeId).toPromise()
    this._modalService.openGenericModal(ReviewLinkGeneratorComponent, url);
  }

  public async openDeleteDialog(): Promise<void> {
    const loc = this.currentLocation
    if(!loc)
      return;

    const domainCompany = this._sessionS.getDomain().branding.company_name;

    if(!await this._modalService.openConfirmModal(
      `Are you sure you want to disconnect ${loc.locationName} from ${domainCompany}?`, 
      `Note: this only disconnects the profile from ${domainCompany}; it does not delete your business profile `+
      `from Google Maps. You can always re-add your business profile to ${domainCompany}.`))
      return;

    try {
      this._spinnerService.loading$.next(true);

      const {gid} = this._sessionS.getSession();
      await this._locationS.deleteLocation(gid, this.accountId, loc);
      await this._router.navigate(['/accounts']); 
    } catch(err) {
      console.error('Error deleting location', err);
    } finally {
      this._spinnerService.loading$.next(false);
    }
  }

  async changePlan() : Promise<void> {
    if (this._sessionS.getSession().isMember) {
      await this._modalService.openWarningModalNoPermissions();
      return;
    }

    await this._apiSubscription.flowChangeLocationsPlan([{ locationId: this.currentLocation.locationId, 
                                                           accountId: this.accountId }]);
  }


  getGBPStatusBadge(status: string): IStatusBadge {
    let data: IStatusBadge = {
      bg: '',
      color: '',
      icon: '',
      status: '',
      tooltip: null
    };

    status = (
      status === 'NOT AUTHORIZED' || status === 'NOT_AUTHORIZED' ? 'c_auth_unauthorized' :
      status === 'NOT FOUND' || status === 'NOT_FOUND' ? 'c_auth_not_found' :
      status === 'VERIFIED' ? 'VERIFIED' : null
    );

    switch(status) {
      case 'VERIFIED':
        data = {
          bg: '#E0F4E7',
          color: '#01893A',
          icon: 'fa fa-badge-check',
          status: 'Verified',
          tooltip: null
        }
        break;
      case 'c_auth_unauthorized':
        data = {
          bg: '#FFE9E9',
          color: '#FF2222',
          icon: 'fas fa-exclamation-triangle',
          status: 'Action Required',
          tooltip: 'This location is no longer authenticated with Google. To fix this, first you must remove the location from Map Labs, then click on "Add New Profiles" and reconnect your Google account to Map Labs.'
        }
        break;
      case 'c_auth_not_found':
        data = {
          bg: '#FDF7E8',
          color: '#4B3900',
          icon: 'fas fa-exclamation-triangle',
          status: 'Issue',
          tooltip: 'This location was not found in GBP. Did you move it or delete it from GBP?'
        }
        break;
    }

    return data;
  }

  getABCStatusBadge(): IStatusBadge {
    return this._locationS.getABCStatusBadge(this.abcStatus);
  }

  async refreshLocation(): Promise<void> {
    this._snackBarS.openInfo('Your location data is being refreshed with Google. You may keep using the app.')
    const {gid} = this._sessionS.getSession();
    await this._locationS.locationRefreshAllDeps(this.accountId, this.locationId, gid);
  }

  async disconnectLocation(): Promise<void> {
    const domainCompany = this._sessionS.getDomain().branding.company_name;

    if(!await this._modalService.openConfirmModal(
      `Are you sure you want to disconnect ${this.currentLocation?.locationName} from ${domainCompany}?`, 
      `Note: this only disconnects the profile from ${domainCompany}; it does not delete your business profile from Google Maps. You can always re-add your business profile to ${domainCompany}.`)) {
      return;
    }
    
    const {gid} = this._sessionS.getSession();
     
    try {
      this._spinnerService.loading$.next(true);
      const accountWasDeleted = await this._locationS.deleteLocation(gid, this.accountId, this.currentLocation);
      if(accountWasDeleted) {
        await this._router.navigate(['/accounts']);
        return;
      } else {
        await this._router.navigate([`/accounts/${this.accountId}/locations`]);
        return;
      }

    } catch(err) {
      console.error('Error deleting location', err);
    } finally {
      this._spinnerService.loading$.next(false);
    }
  }
}
