// dep
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

// app
import { AccountService } from '../../services/account.service';
import { GoogleService } from '../../services/google.service';
import { GoogleAccountService } from '../../services/google-account.service';
import GoogleAccount from '../../constants/firestore/google-account';
// import { GoogleLocationService } from '../../services/google-location.service';
import { LocationService } from '../../services/location.service';
import SavedAccount from '../../constants/firestore/saved-account';
import { SnackbarService } from '../../services/snackbar.service';
import { ObservationService } from '../../services/observation.service';
import { Pageable } from '../../constants/pageable';
import { Pagination } from '../../constants/api-response';
import { SpinnerService } from '../../services/spinner.service';
import { ModalService } from '../../services/modal.service';
import { DialogLocationsComponent } from './dialog-locations.component';
import { AlertType } from '../../components/alert.component';
import { GroupService } from '../../services/group.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';


@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls:  ['./accounts.component.scss']
})
export class AccountsComponent extends BaseComponent implements OnInit {
  accounts: Observable<SavedAccount[]> = null;
  displayedColumns = ['name', 'locations', 'actions'];
  dl;
  da;
  loading_refresh_account$ = new BehaviorSubject(false);
  size = 25;
  page = 1;

  pagination: Pagination = {
    items: [],
    per_page: this.size,
    page: this.page,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  // public accountVerified = false;
  private _paginate: Pageable = { size: this.size, page: this.page };
  private _previousPageable: Pageable;

  public session$ = this._sessionS.session$;

  constructor(
    private _router: Router,
    public accountService: AccountService,
    private _sessionS : SessionService,
    private _dialog: MatDialog,
    private _googleAccountS: GoogleAccountService,
    private _locationService: LocationService,
    private _groupService: GroupService,
    private _google: GoogleService,
    private _cdr: ChangeDetectorRef,
    private _snack: SnackbarService,
    private _obsService: ObservationService,
    private _modalService: ModalService,
  ) {
    super();

    // this.subscription$.subscribe(subscription => {
    //   this.subscription = subscription;
    //   if (this.subscription?.status == GROUP_SUBSCRIPTION_TYPE.TRIAL && !this.accountVerified) {
    //     this.showAccountsMessage();
    //   }
    // });

    if (!this._sessionS.getSession().isAdmin) {
      this.displayedColumns = ['name'];
    }
    this._subscribeSafe(this._obsService.addAccount$, 
                        () => this.onAddNewAccount());
  }

  dataSource = new MatTableDataSource<SavedAccount[]>(null);
  // custom pagination elements => TODO, unused, remove.
  // manualPage: number;
  // errorMessage: boolean;

  @ViewChild(MatSort, { static: true }) sort: MatSort;


  ngOnInit() : void {
    this._locationService.setPaginate({ size: 10, page: 1 });

    this._cdr.detectChanges();
    this.accounts = this.accountService.accounts;
    this.accountService.pagination = this.pagination;
    this.accountService.previousPageable = this._previousPageable;
    this.dataSource.sort = this.sort;

    this._subscribeSafe(this.accounts,
      () => {
        this.pagination        = this.accountService.pagination;
        this._previousPageable = this.accountService.previousPageable;
      });

    this.dataSource.filterPredicate = (data, filter: string) => {
      const accumulator = (currentTerm, key) => {
        return this._nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
    // this.manualPage = 1;
    // this.errorMessage = false;

    this._getData(this._paginate);
  }

  // TODO: Unused, remove?
  // showAccountsMessage() {
  //   // const paginate: Pageable = { size: 5, page: 1 };
  //   this.accountVerified = true;
  //   this.accounts?.subscribe(acc => {
  //     // check if no accounts message should be seen
  //     this._cdr.detectChanges();
  //   })
  // }

  async enableNotifications(accountId : string): Promise<void> {
    if(await this._modalService.openConfirmModal('Enable Notifications', 'Are you sure want you enable notifications?'))
      try {
        await this.accountService.enableNotifications(accountId)
        this._snack.openSuccess('The notifications were successfully enabled.');
      } catch(err) {
        this._snack.openError(err.message);
      }
  }

  async openReauth(account : SavedAccount) : Promise<void> {
    if (account.googleAuthEmailAddress) {
      const dialogRef = this._dialog.open(DialogAccountReauthComponent, {
        width: '680px',
        data: {
          account
        }
      });

      dialogRef.componentInstance.onAccept
        .pipe(take(2))
        .subscribe(async r => {
          if (r) {
            await this.onAddNewAccount(account.accountId);
          }
        });
    } else {
      await this.onAddNewAccount(account.accountId);
    }
  }

  private async _getData(_event: Pageable): Promise<void> {
    try {
      const s = this._sessionS.getSession();
      const f = s.features
      const group = s.group

      const result = await this.accountService.loadAll(s.user, this._paginate)
      if (!result['items'].length && !group.autoAddLocationsTriggered && f.final.autoAddLocationsTrigger) {
        if(await this._modalService.openConfirmModal(
           'Attention: Redirecting you to Google Business Profile Login',
           'After clicking Continue, please ensure you select the account you use for managing your Google Business Profiles',
           null,
           AlertType.INFO,
           'Continue'))
          await this.onAddNewAccount()

        this._groupService.updateGroup(s.gid, { autoAddLocationsTriggered: true })
      }
    } catch (e) {
      console.error(e)
      this._snack.openError('There was an error while loading the data. Please try again or contact support')
    }
  }

  async onAddNewAccount(accountId = null) : Promise<void> {
    this._dialog.closeAll();
    this.da = null;
    this.dl = null;

    this._subscribeSafe(this._googleAccountS.onLoadAll$,
      ev => {
        if (ev.success !== true) {
          this._snack.openError(ev.message);
          return
        }

        // Everything looks good...
        // Prevent multiple dialogs from being opened
        if(!this.da)
          this._openAddAccountsDialog(accountId);
      });

    // TODO: Must refactor as an high level flow against all callers of google.authenticate
    try {
      this._snack.openInfo(`A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings`)
      const {uid, gid} = this._sessionS.getSession();
      const url = await this._google.authenticate(gid, uid);
      // Note, window.open can return null if the window failed to open (maybe popup-blocker is enabled)
      const oauth = window.open(url, '_blank');
      // This popup ends up being a redirection so we cannot detect the real close event.
      // So we use an interval trick to overcome this.
      const popupTick = setInterval(() => {
        if (oauth?.closed) {
          clearInterval(popupTick);
          // TODO: Must wait before loadAll as is not sure the oauth flow impacted firestore?
          // race condition here?
          this._googleAccountS.loadAll();
        }
      }, 1000);

    } catch (e) {
      const message = 'There was an error with the GBP Authentication service';
      console.error(message, e);
      this._snack.openError(message);
    }
  }

  onDeleteAccount(account): void {
    const dialogRef = this._dialog.open(DialogDeleteAccountComponent, {
      width: '680px',
      data: {
        account
      }
    });

    dialogRef.disableClose = true;

    this._subscribeSafe(dialogRef.componentInstance.onDelete,
      () => {
        this._snack.openSuccess('The account was successfully deleted.');
        this.ngOnInit();
      });
  }


  // TODO: Unused, remove?
  // // apply filter from search
  // applyFilter(filterValue: string) : void {
  //   this.dataSource.filter = filterValue;
  //
  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }

  // check for nested objects
  private _nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this._nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  private _openAddAccountsDialog(accountId = null): void {
    // TODO: Replace for modal service
    this.da = this._dialog.open(DialogAccountsComponent, {
      width: '680px',
      data: { googleAccounts: this._googleAccountS.accounts$, 
              accountId: accountId },
      autoFocus: false
    });

    this._subscribeSafe(this.da.backdropClick(),
      () => {
        this._dialog.closeAll();
        this.da = null;
      });

    this._subscribeSafe(this.da.afterClosed(),
     (selectedAccountId : string) => {
        this._dialog.closeAll();
        //this.getData(this.paginate);
        if (!selectedAccountId) {
          this.da = null;
          return;
        }

        if (accountId) {
          this.da = null;
          this._dialog.closeAll();
          return;
        }

        this.dl = this._dialog.open(DialogLocationsComponent, {
          width: '680px',
          data: { selectedAccountId, 
                  googleAccounts: this._googleAccountS.accounts$ }
        });

        // Destroy the dialogs on close to get clean data.
        this._subscribeSafe(this.dl.afterClosed(),
          data => {
            this.dl = null;
            this.da = null;
            if (data === 'back') {
              this._openAddAccountsDialog(null);
            } else if (data !== '') {
              const accountId = selectedAccountId?.split('/')[1];
              this._router.navigate(['accounts', accountId, 'locations']); //, 'locations'
            }
            this._getData(this._paginate);
          });
      });

  }

  handleReload($event: Pageable) : void {
    this._paginate = $event;
    this._getData($event);
  }


  goLocation(element) : void {
    let errorAccount = true;
    if (element?.gauthStatus?.isValid) {
      errorAccount = !element?.gauthStatus?.isValid
    }
    this._router.navigate(['/accounts/', element.id || element.accountId, 'locations'], { queryParams: { errorAccount } });
  }

  async refresh(element) : Promise<void> {
    if(this.loading_refresh_account$.getValue()) {
      this._snack.openInfo("Please wait while we update your account");
      return;
    }

    this.loading_refresh_account$.next(true);
    const elementHtml = document.getElementById(`spin-${element.accountId}`);
    elementHtml.classList.add('active-spin');
    this._snack.openInfo("Account locations are being refreshed. This may take several minutes");

    // FIXME: Why fixed timeout?
    setTimeout(() => {
      elementHtml.classList.remove('active-spin');
    }, 2500);

    try {
      const r = await this.accountService.refreshAccountLocations(element);
      if (r.data.status === 'COMPLETE') {
        this._snack.openSuccess(r.data.response);
      } else if (r.data.status === 'FAIL') {
        throw r;
      }
    } catch (e) {
      console.error(e);
      this._snack.openError('The operation has been failed')
    } 

    this.loading_refresh_account$.next(false);
  }
}

// Modal "Select an account"
@Component({
  selector: 'app-dialog-accounts',
  templateUrl: 'dialog-accounts.html',
})
export class DialogAccountsComponent extends BaseComponent {
  googleAccounts: GoogleAccount[];
  selectedAccount: string;
  // onAccountSelected = new EventEmitter(); // TODO: Unused, remove
  search: string;
  resultSearchAccount: GoogleAccount[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { accountId : string, googleAccounts : Observable<GoogleAccount[]>},
    public dialogRef: MatDialogRef<DialogAccountsComponent>,
    private _router: Router,
    private _accountService: AccountService,
    private _sessionS: SessionService,
    private _snack: SnackbarService
  ) {
    super();
    this.selectedAccount = null;
    this._subscribeSafe(this.data.googleAccounts, accounts => {
      this.googleAccounts = accounts;
      this.resultSearchAccount = accounts;

      if (this.data.accountId != null) {
        this.close();
        this._accountService.updateGauth(this._sessionS.getSession().gid, this.data.accountId).then(res => {
          this._router.navigate(['accounts', this.data.accountId, 'locations']);
          this._snack.openSuccess('Your account has been re-authenticated!');
        });
      }
    });


  }

  close() : void {
    this.dialogRef.close();
  }

  addAccount(): void {
    this.dialogRef.close(this.selectedAccount);
  }

  filterAccount() : void {
    this.resultSearchAccount = this.googleAccounts.filter(account => account.accountName.toLowerCase().includes(this.search.toLowerCase()));
  }
}

// Delete Dialog
@Component({
  selector: 'app-modal-delete',
  templateUrl: 'dialog-delete-account.html',
})
export class DialogDeleteAccountComponent {
  public account;
  public onDelete = new EventEmitter<boolean>();

  public domain$ = this._sessionS.domain$

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,

    public dialogRef: MatDialogRef<DialogDeleteAccountComponent>,
    private _accountService: AccountService,
    private _locationService: LocationService,
    private _sessionS : SessionService,
    private _spinnerService: SpinnerService,
  ) {
    this.account = data.account;
  }

  async handleDeleteAccount() : Promise<void> {
    const {gid}       = this._sessionS.getSession();
    const {accountId} = this.account;

    try {
      this._spinnerService.loading$.next(true)            
      const locations = await this._locationService.fetchAccountLocations(gid, accountId);

      // TODO: Removing account locations should be backend responsibility 
      // on a DELETE /account enpdoint
      // Note that maybe the account has an empty location list. 
      for(const loc of locations) {
        await this._locationService.deleteLocation(gid, accountId, loc,
                                                   {notifyChange : false, deleteEmptyAccount : false});
      }
      // All Account locations deleted, delete account
      await this._accountService.delete(gid, accountId);
      this._locationService.notifySomeLocationChanged();

    } catch(err) {
      console.error(`Error deleting account gid=${gid} accountId=${accountId}`, err);
    } finally {
      await this._sessionS.refresh();
      this.onDelete.emit(true);
      this.dialogRef.close();
      this._spinnerService.loading$.next(false)
    }
  }

}

// Delete Dialog
// TODO: Replace with openConfirmDialog
@Component({
  selector: 'app-modal-reauth',
  templateUrl: 'dialog-account-reauth.html',
})
export class DialogAccountReauthComponent {
  public account;
  onAccept = new EventEmitter(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    public dialogRef: MatDialogRef<DialogDeleteAccountComponent>,
  ) {
    this.account = data.account;
  }

  ReAuth() : void {
    this.onAccept.emit(true);
  }

}
