import { OnInit, Inject, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Category, ServiceList } from '../../constants/google/service-list';
import { ModalServiceItemComponent } from '../modal-service-item/modal-service-item.component';


@Component({
  selector: 'app-modal-add-service',
  templateUrl: './modal-add-service.component.html',
  styleUrls: ['./modal-add-service.component.scss']
})
export class ModalAddServiceComponent implements OnInit {
  serviceTypesOptions = new FormControl();
  category: Category;
  addedServices: ServiceList[] = [];
  filteredOptions: Observable<string[]>;
  item: Partial<ServiceList> = null;
  position: number;

  constructor(
    public dialogRef: MatDialogRef<ModalAddServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
  ) {
    if (this.data) {
      this.category = this.data.category;
      this.addedServices = this.data.addedServices;
      this.position = this.data.position;
    }
  }

  ngOnInit() {
    this.filteredOptions = this.serviceTypesOptions.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(val: string): string[] {
    return this.category.serviceTypes.map(x => x.displayName).filter(option => option.toLowerCase().includes(val.toLowerCase()));
  }

  continue(data: string | Category, isFreeForm) {
    if (isFreeForm) {
      const transform = data as Category;
      this.item = {
        isOffered: true,
        price: {
          currencyCode: '',
          nanos: 0,
          units: ''
        },
        freeFormServiceItem: {
          categoryId: transform.categoryId,
          category: '',
          label: {
            description: '',
            displayName: '',
            languageCode: 'en'
          }
        }
      }
    } else {
      const transform = data as string;
      let service = this.category.serviceTypes.find(x => x.displayName ===  transform)
      this.item = {
        isOffered: true,
        price: {
          currencyCode: '',
          nanos: 0,
          units: ''
        },
        structuredServiceItem:  {
          description: '',
          serviceTypeId: service.serviceTypeId
        }
      }
    }

    const dialog = this.dialog.open(ModalServiceItemComponent, {
      width: '480px',
      panelClass: 'overflow--visible',
      data: {
        item: this.item,
        category: this.category,
        addedServices: this.addedServices,
        position: this.position
      }
    });

    dialog.afterClosed().subscribe( (result) => {
      if (result) {
        this.dialogRef.close(result)
      }
    })
  }

  isActive(option): boolean {
    return !!this.addedServices.find(x => x.displayName === option);
  }
}
