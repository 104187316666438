// dep
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatPaginator } from '@angular/material/paginator';
import firebase from 'firebase/app';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// app
import User from '../constants/firestore/user';
import { FirestoreService } from './firestore.service';
import { GROUPS, USER } from '../constants/firestore/collections';
// import { Pageable } from '../constants/pageable';
import { environment as ENV } from '@environment';
import { HEADERS_NO_AUTH } from '../constants/auth'
import { UserFeatures } from '../constants/user-features';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private dataStore: { accounts: User[] } = { accounts: [] }
  
  private _users = new BehaviorSubject<User[]>([])
  public loading = false;
  paginator: MatPaginator;

  constructor(
    private _http: HttpClient,
    private _afs: AngularFirestore,
    private _fs: FirestoreService) {
  }

  deleteUser(gid : string, uid: string): Promise<void> {
    return this._http.delete<void>(`${ENV.apiUrl}/v2/groups/${gid}/user/${uid}`).toPromise()
  }

  addUserToGroup(gid : string, body): Observable<Object> {
    return this._http.post(`${ENV.apiUrl}/v2/groups/${gid}/user`, body)
  }


  get users(): Observable<User[]> {
    return this._users.asObservable();
  }

  setUsers(users: User[]) : void {
    this._users.next(users);
  }


  private _userRef(gid : string, uid: string) : AngularFirestoreDocument<User> {
    return this._afs.collection(GROUPS).doc(gid).collection(USER).doc<User>(uid);
  }


  async getUserByUid(uid: string) : Promise<User | null>{
    // This searchs for the user under ALL the __user collections, so, all the 
    // groups are searched. 
    const r = await this._afs.collectionGroup<User>(USER, ref => ref.where('uid', '==', uid)).get().toPromise()
    if(!r.docs.length)
      return null
    return r.docs[0].data() as User
  }

  async fetchUser(gid : string, uid : string) : Promise<User | null> {
    return (await this._userRef(gid, uid).get().toPromise()).data() as User || null
  }


  // TODO: Unused, remove
  // getByEmail(email: string) {
  //   return this.afs.collectionGroup<User>(USER, ref => ref.where('email', '==', email)).valueChanges();
  // }


  /**
   * Save in firestore entity User
   */
  // TODO: Direct FS mutation, this must be replaced with a backend endpoint
  async save(user: User) : Promise<void> {
    await this._userRef(user.gid, user.uid).set(user);
  }

  async updateLastLogin(gid : string, uid : string, lastLoginDate : Date, lastLoginXDomain : string) : Promise<void> {
    // TODO: Date assigned on client side, must be done in the backend
    await this.updateUser(gid, uid, { lastLogin       : firebase.firestore.Timestamp.fromDate(lastLoginDate),
                                      lastLoginDomain : lastLoginXDomain });
  }


  // TODO: Unused, remove
  /**
   * this method delete user entity in firestore
   * @param uid  this doc is key in firestore
   */
  // TODO: Direct FS mutation, this must be replaced with a backend endpoint
  // delete(gid : string, uid : string) {
  //   return this.afs.collection(GROUPS).doc(gid).collection(USER).doc(uid).delete();
  // }

  loadAll(gid : string) : void {
    this.loading = true;
    const query = this._afs.collection(GROUPS).doc(gid).collection(USER, ref => ref.where('gid', '==', gid)
      .orderBy('role', 'asc')
      .orderBy('displayName', 'asc')
      .limit(this.paginator.pageSize)).ref;

    this.paginator.pageIndex = 1;

    this._fs.colWithIds$<User>(USER, ref => query).subscribe(data => {
      this.dataStore.accounts = data;
      this._users.next(Object.assign({}, this.dataStore).accounts);
      this.loading = false;
    });
  }

  // TODO: Unused, remove
//   filter(user: User, filter?: any) {
//     this.loading = true;
//     let query; 
//     if (_.has(filter, 'sortBy')) {
//       query = this.afs.collectionGroup(USER, ref => ref.where('registrationDomain', 'in', [filter.domain, filter.domain + ":"])
//         .orderBy(filter.sortBy, filter.direction));
//     } else {
//       query = this.afs.collectionGroup(USER, ref => ref.where('registrationDomain', 'in', [filter.domain, filter.domain + ":"])
//         .orderBy('email', 'asc'));
//     }
// 
//     this.afs.collectionGroup(USER, ref => query).get().subscribe(users => {
//       const result = [];
//       users.docs.forEach(u => {
//         result.push(u.data());
//       });
//       this.dataStore.accounts = result;
//       this.paginator.length = this.dataStore.accounts.length;
//       this._users.next(Object.assign({}, this.dataStore).accounts);
//       this.loading = false;
//     });
//   }

// TODO: Unused, remove
//
//   getUsersByGroup(groupId: string) {
//     const query = this.afs.collection(GROUPS).doc(groupId).collection(USER, ref => ref.where('gid', '==', groupId)
//       .orderBy('email', 'asc')).ref;
// 
//     return this.fs.colWithIds$<User>(USER, ref => query);
//   }


  /**
   * Update user entity in firestore
   */
  // TODO: Direct FS mutation, this must be replaced with a backend endpoint
  async updateUser(gid : string, uid : string, data: Partial<User> ) : Promise<void> {
    // Update fields but will fail if the document doesn't exist
    await this._userRef(gid, uid).update(data);
  }

  getUserChangesStream(gid : string, uid : string) : Observable<User> {
    return this._userRef(gid, uid).valueChanges();
  }

  // TODO: Unused, remove
  //
  // countUsers() {
  //   return this.afs.collectionGroup(USER).valueChanges().pipe(map(r => r.length));
  // }
  //
  // getUsers(pageable: Pageable = {
  //   size: 10,
  //   page: 1
  // }, domain, next, prev, order = 'createdAt', direction = 'desc'): Observable<any> {
  //   return this.fs.paginateUsers(USER, domain, order, direction, pageable, next, prev);
  // }
  // 
  // getAllUsers() {
  //   return this.afs.collectionGroup(USER).get().map(query => query.docs.map(doc => doc.data()));
  // }
  // 
  // getUsersPaginate(count, pageable, actions) {
  //   return this.fs.formatPagination(count, pageable, actions);
  // }

  async getEmailIsVerified(user: User) : Promise<boolean> {
    const u = (await this.fetchUser(user.gid, user.uid))
    return (u.emailVerificationHash === null) || (u.emailVerified !== null)
  }

  async getUserFeature(uid : string): Promise<UserFeatures> {
    return await this._http.get<UserFeatures>(`${ENV.apiUrl}/v2/users/${uid}/enabled-features`).toPromise()
  }

  domainValidation(domain : string, gid : string, uid : string, domainSurfing : boolean) : 
                   Promise<{allowLogin : boolean, domain : string}> {
    const body = { domain, gid, uid, domainSurfing }

    return this._http.post<any>(`${ENV.apiUrl}/v2/auth/domain-validation`, body, HEADERS_NO_AUTH).toPromise();
  }


}
