<form [formGroup]="form">
  <div class="dialog__header">
    <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{!isEdit ? 'Add ' : 'Edit '}}  service details</strong></h2>
  </div>
  <div class="dialog__content" mat-dialog-content>
    <div class="dialog-row pb--0">
      <div class="row">
        <div class="col-12">
          <ng-container *ngIf="structuredItem">
            <ng-container *ngIf="structuredItem.isFreeFormServiceItem; else structuredService">
              <mat-form-field class="input-field">
                <input matInput placeholder="Service" [formControl]="name" name="itemName">
                <mat-error *ngIf="name.errors">
                  <p *ngIf="name.hasError('required')" >Name is required</p>
                  <p *ngIf="name.hasError('duplicated')" >This service item already exists. Service item name must be unique.</p>
                </mat-error>
              </mat-form-field>
            </ng-container>
            <ng-template #structuredService>
              <small>Service</small>
              <h4 style="color: #000; font-size: 16px;margin: 8px 0 16px 0;width: 100%;">{{ structuredItem.displayName}}</h4>
            </ng-template>
          </ng-container>


        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field class="input-field">
            <mat-select placeholder="Type price" [formControl]="typePrice" name="TypePrice">
              <mat-option value="noPrice">No price</mat-option>
              <mat-option value="free">Free</mat-option>
              <mat-option value="fixed">Fixed</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="typePrice.value !== 'free' && typePrice.value !== 'noPrice'">
        <div class="col-12 col-md-6">
          <mat-form-field class="input-field">
            <mat-select [formControl]="currency" placeholder="Currency">
              <mat-option value="USD">USD</mat-option>
              <mat-option value="EUR">EUR</mat-option>
              <mat-option value="GBP">GBP</mat-option>
              <mat-option value="AUD">AUD</mat-option>
              <mat-option value="CAD">CAD</mat-option>
            </mat-select>
            <mat-error *ngIf="units.errors">
              <p *ngIf="units.hasError('required')">Currency is required.</p>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
          <mat-form-field class="input-field">
            <input matInput [placeholder]="'Item Price (' + currency.value + ')'" [formControl]="units" step="0.01"  name="itemPrice">
            <mat-error *ngIf="units.errors">
              <p *ngIf="units.hasError('required')">Units is required.</p>
              <p *ngIf="units.hasError('pattern')">The input must be a number and have up to two decimals.</p>
              <p *ngIf="units.hasError('min')">The minimum price is 0.</p>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <mat-form-field class="input-field">
            <textarea matInput placeholder="Description" [formControl]="description" name="itemDescription" rows="4"></textarea>
            <mat-hint align="end">{{description.value.length }} / 300</mat-hint>
            <mat-error *ngIf="description.hasError('required')"><p>Description is required.</p></mat-error>
          </mat-form-field>
        </div>
      </div>

    </div>
  </div>
  <div mat-dialog-actions class="dialog__footer">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <div>
      <button mat-flat-button (click)="remove()" tabIndex="-1" color="primary" class="btn btn--action mr--10" *ngIf="isEdit">Remove</button>
      <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
    </div>
  </div>
</form>
