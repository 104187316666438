import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { PostService } from '../../services/post.service';
import { Post, SchedulePost, validatePlaceholder } from '../../constants/post';
import { DatesService } from '../../services/dates.service';
import { SnackbarService } from '../../services/snackbar.service';
import AccountReport from '../../services/account-report';
import { ModalService } from '../../services/modal.service';
import { ModalSchedulePostComponent } from '../modal-schedule-post/modal-schedule-post.component';
import { FormPostComponent } from '../form-post/form-post.component';
import * as moment from 'moment'
import { LocalPost, BulkPost, Recurring } from '../../v3.models/posts.models';
import * as constants from '../../constants/post'
import { getNextReccurringDate } from 'src/app/helpers/functions.helpers';
import { AlertType } from 'src/app/components/alert.component';
import { BaseComponent } from 'src/app/components/base.component';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-modal-local-posts',
  templateUrl: `./modal-local-posts.component.html`,
  styleUrls: ['./modal-local-posts.component.css'],
})
export class ModalLocalPostsComponent extends BaseComponent implements OnInit, OnDestroy {
  locationId: string;
  selectedIndex = 0;
  private oneData: string;
  private twoData: any;
  private threeData: any;
  private fourData: string;
  private topic: string;
  edit = false;
  bulkPost: boolean;
  locationShow = false; // Show location toogle
  public accountsSelected: AccountReport[] = [];
  validFormPost = false;
  private postFormValue: any;
  public schedule: SchedulePost;
  public recurring: Recurring | null = null;
  isRecurringValid:boolean;
  publishNow = false;
  duplicatePostAcceptance = false;
  public type = ""; // status of the post ('DRAFT', 'SCHEDULED')
  public progress = false;

  eventStartDate: moment.Moment = null
  eventEndDate: moment.Moment = null

  touched = false;
  validatePlaceholder = false;
  locationsWithoutValidate: {locationName: string, address: any, placeholders: string[]}[] = [];
  private accountId: string;
  isPosted: boolean;
  formData = {};
  @ViewChild('form') 
  form: FormPostComponent;
  disablePostingButton = false
  nextRecurringDate: Date;
  planningDates$ = new BehaviorSubject<{start: Date, end: Date}[]>([])

  constructor(
    private postService: PostService,
    private dateS: DatesService,
    private _sessionS: SessionService,
    private snackS: SnackbarService,
    private modalS: ModalService,
    public dialogRef: MatDialogRef<ModalLocalPostsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      width: string,
      post: LocalPost | BulkPost,
      locationId: string,
      posts?: boolean,
      accountId: string,
      accounts: AccountReport[],
      type: string,
      schedule: SchedulePost,
      recurring: Recurring,
      post_type: 'Posted' | 'Pending'| 'Drafts' | 'Archive'
    },
  ) {
    super();
    this.planningDates$.asObservable()
    dialogRef.disableClose = true
    if (this.data.posts && this.data.posts !== undefined) {
      this.bulkPost = true; // Indicate if a bulk post
      this.accountsSelected = this.data.accounts;
    }
    if (this.data.post && this.data.posts !== undefined) {
      this.type = this.data.type ? this.data.type : null;
      this.isPosted = (this.data.post.status === 'PUBLISH')
      this.edit = true;
      if (_.has(this.data.post, 'status') && _.isEqual(_.get(this.data.post, 'status'), 'DRAFT')) {
        this.data.post = _.omit(_.merge(this.data.post, { ..._.get(this.data.post, 'post') }), ['post']);
      }
      if (this.data.post.postInfo.topicType) {
        this.setView(this.data.post.postInfo.topicType)
      }

      if (data.post.isScheduled)  this.schedule = data.post.scheduled
      if (data.post.isRecurring)  this.recurring = data.post.recurring
    }
  }

  ngOnInit(): void {
    this.locationId = this.data.locationId;
    this.accountId = this.data.accountId;
  }

  async apply(type: string = null, ignorePlaceholder = false): Promise<void> {
    this.progress = true;
    this.touched = true;
    this.type = type;

    if (!this.locationShow) {
      const form = this.form.getData();
      this.validate(form.valid);
      this.topic === 'ALERT' && (this.validFormPost = this.validateForm(this.validFormPost, this.postFormValue, true)) || (this.validFormPost = this.validateForm(this.validFormPost, this.postFormValue, true));
      this.postFormValue = form.value;
    }


    if (!this.postFormValue || !this.validFormPost) {
      this.touched = true;
      return;
    }
    const post = this.prepareToSave(this.postFormValue);
    if (!post) {
      this.progress = false;
      return;
    }

    if (_.isNil(this.type) && !this.schedule && !this.recurring) {
      let placeholders = post.summary.match(/%(\w+)%/gi);
      let validate_entries = `${post.summary}`;
      if ('callToAction' in post) {
        if ('url' in post.callToAction) {
          const placeholders_call = post.callToAction.url.match(/%(\w+)%/gi);
          if (placeholders_call) {
            if (placeholders) {
              placeholders = Object.assign(placeholders_call, placeholders);
            }
            else {
              placeholders = Object.assign([], placeholders_call);
            }
            validate_entries += `${post.callToAction.url}`;
          }
        }
        if (placeholders) {
          placeholders = validatePlaceholder(placeholders);
        }
      }
      if (placeholders && placeholders.length > 0 && !ignorePlaceholder) {
        this.locationsWithoutValidate = await this.postService.validate_placeholders(this.accountsSelected, validate_entries).toPromise();
        if (this.locationsWithoutValidate && this.locationsWithoutValidate.length > 0) {
          this.invalidPlaceHolder()
        } else {
          this.locationsWithoutValidate = [];
          this.save(post);
        }
      } else {
        this.save(post);
      }
    } else if (this.type || this.schedule || this.recurring) {
      post.status = "DRAFT"
      this.savePostManagement(this._sessionS.getSession().gid, this.accountId, this.locationId, post, this.type, this.schedule);
    }
  }

  save(data: Post): void {
    if (this.disablePostingButton) return

    this.disablePostingButton = true
    if (this.bulkPost) {
      data['gid'] = this._sessionS.getSession().gid;
      data['accounts'] = this.accountsSelected;
    }
    const redirectTo =  'Pending';
    const type = this.bulkPost ? 'BULK' : 'LOCAL'
    if (this.edit) {
      if (this.data.post.id) {
        const postId = this.data.post.id;
        data.status = this.isPosted ? constants.POST_STATUS_PENDING_UPDATE: constants.POST_STATUS_PENDING
        this.postService.updateV3( postId, data, type, redirectTo);
        this.progress = false;
        this.modalS.openConfirmModal('Heads up', 'This will take a few moments to complete. See the Pending tab for status updates', () => this.dialogRef.close())
      }
    } else {
      if (data) {
        data.status = constants.POST_STATUS_PENDING
        this.postService.saveV3(this.accountId, this.locationId, data, type, redirectTo).then();
        this.progress = false;
        this.modalS.openConfirmModal('Heads up', 'This will take a few moments to complete. See the Pending tab for status updates', () => this.dialogRef.close())

      }
    }

  }

  invalidPlaceHolder(){
    this.validatePlaceholder = true;
    this.back()
    this.back()
    this.modalS.openAlertModal(
      'Invalid placeholder',
      `The placeholder that was used does not exist on one of your locations`,
      AlertType.ERROR
    );
    this.progress = false;
  }

  private savePostManagement(gid, accountId, locationId, post: Post, status = null, schedule) {
    if (this.disablePostingButton) return

    this.disablePostingButton = true

    if (!status) {
      status = 'PENDING';
    }
    post.status = status;
    post.gid = this._sessionS.getSession().gid;

    if (this.locationId) {
      post.locationId= this.locationId;
      post.accountId= this.locationId;
    }

    if (this.accountsSelected) {
      post.accounts = this.accountsSelected;
    } else {
      post.accounts = [];
    }


    if (schedule) {

      if (status === constants.POST_STATUS_PENDING) {
        post.status = constants.POST_STATUS_SCHEDULED;
      }
      post.scheduled = schedule;
      post.recurring = null

    } else if (this.recurring) {

      if (status === constants.POST_STATUS_PENDING) {
        post.status = constants.POST_STATUS_RECURRING;
      }
      post.recurring = {...this.recurring};
      post.scheduled = null;

    } else if (this.data?.post?.scheduled) {
      post.scheduled = schedule;
      if (status !== constants.POST_STATUS_SCHEDULED) post.scheduled = null
    } else if (this.data?.post?.recurring) {
      post.recurring = {...this.recurring};
      if (status !== constants.POST_STATUS_RECURRING) post.recurring = null
    }

    if (this.publishNow) {
      post.recurring.nextDate = this.nextRecurringDate;
    }
    const type = this.bulkPost ? 'BULK' : 'LOCAL'
    const redirectTo = status === 'DRAFT'  ? 'Drafts' : 'Pending';
    if (this.edit) {
      //this.data.post.hasError && (post.las = null);
      if (this.data.post.id) {
        this.postService.updateV3(this.data.post.id, post, type, redirectTo, this.publishNow).then();
      }
      this.modalS.openConfirmModal('Heads up', 'This will take a few moments to complete. See the Pending tab for schedules or recurring post and the Drafts tab for your draft post', () => this.dialogRef.close())
    } else {
      this.postService.saveV3(accountId, locationId, post, type, redirectTo, this.publishNow).then();
      this.modalS.openConfirmModal('Heads up', 'This will take a few moments to complete. See the Pending tab for schedules or recurring post and the Drafts tab for your draft post', () => this.dialogRef.close())
    }
  }

  getWeeklyNextDate(currentDate: Date, recurring: Recurring) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek > Number(recurring.repeatOn)) {
      const daysToDate =  7 - (dayOfWeek - Number(recurring.repeatOn));
      return moment(new Date(year, month, day + daysToDate )).utc().toDate();
    } else {
      const daysToDate = Number(recurring.repeatOn) - dayOfWeek;
      return moment(new Date(year, month, day + daysToDate )).utc().toDate();
    }
  }

  cleanRecurring(): void {
    this.recurring = null;
  }

  validate(validForm = false): void {
    let data = null;
    this.validFormPost = false;

    switch (this.selectedIndex) {
      case 0:
        this.topic = 'STANDARD';
        if (this.validateForm(validForm, this.oneData, false)) {
          data = this.oneData;
          this.validFormPost = validForm;
        }
        break;
      case 1:
        this.topic = 'EVENT';
        if (this.validateForm(validForm, this.twoData, false)) {
          data = this.twoData;
          this.validFormPost = validForm;
        }
        break;
      case 2:
        this.topic = 'OFFER';
        if (this.validateForm(validForm, this.threeData, false)) {
          data = this.threeData;
          this.validFormPost = validForm;
        }
        break;
      case 3:
        this.topic = 'ALERT';
        if (this.validateForm(validForm, this.fourData, false)) {
          data = this.fourData;
          this.validFormPost = validForm;
        }
        break;
    }

    if (data) {
      this.postFormValue = data;
    }
  }


  prepareToSave(form): Post {

    const post: Post = {
      media: [],
      languageCode: 'en-US',
      summary: form.description,
      topicType: this.topic,
      offer: form.offer,
      state: 'PROCESSING'
    };

    if (form.image) {
      post.media = form.image.map( image => ({mediaFormat: 'PHOTO', sourceUrl: image}) )
    }

    if (this.topic === 'EVENT' || this.topic === 'OFFER') {
      post.event = {
        title: form.title,
        schedule: {
          startDate: this.dateS.dateToJsonDate(form.startDate),
          endDate: this.dateS.dateToJsonDate(typeof(form.endDate) == 'number'? form.startDate : form.endDate),
          endTime: form.endTime && this.dateS.timeToJsonTime(form.endTime) || {},
          startTime: form.startTime && this.dateS.timeToJsonTime(form.startTime) || {}
        }
      };
      if (_.isEmpty(post.event.schedule.startTime) || _.isEmpty(post.event.schedule.endTime)) {
        delete post.event.schedule.startTime;
        delete post.event.schedule.endTime;
      }
    }

    if (this.topic !== 'OFFER') {

      if (form.type) {
        post.callToAction = {actionType: form.type, url: form.url};
      }

      if (form.type === 'CALL' && this.locationId) {
        delete post.callToAction.url;
      }
      delete post.offer;
    }

    if (this.topic === 'ALERT') {
      post.alertType = 'COVID_19';
      delete post.media;
      delete post.offer;
    }

    return post;
  }

  validateForm(isValid: boolean, form, snack?: boolean): boolean {
    if (isValid == false) {
      if (snack) {

        this.snackS.openError('Invalid post', 4000);
      }
      this.progress = false;
      return false;
    }

    if (!form) {
      return;
    }

    /*if (!form.image && !isAlert) {
      if (snack) {
        this.snackS.openError('Image post is required', 4000);
      }

      return false;
    }*/

    /* if (this.topic === 'OFFER' || this.topic === 'EVENT') {
      if (form.startDate > form.endDate) {
        return false;
      }
    } */
    return true;
  }

  /**
   * this method select topic type event
   * @param $event
   */
  handleData($event: any): void {
    this.touched = false;
    switch (this.selectedIndex) {
      case 0:
        this.oneData = $event.value;
        break;
      case 1:
        this.twoData = $event.value;
        this.eventStartDate = this.twoData.startDate && this.twoData.startDate !== "" ? this.twoData.startDate : null;
        this.eventEndDate = this.twoData.endDate && this.twoData.endDate !== "" ? this.twoData.endDate : null;
        break;
      case 2:
        this.threeData = $event.value;
        this.eventStartDate = this.threeData.startDate && this.threeData.startDate !== "" ? this.threeData.startDate : null;
        this.eventEndDate = this.threeData.endDate && this.threeData.endDate !== "" ? this.threeData.endDate : null;
        break;
      case 3:
        this.fourData = $event.value;
        break;
    }
    this.formData = $event.value;
    this.validate($event.valid);
  }


  chooseLocations(): void {
    const form = this.form.getData();
    this.validate(form.valid);
    if (this.topic === 'ALERT') {
      this.validFormPost = this.validateForm(this.validFormPost, this.postFormValue, true);
    }
    this.postFormValue = form.value;
    this.locationShow = !this.locationShow;
  }

  handleLocations($event: AccountReport[]): void {
    this.accountsSelected = $event;
  }


  openSchedule(): void {
    this.modalS.openGenericModal(ModalSchedulePostComponent,
      {schedule: this.schedule, recurring: this.recurring, postType: this.selectedIndex, publishNow: this.publishNow },
      (result: {recurring: boolean, data: any, publishNow: boolean, duplicate: boolean}) => {
        if (result) {
          if (result.recurring) {
            this.schedule = null
            this.recurring = result.data;
            this.type = 'RECURRING';
            this.publishNow = result.publishNow
            this.duplicatePostAcceptance = result.duplicate;
          } else {
            this.recurring = null
            this.schedule = result.data as SchedulePost;
            this.type = 'SCHEDULE';
          }
        }
    }, 470);
  }

  /**
   * close subscriptions
   */
  ngOnDestroy(): void {
    this.data = null;
    this.accountsSelected = null;
    this.validFormPost = false;
    super.ngOnDestroy();
  }


  changeTab($event): void {
    this.validFormPost = false;
    this.touched = false;
    const form = this.form?.getData();
    this.validate(form.valid);
    this.validFormPost = form.valid;
  }

  setView(type: string): void {
    switch (type) {
      case 'STANDARD':
        this.selectedIndex = 0;
        break;
      case 'EVENT':
        this.selectedIndex = 1;
        break;
      case 'OFFER':
        this.selectedIndex = 2;
        break;
      case 'ALERT':
        this.selectedIndex = 3;
        break;
    }
  }

  back(): void {
    if (!this.validatePlaceholder) {
      this.locationShow = !this.locationShow;
    } else {
      this.validatePlaceholder = false;
      this.locationShow = true;
      this.locationsWithoutValidate = [];
    }
  }

  checkForErrors(data): void {
    const hasError = _.get(data, 'lastError', null);
    if (hasError) {
      this.snackS.openInfo('There was a error during the post, check the pending tab', 4000);
    }
  }

  changeRecurring(checked:boolean){
    if(!checked){
      this.recurring = null;
      return;
    }

    this.schedule = null;
    this.recurring = {
      time:"00:01",
      frecuency:'WeeklyOn',
      isAllMonth: false,
      repeatOn: "1",
      nextDate: new Date(),
      duration: 1
    };
    this.nextRecurringDate = this.recurring.nextDate;
  }

  handleRecurring(recurring) {
    this.recurring = recurring;
    this.calculateNextDate();
  }

  handlePublishNow(publishNow) {
    this.publishNow = publishNow;
  }

  calculateNextDate() {
    if (!this.publishNow) {
      this.nextRecurringDate = this.recurring.nextDate;
      return;
    }

    const currentNextDate = moment(this.recurring.nextDate).add(1, 'days').toDate();
    const currentNextDateMoment = getNextReccurringDate(
      currentNextDate,
      this.recurring.repeatOn,
      this.recurring.frecuency,
      this.recurring.time
    );
    this.nextRecurringDate = currentNextDateMoment;
  }
}
