import { Component, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// Delete Dialog
@Component({
    selector: 'app-dialog-delete-locations',
    template: `
        <div class="dialog__header dialog__header--info" >
		    <h2 mat-dialog-title class="m--0 txt--xl"><strong>{{ data.title }}</strong></h2>
	    </div>
        <div class="dialog__content dialog__content--height" mat-dialog-content>
            <div class="dialog-row">
                <p>{{data.description}}</p>
                <ul>
                    <li *ngFor="let location of data.DeletedLocations">
                        <h5 class="m--0" ><b>{{ location.locationName }}</b></h5>
                        <gmb-address [address]="location.address" ></gmb-address>
                    </li>
                </ul>
            </div>
        </div>
    
        <div mat-dialog-actions class="dialog__footer">
            <div class="alternate-theme d-inline-block mr--10">
                <button tabIndex="-1" mat-button color="primary" [mat-dialog-close]="false" class="btn btn-cancel"></button>
            </div>
            <button tabIndex="-1" mat-flat-button [color]="'accent'" class="btn btn--action" (click)="applyDelete()">Continue</button>
        </div>
    `
})
export class DialogDeleteLocationsComponent {
    onAccept: EventEmitter<boolean> = new EventEmitter(false);
  
    constructor(
      @Inject(MAT_DIALOG_DATA) public data: {DeletedLocations: {gid: string, accountId: string, locationId: string, locationName: string, address: string, exist: boolean}[], title: string, description: string},
      public dialogRef: MatDialogRef<DialogDeleteLocationsComponent>
    ) {}
  
    async applyDelete() {
      this.onAccept.emit(true);
      this.dialogRef.close()
    }
  
}
  