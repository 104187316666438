import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IReportFilterConfigData } from './../../../constants/report-filter-config-data';
import { Granularity } from './../../../constants/data-picker';
import { AbcInsightsReportDataService } from 'src/app/modules/reporting/services/abc-insights-report-data.service';

import { ReportsFilterConfigService } from './reports-filter-config.service';
import { IDateRange } from '../../../constants/data-picker';
import { IInsightsReportParams } from 'src/app/constants/insights-report';

@Injectable({
    providedIn: 'root'
})
export class AbcInsightsFilterConfigService extends ReportsFilterConfigService {

    public insightsFilterConfigObject: IReportFilterConfigData = null;
    constructor(
        protected _http: HttpClient,
        private _abcInsRepDataS: AbcInsightsReportDataService
        ) {
        super(_http);
    }

    setInitialFilterConfigData(configData: IInsightsReportParams): void {

        this.insightsFilterConfigObject = {
            gid: configData.gid,
            // accountId: configData.accountId,

            // Note, this was NOT the locationCanonId but the google locationId,
            // as a temp fix will set as such and resolve to locationCanonId on 
            // the backend:
            // locationCanonId: configData.locationId,
            // TODO: Put the real locationCanonId here
            locationId : configData.locationId,

            startDate: this._starterMinDate?.toLocaleDateString('en-CA') || '',
            endDate: this._starterMaxDate?.toLocaleDateString('en-CA') || '',
            granularity: this.buildGranularity(this._starterMinDate, this._starterMaxDate)
            // sort: {
            //     sortBy: 'impressions',
            //     sortOrder: -1
            // },
            // page: 1,
            // size: 25,
        }        
    }

    updateFilterConfigAndGetReportData(selectedDates: IDateRange, period: any = {}): void {
        const minDateSelected = selectedDates.minDate.toLocaleDateString('en-CA');
        const maxDateSelected = selectedDates.maxDate.toLocaleDateString('en-CA');

        this.insightsFilterConfigObject = {
            ...this.insightsFilterConfigObject,
            startDate: minDateSelected,
            endDate: maxDateSelected,
            granularity: this.buildGranularity(selectedDates.minDate, selectedDates.maxDate)
        }
        this._abcInsRepDataS.getInsightsData(this.insightsFilterConfigObject)
        super.validateComparisonPresets(selectedDates);
    }
}