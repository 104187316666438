import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ReportsFilterConfigService } from './reports-filter-config.service';
import { InsightReportDataService } from './insight-report-data.service';
import { IReportFilterConfigData } from './../../../constants/report-filter-config-data';
import { Granularity, IDateRange } from './../../../constants/data-picker';


@Injectable({
  providedIn: 'root'
})
export class InsightFilterConfigService extends ReportsFilterConfigService {

  public insightsFilterConfigObject: IReportFilterConfigData = null;

  constructor(
    protected _http: HttpClient,
    private _insRepDataS: InsightReportDataService
  ) {
    super(_http)
  }

  setInitialFilterConfigData(configData: any): void {
    // const twoMonthsOrLess = this.checkTwoMonthsOrLess();

    this.insightsFilterConfigObject = {
      gid: configData.gid,
      accountId: configData.accountId,
      locationId : configData.locationId,

      startDate: this._starterMinDate?.toLocaleDateString('en-CA') || '',
      endDate: this._starterMaxDate?.toLocaleDateString('en-CA') || '',
      granularity: this.buildGranularity(this._starterMinDate, this._starterMaxDate)
    }
  }

  updateFilterConfigAndGetReportData(selectedDates: IDateRange, period: any = {}): void {
    const minDateSelected = selectedDates.minDate.toLocaleDateString('en-CA');
    const maxDateSelected = selectedDates.maxDate.toLocaleDateString('en-CA');

    this.insightsFilterConfigObject = {
        ...this.insightsFilterConfigObject,
        startDate: minDateSelected,
        endDate: maxDateSelected,
        granularity: this.buildGranularity(selectedDates.minDate, selectedDates.maxDate)
    }
    this._insRepDataS.getInsightsData(this.insightsFilterConfigObject)
    super.validateComparisonPresets(selectedDates);
  }
}
