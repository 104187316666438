// dep
import { Component } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

// app
import { DataTransferService } from '../../services/data-transfer.service';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls:  ['./main.component.scss']
})
export class MainComponent extends BaseComponent {
  public readonly pageNeedsSession : boolean

  public contentClass: { ''                : boolean,
                         'content--full'   : boolean,
                         'content'         : boolean,
                         'content-header'  : boolean,
                         'content--padding': boolean
                        }

  public domain$  = this._sessionS.domain$; 
  public session$ = this._sessionS.session$;
  
  constructor(private _router: Router, 
              private _activatedRoute: ActivatedRoute, 
              private _titleS: Title,
              private _sessionS: SessionService, 
              private _dataTransferS:  DataTransferService<string>) {
      super();
      this.pageNeedsSession = !!this._activatedRoute.snapshot.data?.pageNeedsSession;

      this._subscribeSafe(_router.events, event => (event instanceof NavigationEnd) && this._refreshContentClass());
      this._subscribeSafe(this._dataTransferS.getData(), reportName => this._refreshContentClass(reportName)); 
      this._refreshContentClass()
    }

    private _refreshContentClass(reportName? : string) : void {
      const title = (this._activatedRoute.firstChild?.snapshot?.data?.title  ||
                     this._titleS.getTitle());

      const {company_name} = this._sessionS.getDomain().branding;

      // FIXME: Comparison against description string
      if (title == "Report") {
        if(reportName) {
          this._titleS.setTitle(`${reportName} | ${company_name}`);
        }
      } else {
        this._titleS.setTitle(`${title} | ${company_name}`);
      }
      
      const {url} = this._router;
      
      const isFullWidth   = url.includes('report/');
      const isAuth        = (url.includes('login') || url.includes('register') || url.includes('forgot-password'));
      const isHeader      = url.includes('report/');
      const biggerPadding = url.includes('billing');

       this.contentClass = {
        ''                : isAuth,
        'content--full'   : isFullWidth,
        'content'         : !isFullWidth && !isAuth,
        'content-header'  : isHeader,
        'content--padding': biggerPadding
      };
  }
}
