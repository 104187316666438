<app-dashboard-filter
  [progress]="false"
  [isRefreshing]="isRefreshing"
  [isPurging]="isPurging"
  [accountId]="accountId"
  [typeDashboard]="!isNewPost ? 'posts' : 'post-management'"
  (RefreshButtonPressed)="handleRefreshButton()"
  (PurgeButtonPressed)="openModalConfirm()"
></app-dashboard-filter>

<div>
  <app-local-posts *ngIf="!isNewPost" #localpost dashboardType="LOCAL_POST"></app-local-posts>

  <app-post-management *ngIf="isNewPost" dashboardType="LOCAL_POST" [location]="location"></app-post-management>
</div>
