// dep
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

// app
import { UserFeatures } from '../constants/user-features'
import { SessionService } from '../services/session.service';


@Directive({
  selector: '[appUserFeature]'
})
export class UserFeatureDirective implements OnInit {
  @Input() appUserFeature: keyof UserFeatures['userFeatures'];

  constructor(
    private _sessionS : SessionService,
    private _element: ElementRef
  ) { }

  ngOnInit(): void {
    this._buildFeature();    
  }

  async _buildFeature(): Promise<void> {
    const f = (await this._sessionS.waitSession()).features

    const fname = this.appUserFeature;
    const isFinalEnabled   = !!(f.final?.[fname]);
    const isGeneralEnabled = !!(f.generalFeatures?.[fname]);
    const isNewFeature     = !!(f.generalFeatures?.isNewFeature?.[fname]);

    if (isFinalEnabled) {
      const htmlEl = this._element.nativeElement; 
      htmlEl.classList.remove('d-none'); // Show the element

      if (isNewFeature || !isGeneralEnabled) {
        const itemsToAddBeta = htmlEl.getElementsByClassName('title-dark-launch');
        const message = isNewFeature ? 'New' : 'Beta'
        
        Object.keys(itemsToAddBeta).forEach(el => {
          itemsToAddBeta[el].innerHTML += `<span class="beta-mesage">${message}</span>`
        })
      }
    }
  }
}
