<!--Regular Hours-->
<div class="box__item box__item--editable">
    <div class="d-flex w--100 align-items-start">
        <div class="icon icon--rounded icon--gray mr--20">
            <i class="far fa-clock"></i>
        </div>
        <div class="w--100 d-flex flex-column">
            <div class="w--100" *ngIf="regularHours" [class.mb--20]="specialHours && specialHours?.length > 0">
                <h4 class="w--100 txt--md fw--700 mb--10">Hours</h4>
                <p class="w--100" *ngIf="regularHours?.length == 0; else hoursByDay">No Hours Added</p>
                <ng-template #hoursByDay>
                    <div class="w--100 txt--md hours-subtitle mb--10"><span>Normal Hours</span></div>
                    <ng-container *ngFor="let day of regularHours">
                        <div class="w--100 row mb--10">
                        <div class="col-3">
                            <div class="txt--md">{{ day.day | titlecase }}</div>
                        </div>
                        <div class="col-9">
                            <div *ngFor="let time of day.times; let i = index" [class.pt--5]="i > 0">
                                {{time.startTime | hours12}}—{{time.endTime | hours12}}
                            </div>
                        </div>
                        </div>
                    </ng-container>
                </ng-template>
            </div>

            <div class="w--100" *ngIf="specialHours && specialHours?.length > 0">
                <h4 class="w--100 txt--md fw--700 mb--10">Special Hours</h4>
                <ng-container *ngFor="let item of specialHours">
                    <!--  this needs to be reviewed once connected with real data  -->
                    <div class="w--100 txt--md hours-subtitle mb--10">
                        <div>
                            <span>{{item.descriptions[0].text}}</span>
                        </div>
                        <div>
                            <span class="txt--sm">(From {{item.startDate}} to {{item.endDate}})</span>
                        </div>
                    </div>
                    <ng-container *ngIf="!item.closed; else isClosed">
                        <ng-container *ngFor="let day of item.hoursByDay">
                            <div class="w--100 row mb--10">
                                <div class="col-3">
                                    <div class="txt--md">{{ day.day | titlecase }}</div>
                                </div>
                                <div class="col-9">
                                    <div *ngFor="let time of day.times">{{time.startTime | hours12}}—{{time.endTime | hours12}}</div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-template #isClosed>
                        <div class="w--100 txt--md hours-subtitle mb--10">Closed</div>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>
  </div>
  