import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {LocationEditService} from "../../services/location-edit.service";
import {GoogleService} from "../../services/google.service";
import {Observable, of, Subject} from "rxjs";
import {debounceTime, switchMap} from "rxjs/operators";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import { AbstractControl, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { SnackbarService } from '../../services/snackbar.service';

function autocompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string') {
      return { 'invalidAutocompleteObject': { value: control.value } }
    }
    return null  /* valid option selected */
  }
}

@Component({
  selector: 'app-modal-category',
  templateUrl: './modal-category.component.html',
  styleUrls: ['./modal-category.component.scss']
})
export class ModalCategoryComponent implements OnInit {
  primaryCategory: any = '';
  additionalList: FormArray;
  primaryCategoriesDropdown: any;
  additionalCategoriesDropdown: any;
  newCategory: boolean;
  newCategoryName: string;
  hideAddCategory = false;
  
  @Input() regionCode;
  @Input() bulk = false;
  @Output() validResult = new EventEmitter<boolean>();

  public _search = new Subject<string>();
  search$: Observable<any>;

  formPrimaryCategory = new FormControl(null, [autocompleteObjectValidator(), Validators.required])

  constructor(
    public dialogRef: MatDialogRef<ModalCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    private locationEditS: LocationEditService,
    private googleS: GoogleService,
    private snackS: SnackbarService
  ) {

    this.additionalList = new FormArray([]);
    if (data) {
      this.regionCode = data?.regionCode || this.regionCode;
      if (data.primaryCategory === null || data.primaryCategory === undefined) {
        this.primaryCategory = '';
        this.formPrimaryCategory.setValue('');
      } else {
        this.primaryCategory = data.primaryCategory;
        this.formPrimaryCategory.setValue(data.primaryCategory);
      }


      const additionalData = this.data.additionalCategory;
      if (additionalData !== null && additionalData !== undefined) {

        if (additionalData.length > 0) {
          for (const keyItem in additionalData) {
            this.additionalList.push(new FormControl(additionalData[keyItem], [autocompleteObjectValidator(), Validators.required]));
          }
        }
      }
    }
  }

  ngOnInit() {
    this.search$ = this._search.pipe(
      debounceTime(500),
      switchMap(value => {
        if (value) {
          const usedCategories = this.additionalList.controls.map(el => el.value);
          return this.googleS.getCategories(value, usedCategories, this.regionCode);
        } else {
          return of([]);
        }

      })
    )
  }

  addCategory(): void {
    if (this.additionalList.length < 9) {
      if (this.additionalList.length ==  8) this.hideAddCategory = true;
      this.additionalList.push(new FormControl({categoryId: '', displayName: ''}, [autocompleteObjectValidator(), Validators.required]))
    }
    else this.snackS.openInfo('Cannot add more locations', 3000)
  }

  searchCategories($event) {
    this._search.next($event.target.value);
  }

  removeAdditionalCategory(index): void {
    if (index > -1) {
      if (this.hideAddCategory) this.hideAddCategory = false
      this.additionalList.removeAt(index);
    }
  }

  getAdditionalCategory(index: number): FormControl {
    return this.additionalList.at(index) as FormControl;
  }

  deleteEmptycategories() {
    this.additionalList.controls.forEach((el, index) => {
      if(el.status == 'INVALID' && !el.value) {
        this.removeAdditionalCategory(index);
      }
    });
  }

  apply(): void {
    this.deleteEmptycategories();
    if (this.formPrimaryCategory.status != "VALID" || this.additionalList.status != "VALID") { 
      return 
    }

    const location = this.locationEditS.locationEdit;
    if ('type' in this.primaryCategory) 
      delete this.primaryCategory['type']
    location.primaryCategory = this.formPrimaryCategory.value || this.primaryCategory;
    const additionalList = this.additionalList.value.map( ac => {
      if ('type' in ac) 
        delete ac['type']
      return ac
    })
    location.additionalCategories = additionalList.filter(el => el.categoryId != '');
    this.locationEditS.setAndUpdate().toPromise().then(()=>{
      this.dialogRef.close();
    });
  }

  displayFn(category): string {
    return category ? category.displayName : this.primaryCategory.displayName;
  }

  clearSearch() {
    this.primaryCategoriesDropdown = [];
    this.additionalCategoriesDropdown = [];
    this._search.next()
  }

  selectedAdditional($event: MatAutocompleteSelectedEvent, form: AbstractControl) {
    const data = $event.option.value;
    if ('type' in data) {
      delete data.type;
    }
    form.setValue(data);
  }

  validate(e) {
    this.validResult.emit(e !== '')
  }

  getResult(): { primaryCategory: any, additionalCategories: any[] } {
    if (this.formPrimaryCategory.valid)  {
      if (!this.additionalList.valid) {
        this.validResult.emit(false)
        return
      }
      const additionalList = this.additionalList.controls.filter(el => el.value.categoryId && el.value.displayName);
      this.primaryCategory = this.formPrimaryCategory.value;

      const additionalListValues:any = []
      additionalList.forEach((al) => {additionalListValues.push(al.value)})

      this.validResult.emit(true)
      if ('type' in this.primaryCategory) 
        delete this.primaryCategory['type']
      return {primaryCategory: this.primaryCategory, additionalCategories: additionalListValues }
    } else {
      this.validResult.emit(false)
    }
  }

}

