<div class="table--shadow">
  <div class="mat-row mat-row--padding mat-row--border d-flex justify-content-between align-items-center bg--white">
    <h2 class="m--0">{{title|titlecase}}</h2>
    <div class="d-flex justify-content-between align-items-center w--lg">
      <mat-button-toggle-group class="button-toggle-group button-toggle-group--sm" name="message-status"
                               aria-label="Message Status" value="all" (valueChange)="handleView($event)">
        <mat-button-toggle value="all">All</mat-button-toggle>
        <mat-button-toggle value="read">Read</mat-button-toggle>
        <mat-button-toggle value="unread">Unread</mat-button-toggle>
      </mat-button-toggle-group>

      <div class="d-flex align-items-center w--sm">
        <mat-label class="mr--10">Action</mat-label>
        <div class="input-group m--0 no-padding input-group--select">
          <mat-select value="read" class="input-group__select">
            <mat-option value="read">Mark as Read</mat-option>
            <mat-option value="unread">Mark as Unread</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>

  <app-skeleton-protocol-log *ngIf="progress"></app-skeleton-protocol-log>

  <ng-container *ngIf="!progress">
    <table [dataSource]="datasource" class="table table--lg table--last-row-border no-hover" mat-table matSort *ngIf="datasource.data.length>0">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggleProtocolLog() : null"
                        [checked]="selectionProtocolLog.hasValue() && isAllSelectedProtocolLog()"
                        [indeterminate]="selectionProtocolLog.hasValue() && !isAllSelectedProtocolLog()"
                        [aria-label]="checkboxLabelProtocolLog()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selectionProtocolLog.toggle(row) : null"
                        [checked]="selectionProtocolLog.isSelected(row)"
                        [aria-label]="checkboxLabelProtocolLog(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns" class="with-padding"></tr>
      <tr mat-row *matRowDef="let row; columns: columns;" class="with-padding"></tr>

    </table>
    <app-paginator [pagination]="pagination" (reload)="reloadPagination($event)"
                   *ngIf="pagination.items.length>0"></app-paginator>

    <app-no-data [message]="messageNoData" *ngIf="datasource.data.length==0"></app-no-data>
  </ng-container>

</div>
