<div class="row box--height">
  <!--Actions on your Google Business Profile & total Photo Views on your Google Business Profile-->
  <div class="col-12 mb--30">
    <div class="box box--rounded box--shadow bg--white">
      <div class="box__header d-flex align-items-center justify-content-start">
        <i *ngIf="reportType != 'rollup'" class="fal fa-mouse-pointer mr--15 txt--gray"></i>
        <h2 class="txt--capitalize"><strong>Popular Times of Day (Phone Calls)</strong></h2>
      </div>
      <div class="box__content box--padding-xxs">
        <div class="row-no-margin" *ngIf="days !== null || hours !== null; else AnyData">
          <div class="col-md-6">
            <ng-container *ngIf="days.data.length > 0; else NoData">
              <app-chart-line chartType="bar" type="COLORS-FULL" [commaNumbers]="false" [labels]="days.labels" [dataset]="[{label: 'Day Of The Week', data: days.data}]" [aggregation]="dataPicker.aggregation"></app-chart-line>
            </ng-container>
            <ng-template #NoData>
                <h2 class="txt--capitalize" style="text-align: center;"><strong>We don't have data to show</strong></h2>
            </ng-template>
          </div>
          <div class="col-md-6">
            <ng-container *ngIf="hours.data.length > 0; else NoData">
              <app-chart-line chartType="bar" type="COLORS-FULL" [commaNumbers]="false"  [labels]="hours.labels" [dataset]="[{label: 'Time Of Day', data: hours.data}]" [aggregation]="dataPicker.aggregation"></app-chart-line>
            </ng-container>
            <ng-template #NoData>
              <h2 class="txt--capitalize" style="text-align: center;"><strong>We don't have data to show</strong></h2>
            </ng-template>
          </div>
        </div>
        <ng-template class="row-no-margin"  #AnyData>
          <div class="col-md-12">
            <h2 class="txt--capitalize" style="text-align: center;"><strong>We don't have data to show</strong></h2>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>


