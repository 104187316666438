// dep
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import moment from 'moment';

// app
import { DatesService, JsonDate } from "../../services/dates.service";
import { LocationEditService } from "../../services/location-edit.service";
import { HoursAmPmPipe } from "../../pipes/hours-am-pm.pipe";


@Component({
  selector: 'app-modal-special-hours',
  templateUrl: './modal-special-hours.component.html',
  styleUrls: ['./modal-special-hours.component.scss']
})
export class ModalSpecialHoursComponent {
  previousDay = [];
  specialHoursDropdown: any;
  specialHoursItems: any[];
  private form: FormGroup;

  @Input() bulk = false;
  @Output() validResult = new EventEmitter(false);

  constructor(public dialogRef: MatDialogRef<ModalSpecialHoursComponent>,
              @Inject(MAT_DIALOG_DATA) 
              public data: any,
              private dateS: DatesService,
              private locationEditService: LocationEditService,
              private fb: FormBuilder
  ) {
    if (data) {
      // TODO: Why the JSON roundtrip?
      this.specialHoursItems = this.data.specialDays ? JSON.parse(JSON.stringify(this.data.specialDays)) : [];
      this.previousDay = this.data.previousDay;
    } else 
      this.specialHoursItems = []

    this.specialHoursDropdown = this.dateS.loadHoursDropdown();
    this._initForm();
  }

  private _initForm() {
    const keys = Object.keys(this.specialHoursItems);
    this.form = this.fb.group({
      days: new FormArray([]),
    });

    if (keys?.length > 0){
      keys.forEach(key => {
        const items = this.specialHoursItems[key];
        items.forEach((item, i) => {
          const isNew = (i === 0);
          this.newSpecialHours(item.startDate, item.isClosed, item.openTime, item.closeTime, isNew);
        });
      });
    }
  }

  newSpecialHours(startDate: JsonDate | Date, isClosed : boolean, openTime, closeTime, isNew = true): void {
    let date;

    if (startDate instanceof Date) {
      date = startDate;
    } else {
      date = this.dateS.dateJsonToDate(startDate as JsonDate);
    }
    const hours12 = new HoursAmPmPipe();
    if (isNew) {
      const form = this.fb.group({
        day: [new Date(date)],
        hours: new FormArray([
          this.fb.group({
            startDate: [new Date(date)],
            isOpen: [!isClosed],
            openTime: [openTime ? hours12.transform(openTime) : ''],
            closeTime: [closeTime ? hours12.transform(closeTime) : '']
          })
        ])
      })
      this.days.push(form);
    } else {
      this.days.controls.forEach((el, i) => {
        if(moment(el.value.day).isSame(date)) {
          const form = this.fb.group({
            startDate: new Date(date),
            isOpen: !isClosed,
            openTime: openTime ? hours12.transform(openTime) : '',
            closeTime: closeTime ? hours12.transform(closeTime) : ''
          })
          el['controls'].hours.push(form);
        }
      })
    }
  }

  addNewDate() : void {
    this.newSpecialHours(new Date(), true, '', '')
  }

  addNewHour(date, f): void {
    const form = this.fb.group({
      startDate: new Date(date.value),
      isOpen: true,
      openTime: '',
      closeTime: ''
    })
    f['controls'].hours.push(form);
  }

  deleteDate(dayIndex, hoursIndex) {
    const hours = this.days.controls[dayIndex]['controls'].hours;

    if (hours.length > 1) {
      hours.removeAt(hoursIndex);
    } else {
      this.days.removeAt(dayIndex);
    }
  }

  apply(): void {
    const specialHourPeriods = [];
    this.days.updateValueAndValidity();
    const acceptedDays = this.areAcceptedDays();

    this.isValidForm();
    if (!this.days.valid || !acceptedDays) {
      return
    }

    this.form.value.days.filter(day => {
      day.hours.forEach(hour => {
        if (!hour.isOpen) {
          specialHourPeriods.push({
            isClosed: !hour.isOpen,
            startDate: this.dateS.dateToJsonDate(day.day)
          })
        } else {
          let open = parseInt(hour?.openTime?.split(':')[0]);
          const openMeridian = hour?.openTime?.split(' ')[1];
          open = open === 12 && openMeridian === 'AM' ? 0 : open;

          const close = parseInt(hour?.closeTime?.split(':')[0]);
          const closeMeridian = hour?.closeTime?.split(' ')[1];

          const endDate = (
            (openMeridian == 'PM' &&  closeMeridian == 'AM') ||
            ((openMeridian == 'AM' &&  closeMeridian == 'AM') && open > close) ||
            ((openMeridian == 'PM' &&  closeMeridian == 'PM') && open > close && open != 12) ?
            (moment(day.day).add(1, 'days')).toDate() :
            day.day
          );

          specialHourPeriods.push({
            closeTime: this.dateS.hours12To24(hour.closeTime),
            endDate: this.dateS.dateToJsonDate(endDate),
            openTime: this.dateS.hours12To24(hour.openTime),
            startDate: this.dateS.dateToJsonDate(day.day)
          })
        }
      })
    });
    
    if (this.hasChanges(specialHourPeriods)) {
      this.locationEditService.locationEdit.specialHours = {specialHourPeriods};
      this.locationEditService.setAndUpdate().toPromise().then(() => {
        this.dialogRef.close();
      });
    } else {
      this.dialogRef.close();
    }
  }

  hasChanges(specialHourPeriods) {
    // FIXME: This is a bug, field order is not stable when stringfied, will
    // result on false negatives. Use a proper deep equal comparison function.  
    return JSON.stringify(this.previousDay) != JSON.stringify(specialHourPeriods);
  }

  getResult(): { specialHours: any } {
    const specialHourPeriods = [];
    this.isValidForm();
    if (!this.days.valid || !this.areAcceptedDays()) {
      this.validResult.emit(false);
      return
    }

    const specialHours: any[] = this.form.value.days;
    if (specialHours.length == 0) {
      this.validResult.emit(false);
      return
    }

    this.validResult.emit(true);
    
    this.form.value.days.filter(day => {
      day.hours.forEach(hour => {
        hour.startDate = this.dateS.dateToJsonDate(day.day);
        if (!hour.isOpen) {
          delete hour.openTime;
          delete hour.closeTime;
          hour.isClosed = !hour.isOpen;
        } else {
          let open = parseInt(hour?.openTime?.split(':')[0]);
          const openMeridian = hour?.openTime?.split(' ')[1];
          open = open === 12 && openMeridian === 'AM' ? 0 : open;


          const close = parseInt(hour?.closeTime?.split(':')[0]);
          const closeMeridian = hour?.closeTime?.split(' ')[1];

          const endDate = (
            (openMeridian == 'PM' &&  closeMeridian == 'AM') ||
            ((openMeridian == 'AM' &&  closeMeridian == 'AM') && open > close) ||
            ((openMeridian == 'PM' &&  closeMeridian == 'PM') && open > close && open != 12) ?
            (moment(day.day).add(1, 'days')).toDate() :
            day.day
          );

          delete hour.isClosed;
          hour.endDate = this.dateS.dateToJsonDate(endDate);
          hour.openTime = this.dateS.hours12To24(hour.openTime);
          hour.closeTime = this.dateS.hours12To24(hour.closeTime);
        }
        delete hour.isOpen;
        specialHourPeriods.push(hour)
      })
    });

    return {specialHours: {specialHourPeriods} }
  }

  areAcceptedDays() {
    let condition = true;
    const days = this.days.controls.map(el => el.value.day);
    
    days.forEach((el, i) => {
      const date = moment(el).format('MMMM/DD/YYYY');
      const items = days.filter(d => moment(d).format('MMMM/DD/YYYY').toString() == date.toString());

      if (items.length > 1) {
        const hours = this.days.controls[i].value.hours;
        
        if(hours.length == 1 && !hours[0].isOpen) {
          condition = false 
        }
      }
    })
    
    return condition;
  }

  areAcceptedHours(hours) {
    let condition = false;

    if(hours.get('isOpen').value) {
      condition = (
        hours.get('openTime').value == '' || hours.get('closeTime').value == '' ||
        hours.get('openTime').value == '12:00 AM' && hours.get('closeTime').value == '12:00 AM' ||
        hours.get('openTime').value != hours.get('closeTime').value 
        ? false : true
      );
    }

    if (condition) {
      hours.setErrors({ errorHours: true });
    } else {
      hours.setErrors(null);
    }

    return condition
  }

  isValidForm() {
    this.days.controls.forEach(d => {
      d['controls'].hours.controls.forEach(el => {
        if (el.get('openTime').value == '' && el.get('closeTime').value == '' && (el.get('isOpen').value == '' || !el.get('isOpen').value)) {
          el.get('openTime').setErrors(null);
          el.get('closeTime').setErrors(null);
        }
      })
    })
  }

  toggleChanged(item, action) {
    if(!action.checked) {
      const length = item.controls.hours.value.length;
      for (let i=length; i >= 1; i--) {
        item.controls.hours.removeAt(i);
      }
    }
  }

  toggle($event: MatSlideToggleChange, form: AbstractControl) {
    form.setValue(!$event.checked)
  }

  get days() {
    return this.form.get('days') as FormArray;
  }

  getDay(index: number): FormControl {
    return this.days.at(index) as FormControl;
  }
}
