// dep
import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

// app
import { Recurring } from '../../v3.models/posts.models';

@Component({
  selector: 'app-calculate-recurring',
  templateUrl: './calculate-recurring.component.html',
  styleUrls: ['./calculate-recurring.component.scss']
})
export class CalculateRecurringComponent implements OnInit, OnChanges {
  @Input() recurringModel: Recurring;
  @Input() publishNow = false;
  @Input() duplicateAccept = false;
  @Input() isEdit = false;
  @Input() postType: string;
  @Output() publishPlanDates = new EventEmitter<{start: Date, end: Date}[]>();

  nextDatesEvent: {start: Date, end: Date}[] = [];
  nextDates: {start: Date, end: Date}[] = [];
  timeDate: Date;
  startTimeDate: Date;

  ngOnChanges(): void {
    this.nextDatesEvent = [];
    this.nextDates = [];
    this.init();
  }

  ngOnInit(): void {
    //this.init();
  }

  init(): void {
    this.timeDate = new Date(`2021-12-17T${this.recurringModel.time}:00`)
    this.startTimeDate = new Date(`2021-12-17T${this.recurringModel.eventTimeStart}:00`)
    let nextDate;
    if (!this.isEdit) {
      nextDate = this.recurringModel.nextDate;
    } else {
      nextDate = moment.utc( this.recurringModel.nextDate).toDate();
    }
    const allMonth = this.recurringModel.isAllMonth;

    const timeEvent = this.recurringModel.eventTimeEnd ? this.recurringModel.eventTimeEnd.split(":").map(te => Number(te)) : null;
    let iterator = 0;
    const total = 3;
    while (iterator < total) {
      const nextDateStart = new Date(nextDate.getFullYear(), nextDate.getMonth(), nextDate.getDate(), 0, 0, 0, 0);
      let nextDateEnd;
      if (!allMonth) {
        if (timeEvent) {
          nextDateEnd = this.recurringModel.frecuency === 'WeeklyOn'
          ? new Date(nextDateStart.getFullYear(), nextDateStart.getMonth(), nextDateStart.getDate() + (this.recurringModel.duration-1), timeEvent[0], timeEvent[1])
          : new Date(nextDateStart.getFullYear(), nextDateStart.getMonth()+1 , nextDateStart.getDate()-1, timeEvent[0], timeEvent[1]);
        } else {
          nextDateEnd = this.recurringModel.frecuency === 'WeeklyOn'
          ? new Date(nextDateStart.getFullYear(), nextDateStart.getMonth(), nextDateStart.getDate() + (this.recurringModel.duration-1), 23, 59, 0, 0)
          : new Date(nextDateStart.getFullYear(), nextDateStart.getMonth()+1, nextDateStart.getDate()-1, 23, 59, 0, 0);
        }
      } else {
        if (timeEvent) {
          nextDateEnd = new Date(nextDateStart.getFullYear(), nextDateStart.getMonth() + 1, 0, timeEvent[0], timeEvent[1]);
        } else {
          nextDateEnd = new Date(nextDateStart.getFullYear(), nextDateStart.getMonth() + 1, 0, 23, 59,0,0);
        }
      }
      nextDate = this.configDate(nextDate, true);
      if (nextDateEnd >= nextDate) {
        const dateOffset = (nextDateEnd.getTime() - nextDate.getTime()) + (1000*60*60*24);
        nextDateEnd.setTime(nextDateEnd.getTime() - dateOffset);
        if (timeEvent) nextDateEnd.setHours(timeEvent[0], timeEvent[1], 23, 59, 0 ,0);
      }

      this.nextDatesEvent.push({start: nextDateStart, end: nextDateEnd});

      if (this.publishNow && iterator === 0) {
        const startPublish = moment().utc().toDate();
        startPublish.setHours(0, 0, 0, 0);
        this.nextDates.push({start: startPublish, end: nextDateEnd});
      } else {
        this.nextDates.push({start: nextDateStart, end: nextDateEnd});
      }

      iterator += 1;
    }


    this.publishPlanDates.emit(this.nextDatesEvent);
  }

  configDate(date: Date = null, next = false): Date{
    let result;
    let currentDate: Date;
    let year: number;
    let month: number;
    let day: number;
    let dayOfWeek: number;
    if (date) {
      currentDate = moment(date).utc().toDate(), year = currentDate.getFullYear(), month = currentDate.getMonth(), day = currentDate.getDate(), dayOfWeek = currentDate.getDay();
    } else {
      currentDate = moment().utc().toDate(), year = currentDate.getFullYear(), month = currentDate.getMonth(), day = currentDate.getDate(), dayOfWeek = currentDate.getDay();
    }
    if (this.recurringModel.frecuency === 'WeeklyOn') {
      if (!next) {
        if (dayOfWeek > Number(this.recurringModel.repeatOn)) {
          const daysToDate =  7 - (dayOfWeek - Number(this.recurringModel.repeatOn));
          result = moment(new Date(year, month, day + daysToDate )).utc().toDate();
          return result;
        } else {
          const daysToDate = Number(this.recurringModel.repeatOn) - dayOfWeek;
          result = moment(new Date(year, month, day + daysToDate )).utc().toDate();
          return result;
        }
      } else {
        const daysToDate =  7 - (dayOfWeek - Number(this.recurringModel.repeatOn));
        result = moment(new Date(year, month, day + daysToDate )).utc().toDate();
        return result;
      }

    } else {
      if (!next) {
        if (day >= Number(this.recurringModel.repeatOn)) {
          const newDate = new Date(year, month + 1, Number(this.recurringModel.repeatOn) )
          result = moment(newDate).utc().toDate();
          return result;
        } else {
          const newDate = new Date(year, month, Number(this.recurringModel.repeatOn) )
          result = moment(newDate).utc().toDate();
          return result;
        }
      } else {
        const newDate = new Date(year, month + 1, Number(this.recurringModel.repeatOn) )
        result = moment(newDate).utc().toDate();
        return result;
      }
    }
  }
}
