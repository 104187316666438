import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-photos-section',
  templateUrl: './location-photos-section.component.html',
  styleUrls: ['./location-photos-section.component.scss']
})
export class LocationPhotosSectionComponent implements OnInit {
  @Input() isUnderReview = false;

  public sectionName = 'Photos';

  constructor() { }

  ngOnInit() {
  }

}
