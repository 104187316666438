<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="progress">
    <mat-spinner></mat-spinner>
</div>

<app-dashboard-filter [progress]="progress" [accountId]="accountId" [isProgressFetch]="fetchProgress"
  [isProgressPush]="isProgressPush" [typeDashboard]="'appleLocInfo'" [location]="location$ | async"
  (ClickEvent)="dispatchAction($event)"></app-dashboard-filter>

<ng-container *ngIf="!progress" class="p--20">
    <div class="container--full mt--0">
        <div class="table--shadow mb--30 pt--10 pb--10">
            <div class="mat-row mat-row--padding mat-row--border  bg--white">

                <div class="content__body">
                    <div class="table--shadow mb--30">
                        <div class="mat-row mat-row--padding justify-content-between align-items-center bg--white">
                            <div class="row">
                                <div class="col-12 col-md-8">
                                    <div class="box">
                                        <section class="border-bottom">
                                            <app-location-name 
                                                [isApple]="true" 
                                                [locationName]="locationData?.displayNames[0]?.name || ''" 
                                                [placeId]="locationId" 
                                                [isEdit]="false" 
                                                [locationAddress]="formattedAddress"
                                                [verified]="false"
                                                (onUpdate)="updateLocation($event)"
                                            >
                                            </app-location-name>
                                        </section>

                                        <section class="border-bottom">
                                            <app-location-description [isApple]="true" [description]="formattedDescription" [isEdit]="false" (onUpdate)="updateLocation($event)"></app-location-description>
                                        </section>

                                        <!-- <section class="border-bottom">
                                            <app-location-photos-section></app-location-photos-section>
                                        </section> -->

                                        <section class="border-bottom">
                                            <app-location-address [isApple]="true" [address]="formattedAddress" (onUpdate)="updateLocation($event)" [isEdit]="false">
                                            </app-location-address>
                                        </section>

                                        <section class="border-bottom">
                                            <app-location-abc-hours [specialHours]="locationData?.hours?.specialHours" [regularHours]="locationData?.hours?.openingHoursByDay" [isEdit]="false"></app-location-abc-hours>
                                        </section>

                                        <section class="border-bottom">
                                            <!-- <compass-test-badge></compass-test-badge> -->
                                            <app-location-phone [isApple]="true" [primaryPhone]="primaryPhone" [additionalPhones]="additionalPhones" (onUpdate)="updateLocation($event)" [isEdit]="false"></app-location-phone>
                                        </section>

                                        <section class="border-bottom">
                                            <app-location-url [isApple]="true" [urls]="locationData?.urls"  (onUpdate)="updateLocation($event)" [isEdit]="false"></app-location-url>
                                        </section>

                                        <section class="border-bottom">
                                            <app-location-actions [actions]="locationData?.callToAction"></app-location-actions>
                                        </section>

                                        <!-- <section class="border-bottom">
                                            <app-location-showcase></app-location-showcase>
                                        </section> -->

                                        <section class="border-bottom">
                                            <!-- app -->
                                            <app-location-app-url [urls]="locationData?.urls"></app-location-app-url>
                                        </section>

                                        <section class="border-bottom">
                                            <app-location-primary-categ 
                                                [isApple]="true"
                                                [placeId]="locationId"
                                                [primaryCategory]="primaryCategory"
                                                [additionalCategories]="additionalCategories"
                                                [isEdit]="false"
                                                (onUpdate)="updateLocation($event)"
                                            ></app-location-primary-categ>
                                          </section>

                                        <section class="border-bottom">
                                            <!-- good to know -->
                                            <app-location-good-to-know [attributes]="locationData?.locationAttributes"></app-location-good-to-know>
                                        </section>

                                        <section class="border-bottom">
                                            <!-- apple status -->
                                            <app-location-status [status]="locationData?.locationStatus"></app-location-status>
                                        </section>

                                        <section>
                                            <app-location-brand [brand]="locationData?.brand"></app-location-brand>
                                        </section>
                                    </div>
                                </div>

                                <div class="col-12 col-md-4">
                                    <div class="br--10 p--30 fw--medium txt--align--center mb--30" style="background: #F1F9F1;">
                                        <img *ngIf="locationData?.coverPhotoUrl" [src]="getProcessedUrl(locationData.coverPhotoUrl)" alt="">
                                        <img *ngIf="locationData?.coverPhotoUrl" src="/assets/images/no_files_attached.svg" alt="">
                                    </div>

                                    <div class="bg--dark--white br--10 p--30 mb--30">
                                        <h4 class="w--100 txt--md fw--medium mb--30 title-business-section">Your business is live on Apple</h4>
                                        <a [href]="locationData?.placeCardUrl" target="_blank">
                                            <button mat-stroked-button color="primary" class="btn--rounded txt--blue p--30 pt--5 pb--5">
                                                <div class="justify-content-center">
                                                    <i class="fab fa-apple pr--10"></i>
                                                    <span class="signup-text txt--md">View Live Place Card</span>
                                                </div>
                                            </button>
                                        </a>

                                        <mat-divider class="mt--25 mb--25"></mat-divider>
                                        <div>
                                            <h4 class="w--100 txt--md fw--medium mb--15 txt--black">Advanced Information</h4>

                                            <div class="w--100 txt--14 fw--medium hours-subtitle mb--5"><span>Location Code</span></div>
                                            <div class="w--100 txt--md mb--10"><span>{{locationData.storeCode}}</span></div>

                                            <div class="w--100 txt--14 fw--medium hours-subtitle mb--5 mt--15"><span>Partner ID</span></div>
                                            <div class="w--100 txt--md mb--10"><span>{{locationData.partnersLocationId}}</span></div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>


