import { COLORS_CHART } from '../../../constants/chart-color';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import * as _ from 'lodash';

// app
import { COLORS_CHART_PROGRESS_REVENUE } from '../../../constants/chart-color';
import { DataPicker } from '../../../constants/data-picker';

@Component({
  selector: 'app-chart-stats',
  templateUrl: './chart-stats.component.html',
  styleUrls: ['./chart-stats.component.scss']
})
export class ChartStatsComponent implements OnInit, AfterViewInit {
  @Input() labels: string[];
  @Input() stats: any;
  @Input() line = true;
  @Input() type: string;
  @Input() dataPicker: DataPicker;
  @Input() granularity: string;
  @Input() colors: any[] = [];
  @Input() reportType: string = null;
  @Input() hasDonutGraph = true;
  @Input() showRightProgressBar = false;
  @Input() hasChartLineGraph = true;
  @Input() centerLegend = false;
  @Input() hasMultipleChart = false;
  @Input() isApple = false;


  @Input() isMockedData = false;


  public dataset: any[] = [];
  public titleIcon: string;

  constructor(
    private _cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this._cdr.detectChanges();
  }

  ngOnInit() {
    // switch (this.stats?.title) {
    //   case 'Primary Actions': {
    //     this.titleIcon = 'fa-mouse-pointer';
    //     break;
    //   }
    //   case 'Secondary Actions': {
    //     this.titleIcon = 'fa-mouse-pointer';
    //     break;
    //   } 
    //   case 'Views of Your Place Card': {
    //     this.titleIcon = 'fa-mouse-pointer';
    //     break;
    //   }
    //   case 'Views of Your Business Profile': {
    //     this.titleIcon = 'fa-mouse-pointer';
    //     break;
    //   }
    //   case 'Total Views': {
    //     this.titleIcon = 'fa-search reverse-icon';
    //     break;
    //   }
    //   case 'Actions on your Google Business Profile': {
    //     this.titleIcon = 'fa-mouse-pointer';
    //     break;
    //   }
    //   case 'Photo views': {
    //     this.titleIcon = 'fa-image';
    //     break;
    //   }
    //   case 'Photo quantity': {
    //     this.titleIcon = 'fa-image';
    //     break;
    //   }
    //   case 'total views on search': {
    //     this.titleIcon = 'fa-eye';
    //     break;
    //   }
    //   case 'Search impressions': {
    //     this.titleIcon = 'fa-search reverse-icon';
    //     break;
    //   }
    //   case 'Google Posts Activity': {
    //     this.titleIcon = 'fa-star';
    //     break;
    //   }
    //   case 'What people searched for before tapping your location in search results': {
    //     this.titleIcon = 'fa-mouse-pointer';
    //     break;
    //   }
    //   case 'Taps on Your Location in Search Results': {
    //     this.titleIcon = 'fa-mouse-pointer';
    //     break;
    //   }
    //   default: {
    //     break;
    //   }
    // }
    Object.keys(this.stats).forEach((key, i) => {
      
      if (key !== 'totals' && key !== 'title' && key !== 'labels') {
        let stat = {};
        if (_.isEqual(this.type, 'switch-colors') || _.isEqual(this.type, 'vertical')) {
          stat = { ...this.stats[key], backgroundColor: COLORS_CHART_PROGRESS_REVENUE[i], pointHitRadius: 0};
        } else {
          stat = { ...this.stats[key], backgroundColor: COLORS_CHART[i], pointHitRadius: 0};
        }
        this.dataset.push(stat);
      }
    });

    if(this.dataset[0]?.label === 'Mobile Phone Calls' &&
      this.dataset[1]?.label === 'Direction Requests' &&
      this.dataset[2]?.label === 'Website Visits'
    ){
      this.dataset.sort(function(a, b) {
        return a.label.localeCompare(b.label);
     });
    }
  }

  getColor(data, index) {
    return data?.borderColor || COLORS_CHART[index].borderColor
  }
}
