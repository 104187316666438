// dep
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import * as _ from 'lodash';

// app
import {ReportService} from '../../services/report.service';
import {DataPicker} from '../../constants/data-picker';
import {REPORT_TYPE} from '../../constants/firestore/report';
import {EmailerService} from '../../services/emailer.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-modal-share',
  templateUrl: './modal-share.component.html',
  styleUrls: ['./modal-share.component.scss']
})
export class ModalShareComponent implements OnInit {
  public url: string;
  public isCopy: boolean;
  public successSend: boolean;
  public isToShare = false;
  public progress: boolean;
  public failedSend: boolean;
  public timeoutMessage: boolean;
  public report: any;
  public comparisonMetrics: any[] = [];
  public reportName = '';
  public showComparison = null;
  public questionsType = null;
  public periodDaysValue = null;
  public compareToValue = null;
  public createNew = true;
  
  private readonly _data: any;
  private _dataPicker: DataPicker;
  private _form: FormGroup;
  private _reportType: REPORT_TYPE;

  constructor(
    @Inject(MAT_DIALOG_DATA) private modalConfirmData,
    private fb: FormBuilder,
    private reportS: ReportService,
    private _sessionS: SessionService,
    private emailerS: EmailerService,
  ) {
    this._data = this.modalConfirmData.data;
    this.report = this.modalConfirmData.data.report;
    this._dataPicker = this._data.dataPicker;
    this._reportType = this._data.reportType;
    this.createNew = this._data?.createNew;
    this.comparisonMetrics = this._data?.comparisonMetrics || [];
    this.reportName = this._data?.reportName;
    this.showComparison = this._data?.showComparison;
    this.questionsType = this._data?.questionsType;
    this.periodDaysValue = this._data?.periodDaysValue;
    this.compareToValue = this._data?.compareToValue;

    const paramId = this._data?.route?.snapshot?.params?.id;
    const paramGid = this._data?.route?.snapshot?.params?.gid;
    const id = paramId || (this.report?.id || this.report?._id);
    const gid = paramGid || this.report?.gid;
    const {domainNameWithPort} = this._sessionS.getDomain()
    
    if (this.createNew) {

      this.reportS.share(this._dataPicker, this._reportType, this.comparisonMetrics, this.reportName, this.showComparison, this.questionsType, this.periodDaysValue, this.compareToValue).subscribe(report => {
        if (this._reportType != 'performance-insights' && !this._reportType.includes('-location') && !this._reportType.includes('-report')) {
          report.get().then(result => {
            const reportId = result.id;
            const reportGid = gid || result.data().gid;
            this.url = `https://${domainNameWithPort}/report/${reportGid}/${reportId}/${this._reportType}/shared`;
          });
        } else {
          const reportId = report.reportId;
          const reportGid = gid || this._sessionS.getSession().gid;
          this.url = `https://${domainNameWithPort}/report/${reportGid}/${reportId}/${this._reportType}/shared`;
        }
      });
    } else {
      this.url = `https://${domainNameWithPort}/report/${gid}/${id}/${this._reportType}/shared`;
      this.reportS.setMerge(gid, id, {shared: true}).then();
    }


  }

  ngOnInit() : void {
    this.initForm();
    this.timeoutMessage = true;
  }

  initForm() : void {
    this._form = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  get email() : FormControl {
    return this._form.get('email') as FormControl;
  }

  copyInputMessage($event) {
    $event.preventDefault();
    const selBox = document.createElement('input');
    selBox.value = this.url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.isCopy = true;

    setTimeout(() => {
      this.timeoutMessage = false;
    }, 3000);
  }


  async send() : Promise<void> {
    let reportType = this._reportType.includes('insight') || this._reportType.includes('rollup') ? 'Rollup' : this._reportType;
    reportType = reportType
    .replace(/-/g, ' ')
    .replace(/\breport\b/gi, '')
    .replace(/\b\w/g, char => char.toUpperCase());

    if (this._form.invalid) {
      return;
    }
    this.progress = true;
    const b = this._sessionS.getDomain().branding

    const message = `
      <h2>${b.company_name}</h2>
      <h3>A report has been shared with you!</h3>
      <hr>
      <p>Someone at Map Labs shared a ${reportType} report with you${!!this.reportName ? ': "' + this.reportName + '". ' : '. '}Click <a href="${this.url}" target="_BLANK">here</a> to view it or copy and paste the link below.</p>
      <p>${this.url}</p>
      <hr>
      <p><center><a href="${b.main_website}">${b.company_name}</a></center></p>
    `;

    try {
      await this.emailerS.send(this.email.value, `A ${reportType} report has been shared`, message).toPromise()
      this.successSend = true;
    } catch(err) {
      this.failedSend = true;
      console.error(err);
    }
    this.progress = false;

  }
}
