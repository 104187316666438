// TODO: Remove all Firestore usages

export const DOMAINS = '_domains';
export const GROUPS = '__groups';
export const EXTERNAL_GRADES = '__external_grades';
export const GRADE_LEADS = '__grade_leads';
export const VERIFICATION_EMAIL = '__verifications';
export const USER = '__users';
export const ACCOUNTS = '__accounts';
export const LOCATIONS = '__locations';
export const WIDGET_INFO = '__widgetInfo';
export const NOTIFICATIONS = '__notifications';
export const REPORTS = '__reports';
export const FIRESTORE_AUTOMATED_REPORTS = '__automated_reports'
export const POST_MANAGEMENT_GROUP = '__pending_post';
export const PROTOCOLS = '__protocols';
export const SEARCH_INDEX = '__searchIndex';

// export const LOGS = '__logs';
// export const LOGGER = '__logger';
// export const PENDING_LOCATIONS = '__pending_locations';
// export const LOCATIONS_BASIC = '__locations_basic';
// export const REVIEWS_RESUME = '__reviews_resume';
// export const BULK_LOCATIONS_EDITS = '__bulk_location_edits';
// export const POST_MANAGEMENT_LOCATION = '__postManagementLocation';
// export const REVIEWS_TO_REPLAY = '_reviewsToReply';
