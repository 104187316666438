// dep
import { EventEmitter, Injectable } from "@angular/core";

// app
import {EventMessage} from '../constants/event-message';
import {EventNames} from '../constants/event-names';

@Injectable()
export class EventMessageService {

  private _dispatcher = new EventEmitter<EventMessage>();

  emitMessageEvent(name: EventNames, data: any) {
    this._dispatcher.emit(new EventMessage(name, data))
  }

  getEmitter() {
    return this._dispatcher;
  }

}
