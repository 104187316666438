import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { FoodMenuSection } from '../../../../constants/google/menuList';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-modal-form-section',
  templateUrl: './modal-form-section.component.html',
  styleUrls: ['./modal-form-section.component.scss']
})
export class ModalFormSectionComponent implements OnInit {
  isEdit: boolean;
  public form: FormGroup;
  private sectionIndex: number;
  private foodMenuIndex: number;
  private modelResponse: FoodMenuSection = null;
  typeFormPrice: string ='fixed';
  constructor(
    public dialogRef: MatDialogRef<ModalFormSectionComponent>,
    private decimalPipe: DecimalPipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.isEdit = this.data.edit;
    this.initForm();

    if (this.data.section) {
      this.isEdit = true;
      this.sectionIndex = this.data.sectionIndex;
      this.modelResponse = this.data.section

      this.form.get('labels').setValue({
        displayName: this.modelResponse.labels[0].displayName ? this.modelResponse.labels[0].displayName : '',
        description: '',
        languageCode: 'en'
      })
    }

  }

  initForm() {
    this.form = new FormGroup({
      labels: new FormGroup({
        displayName: new FormControl('', [Validators.required, Validators.maxLength(140)]),
        description: new FormControl(''),
        languageCode: new FormControl('en'),
      }),
      items: new FormArray([new FormGroup({
        displayName: new FormControl('', [Validators.required, Validators.maxLength(140)]),
        description: new FormControl('', [Validators.maxLength(1000)]),
        languageCode: new FormControl('en'),
        attributes: new FormGroup({
          currencyCode: new FormControl('USD', [Validators.required]),
          units: new FormControl(0 , [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{2})?'), Validators.min(0)]),
          type: new FormControl(this.typeFormPrice, Validators.required),
        })
      })])
    });
  
  }

  apply() {
    let FormResult = this.form.value;
    let items = FormResult.items[0]

    if (this.isEdit) {
      this.modelResponse.labels = [{
        displayName: FormResult.labels.displayName,
        description: FormResult.labels.description,
        languageCode: FormResult.labels.languageCode
      }]
      this.dialogRef.close({action: 'update',item: this.modelResponse});
    } else {
      let units: string = '0';
      let nanos: number = 0;
      
      if (items.attributes.type !== 'free' && items.attributes.type !== 'noPrice'  ) {
        const amount = items.attributes.units.split('.');
        units = amount[0];
        nanos = Number(amount[1]);
      }

      
      
      this.modelResponse = {
        labels: [
          {
            displayName: FormResult.labels.displayName,
            description: FormResult.labels.description,
            languageCode: FormResult.labels.languageCode
          }
        ],
        items: [
          {
            attributes: {
              price: {
                currencyCode: items.attributes.currencyCode,
                units,
                nanos
              },
              servesNumPeople: 1,
              mediaKeys: []
            },
            labels: [
              {
                displayName: items.displayName,
                description: items.description,
                languageCode: items.languageCode
              }
            ]
          }
        ]
      };
  
  
      if (items.attributes.type === 'noPrice') {
        delete this.modelResponse.items[0].attributes.price;
      }
      this.dialogRef.close({action: 'added',item: this.modelResponse });
    }
  }

  onChangePrice(index) {
    const formInput = this.getItemArray(index).get('attributes.units');
    const currency = this.decimalPipe.transform(formInput.value, '1.2-2');
    formInput.setValue(currency.replace(/,/g, ''));
  }

  remove() {
    this.dialogRef.close({action: 'remove',item: this.modelResponse });
  }

  get displayName(): AbstractControl {
    return this.form.get('labels.displayName');
  }

  get ItemsArray(): FormArray {
    return (this.form.get('items') as FormArray);
  }

  getItemArray(index): AbstractControl {
    return (this.form.get('items') as FormArray).at(index);
  }

  addNewItem() {
    this.ItemsArray.push(new FormGroup({
      displayName: new FormControl('', [Validators.required, Validators.maxLength(140)]),
      description: new FormControl('', [Validators.maxLength(1000)]),
      languageCode: new FormControl('en'),
      attributes: new FormGroup({
        currencyCode: new FormControl('USD', [Validators.required]),
        units: new FormControl(0 , [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{2})?'), Validators.min(0)]),
        type: new FormControl(this.typeFormPrice, Validators.required),
      })
    }))
  }

  deleteItem(index: number) {
    this.ItemsArray.removeAt(index)
  }
}
