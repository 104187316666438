
<ng-container  *ngFor="let attributeUrl of attributes">

  <div class="box__item box__item--sm" *ngIf="!attributeUrl">
    <div class="d-flex align-items-start justify-content-between">
      <div class="d-flex align-items-start">
        <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '35px', height: '35px', 'margin-right': '30px' }"></ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ width: '270px', height: '20px' }"></ngx-skeleton-loader>
      </div>
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{ width: '30px', height: '30px','margin-right': '30px' }"></ngx-skeleton-loader>
    </div>

  </div>

  <div class="box__item box__item--editable" *ngIf="attributeUrl">
    <div class="d-flex align-items-start w--100">
      <div class="icon icon--rounded icon--gray mr--20" *ngIf="isEdit">
        <ng-container [ngSwitch]="attributeUrl.displayName">
          <ng-container *ngSwitchCase="'Menu link'">
            <i class="fas fa-utensils"></i>
          </ng-container>
          <ng-container *ngSwitchCase="'Reservations links'">
            <i class="fas fa-concierge-bell"></i>
          </ng-container>
          <ng-container *ngSwitchCase="'Order ahead links'">
            <i class="fas fa-calendar-check"></i>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <i class="far fa-at"></i>
          </ng-container>
        </ng-container>
      </div>
      <div class="icon icon--rounded icon--gray mr--20" *ngIf="!isEdit">
        <ng-container [ngSwitch]="attributeUrl.attributeId">
          <ng-container *ngSwitchCase="'url_menu'">
            <i class="fas fa-utensils"></i>
          </ng-container>
          <ng-container *ngSwitchCase="'url_reservations'">
            <i class="fas fa-concierge-bell"></i>
          </ng-container>
          <ng-container *ngSwitchCase="'url_order_ahead'">
            <i class="fas fa-calendar-check"></i>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <i class="far fa-at"></i>
          </ng-container>
        </ng-container>
      </div>
      <div class="d-block w--100">
        <h4 class="w--100 txt--md fw--700">{{ attributeUrl.displayName ? attributeUrl.displayName : '' }}</h4>


        <ng-container *ngIf="attributeUrl.urlValues && attributeUrl.urlValues.length > 0; else noUrl">
          <div *ngIf="attributeUrl.urlValues as urls" class="w--100">
            <div *ngFor="let url of urls; index as i" class="w--100 d-flex justify-content-between mt--5 mb--0 wrap">
              <p *ngIf="url.url" class="txt--md" style="width: 300px; overflow-wrap: break-word;">
                <span [outerHTML]="url.url"></span>
              </p>

              <ng-container *ngIf="!url.url">
                <ng-container *ngIf="attributeUrl.displayName; else noName">
                  <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} {{attributeUrl.displayName}}</p>
                </ng-container>
                <ng-template #noName>
                  <ng-container [ngSwitch]="attributeUrl.attributeId">
                    <ng-container *ngSwitchCase="'url_menu'">
                      <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} Menu link</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="'url_reservations'">
                      <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} Reservations links</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="'url_order_ahead'">
                      <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} Order ahead links</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="'url_covid_19_info_page'">
                      <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} COVID-19 link</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="'url_facility_telemedicine_page'">
                      <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} Virtual Care link </p>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} website</p>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-template #noUrl>
          <div class="d-flex">
            <ng-container *ngIf="attributeUrl.displayName; else noUrlNoName">
              <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} {{attributeUrl.displayName}}</p>
            </ng-container>
            <ng-template #noUrlNoName>
              <ng-container [ngSwitch]="attributeUrl.attributeId">
                <ng-container *ngSwitchCase="'url_menu'">
                  <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} Menu link</p>
                </ng-container>
                <ng-container *ngSwitchCase="'url_reservations'">
                  <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} Reservations links</p>
                </ng-container>
                <ng-container *ngSwitchCase="'url_order_ahead'">
                  <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} Order ahead links</p>
                </ng-container>
                <ng-container *ngSwitchCase="'url_covid_19_info_page'">
                  <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} COVID-19 link</p>
                </ng-container>
                <ng-container *ngSwitchCase="'url_facility_telemedicine_page'">
                  <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} Virtual Care link </p>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <p class="w--100 d-flex justify-content-between mt--5 mb--0 txt--md">{{isEdit ? 'Add' : 'No'}} website</p>
                </ng-container>
              </ng-container>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </div>
    <button mat-button (click)="openUrlAttributes(attributeUrl)" class="btn btn-icon btn-icon--gray"
      *ngIf="isEdit">
      <em class="fas fa-pencil"></em>
    </button>
  </div>
</ng-container>
