// dep
import { Router } from '@angular/router';
import { Component, Inject } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

// app
import { SessionService } from 'src/app/services/session.service';
import { NavigationService } from 'src/app/services/navigation.service';

/**
 * Dialog to show to the Customer when the Trial has ended.
 * 
 * Depending on pricingVersion:
 * - V1,V2,(V3 + locationsQty == 0): Flow is restricted to a "Contact Sales" button.
 * - V3: A "Continue to Payment" button is shown, to start the First Payment flow.
 */
@Component({
  selector: 'app-modal-trial-ended',
  templateUrl: './modal-trial-ended.component.html',
  styleUrls:  ['./modal-trial-ended.component.scss']
})
export class ModalTrialEnded {
    public enableFirstPayment = false;

    // Only for enableFirstPayment == true:
    public step : 'PAYWALL' | 'PAYMENT' = 'PAYWALL'
    public totalPrice: number
    public unitPrice: number
    public locations_qty: number

    constructor(
        public dialogRef: MatDialogRef<ModalTrialEnded>,
        @Inject(MAT_DIALOG_DATA) 
        public data: { title : string, message : string, buttons : any},
        private _sessionS : SessionService,
        private _navigationS : NavigationService,
        private _router: Router
    ){
        const {features, subscription: sub } = this._sessionS.getSession();

        // If the Customer has no locations, then we cannot enable the first payment, 
        // as we canot charge him for $0 (V3 pricing)
        this.enableFirstPayment = (features.final.updatedPricingModal && 
                                   (sub.locationsQty && sub.pricingVersion >= 3));

        if(this.enableFirstPayment) {
            this.locations_qty = sub.locationsQty;
            // FIXME: This doesn't take into account CHARGE_FLAT_RATE, etc. 
            // Also the correct algorithm should be encapsulated in a single place.
            const tiers = sub.pricing?.pkg_bulk_actions?.tiers;
            for (const t of tiers) {
                if (this.locations_qty >= t?.locationsQtyMin && 
                    this.locations_qty <= t?.locationsQtyMax) {
                    this.totalPrice = t?.price * this.locations_qty;
                    this.unitPrice = t?.price;
                    return;
                }
            }
        }

    }

    public accept_cta() : void {
        window.open(this.data.buttons['accept_cta'], "_blank");
    }

    public moveToPayment() : void {
        this.step = 'PAYMENT'
    }

    public onClosePlanBilling(event = false): void {
        if (event) {
            this._sessionS.refresh();
            this.dialogRef.close();
            this._router.navigate(['/settings/billing']);
        }
    }

    public close(event = false) : void {
        if (!event) {
            this.dialogRef.close();
            this._navigationS.signOut();
        } else { // if event is true, it means the payment went through, so we redirect the user to locations
            this._sessionS.refresh();
            this.dialogRef.close();
            this._router.navigate(['/locations']);
        }
    }
}
