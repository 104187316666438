
<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="updatingData || firstLoad">
  <mat-spinner></mat-spinner>
</div>

<app-reports-filter 
  [reportType]="'abc-insights'"
  [comparison]="false"
  [hasShare]="true"
  [hasExportCSV]="false"
  [hasExportPDF]="false"
  (navigateBackEmitter)="goToLocations()"></app-reports-filter>

<div *ngIf="!updatingData && !firstLoad" id="charts-container" [class.view-without-padding]="!isReport" class="container--full container--padding-lg pb--0">
    <h2 *ngIf="reportName"><strong>{{reportName|uppercase}}</strong></h2>

    <div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="isProgress">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="!isProgress && stats?.above?.length > 0">
      <ng-container *ngIf="stats?.above">
        <ng-container *ngFor="let stat of stats?.above">
          <app-chart-stats 
            *ngIf="stat?.totals > 0"
            [hasChartLineGraph]="!isDonutsGrpah(stat.title)"
            [reportType]="typeReport"
            [stats]="stat"
            [labels]="stat?.labels"
            [granularity]="granularity"
            [hasDonutGraph]="isDonutsGrpah(stat.title)"
            [centerLegend]="true"
            [isApple]="true"
            [isMockedData]="stat.title === 'What people searched for before tapping your location in search results' ? true : false"
          ></app-chart-stats>
        </ng-container>
      </ng-container>
    </div>

    <div class="box--height mb--30"  *ngIf="stats?.above?.length === 0 && !isProgress">
      <div class="box--rounded box--shadow bg--white">
        <div class="box__header d-flex align-items-center justify-content-center">
          <h2 class="txt--capitalize"><strong>No data</strong></h2>
        </div>
      </div>
    </div>

    <ng-container *ngIf="stats?.below?.length > 0 && !isProgress">
      <div class="col-12 mb--30 p--0">
        <div class="box--rounded box--shadow bg--white row">
          <section class="col-6">
            <div class="align-items-center justify-content-start p--20">
                <h2 class="txt--md m--0 mb--15 txt--red"><strong>Where people were when they tapped your location in search results</strong></h2>
            </div>

            <table mat-table matSort matSortDisableClear class="table" [dataSource]="searchLocationClicksData">
              <ng-container matColumnDef="rank">
                <th mat-header-cell *matHeaderCellDef class="txt--black">
                  <span>Rank</span>
                </th>
                <td mat-cell *matCellDef="let row">
                  <div class="cell__content flex-column align-items-start justify-content-center">
                    <p class="m--0 txt--md">{{ row?.rank }}</p>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="zipCodes">
                <th mat-header-cell *matHeaderCellDef class="txt--black">
                  <span>Zip Codes</span>
                </th>
                <td mat-cell *matCellDef="let row">
                  <div class="cell__content flex-column align-items-start justify-content-center">
                    <p class="m--0 txt--md">{{ row?.zipCodes | titlecase }}</p>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="percent">
                <th mat-header-cell *matHeaderCellDef class="txt--black txt--right p--0 w--10">
                  <span class="mr--10">%</span>
                </th>
                <td mat-cell *matCellDef="let row" class="p--0">
                  <div class="flex-column align-items-end justify-content-center">
                    <p class="m--0 txt--md txt--right mr--10">{{ row?.percent }}</p>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>                    
          </section>

          <section class="col-6">
            <div class="align-items-center justify-content-start p--20">
                <h2 class="txt--md m--0 mb--15 txt--red"><strong>Where people were when they got direction to your location</strong></h2>
            </div>

            <table mat-table matSort matSortDisableClear class="table" [dataSource]="directionsRequestsLocationData">
              <ng-container matColumnDef="rank">
                <th mat-header-cell *matHeaderCellDef class="txt--black">
                  <span>Rank</span>
                </th>
                <td mat-cell *matCellDef="let row">
                  <div class="cell__content flex-column align-items-start justify-content-center">
                    <p class="m--0 txt--md">{{ row?.rank }}</p>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="zipCodes">
                <th mat-header-cell *matHeaderCellDef class="txt--black">
                  <span>Zip Codes</span>
                </th>
                <td mat-cell *matCellDef="let row">
                  <div class="cell__content flex-column align-items-start justify-content-center">
                    <p class="m--0 txt--md">{{ row?.zipCodes | titlecase }}</p>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="percent">
                <th mat-header-cell *matHeaderCellDef class="txt--black txt--right p--0 w--10">
                  <span class="mr--10">%</span>
                </th>
                <td mat-cell *matCellDef="let row" class="p--0">
                  <div class="flex-column align-items-end justify-content-center">
                    <p class="m--0 txt--md txt--right mr--10">{{ row?.percent }}</p>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </section>
        </div>
      </div>

      <section class="col-12 d-flex p--0 photo-metrics">
        <div class="col-6 mb--30">
          <div class="box--rounded box--shadow bg--white row pl--20 pr--20 d--block">
            <section>
              <div class="align-items-center justify-content-start pt--20 pb--20">
                <h2 class="txt--md m--0 mb--15 txt--red"><strong>Photos on Your Place Card</strong></h2>
              </div>
              
              <div *ngIf="photoMetrics && photoMetrics?.placeCardPhotos">
                <app-chart-progress 
                  [total]="photoMetrics?.placeCardPhotos?.yourLocation" 
                  [label]="'Your Location'" 
                  [bgColor]="photoMetrics?.placeCardPhotos?.yourLocation?.borderColor" 
                  [index]="0"
                ></app-chart-progress>

                <app-chart-progress 
                  [total]="photoMetrics?.placeCardPhotos?.othersLocations" 
                  [label]="'Locations like yours'" 
                  [bgColor]="photoMetrics?.placeCardPhotos?.othersLocations?.borderColor" 
                  [index]="1"
                ></app-chart-progress>
              </div>
            </section>
          </div>
        </div>

        <div class="col-6 mb--30">
          <div class="box--rounded box--shadow bg--white row pl--20 pr--20 d--block">
            <section>
              <div class="align-items-center justify-content-start pt--20 pb--20">
                <h2 class="txt--md m--0 mb--15 txt--red"><strong>Interactions with Photos</strong></h2>
              </div>
              <div class="pl--20 pr--20" *ngIf="photoMetrics && photoMetrics?.placeCardPhotoInteractions">
                <app-chart-progress 
                  [total]="photoMetrics?.placeCardPhotoInteractions?.yourLocation" 
                  [label]="'Your Location'" 
                  [bgColor]="photoMetrics?.placeCardPhotoInteractions?.yourLocation?.borderColor" 
                  [index]="0"
                ></app-chart-progress>
              
                <app-chart-progress 
                  [total]="photoMetrics?.placeCardPhotoInteractions?.othersLocations" 
                  [label]="'Locations like yours'" 
                  [bgColor]="photoMetrics?.placeCardPhotoInteractions?.othersLocations?.borderColor" 
                  [index]="1"
                ></app-chart-progress>
              </div>
            </section>
          </div>
        </div>
      </section>

      <div>
        <ng-container *ngIf="stats?.below">
          <ng-container *ngFor="let stat of stats?.below">
            <app-chart-stats 
              *ngIf="stat?.totals > 0"
              [reportType]="typeReport"
              [stats]="stat"
              [labels]="stat?.labels"
              [dataPicker]="dataPicker"
              [hasDonutGraph]="false"
              [centerLegend]="true"
              [isApple]="true"
              [isMockedData]="true"
            ></app-chart-stats>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>


    <!-- <div class="box--height mb--30"  *ngIf="(stats?.below?.length === 0 && !isProgress)">
      <div class="box--rounded box--shadow bg--white">
        <div class="box__header d-flex align-items-center justify-content-center">
          <h2 class="txt--capitalize"><strong>No data</strong></h2>
        </div>
      </div>
    </div> -->
</div>