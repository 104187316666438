// dep
import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Observable } from 'rxjs';

// app
import { environment as ENV} from "@environment"
import { ModalService } from "./modal.service"
import { ISubscription } from "../constants/subscription"
import { UpdateCardComponent } from "../settings/billing/update-card/update-card.component"
import { SpinnerService } from "./spinner.service"
import { GROUP_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE, LOCATION_SUBSCRIPTION_TYPE_PriceOrder } from "../constants/firestore/account-location"
import { LocationRef, normalizeLocationRef } from "../constants/firestore/location-object"
import { UpgradeLocationsComponent } from '../components/upgrade-locations';
import { ChangePlanLocationComponent } from "../modules/change-plan-location/change-plan-location.component"
import { LocationService } from "./location.service"
import { PaymentsService } from "./payments.service"
import { NavigationService } from "./navigation.service"
import { SessionService } from "./session.service"
import { ApiResponse2 } from "../constants/api-response";

type LocationsChangePlanResult = { total: number, 
                                   nextPlan: LOCATION_SUBSCRIPTION_TYPE, 
                                   success: boolean }
@Injectable()
export class SubscriptionService {

  constructor(
    private _sessionS: SessionService, 
    private _navigationS: NavigationService,
    private locationService: LocationService,
    private paymentsService : PaymentsService,
    private spinnerService: SpinnerService,
    private http: HttpClient,
    private modalService: ModalService,
  ) { }
  
  async fetchInvoices(gid : string) : Promise<{data : any[]}> {
    return await this.http.get<{data : any[]}>(`${ENV.billingApiUrl}/invoice/gid/${gid}`).toPromise();
  }

  /**
   * Asks the user to add a Payment Method (only a Credit Card for now) if his Subscription needs it and
   * there is none.
   * @returns string if the user added a PM of if he didn't need to.
   * @returns false if the subscription needed a PM but the user didn't add one.
   * TODO: Move to UX flows module
   */
  async askForPaymentMethodIfNeededAndNoneFound(sub : ISubscription) : Promise<'OK_NOT_NEEDED' | 'OK_HAS_ONE' | 'OK_ADDED' | false> {
    if(!sub.collectionByBillingOverride[sub.billingOverride.toString()].requiresPaymentMethod)
      return 'OK_NOT_NEEDED'

    try {
      this.spinnerService.loading$.next(true)
      if(await this.paymentsService.hasPaymentMethods(sub.gid))
        return 'OK_HAS_ONE'
    } finally {
      this.spinnerService.loading$.next(false)
    }

    try {
      if(await this.modalService.openModal(UpdateCardComponent, {}))
        return 'OK_ADDED'
    } catch (e) {
      console.error("Error adding Credit Card", e)
    }
    return false

  }


  /**
   * Stops Trial if it's possible
   * @returns true if the Trial was stopped
   */
  async stopTrial(sub : ISubscription) : Promise<boolean> {
    if(sub.status !== GROUP_SUBSCRIPTION_TYPE.TRIAL)
      return false

    const r = await this.askForPaymentMethodIfNeededAndNoneFound(sub)

    if(!r)
      return false

    if(r === 'OK_ADDED')
      // When the PM is added, the subscription is automatically stoped, we don't need to do anything
      return true
 
    // assert r !== 'OK_HAS_ONE'  // if has one, then should not be on trial

    await this.http.post(`${ENV.billingApiUrl}/subscription/${sub.gid}/stop-trial`, {}).toPromise()
    await this._sessionS.refresh();
    // Locations are changed from FREE to ESSENTIAL
    this.locationService.someLocationChanged$.next(null)
    return true
  }

  // TODO: Move this to an UX flows module, as this is too high level to be put
  // on a service that should only handle the API calls.
  async flowChangeLocationsPlan(locations: LocationRef[]): Promise<LocationsChangePlanResult | null> {
    const sub = this._sessionS.getSession().subscription

    // Normalize, TODO: make the callers send this on the correct format and remove this
    locations = locations.map(l => normalizeLocationRef(l))

    try {  
      //---------------------------------------------------------
      // 1- Ask the user which Plan he wants for his location(s)
      //---------------------------------------------------------

      // - Show the pricing modal asking for plan selection (downgrades or upgrades),
      //   a SINGLE plan will be choosen for all locations.
      // - Locations are only used to determine which possible plans offer to the user. 
      // - Before using ChangePlanLocationComponent this used UltimateComponent
      const changePlan = await this.modalService.openModal(ChangePlanLocationComponent, locations)
      if (!changePlan)
        return null

      //------------------------------------------------------------
      // 2- Ask to select locations if they weren't selected before 
      //------------------------------------------------------------
      if (!locations) {
        locations = await this._openSelectLocationsToChangePlanDialog()

        if (!locations)
          // No location selected
          return null
      }

      // Locations selected, start fetching them.
      let locationsPromiseFinished = false
      const locationsPromise = (async () => {
        try {
          return await this.locationService.fetchMultipleLocations(sub.gid, locations, ['subscriptionType'] as ['subscriptionType'])
        } finally {
          locationsPromiseFinished = true
        }})()


      //-------------------------
      // 3- Ask for confirmation
      //-------------------------
      const {requiresPaymentMethod} = sub.collectionByBillingOverride[sub.billingOverride.toString()]

      let message = ""
      let title = ""
      let description = ""

      const loc_desc = (locations.length == 1 ? "this location" : `these ${locations.length} locations`)

      switch (changePlan.action) {
        case 'upgrade':
          title       = "Upgrade Profile"
          message     = `upgrade ${loc_desc}?`
          description = "By clicking 'Confirm' " + (requiresPaymentMethod ? "your card will be charged immediately." : "the changes will be applied immediately.")
          break;

        case 'downgrade':
          title       = "Downgrade Profile"
          message     = `downgrade ${loc_desc}?`
          description = "By clicking confirm, you will still have access to all the features on your current plan through the end of the current billing cycle."
          break;

        case 'change_plan':
          title       = "Change Profile Plan"
          message     = `change the subscription plan for ${loc_desc}?`
          description = "By clicking confirm, you will still have access to all the features on your current plan through the end of the current billing cycle."
          break;
      }

      if (!await this.modalService.openConfirmModal(title, "Are you sure you want to "+message, null, null, null, false, description, true))
        return null

      //------------------------------------
      // 4- Check if plan change is allowed
      // TODO: After all the refactor works, move check to backend
      //------------------------------------

      let currentPlans : LOCATION_SUBSCRIPTION_TYPE[]
      let spinnerStarted = false
      try {
        if(!locationsPromiseFinished) {
          this.spinnerService.loading$.next(true)
          spinnerStarted = true
        }
        currentPlans = [... new Set((await locationsPromise).map(l => l.subscriptionType))]
      } finally {
        if(spinnerStarted)
          this.spinnerService.loading$.next(false)
      }

      const has_downgrades = !!currentPlans.find( plan => LOCATION_SUBSCRIPTION_TYPE_PriceOrder.indexOf(plan) > 
                                                          LOCATION_SUBSCRIPTION_TYPE_PriceOrder.indexOf(changePlan.nextPlan))
      const has_upgrades   = !!currentPlans.find( plan => LOCATION_SUBSCRIPTION_TYPE_PriceOrder.indexOf(plan) < 
                                                          LOCATION_SUBSCRIPTION_TYPE_PriceOrder.indexOf(changePlan.nextPlan))
 
      let only_ask_for_payment_method = false
      if (has_downgrades && requiresPaymentMethod && sub.customerPendingChargesTotal > 0) {
        // Downgrades are not allowed if there are pending charges (MAP-1253)
        const mail  = this._navigationS.supportMailURL
        const name  = this._sessionS.getSession().user.displayName
        const total = sub.customerPendingChargesTotal.toFixed(2)

        await this.modalService.openErrorModal('Unpaid balance on your account',
                                                `Hi ${name}, it looks like your subscription have an unpaid balance `+
                                                `of $${total}. After paying your balance, you’ll be ` +
                                                "able to downgrade. If you believe this is an error, "+
                                                `<a class="cursor--pointer txt--underline" [href]="'${mail}'">`+
                                                  "<span>contact support</span>"+
                                                "</a> and we’ll help.")
        only_ask_for_payment_method = true
      } 

      // TODO: Disallow upgrades if there are pending charges? Check MAP-2243


      //-------------------------------------
      // 5- Ask to add a Card if he needs to 
      //-------------------------------------
      if(!await this.askForPaymentMethodIfNeededAndNoneFound(sub))
        return null 

      if(only_ask_for_payment_method)
        return null

      //------------------
      // 6- Apply changes
      //------------------
      this.spinnerService.loading$.next(true)
      const changeResult = await this.applyChangePlan(locations, changePlan.nextPlan)
      this.spinnerService.loading$.next(false)
      if (changeResult && changeResult.total > 0 && requiresPaymentMethod)
        // Only show this if he will be charged directly, see MS-716
        await this.modalService.openInfoModal('Payment Confirmation',
                                              `Your transaction was successful. You were charged $${changeResult.total / 100}`)
      return changeResult

    } catch (e) {
      console.error("Error on flowChangeLocationsPlan", e)
      await this.modalService.openErrorLoadingModal(6);
      return null
    } finally {
      await this._sessionS.refresh()
      this.locationService.someLocationChanged$.next(null)
    }
  }


  async applyChangePlan(locations : LocationRef[], nextPlan : LOCATION_SUBSCRIPTION_TYPE): Promise<LocationsChangePlanResult> {
    const locationsToChange = locations.map( l => ({ ... normalizeLocationRef(l),
                                                     nextPlan }))
    const {user} = this._sessionS.getSession()

    // console.log(locationsToUpgrade);
    const changeResult = await this._sendChangePlan(user.registrationDomain, user.gid, locationsToChange)
 
    await this._sessionS.refresh()
    return { total:    changeResult.total, 
             nextPlan, 
             success:  true }
  }

  private async _openSelectLocationsToChangePlanDialog() : Promise<LocationRef[]> {   
    const als = await this.modalService.openModal(UpgradeLocationsComponent, { closeButtonLabel: 'Ok'}, {disableClose: true})

    const locations = [];
    (als || []).forEach(acc => {
      acc.locations.forEach(loc => locations.push(loc));
    })
    return locations
  }


  private async _sendChangePlan(domain : string, gid : string, locations : (LocationRef & { nextPlan : LOCATION_SUBSCRIPTION_TYPE })[]) : 
    Promise<{ total : number
              products : { gid : string
                           account_id : string
                           location_id : string
                           changes : any }[]}>  
  {
    return (await this.http.post(`${ENV.billingApiUrl}/locations/plan/update`, {domain, gid, locations}).toPromise() as any).data
  }

  // TODO: Review this endpoint, as is still using main-api for plan change. 
  upgradeLocation(placeId : string, accountId : string, gid : string, plan?) {
    return this.http.post(`${ENV.apiUrl}/v2/locations/${placeId}/upgrade`, { accountId, gid, plan }).toPromise();
  }

  // response is a blob
  getInvoicePDF(invId: string): Observable<Blob> {
    return this.http.get(`${ENV.billingApiUrl}/invoice/${invId}/pdf`, { responseType: 'blob' })
  }

  async createSubscription() : Promise<void> {
    // All parameters required by the endpoint are sent as headers (gid, uid and x-domain)
    await this.http.post(`${ENV.billingApiUrl}/customer/subscription`, {}).toPromise();
  }

  async fetchSubscription(gid : string, products=false) : Promise<ISubscription> {
    return ((await this.http.get<ApiResponse2<ISubscription>>(`${ENV.billingApiUrl}/subscription/${gid}?products=${products ? 1 : 0}`).toPromise()) as any).data
  }
  
}
