<section #attributes>
<!--Add attributes-->
  <app-skeleton-location-info-box type="icon" *ngIf="isProgressAttributes"></app-skeleton-location-info-box>
  <p *ngIf="!isEdit && attributesItems?.length > 0" class="mb--15"><strong>Attributes</strong></p>
  <div class="box__item box__item--editable" [class.align-items-start]="attributesInGroups" *ngIf="!isProgressAttributes && (attributesItems?.length > 0 || isEdit)">
    <div class="d-flex" [ngClass]="attributesInGroups ? 'align-items-start' : 'align-items-center'">
      <div class="icon icon--rounded icon--gray mr--20"><em class="far fa-tag"></em></div>
      <div class="d-block" *ngIf="!isProgressAttributes && isEdit">
        <p class="w--100 txt--md fw--700" *ngIf="hasAttributes()">Attributes</p>
        <ng-container *ngFor="let group of attributesInGroups">
          <ng-container *ngIf="group.active">
            <h4 class="w--100 txt--md mt--10" *ngIf="group?.groupName!='Place page URLs'"><strong>{{group?.groupName | titlecase}}</strong></h4>
            <ng-container class="w--100 ml--10 m--0" *ngFor="let item of group.items">
              <ng-container *ngIf="item.valueType=='BOOL'">
                <ng-container *ngIf="item.active != null">
                  <p class="ml--10 m--0 txt--md" [ngStyle]="{'text-decoration': !item.active ? 'line-through' : 'initial'}">
                    {{item?.displayName}}
                  </p>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="item.valueType == 'REPEATED_ENUM'">
                <p class="ml--10 m--0" *ngIf="item.active">{{item.displayName}}</p>
                <ng-container *ngFor="let metadata of item.valueMetadata">
                  <ng-container *ngIf="metadata.active != null">
                    <p class="ml--30 m--0 txt--md" [ngStyle]="{'text-decoration': !metadata.active ? 'line-through' : 'initial'}">
                      {{metadata?.displayName}}
                    </p>
                  </ng-container>
                </ng-container>
              </ng-container>

            </ng-container>
            <ng-container class="w--100 ml--10 m--0" *ngIf="!googleItemActive && !hasAttributes()">
              <p class="mb--5 txt--md">Add attributes</p>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- <div class="w--100" *ngIf="!leastOneGroupActive">
          <h4 class="w--100 txt--md fw--700 pt--10">Attributes</h4>
          <p class="mb--5 txt--md">Add attributes</p>
        </div> -->
      </div>

      <div class="d-block" *ngIf="!isProgressAttributes && !isEdit">
        <ng-container class="w--100 ml--10" *ngFor="let item of attributesItems">
          <ng-container *ngIf="item.valueType=='BOOL'">
            <ng-container>
              <p class="ml--10">
                <mat-chip>
                  <i class="far fa-check mr--15" *ngIf="item.values[0] === true"></i>
                  <i class="far fa-ban mr--15" *ngIf="item.values[0] === false"></i>
                  <i class="far fa-plus mr--15" *ngIf=" item.values[0]==undefined"></i>
                </mat-chip>
                <span [ngClass]="{'border--error': item.values[0] === false, 'border--success': item.values[0] === true, 'border--undefined': item.values[0]==undefined}"
                >{{item.attributeId | attributtesFetch | titlecase}}</span>
              </p>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="item.valueType == 'REPEATED_ENUM'">
            <p class="ml--10" *ngIf="item.active">{{item.displayName}}</p>
            <ng-container *ngIf="item?.repeatedEnumValue?.setValues">
              <ng-container *ngFor="let value of item.repeatedEnumValue.setValues">
                <p class="ml--30">
                  <mat-chip><i class="far fa-check mr--15"></i></mat-chip>
                  {{value | attributtesFetch | titlecase}}
                </p>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="item?.repeatedEnumValue?.unsetValues">
              <ng-container *ngFor="let value of item.repeatedEnumValue.unsetValues">
                <p class="ml--30 txt--md">
                  <mat-chip><em class="far fa-ban mr--15"></em></mat-chip>
                  {{value | attributtesFetch | titlecase}}
                </p>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <div class="w--100" *ngIf="!leastOneGroupActive && isEdit">
          <p class="mb--5 txt--md">Add attributes</p>
        </div>
      </div>
    </div>
    <button mat-button (click)="openAttributesDialog()" class="btn btn-icon btn-icon--gray" *ngIf="isEdit">
      <em class="fas fa-pencil"></em>
    </button>

  </div>
</section> 

<section class="wrap" #socialMedia *ngIf="socialMediaItems?.length > 0 || isEdit">
  <app-skeleton-location-info-box type="icon" *ngIf="isProgressAttributes"></app-skeleton-location-info-box>
  <div class="box__item box__item--editable" [class.align-items-start]="socialMediaItems.length > 0"  *ngIf="!isProgressAttributes">
    <div class="d-flex w--100" [ngClass]="socialMediaItems.length > 0 ? 'align-items-start' : 'align-items-center'">
      <div class="icon icon--rounded icon--gray mr--20"><em class="far fa-hashtag"></em></div>
      <div class="d-block p--0 w--100" *ngIf="!isProgressAttributes">
        <ng-container *ngIf="socialMediaItems?.length > 0">
          <h4 class="w--100"><strong>Social Media Profiles</strong></h4>
          <section *ngFor="let item of socialMediaItems" class="mb--10">
            <svg-icon [src]="getSocialMediaItems(item.attributeId)?.icon" class="menu__item__icon vertical--align--sub m--0 mr--10"></svg-icon>
            <span [outerHTML]="getUrl(item)"></span>
          </section>
        </ng-container>

        <div class="w--100" *ngIf="socialMediaItems?.length === 0 && isEdit">
          <h4 class="w--100"><strong>Social Media Profiles</strong></h4>
          <p class="mb--5">Add Social Media</p>
        </div>
      </div>

    </div>
    <button mat-button (click)="openAttributesDialog(true)" class="btn btn-icon btn-icon--gray" *ngIf="isEdit">
      <em class="fas fa-pencil"></em>
    </button>
  </div>

</section>

