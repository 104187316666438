<div class="box__item box__item--editable align-items-start" [class.pb--0]="isUnderReview">
  <div class="d-flex w--100">
    <div class="icon icon--rounded icon--gray mr--20 d-block">
      <img alt="" src="../../../assets/images/icons/more-clock.svg" class="mw--xxs">
    </div>

    <div class="d-block" *ngIf="!moreHoursFormatted?.length">
      <h4 class="w--100 txt--md fw--700 mb--10">More Hours</h4>
      <p class="w--100 wrap">Add hours</p>
    </div>

    <div class="d-block w--100" [class.txt--medium-gray]="isUnderReview" *ngIf="moreHoursFormatted?.length">
      <h4 class="w--100 txt--md fw--700 mb--10">More Hours</h4>
      <ng-container *ngFor="let item of moreHoursFormatted">
        <div class="mt--10">
          <p class="txt--md mb--5"><strong>{{ item.displayName | titlecase}}</strong></p>
        </div>
        <div class="w--100 row">
          <ng-container *ngFor="let day of item.periodsByDay | keyvalue: originalOrder">
            <ng-container *ngFor="let hours of day.value; let i = index;">
              <ng-container class="data-hours" *ngIf="hours?.open">
                <div class="col-4">
                  <p *ngIf="i === 0" class="txt--md">{{ day.key | titlecase}}</p>
                </div>
                <div class="col-8">
                  <p class="txt--uppercase txt--md">
                    <span>{{ hours?.openTime}} - {{ hours?.closeTime}}</span>
                    <ng-template #allDay>
                      {{ hours?.startTime }}
                    </ng-template>
                  </p>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  
  <button mat-button (click)="openMoreHoursDialog()" [disabled]="countRegularHours == 0" class="btn btn-icon btn-icon--gray" *ngIf="isEdit">
    <em class="fas fa-pencil" [matTooltipDisabled]="countRegularHours > 0" matTooltip="You must add regular hours before you can add more hours. " matTooltipPosition="below" matTooltipClass="tooltip tooltip--black"></em>
  </button>
</div>
<div *ngIf="isUnderReview" class="txt-pendingMask">
  <span class="under-review" role="alert">Under review</span>
</div>
