// dep
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

// app
import { SessionService } from '../services/session.service';

@Component({
  selector: 'app-featured',
  template: `
      <div class="dialog__content dialog--featured" mat-dialog-content>
        <img src="/assets/images/modal-star.png" alt="Image of a star" class="dialog__icon" width="128" height="128">
        <button mat-dialog-close="" class="btn btn-dialog-close btn--absolute"><i class="txt--lg fal fa-times"></i></button>
        
        <div *ngIf="!(session$ | async).isMember"> 
          <div class="dialog-row txt--center">
            <h2 class="heading--xl mb--10 txt--black"><strong>This is a Premium Feature</strong></h2>
            <p class="heading-h2 mb--10">To access this feature you need to upgrade your subscription.</p>
          </div>
          <div class="d-flex justify-content-center">
            <button mat-flat-button color="accent" class="btn btn--md txt--md" mat-dialog-close="true">Upgrade Now</button>
          </div>
        </div>

        <div *ngIf="(session$ | async).isMember"> 
          <div class="dialog-row txt--center">
            <h2 class="heading--xl mb--10 txt--black"><strong>This is a Premium Feature</strong></h2>
            <p class="heading-h2 mb--10">To access this feature you need to contact your administrator.</p>
          </div>
        </div>
        
      </div>
  `,
})
export class FeaturedComponent {
  OPTS_CONFIG = {config : {width: '470px',
                           height: '420px'},
                 disableClose : true }

  session$ = this._sessionS.session$
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialog: MatDialog,
    private _sessionS : SessionService
  ) {
  }


}
