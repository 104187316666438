
<app-reports-filter [locations]="locations" type="Review Assistant" [isShared]="isShared" [lockDates]="lockDates"></app-reports-filter>

<div id="charts-container">
    <div class="container--full container--padding-lg" >
        <h2 *ngIf='reportName'><strong>{{reportName|uppercase}}</strong></h2>
        <!--review insight content-->
        <div class="box box--rounded box--shadow bg--white mb--30" *ngIf="noData">
            <div class="box__header d-flex align-items-center justify-content-center">
                <h2 class="txt--capitalize"><strong>No reviews data</strong></h2>
            </div>
        </div>
        <div class="row box--height">

            <!--overall star rating-->
            <div class="col-12 col-sm-6 col-lg-3 mb--30">
                <div class="box box--padding-xxs box--shadow box--rounded bg--white">
                    <h2 class="txt--capitalize"><strong>overall star rating</strong></h2>

                    <app-skeleton-reviews type="OVERALL-RATING" *ngIf="isProgressResume && !reviewsStats">
                    </app-skeleton-reviews>

                    <div class="d-flex justify-content-between flex-column"
                         *ngIf="reviewsStats">

                        <div class="d-flex align-items-center justify-content-start mb--25">
                            <p class="txt--xxxl txt--lightblue fw--medium m--0">
                              {{reviewsStats.averageRating| number : '1.1-1'}}
                            </p>
                            <app-starts type="average" [starts]="reviewsStats.averageRating"></app-starts>
                        </div>
                        <!-- <div class="d-flex align-items-end statistics statistics--up" *ngIf="differenceAverage>0.1">
                            <em data-html2canvas-ignore="true" class="far fa-long-arrow-up mr--10"></em>
                            <p class="txt--lightgray"><span
                                class="statistics--number">+ {{differenceAverage | number: '1.0-2'}}</span>points from
                            last month</p>
                        </div> -->
                    </div>
                    <p class="txt--capitalize txt--lightgray"
                       *ngIf="!reviewsStats && !isProgressResume">
                      <strong>No data</strong>
                    </p>
                </div>
            </div>

            <!--total reviews-->
            <div class="col-12 col-sm-6 col-lg-3 mb--30">
                <div class="box box--padding-xxs box--shadow box--rounded bg--white">
                    <h2 class="txt--capitalize"><strong>total reviews</strong></h2>

                    <app-skeleton-reviews type="TOTAL-REVIEWS"
                                          *ngIf="isProgressResume && !reviewsStats">
                    </app-skeleton-reviews>


                    <div class="d-flex justify-content-between flex-column"
                         *ngIf="reviewsStats">
                        <div class="d-flex align-items-center justify-content-start mb--25">
                            <p
                                class="txt--xxxl txt--lightblue fw--medium m--0">
                              {{reviewsStats.totalReviewCount | number: '1.0-0'}}
                            </p>
                        </div>
                        <div class="d-flex align-items-end statistics statistics--up"
                             *ngIf="differenceTotal>0">
                            <em class="far fa-long-arrow-up mr--10">
                            </em>
                        </div>
                    </div>
                    <p class="txt--capitalize txt--lightgray"
                       *ngIf="!reviewsStats && !isProgressResume">
                      <strong>No data</strong>
                    </p>
                </div>
            </div>

            <!--rating distribution-->
            <div class="col-12 col-sm-6 col-lg-3 mb--30">
                <div class="box box--padding-xxs box--shadow box--rounded bg--white">
                    <h2 class="txt--capitalize mb--10"><strong>rating distribution</strong></h2>

                    <app-skeleton-reviews type="RATING-DISTRIBUTION"
                                          *ngIf="isProgressDistribution && !rating">
                    </app-skeleton-reviews>

                    <div class="rating-distribution"
                         *ngIf="rating">
                        <div class="d-flex justify-content-start align-items-center flex-wrap mb--5">
                            <span class="txt--md">5</span>
                            <span class="single-star" *ngIf="rating.FIVE.count == 0"></span>
                            <span class="single-star single-star--full" *ngIf="rating.FIVE.count > 0"></span>
                            <mat-progress-bar mode="determinate" [value]="rating.FIVE.percent" color="primary"></mat-progress-bar>
                            <span class="rating--percentage txt--md">
                                {{rating.FIVE.percent | number:'1.0-0'}}%
                                <sub *ngIf="rating.FIVE.count != ''" matTooltipPosition="above" [matTooltip]="rating.FIVE.count| number: '1.0-0'" class="txt--lightgray"> ({{rating.FIVE.count <= 99999 ? (rating.FIVE.count| shortNumber: "1.0-0" ) : '+9.9K' }})</sub>
                                <sub *ngIf="rating.FIVE.count == ''" class="txt--lightgray"> (0)</sub>
                            </span>
                        </div>
                        <div class="d-flex justify-content-start align-items-center flex-wrap mb--5">
                            <span class="txt--md">4</span>
                            <span class="single-star" *ngIf="rating.FOUR.count == 0"></span>
                            <span class="single-star single-star--full" *ngIf="rating.FOUR.count > 0"></span>
                            <mat-progress-bar mode="determinate" [value]="rating.FOUR.percent"
                                                color="primary"></mat-progress-bar>
                            <span class="rating--percentage txt--md">
                                {{rating.FOUR.percent| number:'1.0-0'}}%
                                <sub *ngIf="rating.FOUR.count != ''" matTooltipPosition="above" [matTooltip]="rating.FOUR.count| number: '1.0-0'"  class="txt--lightgray">({{rating.FOUR.count <= 99999 ? (rating.FOUR.count | shortNumber:'1.0-0') : '+9.9K'}})</sub>
                                <sub *ngIf="rating.FOUR.count == ''" class="txt--lightgray"> (0)</sub>
                            </span>
                        </div>
                        <div class="d-flex justify-content-start align-items-center flex-wrap mb--5">
                            <span class="txt--md">3</span>
                            <span class="single-star" *ngIf="rating.THREE.count == 0"></span>
                            <span class="single-star single-star--full" *ngIf="rating.THREE.count > 0"></span>
                            <mat-progress-bar mode="determinate" [value]="rating.THREE.percent"
                                                color="primary"></mat-progress-bar>
                            <span class="rating--percentage txt--md">
                                {{rating.THREE.percent| number:'1.0-0'}}%
                                <sub *ngIf="rating.THREE.count != ''" matTooltipPosition="above" [matTooltip]="rating.THREE.count| number: '1.0-0'"  class="txt--lightgray">({{rating.THREE.count <= 99999 ? (rating.THREE.count | shortNumber:'1.0-0') : '+9.9K'}})</sub>
                                <sub *ngIf="rating.THREE.count == ''" class="txt--lightgray"> (0)</sub>
                            </span>
                        </div>

                        <div class="d-flex justify-content-start align-items-center flex-wrap mb--5">
                            <span class="txt--md">2</span>
                            <span class="single-star" *ngIf="rating.TWO.count == 0"></span>
                            <span class="single-star single-star--full" *ngIf="rating.TWO.count > 0"></span>
                            <mat-progress-bar mode="determinate" [value]="rating.TWO.percent"
                                                color="primary"></mat-progress-bar>
                            <span class="rating--percentage txt--md">
                                {{rating.TWO.percent| number:'1.0-0'}}%
                                <sub *ngIf="rating.TWO.count != ''" matTooltipPosition="above" [matTooltip]="rating.TWO.count| number: '1.0-0'" class="txt--lightgray">({{rating.TWO.count <= 99999 ? (rating.TWO.count | shortNumber:'1.0-0') : '+9.9K'}})</sub>
                                <sub *ngIf="rating.TWO.count == ''" class="txt--lightgray"> (0)</sub>
                            </span>
                        </div>

                        <div class="d-flex justify-content-start align-items-center flex-wrap mb--5">
                            <span class="txt--md">1</span>
                            <span class="single-star" *ngIf="rating.ONE.count == 0"></span>
                            <span class="single-star single-star--full" *ngIf="rating.ONE.count > 0"></span>
                            <mat-progress-bar mode="determinate" [value]="rating.ONE.percent"
                                                color="primary"></mat-progress-bar>
                            <span class="rating--percentage txt--md">
                                {{rating.ONE.percent| number:'1.0-0'}}%
                                <sub *ngIf="rating.ONE.count != ''"  matTooltipPosition="above" [matTooltip]="rating.ONE.count| number: '1.0-0'" class="txt--lightgray">({{rating.ONE.count <= 99999 ? (rating.ONE.count|shortNumber:'1.0-0') : '+9.9K'}})</sub>
                                <sub *ngIf="rating.ONE.count == ''" class="txt--lightgray"> (0)</sub>
                            </span>
                        </div>
                    </div>
                    <p class="txt--capitalize txt--lightgray"
                       *ngIf="!rating && !isProgressDistribution">
                      <strong>No data</strong>
                    </p>
                </div>
            </div>

            <!--review responses-->
            <div class="col-12 col-sm-6 col-lg-3 mb--30">
                <div class="box box--padding-xxs box--shadow box--rounded bg--white">
                    <h2 class="txt--capitalize mb--15"><strong>review responses</strong></h2>

                    <app-skeleton-reviews type="REVIEW-RESPONSES"
                                          *ngIf="isProgressResume && !reviewsStats">
                    </app-skeleton-reviews>

                    <ng-container *ngIf="reviewsStats">
                        <div class="review-response review-response--success mb--15">
                            <span>
                                <em  class="far fa-check-circle icon-respose" data-html2canvas-ignore="true"></em>
                            </span>

                            <p class="txt--md m--0">Responded</p>
                            <span class="review-response--num">{{reviewsStats.answered|number:'1.0-0'}}</span>
                        </div>
                        <div class="review-response review-response--error">
                            <em class="far fa-times-circle icon-respose" data-html2canvas-ignore="true"></em>
                            <p class="txt--md m--0">Unresponded</p>
                            <span class="review-response--num">{{reviewsStats.notAnswered|number:'1.0-0'}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>

        </div>

        <div class="row box--height">

            <!--Total # of Reviews Replied-->
            <div class="col-12 col-sm-12 col-lg-12 mb--30">
                <app-reviews-number-protocols [locations]="locations" [dataPicker]="dataPicker" ></app-reviews-number-protocols>
            </div>
        </div>

        <div class="row box--height">

            <!--Total # of Reviews Replied-->
            <div class="col-12 col-sm-6 col-lg-6 mb--30">
                <div class="box box--padding-xxs box--shadow box--rounded bg--white">
                    <h2 class="txt--capitalize"><strong>Total number of Reviews Replied</strong></h2>

                    <app-skeleton-reviews type="TOTAL-REVIEWS"
                                          *ngIf="(count_reviews_replies_protocols$ | async) === null">
                    </app-skeleton-reviews>

                    <div class="d-flex justify-content-between flex-column"
                         *ngIf="!(count_reviews_replies_protocols$ | async) !== null">

                        <div class="d-flex align-items-center justify-content-start mb--25">
                            <p class="txt--xxxl txt--lightblue fw--medium m--0">
                              {{(count_reviews_replies_protocols$ | async) | number: '1.0-0'}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!--Total # of Reviews Replied-->
            <div class="col-12 col-sm-6 col-lg-6 mb--30">
                <div class="box box--padding-xxs box--shadow box--rounded bg--white">
                    <h2 class="txt--capitalize"><strong>Total number of Reviews Remaining</strong></h2>

                    <app-skeleton-reviews type="TOTAL-REVIEWS"
                                          *ngIf="isProgressResume && !noDataGoogle">
                    </app-skeleton-reviews>

                    <div class="d-flex justify-content-between flex-column"
                         *ngIf="!isProgressResume && !noDataGoogle && !isFailedResume">

                        <div class="d-flex align-items-center justify-content-start mb--25">
                            <p class="txt--xxxl txt--lightblue fw--medium m--0">
                              {{count_reviews_remaining | number: '1.0-0'}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row box--height">

            <!--Total # of Reviews Replied-->
            <div class="col-12 col-sm-12 col-lg-12 mb--30">
                <div class="box box--padding-xxs box--shadow box--rounded bg--white">
                    <h2 class="txt--capitalize"><strong>Review activity</strong></h2>
                    <app-protocol-log [locations]="locations" [shared]="isShared" ></app-protocol-log>
                </div>
            </div>
        </div>

        <div class="row box--height">
            <!-- Protocol List -->
            <div class="col-12 table--shadow mb--30" >
                <div class="box box--padding-xxs box--shadow box--rounded bg--white">
                    <app-skeleton-protocol *ngIf="(progress | async)"></app-skeleton-protocol>

                    <ng-container *ngIf="!(progress | async)">
                        <ng-container *ngIf="protocolsExist; else protocolsEmpty">
                            <h2 class="txt--capitalize"><strong>Matching Protocols</strong></h2>
                            <div class="mat-row mat-row--padding mat-row--border d-flex justify-content-between align-items-center">
                                <div class="input-field input-field--search">
                                    <div class="table__icon table__icon--lg">
                                        <em class="fas fa-search"></em>
                                    </div>
                                    <input #searchInput (input)="applyFilter(searchInput.value, $event)" [(ngModel)]="searchText" placeholder="Type to search">
                                </div>
                            </div>

                            <table mat-table matSort [dataSource]="dataSource" class="table table--lg table--last-row-border">
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef><strong>Name</strong></th>
                                    <td mat-cell *matCellDef="let element" [style.width]="'33%'">
                                        <div class="cell__content flex-column align-items-start justify-content-center">
                                            <p class="txt--darkgray m--0">{{ element.name }}</p>
                                            <button mat-flat-button color="accent" [matMenuTriggerFor]="locationMenu"
                                                class="d-flex align-items-center btn btn--badge btn--badge--sm txt--uppercase txt--xs">
                                                    {{ countLocations(element)  || 0 }} locations <em class="fas fa-angle-down ml--10 mr--0"></em>
                                            </button>
                                            <mat-menu #locationMenu="matMenu" class="mat-menu--lg">
                                                <ng-container *ngFor="let account of element.accounts">
                                                    <button class="d-flex align-items-center" mat-menu-item *ngFor="let location of account.locationsBasics">
                                                        <span class="mr--5">{{location?.locationName}} -</span><gmb-address [address]="location?.address || location?.location?.address" *ngIf="location?.address || location?.location?.address"></gmb-address>
                                                    </button>
                                                </ng-container>
                                            </mat-menu>
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef><strong>Replied/Unreplied</strong></th>
                                    <td mat-cell *matCellDef="let element" [style.width]="'33%'">
                                        <div class="cell__content">
                                            <div class="progress-label">
                                                <mat-progress-bar
                                                    color="primary"
                                                    mode="determinate"
                                                    [value]="progressValue(element)">
                                                </mat-progress-bar>
                                                <p class="progress-label__txt {{ (element.status.replied / element.status.total) < 0.6 ? 'txt--black' : 'txt--white' }}"> {{ element.status.replied}}/{{element.status.total }} </p>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="triggers">
                                    <th mat-header-cell *matHeaderCellDef><strong>Triggers</strong></th>
                                    <td mat-cell *matCellDef="let element" [style.width]="'33%'">
                                        <div class="cell__content flex-column align-items-start justify-content-center">
                                            <app-starts [starts]="star" class="stars--xs" [showEmpty]="false"
                                                        *ngFor="let star of element.stars"></app-starts>
                                            <p class="txt--darkgray m--0" *ngIf="element.withComment">With comments</p>
                                            <p class="txt--darkgray m--0" *ngIf="element.withOutComment">Without comments</p>
                                            <p class="txt--darkgray m--0" *ngIf="element.triggers.delay > 0">Delay: {{ element.triggers.delay }}</p>
                                            <p class="txt--darkgray m--0" *ngIf="element.triggers.keywords.length > 0">
                                            Keywords: {{ element.triggers.keywords.join(' ') }}</p>
                                        </div>
                                    </td>
                                </ng-container>


                                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="with-padding no-hover"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns" class="with-padding no-hover"></tr>

                            </table>
                            <app-paginator [pagination]="pagination" (reload)="handleReload($event)"></app-paginator>
                        </ng-container>
                        <ng-template #protocolsEmpty>
                            <app-no-data message="No available protocols"></app-no-data>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
