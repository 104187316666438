// dep
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// app
import { GoogleAccountService } from '../services/google-account.service';
import { GoogleService } from '../services/google.service';
import { SnackbarService } from '../services/snackbar.service';
import { ReviewsService } from '../services/reviews.service';
import User from '../constants/firestore/user';
import { SessionService } from '../services/session.service';
import { BaseComponent } from './base.component';

@Component({
  selector: 'app-auto-reply-review-error',
  template: `
    <div class="dialog__header dialog__header--warn txt--center">
      <h2 mat-dialog-title class="m--0 txt--xl"><strong>Auto Responder Error</strong></h2>
    </div>
    <div class="dialog__content" mat-dialog-content>

      <div class="dialog-row txt--center" >
        <ng-container [ngSwitch]="review?.error">
          <div *ngSwitchCase="'UNAUTHENTICATED'">
            <read-more text="You need re-authenticate your account in order to fix this." [maxLength]="300"></read-more>
            <button tabIndex="-1" mat-flat-button color="accent" class="btn btn--action" (click)="openReauth(review)"> Re-authenticate</button>
          </div>
          <div *ngSwitchCase="'INTERNAL'">
            <p>An unexpected error happened while trying to reply. Please try again.</p>
            <button tabIndex="-1" mat-flat-button color="accent" class="btn btn--action" (click)="replyReview(review)"> Try again</button>
          </div>
          <div *ngSwitchCase="'NOT_FOUND'">
            <p>The location was not found in GBP. Did you move it or delete it from GBP?</p>
          </div>
          <div *ngSwitchDefault>
            <read-more [text]="review.error" [maxLength]="300"></read-more>
          </div>
        </ng-container>
      </div>
    </div>

    <div mat-dialog-actions class="dialog__footer">
    <button tabIndex="-1" mat-button color="primary" [mat-dialog-close]="false" class="btn btn-cancel">Cancel</button>
    </div>
  `,
  styles: []
})

export class AutoReplyReviewErrorComponent extends BaseComponent {
  review: any = {};
  user : User

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<any>,
    private googleAccountService: GoogleAccountService,
    private google: GoogleService,
    private snack: SnackbarService,
    private reviewService: ReviewsService,
    private _sessionS : SessionService
  ) {
    super();
    this.user = this._sessionS.getSession().user;
    this.setUp();
    this.review = data.data;
  }


  async setUp() {
    //this.getError(this.data.data.error)
  }

  getError(error) {
    if (error) {
      switch (error) {
        case 'INVALID_ARGUMENT':
          return `${error.message} ${error.details[0].errorDetails[0].message}`;
        default:
          return error.message;
      }
    }

    return error.errorMessage;
  }

  async openReauth(element) {
    await this.onAddNewAccount(element);
  }

  async replyReview(element) {
    const r = this.review
    const accountId = r.name.split('/')[1]
    await this.reviewService.reply_with_errors(accountId, r.locationId,
                                               r.reviewId, r.comment,
                                               r.protocolId, r.replyId).toPromise()
    this.dialogRef.close()
  }

  async onAddNewAccount(element) {
    this._subscribeSafe(this.googleAccountService.onLoadAll$,
     ev => {
        if (ev.success !== true) {
          return this.snack.openError(ev.message);
        } else {
          const accountId = this.data.name.split('/')[1];
          this.reviewService.reply_with_errors(accountId, this.review.locationId,
                                                this.review.reviewId, this.review.comment,
                                                this.review.protocolId, this.review.replyId).toPromise()
          .then( d => {
            if (d == '') {
              this.snack.openInfo("The reply can't send, please edit it and send again", 2000)
            }

            this.snack.openInfo("The reply can't send, please edit it and send again", 2000)
            this.dialogRef.close()
          })
        }

      });


    // TODO: Duplicated code in auto-reply-review-error.component.ts, posts-error.ts, account.component.ts, google-account-service.ts
    // TODO: Must refactor as an high level flow against all callers of google.authenticate
    try {
      this.snack.openInfo(`A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings`, 2000);
      const url = await this.google.authenticate(element.gid || this.user.gid, this.user.uid, element.accountId);
      const oauth = window.open(url, '_blank');

      // This popup ends up being a redirection so we cannot detect the real close event.
      // So we use an interval trick to overcome this.
      const popupTick = setInterval(() => {
        if (oauth.closed) {
          clearInterval(popupTick);
          this.googleAccountService.loadAll();
        }
      }, 1000);
    } catch (e) {

      // This popup ends up being a redirection so we cannot detect the real close event.
      // So we use an interval trick to overcome this, with an event
      return this.snack.openError('There was an error with the GBP Authentication service')
    }
  }


}
