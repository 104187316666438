import { Component, Input, OnInit } from '@angular/core';
import { ILocationStatus } from './../../../constants/abc-location-info';

@Component({
  selector: 'app-location-status',
  templateUrl: './location-status.component.html',
  styleUrls: ['./location-status.component.scss']
})
export class LocationStatusComponent implements OnInit {
  @Input() isUnderReview = false;
  @Input() status: ILocationStatus;

  public sectionName = 'Status';
  
  constructor() { }

  ngOnInit() {
  }

}
