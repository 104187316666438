import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {LocationEditService} from '../../services/location-edit.service';

@Component({
  selector: 'app-modal-description',
  templateUrl: './modal-description.component.html',
  styleUrls: ['./modal-description.component.scss']
})
export class ModalDescriptionComponent {
  public description: string;
  @Input() bulk = false;
  @Output() validResult = new EventEmitter(false);

  constructor(
    public dialogRef: MatDialogRef<ModalDescriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public config: any,
    private locationEditS: LocationEditService
  ) {
    if(config) this.description = config.data.description;
    else this.description = ''
  }

  apply(): void {
    if (this.isValidValue()) {
      return
    }
    if (this.description) {
      let value = this.description.trim();
      value = value.replace(/^\s+|\s+$|\s+(?=\s)/g, "")
      this.description =value;
    }
    if (!this.locationEditS.locationEdit.profile) {
      this.locationEditS.locationEdit.profile = {description: this.description};
    } else {
      this.locationEditS.locationEdit.profile.description = this.description;
    }


    this.locationEditS.setAndUpdate().toPromise().then(() => {
      this.dialogRef.close();
    });
  }

  isValidValue() {
    const valid = (/^ *$/.test(this.description));
    return valid;
  }

  hasAllCharacters() {
    return this.description.length == 750;
  }

  getResult(): { [k: string]: any } {
    if (this.isValidValue()) {
      this.validResult.emit(false);
      return
    }
    this.validResult.emit(true);
    return {profile: {description: this.description} }
  }

}
