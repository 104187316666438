<div class="sidebar d-print-none bg--pastel--gray">
	<div class="sidebar__group p--0 sidebar__location-header bg--pastel--gray">
		<div class="sidebar__title">
			<ng-container *ngIf="!(location | async)?.locationName">
				<ngx-skeleton-loader count="1" [theme]="{ width: '100px', height: '20px', 'display': 'block', 'background-color': '#1a2333', 'opacity': '.15' }"></ngx-skeleton-loader>
				<ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '35px', 'margin-top': '5px', 'background-color': '#1a2333', 'opacity': '.15' }"></ngx-skeleton-loader>
			</ng-container>
			<ng-container *ngIf="(location | async)?.locationName">
				<h2 gmbGLI [gid]="(session$ | async).gid" [accountId]="accountId" [locationId]="locationId" class="txt--dark-black m--0">
					<p class="col-10 p--0 m--0 mb--5 d--inl-block txt--md">{{(location | async)?.locationName}} </p>
					
					<!-- Remove it when we work on the status badge -->
					<!-- <section class="col-2 p--0 d--inl-block vertical--align--top mb--10" 
								[ngSwitch]="getStatus((location | async)?.lastCheck?.status)">
						<span class="text-warning" 
							*ngSwitchCase="c_auth_unauthorized"
							matTooltip='This location is no longer authenticated with Google. To fix this, first you must remove the location from Map Labs, then click on "Add New Profiles" and reconnect your Google account to Map Labs.'
							matTooltipPosition="right"
							matTooltipClass="tooltip tooltip--white"
						>
							<i class="fas fa-exclamation-triangle txt--14"></i>
						</span>
					
						<span class="text-warning"
							*ngSwitchCase="c_auth_not_found"
							matTooltip='This location was not found in GBP. Did you move it or delete it from GBP?'
							matTooltipPosition="right"
							matTooltipClass="tooltip tooltip--white"
						>
							<i class="fas fa-exclamation-triangle txt--14"></i>
						</span>
						<span *ngSwitchDefault></span>
					</section> -->
					
				</h2>
				<gmb-address [address]="currentLocation?.location?.address" 
				             [serviceAreas]="currentLocation?.location?.serviceArea?.places?.placeInfos" 
				             [hasInfoModal]="true">
				</gmb-address>
			</ng-container>
			<div *ngIf="isRevealPath">{{(session$ | async).gid}}/{{(location | async)?.locationName}}</div>
		</div>
	</div>
	
	<mat-divider class="ml--15 mr--15"></mat-divider>

	<div class="sidebar__group">
		<section class="sidebar__group__header">
			<p class="group-name d--inl-block">Google Business Profile</p>
			<button mat-button [matMenuTriggerFor]="menuLocation">
				<i class="txt--14 fas fa-ellipsis-h"></i>
			</button>

			<mat-menu #menuLocation="matMenu" [overlapTrigger]="false" xPosition="after" class="mat-menu-header">
				<button mat-button color="primary" (click)="refreshLocation()">Refresh</button>
				<button mat-button color="primary" (click)="disconnectLocation()">Disconnect</button>
			</mat-menu>
		</section>

		<section *ngIf="(location | async)?.lastCheck?.status !== 'NO_DATA' && (location | async)?.lastCheck?.status !== 'UNKNOWN'"
		  class="status-badge" [ngStyle]="{'background-color': getGBPStatusBadge((location | async)?.lastCheck?.status)?.bg, 'color': getGBPStatusBadge((location | async)?.lastCheck?.status)?.color }"
		  [matTooltip]="getGBPStatusBadge((location | async)?.lastCheck?.status)?.tooltip" matTooltipPosition="right" matTooltipClass="tooltip tooltip--white"
		>
			<i class="mr--5 mt--5 txt--14" [ngClass]="getGBPStatusBadge((location | async)?.lastCheck?.status)?.icon"></i>
			<p class="m--0 d--inl-block">{{getGBPStatusBadge((location | async)?.lastCheck?.status)?.status | titlecase}}</p>
		</section>
		
		<div class="sidebar__group">
			<a [routerLink]="['insights']" class="sidebar__item" routerLinkActive="active">
				<div class="sidebar__menu-icon"><i class="fas fa-chart-bar txt--14"></i></div> Performance Insights
			</a>
			<a [routerLink]="['keywords']" class="sidebar__item" routerLinkActive="active" [appUserFeature]="'searchTermsLocationView'">
				<div class="sidebar__menu-icon"><i class="fa fa-arrow-down-arrow-up txt--14"></i></div> 
				<span class="title-dark-launch">Keywords</span>
			</a>
			<a [routerLink]="['reviews']" class="sidebar__item" routerLinkActive="active">
				<div class="sidebar__menu-icon"><i class="fas fa-stars txt--14"></i></div> Reviews
			</a>
			<a [routerLink]="['location-info']" class="sidebar__item" routerLinkActive="active">
				<div class="sidebar__menu-icon"><i class="fas fa-store txt--14"></i></div> Location Info
			</a>
			<ng-container *ngIf="(location | async) as locationData">
				<ng-container *ngIf="locationData.location as location">
					<a [routerLink]="['posts']" class="sidebar__item d-none" routerLinkActive="active" [appUserFeature]="'legacyPost'">
						<div class="sidebar__menu-icon"><i class="fas fa-sticky-note txt--14"></i></div>
						Posts
					</a>
					<a [routerLink]="['post-management']" class="sidebar__item d-none" routerLinkActive="active" [appUserFeature]="'postManagement'">
						<div class="sidebar__menu-icon"><i class="fas fa-sticky-note txt--14"></i></div>
						<span class="title-dark-launch">Posts</span>
					</a>
					<a [routerLink]="['services']" class="sidebar__item" routerLinkActive="active" *ngIf="location.locationState?.canModifyServiceList">
						<div class="sidebar__menu-icon"><i class="fas fa-concierge-bell txt--14"></i></div> Services
					</a>
					<a [routerLink]="['menu']" class="sidebar__item" routerLinkActive="active" *ngIf="location.locationState?.canHaveFoodMenus">
						<div class="sidebar__menu-icon"><i class="fas fa-list txt--14"></i></div> Menu
					</a>
				</ng-container>
			</ng-container>
			<a [routerLink]="['photos']" class="sidebar__item" routerLinkActive="active" >
				<div class="sidebar__menu-icon"><i class="far fa-images txt--14"></i></div> Photos
			</a>
			<a [routerLink]="['qanda']" class="sidebar__item" routerLinkActive="active" [appUserFeature]="'qandaLocationView'">
				<div class="sidebar__menu-icon"><i class="fa fa-comments-alt txt--14"></i></div>
				<span class="title-dark-launch">Questions & Answers</span>
			</a>
			<a [routerLink]="['grade']" class="sidebar__item" routerLinkActive="active">
				<div class="sidebar__menu-icon"><i class="fas fa-clipboard-list-check txt--14"></i></div> Grader
			</a>
			<a [routerLink]="['widget']" class="sidebar__item" routerLinkActive="active">
				<div class="sidebar__menu-icon"><i class="fas fa-code txt--14"></i></div> Widget Generator
			</a>
			<a (click)="handleReviewLinkGenerator()" class="sidebar__item">
				<div class="sidebar__menu-icon"><i class="fas fa-link txt--14"></i></div> Review Link Generator
			</a>
		</div>
		<!-- <div class="sidebar__group">
			<a [href]="(location | async)?.location.metadata?.mapsUrl" target="_blank" class="sidebar__item"
			routerLinkActive="active" rel="noopener">
				<div class="sidebar__menu-icon"><i class="fas fa-map-marker-alt txt--14"></i></div> View on Maps
			</a>
			<a [href]="(location | async)?.search_url" target="_blank" class="sidebar__item"
			routerLinkActive="active" rel="noopener">
				<div class="sidebar__menu-icon"><i class="fas fa-search txt--14"></i></div> View on Search
			</a>
		</div> -->

		<!-- <div class="sidebar__group sidebar-edit-location" *ngIf="currentLocation && (session$ | async).subscription.pricingVersion < 3">
			<a class="sidebar__item change-plan" (click)="changePlan()">
				<div class="sidebar__menu-icon"><i class="far fa-pen-alt txt--14"></i></div>
				<div>
					Change Subscription
				</div>
				<span *ngIf="currentLocation?.subscriptionStatus">({{currentLocation.subscriptionStatus}})</span>
			</a>
		</div> -->

		<!-- <div class="sidebar__group sidebar-edit-location" *ngIf="currentLocation">
			<a class="sidebar__item" (click)="openDeleteDialog()">
					<div class="sidebar__menu-icon"><i class="far fa-trash-alt txt--14"></i></div> Delete Location
				</a>
		</div> -->
	</div>
	<ng-container *ngIf="isABCflagged">

		<mat-divider class="ml--15 mr--15"></mat-divider>
		
		<div class="mb--50">
			<p class="group-name">Apple Business Connect</p>
			
			<section *ngIf="abcStatus"
				class="status-badge" [ngStyle]="{'background-color': getABCStatusBadge()?.bg, 'color': getABCStatusBadge()?.color }"
				[matTooltip]="getABCStatusBadge()?.tooltip" matTooltipPosition="right" matTooltipClass="tooltip tooltip--white"
			>
				<i class="mr--5 mt--5 txt--14" [ngClass]="getABCStatusBadge()?.icon"></i>
				<p class="m--0 d--inl-block">{{getABCStatusBadge()?.status | titlecase}}</p>
			</section>

			<div class="sidebar__group">
				<a [routerLink]="['apple-performance-insights']" class="sidebar__item" routerLinkActive="active">
					<div class="sidebar__menu-icon"><i class="fas fa-chart-bar txt--14"></i></div> Insights
				</a>
				<a [routerLink]="['apple-location-info']" class="sidebar__item" routerLinkActive="active">
					<div class="sidebar__menu-icon"><i class="fas fa-store txt--14"></i></div> Location Info
				</a>
			</div>
		</div>
	</ng-container>
</div>
