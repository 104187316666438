// dep
import {Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';

// app
import {UserService} from '../../../services/user.service';
import { SnackbarService } from '../../../services/snackbar.service';
import User from 'src/app/constants/firestore/user';

@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: './delete-user-dialog.component.html',
  styleUrls: ['./delete-user-dialog.component.scss']
})
export class DeleteUserDialogComponent {
  static readonly OPTS_DEFAULT = { config: {width: '680px'} };
  RESULT_TYPE : boolean;

  public user : User;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { user : User },
    public router: Router,
    public dialogRef: MatDialogRef<DeleteUserDialogComponent>,
    private userService: UserService,
    private snack: SnackbarService,
  ) {
    this.user = data.user;
  }

  /**
   * User clicks on 'Delete user'
   */
  public async delete(): Promise<void> {
    try {
      await this.userService.deleteUser(this.user.gid, this.user.uid);
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      const msg = err.error?.message;
      this.snack.openError(msg ? `Error: ${msg}` : 'Unexpected Error: Please contact support');
      this.dialogRef.close(false);
    }
  }

}
