<div class="d-flex bg--white filter ng-star-inserted">

  <!-- Account multiselect -->
  <div class="input-group m--0 p--0 input-group--select row">

    <div class="filter__item filter-by-accounts p--0" 
      *ngIf="this.accounts?.length > 1"
    >
      <button class="filter__item__btn filter-by-accounts p--0 account-filter-button"
        mat-button 
        [matMenuTriggerFor]="accountFilter" 
        #metricsTrigger="matMenuTrigger" 
      >
        <p>{{labelAccountsFiltered}}</p> 
        <em class="fal fa-angle-down" 
          data-html2canvas-ignore="true"
        ></em>
      </button>

      <mat-menu #accountFilter="matMenu" 
        [overlapTrigger]="false" 
        xPosition="before" 
        class="filter__item__content p--0"
      >
        <div (click)="$event.stopPropagation()">
          <ng-container>
            <form>
              <mat-form-field class="col-12 p--0" appearance="fill">
                <mat-label>Search</mat-label>
                <input type="text" 
                  aria-label="Search" 
                  matInput 
                  [formControl]="filterAccountsControl"
                >
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let account of filteredOptions | async" 
                    [value]="account"
                  >
                    <ng-container>
                      <div class="filter-by-accounts-options">
                        <mat-option class="m--0" 
                          *ngFor="let account of accountsOptions" 
                          [value]="account"
                        >
                          <mat-checkbox name="checked" 
                            [(ngModel)]="account.checked" 
                            (change)="getLocationsFiltered($event, account)"
                          >
                            <p class="m--0" 
                              *ngIf="account.accountName"
                            >
                              {{account.accountName | titlecase}}
                            </p>
                          </mat-checkbox>
                        </mat-option>
                      </div>
                    </ng-container>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </form>
          </ng-container>

          <div class="filter__content">
            <ng-container>
              <div class="filter-by-accounts-options">
                <mat-option class="m--0" 
                  *ngFor="let account of accountsOptions" 
                  [value]="account"
                  matTooltip=" {{'This account is connected with: ' + account?.googleAuthEmailAddress}}" 
                  matTooltipPosition="above"
                  matTooltipClass="mat-tooltip--override" class="cursor--pointer"
                >
                  <mat-checkbox name="checked" 
                    [(ngModel)]="account.checked" 
                    (change)="getLocationsFiltered($event, account)"
                  >
                    <p class="m--0" 
                    *ngIf="account.accountName"
                    >
                      {{account.accountName | titlecase}}
                    </p>
                  </mat-checkbox>
                </mat-option>
              </div>
            </ng-container>
          </div>

          <mat-divider></mat-divider>

          <div class="filter-by-accounts-apply w--100 bg--white">
            <button mat-flat-button 
              class="btn txt--md p--10 m--10 bg--gray"
              (click)="filterAccountChanged(accountFilter, true)"
            >
              Clear
            </button>
            <button mat-flat-button 
              color="accent" 
              class="btn txt--md p--10 m--10"
              (click)="filterAccountChanged(accountFilter, false)"
            >
              Apply
            </button>
          </div>

        </div>
      </mat-menu>
    </div>

  </div>
  
  <!-- Add new profile -->
  <div class="p--0">
    <button *ngIf="(session$ | async).isAdmin" 
      mat-flat-button class="btn btn--icon btn--md m--0" 
      color="accent"
      (click)="addNewProfiles()"
    >
      Add New Profile
    </button>
  </div>

</div>
  
<div class="content__body">

  <div class="table--shadow bg--white">
    <div class="mat-row mat-row--padding mat-row--border d-flex justify-content-between align-items-center">
      <p class="txt--lg fw--medium m--0">Profiles</p>

      <!-- Actions dropdown -->
      <div class="input-group col-2 m--0 p--0 input-group--select">
        <mat-select [disabled]="selectedLocations.length === 0"
          value="" 
          class="input-group__select" 
        >
          <mat-option value="">Actions</mat-option>
          <mat-option value="changeSubscription">Change subscription</mat-option>
          <mat-option value="refreshData">Refresh insights & reviews data</mat-option>
          <mat-option value="lockBusiness"
            (click)="lockSelectedLocations()"
          >Lock business profile info</mat-option>
          <mat-option value="bulkUpdate">Bulk update</mat-option>
          <mat-option value="addPhotos">Add photos</mat-option>
          <mat-option value="deleteFromAccounts"
            (click)="deleteBulk()"
          >
            Delete from your account
          </mat-option>
        </mat-select>
      </div>

    </div>
  </div>

  <div>
    <app-skeleton-location *ngIf="loadingTable"></app-skeleton-location>
    <app-no-data [type]="'box--shadow'" *ngIf="!locations.data.length"></app-no-data> 

    <table mat-table 
      summary="Profiles"
      *ngIf="locations.data.length && !loadingTable" 
      matSort 
      [dataSource]="locations" 
      class="table table--sm table--last-row-border"
    >

      <!-- Column Checkbox -->
      <ng-container matColumnDef="check">
        <th mat-header-cell *matHeaderCellDef class="w--7">
          <mat-checkbox class="display-in-bl" 
            [(ngModel)]="allChecked" 
            (change)="toggleCheckAll($event)"
          ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="cell__content d-flex justify-content-start align-items-center">
            <mat-checkbox [id]="element.locationId" 
              [(ngModel)]="element.isChecked" 
              (change)="selectLocation(element, $event)"
            ></mat-checkbox>
          </div>
        </td>
      </ng-container>

      <!-- Column company -->
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef>

          <!-- Checkbox select all pages -->
          <ng-container *ngIf="!allChecked && !selectedLocations.length">
            <p class="display-in-bl br--5" 
              style="background-color: #eff0f3; padding: 10px 15px; width: fit-content;cursor:context-menu"
            >Select Profiles</p>
          </ng-container>
          <ng-container *ngIf="allChecked">
            <div style="background-color: #eff0f3; border-radius: 10px; padding: 10px 15px; width: fit-content; display: inline; margin-right:5px">
              <ng-container *ngIf="allLocationCheck; else elseTemplate">
                Selected <span style="color: #424656;" >({{ pagination?.total }})</span>.
                <a style="color: #2f89fc; cursor: pointer; font-weight: bold;" 
                  (click)="selectAll()"
                >Unselect All</a>
              </ng-container>
              <ng-template #elseTemplate>
                Selected <span style="color: #424656;">({{ selectedLocations.length }})</span>.
                <a style="color: #2f89fc; cursor: pointer;" 
                  (click)="selectAll()"
                >Select All Profiles ({{ pagination?.total }})</a>
              </ng-template>
            </div>
          </ng-container>
          <ng-container *ngIf="!allChecked && selectedLocations.length > 0">
            <div style="background-color: #eff0f3; border-radius: 5px; padding: 10px 15px; width: fit-content;margin-right: 10px; display:inline; cursor:context-menu">
              Selected <span style="color: #424656;">({{ selectedLocations.length }})</span>
            </div>
          </ng-container>

        </th>

        <!-- Location Name -->
        <td mat-cell *matCellDef="let row">
          <div class="cell__content flex-column align-items-start justify-content-center">
            <p class="m--0 txt--md txt--black">
              <strong class="txt--underline mr--10 cursor--pointer"
                (click)="goToLocation(row)"
              >{{ row?.locationName | titlecase }}</strong>
              <em class="fal fa-external-link cursor--pointer"
                src="/assets/images/icons/newTabIcon.svg"
                (click)="goToLocationNewTab(row)"
              ></em>
            </p>
            <p class="company__address m--0">
              <gmb-address [address]="row?.address"></gmb-address>
            </p>
          </div>
        </td>
      </ng-container>

      <!-- Column Store Code -->
      <ng-container matColumnDef="storeCode">
        <th mat-header-cell *matHeaderCellDef class="txt--black m-w--200-px">
          <span>Store Code</span>
        </th>
        <td mat-cell *matCellDef="let row">
          <div class="cell__content flex-column align-items-start justify-content-center">
            <p class="m--0 txt--md w--200-px txt-overflow-ellipsis">{{ row?.storeCode }}</p>
          </div>
        </td>
      </ng-container>

      <!-- Column status -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="txt--black"><span>Status</span></th>
        <td mat-cell *matCellDef="let row">
          <div class="cell__content flex-column align-items-start justify-content-center field-status mr--45">
            <p class="m--0 txt--md pt--10 pb--10 pl--20 pr--20">
              <em *ngIf="row?.status?.toLowerCase() == 'verified'" 
                class="fa fa-badge-check txt--green"
              ></em>
              <img class="pending-icon" 
                *ngIf="row?.status?.toLowerCase() == 'pending_edits'" 
                src="/assets/images/hexagon-exclamation.svg" 
                alt="Add photo"
              >
              <em *ngIf="row?.status?.toLowerCase() == 'not_verified'" 
                class="fa fa-circle-minus txt--red"
              ></em>
              <span *ngIf="row?.status?.toLowerCase() == 'verified_locked'">
                <em class="fa fa-lock txt--green"></em>
                <em class="far fa-check txt--white check-icon"></em>
              </span>
              {{ profileStatus[row?.status?.toLowerCase()] | titlecase}}
            </p>
          </div>
        </td>
      </ng-container>

      <!-- Column actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="txt--black m-w--200-px">
          <span>Actions</span>
        </th>
        <td mat-cell *matCellDef="let row" class="m-w--200-px">
          <div>
            <button mat-flat-button 
              class="btn btn-icon mr--10 bg--gray" 
              matTooltip="Refresh" 
              matTooltipClass="tooltip tooltip--white" 
              (click)="refresh(row)"
            >
              <em class="fas fa-redo" [ngClass]="{ 'active-spin': row.lastInsightsTask?.status == 'SENT'}"></em>
            </button>
            <button mat-flat-button 
              class="btn btn-icon mr--10 bg--gray" 
              matTooltip="Add Photos" 
              matTooltipClass="tooltip tooltip--white" 
              (click)="addPhotos()"
            >
              <img src="/assets/images/add_photo_alternate_dark_fill.svg" alt="Add photo">
            </button>
            <!-- <button mat-flat-button class="btn btn-icon mr--10 bg--gray" matTooltip="Edit Location" matTooltipClass="tooltip tooltip--white" (click)="editLocation(row.locationId)">
              <em class="far fa-pencil"></em>
            </button> -->
            <button mat-flat-button 
              *ngIf="!row.lockedOn"
              class="btn btn-icon mr--10 bg--gray" 
              matTooltip="Lock location"
              matTooltipClass="tooltip tooltip--white"
              (click)="lockLocation(row)"
            >
              <span>
                <em  class="fa fa-lock-open"></em>
              </span>
            </button>
            <button mat-flat-button 
              *ngIf="row.lockedOn"
              class="btn btn-icon mr--10 bg--gray" 
              matTooltip="Unlock location"
              matTooltipClass="tooltip tooltip--white"
              (click)="unlockLocation(row)"
            >
              <span>
                <em class="fa fa-lock"></em>
              </span>
            </button>
            <button mat-flat-button class="btn btn-icon mr--10 bg--gray" 
              matTooltip="Delete" 
              matTooltipClass="tooltip tooltip--white" 
              (click)="deleteLocationModal(row)"
            >
              <em class="far fa-trash-alt"></em>
              </button>
          </div>
        </td>
      </ng-container>
      
      <!-- Subscription -->
      <ng-container matColumnDef="subscription">
        <th mat-header-cell *matHeaderCellDef class="txt--center">
          <strong>Subscription</strong>
        </th>
        <td mat-cell *matCellDef="let element" class="txt--center">
          <div class="cell__content alternate-theme">
            <button
              mat-flat-button
              color="primary"
              class="btn btn--badge btn--badge--lg"
            >
              <strong
                >ESSENTIAL<i class="fas fa-pen ml--5"></i>
              </strong>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <!-- Paginator -->
    <app-paginator [pagination]="pagination" (reload)="handleReload($event)" [options]="[25,50,100,200,500]"></app-paginator>
  </div>
</div>

<!-- Drawer Profiles side panel -->
<mat-drawer-container
  [ngClass]="{'position--absolute z-index--9999': drawerOpened, 'position--relative': !drawerOpened}"
  class="example-container w--100" 
  [hasBackdrop]="false"
>
  <mat-drawer #drawer 
    (openedChange)="onMatDrawerOpenedChange($event)"
    class="w--50"
    mode="side" 
    position="end"
  >
    
    <!-- Drawer content -->
    <div>

      <!-- Header -->
      <div class="bg--primary txt--white p--25">
        <div class="txt--xl mat-dialog-title">Accounts</div>
        <div>
          <strong>Step 1/2: </strong>
          <span>Select and account to add.</span>
        </div>
      </div>

      <!-- Body -->
      <div class="p--25">

        <mat-horizontal-stepper hide-header>

          <!-- Step 1 -->
          <mat-step>
            
            <div>
              <!-- Search Accounts -->
              <div class="mb--10">Search Accounts</div>
              <div>
                <div 
                  *ngIf="(this.googleAccounts?.length > 1)"
                >
                  <div (click)="$event.stopPropagation()">
                    
                    <mat-form-field class="input-group input-group--override input-group--override-label">
                      <mat-label>Type To Search Account..</mat-label>
                      <input type="text" 
                        class="input-group__field"
                        aria-label="Search" 
                        matInput 
                        [formControl]="addProfileFilterAccountsControl"
                      >
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let account of addProfileFilterSelected | async" 
                          [value]="account"
                        >
                          <ng-container>
                            <div class="filter-by-accounts-options">
                              <mat-option class="m--0" 
                                *ngFor="let account of googleAccounts" 
                                [value]="account"
                              >
                                <mat-checkbox name="checked" 
                                  [(ngModel)]="account.checked" 
                                  (change)="getLocationsFiltered($event, account)"
                                >
                                  <p class="m--0" 
                                    *ngIf="account.accountName"
                                  >
                                    {{account.accountName | titlecase}}
                                  </p>
                                </mat-checkbox>
                              </mat-option>
                            </div>
                          </ng-container>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
          
                    <!-- Accounts -->
                    <div class="mb--15">Accounts</div>

                    <mat-checkbox name="checked" 
                      (change)="checkAllAccounts($event)"
                    >
                      <p class="m--0">
                        SELECT ALL ACCOUNTS
                      </p>
                    </mat-checkbox>

                    <div class="filter__content">
                        <div>
                          <mat-option class="m--0" 
                            *ngFor="let account of googleAccounts" 
                            [value]="account"
                          >
                            <mat-checkbox name="checked" 
                              [(ngModel)]="account.checked" 
                            >
                              <p class="m--0" 
                              *ngIf="account.accountName"
                              >
                                {{account.accountName | titlecase}}
                              </p>
                            </mat-checkbox>
                          </mat-option>
                        </div>
                    </div>
          
                  </div>
                </div>
            
              </div>

              <mat-divider class="mb--15"></mat-divider>

              <!-- Action buttons -->
              <div class="dialog__footer d-flex justify-content-between">
                <div class="alternate-theme d-inline-block mr--10">
                  <button mat-button tabIndex="-1" color="primary" class="btn btn-cancel">Cancel</button>
                </div>
                <button matStepperNext 
                  [disabled]="!hasAnySelectedAccount()" 
                  tabIndex="-1" 
                  mat-flat-button 
                  color="primary" 
                  class="btn btn--action"
                  (click)="loadStep2Profiles()"
                >
                  Add Accounts
                </button>
              </div>
            </div>
          </mat-step>

          <!-- Step 2 -->
          <mat-step>
            <button mat-button matStepperPrevious>Go Back</button>

            <!-- Profile accordion -->
            <mat-accordion multi>
              <mat-expansion-panel 
                #AccordionPanel 
                [expanded]="true"
                (opened)="openAccountExpansionPanel(a)"
                *ngFor="let a of checkedGoogleAccounts();let i = index;" 
                hideToggle
                [ngStyle]="{'margin-left': '-30px', 'margin-right': '-30px'}">
                  <mat-expansion-panel-header *ngIf="a.checked">
                    <div
                      class="box box--padding-xxxs box--rounded bg--lightblue d-flex justify-content-between align-items-center">
                      <h6 class="fw--medium txt--blue m--0">{{a?.accountName}}</h6>
                      <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" role="img" aria-hidden="true">
                        <span class="mat-expansion-panel-icon" data-html2canvas-ignore="true"></span>
                      </mat-icon>
                    </div>
                  </mat-expansion-panel-header>
                  <p class="ml--20" *ngIf="!a.profiles">No profiles to add</p>
        
                  <ng-container *ngIf="a.checked && a.profiles && a.profiles?.length > 0">
                    <mat-checkbox
                      (change)="checkProfiles($event, a.profiles)"
                    >
                      SELECT ALL
                    </mat-checkbox>
        
                    <mat-divider></mat-divider>

                    <div [id]="l.accountId"
                      *ngFor="let l of a.profiles">

                      <mat-checkbox class="mb--10 mt--10"
                        name="checked" 
                        [(ngModel)]="l.checked" 
                      >
                        <p class="d-flex mb--10 mt--10">
                          <strong>{{l.locationName}}</strong><span class="ml--5 mr--5">|</span>
                          <gmb-address class="d-inline txt--medium-gray"
                            [address]="l?.location?.address || l?.address"></gmb-address>
                        </p>
                      </mat-checkbox>
        
                    </div>
                  </ng-container>
              </mat-expansion-panel>
            </mat-accordion>

            <!-- Profiles Action buttons -->
            <div class="dialog__footer d-flex justify-content-between">
              <div class="alternate-theme d-inline-block mr--10">
                <button mat-button tabIndex="-1" color="primary" class="btn btn-cancel">Cancel</button>
              </div>
                <!-- <button [disabled]="!hasAnySelectedProfile() || addingProfile" 
                  tabIndex="-1" 
                  mat-flat-button 
                  color="primary" 
                  class="btn btn--action"
                  (click)="addProfiles()"
                >
                  Add Profiles 
                  <mat-progress-spinner class="d-inline"
                    mode="indeterminate" 
                    diameter="15" 
                    *ngIf="addingProfile">
                  </mat-progress-spinner>
                </button> -->
            </div>
          </mat-step>

        </mat-horizontal-stepper>

      </div>

    </div>

  </mat-drawer>
</mat-drawer-container>