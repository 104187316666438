import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {LocationEditService} from "../../services/location-edit.service";

@Component({
  selector: 'app-modal-company-name',
  templateUrl: './modal-company-name.component.html',
  styleUrls: ['./modal-company-name.component.scss']
})
export class ModalCompanyNameComponent implements OnInit {
  nameInvalid: boolean;
  name: string = '';
  @Input() bulk: boolean = false;
  @Output() validResult: EventEmitter<boolean> = new EventEmitter(false);

  constructor(
    private locationEditS: LocationEditService,
    public dialogRef: MatDialogRef<ModalCompanyNameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.nameInvalid = false;
    if (data) {
      if (data.name === null || data.name === undefined) {
        this.name = '';
      } else {
        this.name = data.name;
      }
    }
    
  }

  ngOnInit() {
  }

  nameChange(): void {
    this.nameInvalid = (!this.name);
    
  }

  getResult(): { [k: string]: string } {
    if (!this.name) { 
      this.validResult.emit(false)
      return 
    }
    this.validResult.emit(true)
    return {locationName: this.name }
  }

  apply(): void {
    if (this.name) {
      this.nameInvalid = false;
      const edit = this.locationEditS.locationEdit;
      edit.locationName = this.name;
      this.locationEditS.setAndUpdate().toPromise().then(()=>{this.dialogRef.close(this.name);});
      
    } else {
      this.nameInvalid = true;
    }
  }

  hasAllCharacters() {
    return this.name.length == 125;
  }
}
