import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ServiceList, Service, Category } from '../../constants/google/service-list';

@Component({
  selector: 'app-modal-service-item',
  templateUrl: './modal-service-item.component.html',
  styleUrls: ['./modal-service-item.component.scss']
})
export class ModalServiceItemComponent implements OnInit {

  public structuredItem: Partial<Service> = null;
  public isEdit: boolean = false;
  public form: FormGroup;

  private _item: ServiceList = null;
  private _category: Category = null;
  private _typeFormPrice: string ='fixed';
  private _position: number;
  private _addedServices: ServiceList[] = [];
  duplicated = false;
  constructor(
    public dialogRef: MatDialogRef<ModalServiceItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder
  ) {
    if (this.data) {    
      this._addedServices = this.data.addedServices;
      this._position = this.data.position;
      this._item = {...this.data.item};
    }
    if (this.data.structuredService) {
      this.structuredItem =this.data.structuredService;
      this._typeFormPrice = this.data.structuredService?.price?.type || this.data.typeServiceItem || 'noPrice';
      this.isEdit = true;
    } else {
      this._category = this.data.category;
      if (this._item?.freeFormServiceItem) {
        this.structuredItem = {
          description: '',
          displayName: '',
          isOffered: this._item?.isOffered,
          price: this._item?.price,
          languageCode: 'en',
          isFreeFormServiceItem: true,
          serviceTypeId: '',
          positionEdit: null
        }
      } else {
        this.structuredItem = {
          description: '',
          displayName: this._category?.serviceTypes.find(x => x.serviceTypeId === this._item.structuredServiceItem?.serviceTypeId).displayName,
          isOffered: this._item?.isOffered,
          price: this._item?.price,
          languageCode: 'en',
          isFreeFormServiceItem: false,
          serviceTypeId: this._item?.structuredServiceItem?.serviceTypeId,
          positionEdit: null
        }
      }

    }
    this.initForm();
  }

  ngOnInit() {
  }

  apply(): void {

    let units: string = '0';
    let nanos: number = 0;

    if (this.form.valid) {
      if (this.description) {
        let value = this.description.value.trim();
        value = value.replace(/^\s+|\s+$|\s+(?=\s)/g, "").replace(/\n/g, ' ').trim();
        this.description.setValue(value);
      }
      if (this.typePrice.value !== 'free' && this.typePrice.value !== 'noPrice'  ) {
        const amount = typeof(this.form.value.units) === 'string' ? this.form.value.units.split('.') : this.form.value.units;
        if (amount.length >= 2) {
          nanos = Number(amount[amount.length -1]);
          if (nanos > 99) {
            nanos = 0;
            units = amount.join('').replace(/\,/gi, '')
          } else {
            amount.splice(amount.length -1, 1);
            units = amount.join('').replace(/\,/gi, '')
          }
        } else if (amount.length == 1) {
          units = amount.join('').replace(/\,/gi, '')
        }
      }

      this._item = {
        ...this._item,
        price: {
          type: this.typePrice?.value,
          currencyCode: this.form.value.currency,
          units: units,
          nanos: nanos
        },
        isOffered: true,
      };

      this.structuredItem = {
        ...this.structuredItem,
        description: this.form.value.description,
        isOffered: true,
        languageCode: 'en',
        positionEdit: this._position,
        price: {
          type: this.typePrice?.value,
          currencyCode: this.form.value.currency,
          units: units,
          nanos: nanos
        },
      }

      if (this.typePrice.value === 'noPrice') {
        delete this._item['price'];
        delete this.structuredItem['price'];
      }

      if (this.isEdit) {
        this.update();
        return;
      } else {
        this.add();
        return;
      }
    } else {
      this.form.markAllAsTouched();
    }

    // this.update();
  }

  remove(): void {
    this.dialogRef.close({action: 'removed', position: this._position, item: this._item});
  }

  add(): void {
    if (!this.structuredItem.isFreeFormServiceItem) {
      this._item.structuredServiceItem = {
        ...this._item.structuredServiceItem,
        description: this.form.value.description
      }
    } else {
      this._item.freeFormServiceItem = {
        ...this._item.freeFormServiceItem,
        label: {
          description: this.form.value.description,
          displayName: this.form.value.name,
          languageCode: 'en'
        }
      }
      this.structuredItem.displayName = this.form.value.name
      this.structuredItem.positionEdit = this._addedServices?.length;
    }

    this.dialogRef.close({action: 'added',item: this._item, structuredItem: this.structuredItem, category: this._category.categoryId});
  }

  update(): void {
    if (!this.structuredItem.isFreeFormServiceItem) {
      this._item.structuredServiceItem = {
        ...this._item.structuredServiceItem,
        description: this.form.value.description
      }
    } else {
      this._item.freeFormServiceItem = {
        ...this._item.freeFormServiceItem,
        label: {
          description: this.form.value.description,
          displayName: this.form.value.name,
          languageCode: 'en'
        }
      }
      this.structuredItem.displayName = this.form.value.name
    }

    this.dialogRef.close({action: 'updated', position: this._position, item: this._item, structuredItem: this.structuredItem});
  }


  initForm(): void {
    if (this.isEdit) {
      let units = 0.0;
      if (this.structuredItem?.price?.units || this.structuredItem?.price?.nanos) {
        if (this.structuredItem?.price?.nanos) units = Number(`${this.structuredItem?.price?.units || 0}.${this.structuredItem?.price?.nanos}`)
        else units = Number(this.structuredItem?.price?.units)
      }

      if (this.structuredItem.isFreeFormServiceItem) {

        this.form = this._fb.group({
          name: [this.structuredItem.displayName, [Validators.required, this.duplicateValidator.bind(this)]],
          units: [units , [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?'), Validators.min(0)]],
          nanos: [this.structuredItem?.price?.nanos ? this.structuredItem?.price?.nanos : 0, [Validators.pattern('^[0-9]*'), Validators.min(0)]],
          description: [this.structuredItem?.description ? this.structuredItem?.description : '', [Validators.maxLength(300)]],
          currency: [this.structuredItem?.price?.currencyCode ? this.structuredItem?.price?.currencyCode : 'USD', Validators.required],
          type: [this._typeFormPrice, Validators.required],
        });
      } else {
        this.form = this._fb.group({
          units: [units , [Validators.required, Validators.pattern('^[[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?'), Validators.min(0)]],
          nanos: [this.structuredItem?.price?.nanos ? this.structuredItem?.price?.nanos : 0, [Validators.pattern('^[0-9]*'), Validators.min(0)]],
          description: [this.structuredItem?.description ? this.structuredItem?.description : '', [Validators.maxLength(300)]],
          currency: [this.structuredItem?.price?.currencyCode ? this.structuredItem?.price?.currencyCode : 'USD', Validators.required],
          type: [this._typeFormPrice, Validators.required],
        });
      }
    } else {
      if (this._item?.freeFormServiceItem) {
        this.form = this._fb.group({
          name: ['', [Validators.required, this.duplicateValidator.bind(this)]],
          units: [0, [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?'), Validators.min(0)]],
          nanos: [0, [Validators.pattern('^[0-9]*'), Validators.min(0)]],
          description: ['', [Validators.maxLength(300)]],
          currency: ['USD', Validators.required],
          type: [this._typeFormPrice, Validators.required],
        });
      } else {
        this.form = this._fb.group({
          units: [0, [Validators.required, Validators.pattern('^[+-]?[0-9]{1,5}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?'), Validators.min(0)]],
          nanos: [0, [Validators.pattern('^[0-9]*'), Validators.min(0)]],
          description: ['', [Validators.maxLength(300)]],
          currency: ['USD', Validators.required],
          type: [this._typeFormPrice, Validators.required],
        });
      }
    }
  }

  duplicateValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    const isDuplicated = !!this._addedServices?.find(x => x.displayName === control.value);
    return isDuplicated ? { duplicated: true } : null;
  }

  get name() : FormControl {
    return this.form.get('name') as FormControl;
  }

  get units() : FormControl {
    return this.form.get('units') as FormControl;
  }

  get description() : FormControl {
    return this.form.get('description') as FormControl;
  }

  get currency() : FormControl {
    return this.form.get('currency') as FormControl;
  }

  get typePrice() : FormControl {
    return this.form.get('type') as FormControl;
  }

}
