
// Should match the keys on the 'dark_launch_features'/'dark_launch_users' collections on DB
export const FEATURES_NAMES = [
    'reviewUpdatesFromGoogle',
    'legacyPost',
    'updatedDatePicker',
    'updatedPricingModal',
    'appleBusinessConnect',
    'flags',
    'searchTermsLocationView',
    'searchTermsReportList',
    'searchTermsSearch',
    'searchTermsMultilocationTable',
    'searchTermsImpressionsGraph',
    'searchKeywordsAtlas',
    //'isNewFeature',
    'questionsAndAnswersLocationView',
    'qandaReportView',
    'qandaLocationView',
    'profilesFeature',
    'isUpdatedFeature',
    'reviewsLocationView',
    'reviewsReportList',
    'postManagement',
    // TODO: Change 'bento' to "userGuides"
    'bento',
    'autoAddLocationsTrigger',
    'reviewUpdatesFromGoogle',
] as const; 


type Features = {
    [k in typeof FEATURES_NAMES[number]]?: boolean;
}


export type UserFeatures =  {
    userFeatures    : Features;
    generalFeatures : Features & { isNewFeature : Features }
}

export type UserFeaturesFinal = UserFeatures & { 
   final : {[k in typeof FEATURES_NAMES[number]]: boolean}
}
