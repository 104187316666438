import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewContainerRef, ComponentFactoryResolver, ViewChild } from '@angular/core';

@Component({
  selector: 'app-datepicker-featureflag-wrapper',
  template: `<ng-template #dynamicContainer></ng-template>`,
})
export class DatepickerFeatureflagWrapperComponent implements OnInit {
  @ViewChild('dynamicContainer', {read: ViewContainerRef, static: true})
  container: ViewContainerRef;

  constructor(private _route: ActivatedRoute, private _resolver: ComponentFactoryResolver) { }

  // We implement a component factory to decide, based on the resolver hooked to the correspondant route, which component to load
  ngOnInit(): void {
    this._route.data.subscribe(data => {
      const componentToLoad = data.component;

      if (componentToLoad) {
        const factory = this._resolver.resolveComponentFactory(componentToLoad);
        this.container.clear();
        this.container.createComponent(factory);
      }
    });
  }

}
