import { ChartsModule } from 'ng2-charts';
import { ZuluToDatePipe } from './../pipes/zulu-to-date.pipe';
import { TopicTypePipe } from './../pipes/topic-type.pipe';
import { ShortNumberPipe } from './../pipes/short-number.pipe';
import { ReplacePipe } from './../pipes/replace.pipe';
import { OTimeAgoPipe } from './../pipes/o-time-ago.pipe';
import { HourAmPmSchedulePipe } from './../pipes/hour-am-pm-schedule.pipe';
import { EllipsisPipe } from './../pipes/ellipsis.pipe';
import { DateJsonPipe } from './../pipes/date-json.pipe';
import { DateChartPipe } from './../pipes/date-chart.pipe';
import { BuildPercentageFormatPipe } from './../pipes/build-percentage-format.pipe';
import { AttributtesFetchPipe } from './../pipes/attributtes-fetch.pipe';
import { AcceptFormatImagePipe } from './../pipes/accept-format-image.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ColorPickerModule } from 'ngx-color-picker';
import { QuillModule } from 'ngx-quill';

import { StartsComponent } from '../modules/starts/starts.component';
import { AddressComponent, AddressOuterComponent } from '../components/address.component';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { WidgetComponent } from '../widget/components/widget.component';
import { ColorDatePickerComponent } from '../modules/color-date-picker/color-date-picker.component';
import { ReadMoreComponent } from '../components/read-more.component';
import { FormInputUploadComponent } from '../modules/form-input-upload/form-input-upload.component';
import { FormInputUploadMultipleComponent } from '../modules/form-input-upload-multiple/form-input-upload-multiple.component';
import { TrialMessageComponent } from '../modules/trial-message/trial-message.component';
import { HoursAmPmPipe } from '../pipes/hours-am-pm.pipe';
import { FireTimestampPipe } from '../pipes/date-fire-timestamp.pipe';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
 
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    ColorDatePickerComponent,
    WidgetComponent,
    StartsComponent,
    AddressComponent,
    AddressOuterComponent,
    FormInputUploadComponent,
    FormInputUploadMultipleComponent,
    ReadMoreComponent,
    TrialMessageComponent,
    AcceptFormatImagePipe,
    AttributtesFetchPipe,
    BuildPercentageFormatPipe,
    DateAgoPipe,
    DateChartPipe,
    DateJsonPipe,
    EllipsisPipe,
    FireTimestampPipe,
    HourAmPmSchedulePipe,
    HoursAmPmPipe,
    OTimeAgoPipe,
    ReplacePipe,
    ShortNumberPipe,
    TopicTypePipe,
    ZuluToDatePipe,
    
  ],
  imports: [
    CommonModule,
    ChartsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSidenavModule,
    MatTableModule,
    MatCheckboxModule,
    ColorPickerModule,
    FormsModule,
    MatProgressBarModule,
    MatToolbarModule,
    NgxSkeletonLoaderModule,
    QuillModule.forRoot(),
    MatPaginatorModule,
    MatProgressSpinnerModule,
    SwiperModule
  ],
  exports: [
    ColorDatePickerComponent,
    CommonModule,
    FormsModule,
    QuillModule,
    AddressComponent,
    AddressOuterComponent,
    FormInputUploadComponent,
    FormInputUploadMultipleComponent,
    StartsComponent,
    WidgetComponent,
    ReadMoreComponent,
    TrialMessageComponent,
    AcceptFormatImagePipe,
    AttributtesFetchPipe,
    BuildPercentageFormatPipe,
    DateAgoPipe,
    DateChartPipe,
    DateJsonPipe,
    EllipsisPipe,
    FireTimestampPipe,
    HourAmPmSchedulePipe,
    HoursAmPmPipe,
    OTimeAgoPipe,
    ReplacePipe,
    ShortNumberPipe,
    TopicTypePipe,
    ZuluToDatePipe
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class SharedModule {
}
