<!--Apple Brand-->
<div class="box__item box__item--editable">
    <div class="d-flex w--100">
      <!-- <div class="icon icon--rounded icon--gray mr--20"><i class="far fa-info"></i></div> -->
      <div class="w--100" [class.txt--medium-gray]="isUnderReview">
        <h4 class="w--100 txt--md fw--700 mb--10">{{sectionName}}</h4>
        <div class="d-flex w--100 mb--10">
            <div class="brand-img-container">
                <img *ngIf="brand?.brandLogo" [src]="getProcessedUrl()" alt="">
                <img *ngIf="!brand?.brandLogo" src="/assets/images/no_files_attached.svg" alt="">
            </div>
            <div class="d-flex flex-column ml--10">
                <div class="txt--md mb--5">
                    {{getName() | titlecase}}
                </div>
                <div>
                    <span *ngIf="brand?.state" class="selected-label"><i class="fas fa-badge-check mr--5"></i>{{brand.state | titlecase}}</span>
                </div>
            </div>
        </div>
        <p class="txt--md action-subtitle">To manage your business profile, including logo, cover photo, primary category across locations, please go to your Apple Business Connect account.</p>
      </div>
    </div>
</div>