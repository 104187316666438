import { ActivatedRoute } from '@angular/router';
import { ViewChild, ChangeDetectorRef } from '@angular/core';
// dep
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// dep
import { DateRangePickerComponent } from '@syncfusion/ej2-angular-calendars';

// app
import { ReportsFilterConfigService } from './../services/reports-filter-config.service';
import { KeywordsFilterConfigService } from './../services/keywords-filter-config.service';
import { InsightFilterConfigService } from './../services/insight-filter-config.service';
import { AbcInsightsFilterConfigService } from './../services/abc-insights-filter-config.service';
import { IDateRange, IFilterConfig, Granularity, IComparisonItem } from '../../../constants/data-picker';
import { ModalShareComponent } from './../../modal-share/modal-share.component';
import { ModalService } from 'src/app/services/modal.service';
import { alwaysShowCalendar } from '../../../helpers/syncfusion.helpers';
import { SessionService } from 'src/app/services/session.service';
import { BaseComponent } from 'src/app/components/base.component';
import { ReportService } from 'src/app/services/report.service';
import { LocationService } from 'src/app/services/location.service';
import { PdfService } from 'src/app/services/pdf.service';

@Component({
  selector: 'app-reports-filter',
  templateUrl: './reports-filter.component.html',
  styleUrls: ['./reports-filter.component.scss']
})

export class ReportsFilterComponent extends BaseComponent implements OnInit {
  @Input() isReport = false;
  @Input() comparison = true;
  @Input() accountId = '';
  @Input() locationId = '';
  @Input() hasExportCSV = true;
  @Input() hasExportPDF = true;
  @Input() hasShare = false;
  @Input() set reportType(name: string) { // this mapping could be improved
    this._reportType = name;
    switch (name) {
      case 'keyword-location':
        this.comparisonAvailable = true;
        this._reportCleanName = 'keywords';
        break;
        case 'performance-insights':
        this.comparisonAvailable = false;
        this._reportCleanName = 'rollup';
        break;
        default:
        this.comparisonAvailable = false;
        this._reportCleanName = name;
        break;
    }
  };

  @Output() navigateBackEmitter = new EventEmitter(); // <- the parent should handle where to redirect
  @Output() dateSelectionChanged = new EventEmitter();

  @ViewChild('dateRangePickerMonthly',{ static: false }) dateRange: DateRangePickerComponent;
  
  public readonly session$ = this._sessionS.session$;
  public initialized = false;
  public isProgress = false;
  public dateReady = false;
  public comparisonAvailable = false;
  public dateRangeObject: IFilterConfig = {
    dateRangeLimits: {
      minDate: null,
      maxDate: null
    },
    selectedRange: { minDate: null, maxDate: null },
    granularity: {
      start: Granularity.Year,
      depth: Granularity.Year
    },
    presetDateRanges: null, // <- saves the presets
    viewBy: Granularity.Month,
    comparisonPresets: null,
  }
  public comparisonPeriod: IComparisonItem = {
    comparisonID: null, 
    name: 'None', 
    enabled: true
  }; // <- use it to save the selected comparison period
  public isCapturing = false;
  
  private _reportType = '';
  private _reportCleanName = '';
  private _filterConfigS: ReportsFilterConfigService;

  constructor(
    private _keywordsFilterS: KeywordsFilterConfigService,
    private _insightsFilterS: InsightFilterConfigService,
    private _abcInsightsFilterS: AbcInsightsFilterConfigService,
    private _sessionS: SessionService,
    private _route: ActivatedRoute,
    private _modalS: ModalService,
    private _cdRef: ChangeDetectorRef,
    private _reportS: ReportService,
    private _locationS: LocationService,
    private _pdfS: PdfService
  ) {
    super();
    this.locationId = this._route.parent.snapshot.params.locationId;
    this.accountId  = this._route.parent.snapshot.params.accountId;
  }

  ngOnInit(): void {
    switch (this._reportType) {
      case 'keyword-location':
        this._filterConfigS = this._keywordsFilterS;
        break;
      case 'abc-insights':
        this._filterConfigS = this._abcInsightsFilterS;
        break;
      case 'performance-insights':
        this._filterConfigS = this._insightsFilterS;
    };
    this._subscribeSafe(this._filterConfigS.dateRestrictionSubject$,
     (dateRestriction: IDateRange) => {
      this._filterConfigS.getDatePresets();
      
      
      this._subscribeSafe(this._filterConfigS.datePresetsSubject$, data => {
        if (data?.length > 0) {

          this.dateRangeObject.presetDateRanges = data;
        }
      })
      
      if (dateRestriction) { 
        this.dateRangeObject.dateRangeLimits.minDate = dateRestriction.minDate;
        this.dateRangeObject.dateRangeLimits.maxDate = dateRestriction.maxDate;
        this.dateReady = true;
        if (!this.initialized) {
          this._filterConfigS.updateFilterConfigAndGetReportData({
            // testing, we need to initialize with the default min/max dates (defined in doc) if possible
            minDate: dateRestriction?.minDate,
            maxDate: dateRestriction?.maxDate
          })
          this.initialized = true;
        }
      }

      
      this._subscribeSafe(this._filterConfigS.comparisonPresetsSubject$, data => {
        if (data?.length > 0) {

          this.dateRangeObject.comparisonPresets = data;
        }
      })

      this._subscribeSafe(this._filterConfigS.granularitySubject$, granularity => {
        if (granularity) {
          this.dateRangeObject.viewBy = granularity;
        }
      })
    })
  } 

  dateChanged(event): void {
    // Date().toISOString() creates the wrong date, 31/12/2023 if you select 01/01/2024 and you are in Tokyo (UTC+9)
    // Date().toLocaleDateString() creates the correct date, 01/01/2024 if you select 01/01/2024 and you are in Tokyo (UTC+9)
    // same scenario if you are in LA (UTC-8) or anywhere else in the world
    this.dateRangeObject.selectedRange.minDate = event.startDate, 
    this.dateRangeObject.selectedRange.maxDate = event.endDate
    
    // should prompt a report update
    this._filterConfigS
      .updateFilterConfigAndGetReportData(
        {
          minDate: event.startDate, 
          maxDate: event.endDate
        }, 
        this.comparisonPeriod, // <- either null when None is selected, or a defined period
      );
    this._filterConfigS.validateComparisonPresets({minDate: event.startDate, maxDate: event.endDate});
    this.dateSelectionChanged.emit({minDate: event.startDate, maxDate: event.endDate})
  }

  onComparisonchage(period): void {
    this.comparisonPeriod = period;
    this._filterConfigS.updateFilterConfigAndGetReportData({minDate: this.dateRangeObject.selectedRange.minDate, maxDate: this.dateRangeObject.selectedRange.maxDate}, period);
  }

  hasLastMonth(): boolean {
    return false; // TESTING
  }

  navigateBack(): void {
    this.navigateBackEmitter.emit(true);
  }

  handleShared(): void { // This will need to be refactored

    // we'll deal with this later
    // const reportType = (
    //   this.reportType?.includes('rollup') && !this.isLegacyInsights ? 'performance-insights' : 
    //   !this._router.url.includes('report')  ? `${this.reportType}-location` : 
    //   this.reportType?.includes('revenue') && this._router.url.includes('performance') ? 'performance-revenue-report' : `${this.reportType}-report`
    // );

    // if(this.reportType.includes('qanda')) {
    //   const dates = this._questionAnswerS.buildPeriodDate(this.periodDaysValue.value);
    //   start = dates.startDatetime;
    //   end = dates.endDatetime;
    // } else {
    //   start = this.dataPicker?.range?.start;
    //   end = this.dataPicker?.range?.end;
    // }

    // this.dataPicker.range.start = this.reportS.getFormattedStringDate(start);
    // this.dataPicker.range.end   = this.reportS.getFormattedStringDate(end);

    // if(this.reportType.includes('comparison')) {  
    //   const startB = moment.isMoment(this.dataPicker?.rangeB?.start) ? this._dateS.getStringDateYMD(this.dataPicker?.rangeB?.start) : this.dataPicker?.rangeB?.start;
    //   const endB = moment.isMoment(this.dataPicker?.rangeB?.end) ? this._dateS.getStringDateYMD(this.dataPicker?.rangeB?.end) : this.dataPicker?.rangeB?.end;
      
    //   this.dataPicker.rangeB = {start: null, end: null};
    //   this.dataPicker.rangeB.start = this.reportS.getFormattedStringDate(startB);
    //   this.dataPicker.rangeB.end   = this.reportS.getFormattedStringDate(endB);
    // }

    let newDataPicker = {
      range: { 
        start: (this.dateRangeObject.selectedRange.minDate || this.dateRangeObject.dateRangeLimits.minDate).toLocaleDateString('en-CA'), 
        end: (this.dateRangeObject.selectedRange.maxDate || this.dateRangeObject.dateRangeLimits.maxDate).toLocaleDateString('en-CA') 
      },
      rangeB: null,
      aggregation: 'MONTH',
      locations: [{accountId: this.accountId, locationId: this.locationId}] // <- this WONT work for reports with multiple locations
    }
    
    // deal with comparison
    // newDataPicker = {
    //   ...newDataPicker,
    //   rangeB: { start: , end:  },
    // }

    const comparisonValue = {disabled: false, displayName: this.comparisonPeriod.name, value: this.comparisonPeriod.comparisonID}
      
    this._modalS.openGenericModal(ModalShareComponent, {
      createNew: true,
      dataPicker: newDataPicker,

      reportType: this._reportType,
      comparisonMetrics: [], // <- needs to be refactored
      showComparison: this._reportType == 'keyword-location' ? !!this.comparisonPeriod.comparisonID : null,
      compareToValue: this._reportType == 'keyword-location' ? comparisonValue : null,
      // questionsType: this.reportType == 'qanda' ? this.questionsTypeValue?.value : null,
      // periodDaysValue: this.reportType == 'qanda' ? this.periodDaysValue?.value : null,
      questionsType: null,
      periodDaysValue: null,
      reportName: '', // <- needs to be refactored for reports
      route: this._route
    }, () => {
      this._cdRef.detectChanges();
    });
  }

  onOpen(): void {
    // possible place to add footer injection through javascript, as there doesn't seem to be a native way of modifying the component's footer
    
  }

  datePickerCreation(): void {
    alwaysShowCalendar();
  }

  async exportCSV() {
    this.isProgress = true;
    let gid;
    this._subscribeSafe(this.session$, s => gid = s.gid)
    
    const startDate = this.dateRangeObject.selectedRange.minDate || this.dateRangeObject.dateRangeLimits.minDate;
    const startDateString= startDate.toLocaleDateString('en-CA');

    const endDate = this.dateRangeObject.selectedRange.maxDate || this.dateRangeObject.dateRangeLimits.maxDate;
    const endDateString = endDate.toLocaleDateString('en-CA');


    await this._reportS.handleExportCsvMongo(
      null,
      gid,
      this.accountId,
      this.locationId,
      startDateString,
      endDateString,
      this.dateRangeObject.viewBy,
      this._reportCleanName,
      this.comparisonPeriod?.comparisonID
    );

    this.isProgress = false;
  }

  async exportPDF() {
    try {
      this.isProgress = true;
      const params = this._route.snapshot.params;
      let filename : string
      this.disableScrollingAndPointerEvents();

      // This will be added when we implement reporting
      // if (params.id) {
      //   const report = (await this._reportS.getById(params.gid, params.id).get().toPromise()).data()
      //   const name = report?.reportName ? report.reportName : this.reportName;
      //   filename = `${name.toLowerCase()} - ${this.reportType.toLowerCase()}`;
      // } else {
        let gid;
        this._subscribeSafe(this.session$, s => gid = s.gid)
        const location = await this._locationS.fetchLocation(gid, this.accountId, this.locationId)
        const address = this._locationS.formatAddress(location.location.address);
        filename = `${location.locationName}` + (address ? ` - ${address}` : '');
      // }
      await this._pdfS.base64AndSave('charts-container', filename, '')
    } finally {
      this.isProgress = false;   
      this.enableScrollingAndPointerEvents();
    }
  }

  disableScrollingAndPointerEvents() : void {
    this.scrollTop();
    document.body.classList.add('disable-scroll');
    document.body.classList.add('disable-pointer-events');
    this.isCapturing = true
  }
  
  enableScrollingAndPointerEvents() : void {
    document.body.classList.remove('disable-scroll');
    document.body.classList.remove('disable-pointer-events');
    this.isCapturing = false
  }

  scrollTop(): void {
    (function goUp(){
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

      if(currentScroll > 0)
      {
        window.requestAnimationFrame(goUp);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }
}
