// dep
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

// app
import { LoadModalComponent } from '../components/load-modal/load-modal.component';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  dialogRef: MatDialogRef<LoadModalComponent> = null;

  constructor(private dialog: MatDialog) { }

  open(steps: number) {
    this.dialogRef = this.dialog.open(LoadModalComponent, { data: {steps, complete: 0, completed: null}});

    this.dialogRef.afterClosed().take(2).subscribe(
      () => {
        this.dialogRef = null
      }
    );

    return this.dialogRef;
  }

  reportAdvanced(complete: number, completed: string) {
    if(this.dialogRef === null) 
      return;
    this.dialogRef.componentInstance.updatedData(complete, completed)
  }

}
