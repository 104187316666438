// dep
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as uuid from 'uuid';

@Component({
  selector: 'app-modal-products-item',
  templateUrl: './modal-products-item.component.html',
  styleUrls: ['./modal-products-item.component.scss']
})
export class ModalProductsItemComponent {

  productName: string;
  productNameInvalid: boolean;
  productDescription: string;
  productImage: string;

  priceRange: boolean;
  productPrice: string;
  productPriceMax: string;
  currencyCode: string;

  buttonAdded: boolean;
  buttonType: string;
  buttonText: string;

  readonly linkType = ['Order Online', 'Buy', 'Learn More', 'Get offer']

  constructor(
    public dialogRef: MatDialogRef<ModalProductsItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.priceRange = false;
    this.buttonAdded = false;
    this.productNameInvalid = false;
    this.currencyCode = data?.product?.price?.currencyCode ? data?.productItem?.price?.currencyCode : '';
  }


  showLinkText(type: string): void {
    this.buttonAdded = (type !== 'hideButton');
    this.buttonType = type;
  }

  apply(): void {
    if ( this.productName ) {
      this.productNameInvalid = false;
      const productItem = {
        itemId: uuid.v4(),
        labels: [
          { 
            displayName: this.productName,
            description: this.productDescription || ''
          }
        ],
        price: {
          currencyCode: "USD",
          units: this.productPrice || ""
        },
      };
      const data = { productItem };
      this.dialogRef.close(data);
    } else {
      this.productNameInvalid = true;
    }
  }
}
