import { LocationRef } from "./firestore/location-object";
import { CalendarView } from '@syncfusion/ej2-angular-calendars';

export interface DataPicker {
  range: { start: string, end: string }
  rangeB?: { start: string, end: string }
  aggregation?: string;
  multiChart?: MultiChart[];
  locations?: LocationRef[];
  gid?: string;
  uid?: string;
}


export interface MultiChart {
  id: number,
  value: string,
  checked: boolean
}


// creating new interfaceS to represent the new datePicker functionality
// we will expand this as we apply the datepicker to other reports

export interface IDateRange { 
  minDate: Date | null;
  maxDate: Date | null;
}

export interface IFilterConfig {
  // reportType: ReportType;
  dateRangeLimits: IDateRange;
  granularity: {
    start: string;
    depth: CalendarView;
  };
  presetDateRanges: IPreset[];
  selectedRange: IDateRange;
  viewBy: Granularity;
  comparisonPresets: IComparison[];
}

export interface ISelectedDateRange {
  minDate: string;
  maxDate: string;
}

// the preset dates will be calculated on the reports filter component
export interface IPreset {
  presetID: Preset; 
  name: string;
  dateRange: IDateRange;
  enabled?: boolean;
}

export interface IComparison {
  header: string; 
  items: IComparisonItem[];
}
export interface IComparisonItem {
  comparisonID: Preset; 
  name: string;
  enabled: boolean;
}


export enum Preset {
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  LAST_9_MONTHS = 'LAST_9_MONTHS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  LAST_18_MONTHS = 'LAST_18_MONTHS',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  LAST_YEAR = 'LAST_YEAR',
  ALL_TIME = 'ALL_TIME',
  PREVIOUS_PERIOD = 'prevPeriod',
  SAME_PERIOD_PREVIOUS_PERIOD = 'SAME_PERIOD_PREVIOUS_PERIOD',
  MONTH_TO_MONTH = 'prevMonth',
  QUARTER_TO_QUARTER = 'QUARTER_TO_QUARTER',
  SIX_MONTH_TO_SIX_MONTH = 'SIX_MONTH_TO_SIX_MONTH',
  YEAR_TO_YEAR = 'prevYear',
}

export enum Granularity {
  Decade = 'Decade',
  Year = 'Year',
  Month = 'Month',
  Week = 'Week',
  Day = 'Day'
}

export enum ReportType {
  Keywords = 'keywords',
  Performance = 'performance',
  Review = 'review'
}
