// dep
import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-modal-upgrade',
  templateUrl: './modal-upgrade.component.html',
  styleUrls: ['./modal-upgrade.component.scss']
})
export class ModalUpgradeComponent {

  stepComplete: boolean;
  value: number;
  upgradeForm: any;
  stepReached: boolean;
  showMessage: boolean;

  constructor(
      private router: Router,
      public dialogRef: MatDialogRef<ModalUpgradeComponent>,
      private formBuilder: FormBuilder, ) {
    this.stepComplete = false;
    this.value = 0;
    this.stepReached = false;
    this.showMessage = true;

    this.upgradeForm = this.formBuilder.group({
      name:       new FormControl('', [ Validators.required]),
      cardNumber: new FormControl('', [ Validators.required]),
      expDate:    new FormControl('', [ Validators.required]),
      cvv:        new FormControl('', [ Validators.required])
    });
  }

  getErrorMessage(type) {
    if (type.hasError('required')) {
      return 'You must enter a value';
    }
  }

  changeStep() {
    const c = this.upgradeForm.controls
    if ( c.name.hasError('required') || c.cardNumber.hasError('required') ||
         c.expDate.hasError('required') || c.cvv.hasError('required')) {
      return false;
    } else {
      this.stepComplete = !this.stepComplete;
      this.stepReached  = !this.stepReached;
      this.value = 100;
    }
  }
  goBack() {
    this.changeStep();
    this.value = 0;
  }
  confirm() {
    this.dialogRef.close();
    this.router.navigate(['/locations']);
  }

}
