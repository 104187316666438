import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as ENV } from '@environment';

import * as moment from 'moment';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UpdateCardComponent } from './../billing/update-card/update-card.component';
import { ModalService } from 'src/app/services/modal.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ISubscription } from 'src/app/constants/subscription';
import { SessionService } from 'src/app/services/session.service';
import { IAddon, pkg_names, pkg_descriptions, pkg_features, IPricingTier } from './../constants/pricing';
import { IPricingObject } from '../constants/pricing';

@Injectable({
    providedIn: 'root'
})
export class ManagePlanService {

    public subscription: ISubscription; // FIXME: Don't cache subscription
    public pricingObject: IPricingObject;

    private _pricingObjectSubject$ = new BehaviorSubject<IPricingObject>(null);
    private _isLoading$ = new BehaviorSubject<boolean>(false);

    constructor(
        private _http: HttpClient,
        private _sessionS: SessionService,
        private _subscriptionS: SubscriptionService,
        private _paymentsS: PaymentsService,
        private _modalS: ModalService,
        private _snackS: SnackbarService
    ) {
        this.emptyPricingObject();
    }

    async loadPlan(): Promise<void> {
        this._isLoading$.next(true);
        this.subscription = this._sessionS.getSession().subscription;

        if (this.subscription && this.subscription.locationsQty) {
            this.fetchInvoices();
            this.fetchCards();
            
            // dates in this object are prone to cause errors if they are missing in the subscription
            // they shouldn't be missing, but error handling logic should be implemented in the future
            this.pricingObject.nextDueDate = this.subscription.nextDueDate;
            this.pricingObject.billingOverride = this.subscription.billingOverride;
            this.pricingObject.nextInvoice = this.subscription.nextDueDate;
            this.pricingObject.trialStart = this.subscription.trialStart || '';
            this.pricingObject.trialEnd = this.subscription.trialEnd || '';
            this.pricingObject.trialActive = (this.subscription.status === 'TRIAL');
            this.pricingObject.totalLocations = this.subscription.locationsQty;
            this.getActiveAddons();
            this.getTiers(); // requires totalLocations
            this.getPricing(this.pricingObject.totalLocations, 'core');
            if (this.subscription?.pricing?.pkg_bulk_actions) {
              this.getPricing(this.pricingObject.totalLocations, 'bulk');
            }
            // this formula has to be modified when we add more add-ons, right now it only works with bulk (availableAddons[0])
            
      
            const momentDate1 = moment(this.pricingObject.trialEnd, "YYYY-MM-DD HH:mm:ss.SSSSSS");
            const momentDate2 = moment(this.pricingObject.trialStart, "YYYY-MM-DD HH:mm:ss.SSSSSS");
      
            // Calculate the difference in days
            const trialDuration = momentDate1.diff(momentDate2, 'days');
            
            // if today is after trialEnd then daysLeftInTrial has to be 0
      
            if (moment().isAfter(moment(this.pricingObject.trialEnd, "YYYY-MM-DD HH:mm:ss.SSSSSS"))) {
            
              // trialProgress should be 100, because it has finished
              this.pricingObject.trialProgress = 100;
              this.pricingObject.trialLeft = 0;
              
            } else {
              // else, daysLeftInTrial should be equal to trialDuration minus the number of days since trialStart until today
      
              const daysLeftInTrial = trialDuration - moment().diff(moment(this.pricingObject.trialStart, "YYYY-MM-DD HH:mm:ss.SSSSSS"), 'days');
              this.pricingObject.trialLeft = daysLeftInTrial;
      
              // trialProgress should be equal to daysLeftInTrial divided by trialDuration multiplied by 100
              this.pricingObject.trialProgress = ((trialDuration - daysLeftInTrial) * 100) / trialDuration;
              
      
            }
            this._isLoading$.next(false);
          } else {
            console.error('no subscription or missing locationsQty');
            this._isLoading$.next(false);
        }
        this._pricingObjectSubject$.next(this.pricingObject);
    }

    // getters

    get isLoading$(): Observable<boolean> {
        return this._isLoading$.asObservable();
    }
    get pricingObject$(): Observable<IPricingObject> {
        return this._pricingObjectSubject$.asObservable();
    }

    // methods for pricingObject

    async fetchInvoices(): Promise<void> {
        const invoices = await this._subscriptionS.fetchInvoices(this.subscription.gid) // <-- move this to ngOnInit, its async
        this.pricingObject.invoices = invoices.data.filter(el => ['paid', 'pending', 'pre authorized', 'unpaid', 'canceled'].includes(el.status.toLowerCase()))
        this.pricingObject.invoices.reverse();
    }

    getActiveAddons(): void {
        this.pricingObject.activeAddons.names = Object.keys(this.subscription?.packages);
        this.pricingObject.activeAddons.values = this.subscription?.packages;
      }
    
    getTiers(): void { // Right now it works by manually adding a forEach case for each pkg type, could be automated in the future
        const coreTiers = this.subscription?.pricing?.pkg_core?.tiers;
        const bulkTiers = this.subscription?.pricing?.pkg_bulk_actions?.tiers;

        const bulkFormattedTiers: IPricingTier[] = [];

        coreTiers.forEach((tier, index) => {
            const profileSegment = `${tier.locationsQtyMin}${
            tier.locationsQtyMax && tier.locationsQtyMax > 1 
                ? '-' + tier.locationsQtyMax 
                : !tier.locationsQtyMax 
                ? '+' 
                : ''
            }`;

            const isActive = this.pricingObject.totalLocations >= tier.locationsQtyMin &&
            (tier.locationsQtyMax === undefined || this.pricingObject.totalLocations <= tier.locationsQtyMax);

            const t : IPricingTier = {
            tierNumber: index,
            locationsQtyMin: tier.locationsQtyMin,
            locationsQtyMax: tier.locationsQtyMax,
            price: tier.price,
            profileSegment: profileSegment,
            segmentPriceString: `${tier.locationsQtyMax ? '$' + tier.price + '/mo' : 'Custom'}`,
            isActive
            }

            this.pricingObject.coreTiers.push(t);
        })

        bulkTiers.forEach((tier, index) => {
            const profileSegment = `${tier.locationsQtyMin}${
            tier.locationsQtyMax && tier.locationsQtyMax > 1 
                ? '-' + tier.locationsQtyMax 
                : !tier.locationsQtyMax 
                ? '+' 
                : ''
            }`;

            const isActive = this.pricingObject.totalLocations >= tier.locationsQtyMin &&
            (tier.locationsQtyMax === undefined || this.pricingObject.totalLocations <= tier.locationsQtyMax);

            const t : IPricingTier = {
            tierNumber: index,
            locationsQtyMin: tier.locationsQtyMin,
            locationsQtyMax: tier.locationsQtyMax,
            price: tier.price,
            profileSegment: profileSegment,
            segmentPriceString: `${tier.locationsQtyMax ? '$' + tier.price + '/mo' : 'Custom'}`,
            isActive
            }

            
            bulkFormattedTiers.push(t);
        })
        const bulkAddon : IAddon = {
            name: 'pkg_bulk_actions', // needs to be dynamic in the future
            formattedName: pkg_names['pkg_bulk_actions'], // should also be dynamic once we modify this method to be automated
            assignedTierNumber: 0,
            unitPrice: 0,
            unitDiscount: 0,
            totalPrice: 0,
            tiers: bulkFormattedTiers,
            isActive: this.subscription.packages.hasOwnProperty('pkg_bulk_actions') && !this.subscription.packages['pkg_bulk_actions'].isTerminationScheduled,
            isTerminationScheduled: this.subscription.packages.hasOwnProperty('pkg_bulk_actions') && this.subscription.packages['pkg_bulk_actions'].isTerminationScheduled || false,
            terminationDate: this.pricingObject.nextDueDate || null,
            description: pkg_descriptions['pkg_bulk_actions'],
            features: pkg_features['pkg_bulk_actions'],
        }

        this.pricingObject.availableAddons.push(bulkAddon);
    }

    getPricing(locations: number, pkgName: string): void {
        switch (pkgName) {
            case 'core':
            const prcObj = this.pricingObject;
            const basePrice = (this.pricingObject.coreTiers.find(tier => tier.price > 0))?.price || 0;
            for (let tier of prcObj.coreTiers) {
                if (locations >= tier.locationsQtyMin && 
                    (tier.locationsQtyMax === undefined || locations <= tier.locationsQtyMax)) {
                prcObj.unitPrice = tier.price;
                prcObj.assignedCoreTierNumber = tier.tierNumber;
                prcObj.assignedTierDiscount = prcObj.coreTiers[prcObj.assignedCoreTierNumber].price > 0 
                        ? basePrice - prcObj.coreTiers[prcObj.assignedCoreTierNumber].price
                        : prcObj.coreTiers[prcObj.assignedCoreTierNumber].price;
                    prcObj.totalPrice = 
                        (prcObj.coreTiers[prcObj.assignedCoreTierNumber].price - prcObj.assignedTierDiscount) * locations;
                }
            }
            break;
            case 'bulk':
                if (this.pricingObject.availableAddons.some((addon: IAddon) => addon.formattedName === pkg_names['pkg_bulk_actions'])) {
                const addon = this.pricingObject.availableAddons.find((addon: IAddon) => addon.formattedName === pkg_names['pkg_bulk_actions']);
                const basePrice = (addon?.tiers?.find(tier => tier.price > 0))?.price || 0;
                for (let tier of addon.tiers) {
                    if (locations >= tier.locationsQtyMin && 
                    (tier.locationsQtyMax === undefined || locations <= tier.locationsQtyMax)) {
                    addon.unitPrice = tier.price;
                    addon.assignedTierNumber = tier.tierNumber;
                    addon.assignedTierDiscount = addon.tiers[addon.assignedTierNumber].price > 0 
                        ? basePrice - addon.tiers[addon.assignedTierNumber].price
                        : addon.tiers[addon.assignedTierNumber].price;
                    addon.totalPrice = 
                        (addon.tiers[addon.assignedTierNumber].price - addon.assignedTierDiscount) * locations;
                    }
                }
                } else {
                console.error('addon not found');
                }
        }

        // calculate total price based on active add-ons
        this.pricingObject.totalPrice = (this.pricingObject.unitPrice - this.pricingObject.unitDiscount) * this.pricingObject.totalLocations;
        if (this.pricingObject.activeAddons.names.includes('pkg_bulk_actions') 
            && !this.pricingObject.activeAddons.values['pkg_bulk_actions']?.isTerminationScheduled
        ) {
            this.pricingObject.totalPrice += 
            this.pricingObject.availableAddons
                .find((addon: IAddon) => addon.formattedName === pkg_names['pkg_bulk_actions'])?.totalPrice || 0;
        }
        
    }

    async reloadPricingObject(): Promise<void> {
        this._isLoading$.next(true);
        this.emptyPricingObject();
        this.subscription = null;
        await this._sessionS.refresh();
        this.loadPlan();
    }

    emptyPricingObject(): void {
        this.pricingObject = {
            unitPrice: 0,
            unitDiscount: 0, // not being used?? is never reassigned
            availableAddons: [],
            activeAddons: {names: [], values: {}},
            totalPrice: 0,
            totalLocations: 0,
            trialActive: false,
            trialDaysTotal: 0,
            trialStart: '',
            trialEnd: '',
            trialProgress: 0,
            trialLeft: 0,
            coreTiers: [],
            assignedCoreTierNumber: 0,
            assignedTierDiscount: 0,
            billingOverride: false,
            invoices: [],
            nextInvoice: '',
            cards: [],
        }
    }

    // methods for add-ons

    updateAddons(addOns: IAddon[]): Observable<any> | any {
        const gid = this._sessionS.getSession()?.subscription?.gid || null;

        if (!gid) {
            return of(null);
        }
        // TODO: should make the body dynamic and not hardcoded, as other addons won't work here
        const body = {
            packages: {
               pkg_core: {
                   isTerminationScheduled: false, // <- TODO: only changes if the user decides to terminate the subscription
                 },
               pkg_bulk_actions: {
                   isTerminationScheduled: !addOns[0].isActive, // if its scheduled its because it will no longer be active, hence the negation mark
                 }
               }
           };
        return this._http.post(`${ENV.billingApiUrl}/subscription/${gid}/packages`, body)
            .pipe(map(res => {
                res = body;
                return res;
            }))
    }

    // methods for card management

    async fetchCards(): Promise<void> {
        const cards = await this._paymentsS.getCards(this.subscription.gid)
        this.pricingObject.cards = cards || [];
    }

    async onAddCard() : Promise<void> {
        if(!(await this._modalS.openModal(UpdateCardComponent, {})))
          return
        await this._sessionS.refresh()
        await this.fetchCards()
    }

    async handleDefaultSource(card): Promise<void> {
        if(await this._modalS.openConfirmModal("Set as default",
                                                "Are you sure you want use this Credit Card as the default one?",
                                                null, null, "Confirm", false,null, false)) {
            this.applyDefaultSource(card.card); 
        }
    }
    
    async applyDefaultSource(card): Promise<void> {
        await this._paymentsS.setDefaultSource(this.subscription.gid, card.id)
        this._snackS.openSuccess('Set as default Credit Card succesfully', 3000);
        this.fetchCards();
    }

    async handleDeleteSource(card): Promise<void> {
    if(await this._modalS.openConfirmModal("Remove Credit Card",
                                            "Are you sure you want to remove this Credit Card?",
                                            null, null, "Confirm", false, null, false)) {
        this._deleteSource(card.card);
    }
    }

    private async _deleteSource(card): Promise<void> {
        await this._paymentsS.deleteCard(this.subscription.gid, card.id) 
        this._snackS.openSuccess('Removed Credit Card succesfully', 3000)
        this.fetchCards();
    }

    // methods for PDF management

    onDownloadInvoicePDF(element): void {
        if (!element?.invoicePDF) { 
          return; 
        }
        const invId = element?._id?.$oid || '';
        try {
          this._subscriptionS.getInvoicePDF(invId)
          .subscribe(
            blob => this._downloadFile(blob, `invoice_${invId}.pdf`), 
            error => this._snackS.openError('There was an issue downloading the invoice')
          )
          } catch (error) {
          this._snackS.openError('There was an issue downloading the invoice');
        }
      }
      
      private _downloadFile(blob: Blob, filename: string): void {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
}