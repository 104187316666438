import { ILocationUrl } from './../../../constants/abc-location-info';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-app-url',
  templateUrl: './location-app-url.component.html',
  styleUrls: ['./location-app-url.component.scss']
})
export class LocationAppUrlComponent implements OnInit {
  @Input() isUnderReview = false;
  @Input() set urls(urls: ILocationUrl[]) {
    this.appUrl = urls.find(url => url.type === 'APP') || null;
  }

  public appUrl: ILocationUrl = null;
  public sectionName = 'App';
  constructor() { }

  ngOnInit() {
  }

}
