import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as _ from 'lodash';

import { LocationEditService } from '../../services/location-edit.service';

@Component({
  selector: 'app-modal-phone',
  templateUrl: './modal-phone.component.html',
  styleUrls: ['./modal-phone.component.scss']
})
export class ModalPhoneComponent implements OnInit {
  form: FormGroup;

  @Input() bulk: boolean = false;
  @Output() validResult: EventEmitter<boolean> = new EventEmitter(false);

  constructor( 
    public dialogRef: MatDialogRef<ModalPhoneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private locationEditS: LocationEditService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      primary: ['', Validators.required],
      additional: this.fb.array([])
    });

    if (data) {
      let primary = data.primaryPhone;
      if (primary)  {
        primary = data?.primaryPhone?.includes('+') ? data.primaryPhone : `+1 ${data.primaryPhone}`;
      }
      this.primary.setValue(primary);

      if ( data.additionalPhones === null || data.additionalPhones === undefined) {
        this.additional.setValue([]);
      } else {
        data.additionalPhones.forEach(item => {
          const number = item.includes('+') ? item : `+1 ${item}`;
          this.addPhone(number);
        });
      }
    }
  }

  ngOnInit() {}

  // add phone
  addPhone(phone?): void {
    this.additional.push(this.fb.group({
      phone: [null],
    }));

    if (phone) {
      const pos = this.additional.controls.length - 1;
      this.additional.controls[pos].get('phone').setValue(phone);
    }
  }

  // remove phone and reindex array
  removePhone(index): void {
    this.additional.removeAt(index);
  }
  
  // apply changes
  apply(): void {
    if (!this.form.valid) {
      return;
    }

    const primary = this.primary.value?.internationalNumber;
    const additional = this.additional.value.map(item => item.phone?.internationalNumber);

    this.locationEditS.locationEdit.primaryPhone = primary;
    this.locationEditS.locationEdit.additionalPhones = additional.filter(el => el != null);
    this.locationEditS.setAndUpdate().toPromise().then(()=>{this.dialogRef.close(data);});
    const data = { primary, additional };
  }

  getResult(): { primaryPhone: any, additionalPhones: any } {
    if (!this.form.valid) {
      this.validResult.emit(false);
      return
    } 

    this.validResult.emit(true);
    const primary = this.primary.value?.internationalNumber;
    const additional = this.additional.value.map(item => item.phone?.internationalNumber);

    return {primaryPhone: primary, additionalPhones: additional};
  }

  get primary() {
    return this.form.get('primary');
  }

  get additional() {
    return this.form.get('additional') as FormArray;
  }
}
