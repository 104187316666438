// TODO: This component seems to have a very different appearence/functionality when
// included from a report (isReport=true). Probably the report functionality
// should be refactored out. 

// dep
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl} from '@angular/forms';
import { Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime} from 'rxjs/operators';

const FlexSearch = require('flexsearch');

// app
import { GroupService } from '../../services/group.service';
import { SearchIndexElastic } from '../../constants/firestore/group';
import { NotificationService } from '../../services/notification.service';
import { LocationService } from '../../services/location.service';
import { ModalService } from '../../services/modal.service';
import { NOTIFICATION_ERROR_FETCH_DATA, NOTIFICATION_ERROR_LOCATION, NOTIFICATION_WARNING, 
         NOTIFICATION_GENERAL, NOTIFICATION_PROTOCOLS } from '../../constants/notifications';
// import { ModalFetchComponent } from '../modal-fetch/modal-fetch.component';
import { Pageable } from '../../constants/pageable';
import { Pagination } from '../../constants/api-response';
import { GlobalSearchService } from '../../services/global-search.service';
import { AccountService } from '../../services/account.service';
// import { ModalGetAddOn } from '../modal-get-addon/modal-get-addon.component';
import { SessionService } from 'src/app/services/session.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { BaseComponent } from 'src/app/components/base.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {
  // fetchProgress = false; // TODO: Unused, remove. 
  @Input() isReport: boolean;
  @Input() noToggle: boolean;

  // NOTIFICATIONS TYPES
  fetchLocationInfoData = NOTIFICATION_ERROR_FETCH_DATA;
  authFail = NOTIFICATION_ERROR_LOCATION;
  oldAuthFail = NOTIFICATION_WARNING;
  informative = NOTIFICATION_GENERAL;
  type_protocol = NOTIFICATION_PROTOCOLS;

  page = 1;
  size = 5;
  loadingNotifications = new BehaviorSubject(false);
  private paginate: Pageable = {size: this.size, page: this.page};
  private previousPageable: Pageable={page: 1, size: 5};
  loadingNoti = false;
  pagination: Pagination = {
    items: [],
    per_page: this.size,
    page: this.page,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };

  @Output() public sidenavToggle = new EventEmitter<void>();
  flexSearch = new FlexSearch({
    encode: "advanced",
    tokenize: "reverse",
    suggest: true,
    cache: true,
    doc: {
      id: 'id',
      field: [
        'accountName',
        'locationName',
        'locName',
        'address',
        'reportName',
        'labels'
      ]
    }
  });
  results: SearchIndexElastic[];
  selectedSearch = new FormControl();
  isUser = false;
  countNotification = 0;
  notifications: any[] = [];
  isShared: boolean;
  private _searchSubject = new Subject<string>();
  public isLoading = true;

  public showChromeWarning = false;
  
  public domain$  = this._sessionS.domain$;
  public session$ = this._sessionS.session$;

  constructor(
    // Public attributes for services
    public dialog: MatDialog,
    public navigationS : NavigationService,

    // Private attributes for services
    private _sessionS : SessionService,
    private _router: Router,
    private _groupS: GroupService,
    private _notificationS: NotificationService,
    private _locationS: LocationService,
    private _globalSearchService: GlobalSearchService,
    private _accountS: AccountService,
    private _modalService: ModalService,
  ) {
    super();
    this.isShared = _.endsWith(this._router.url, 'shared');

    // This component can be used both from logged-off and logged-in routes (is too overloaded,
    // TODO: must be refactored. This will not be executed for logged-off routes.
    let onHasSessionExecuted = false;
    this._subscribeSafe(this._sessionS.session$, () => {
      if(onHasSessionExecuted)
        return
      onHasSessionExecuted = true;
      this._onSessionLoaded();
    });

  }

  private _onSessionLoaded() : void {
    const {gid} = this._sessionS.getSession();
    this._subscribeSafe(this._groupS.getIndexes(gid), indexes_list => {
      if (!indexes_list?.length)
        return

      for (const index of indexes_list) {
        index.id = JSON.stringify(index);
        if(_.has(index, 'address'))
          index.address = this._locationS.formatAddress(index.address)
        
        const {isAdmin, user} = this._sessionS.getSession();
        if (!isAdmin) {
          if (index.type !== 'location' && index.type !== 'account') {
            continue;
          } else if (index.type === 'account') {
            if (!user.accounts?.find(acc => acc.accountId === index.source)) {
              continue;
            }
          } else {
            const [accountId, locationId] = index.source.split('/');
            const findAccount = user.accounts?.find(acc => acc.accountId === accountId);
            if (!findAccount || !findAccount.locations.find(loc => loc.locationId === locationId))
              continue            
          }
        }

        this.flexSearch.add(index);
      }

      this.isUser = true;
    });

  }

  capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  ngOnInit(): void {
    this._subscribeSafe(this._searchSubject.pipe(debounceTime(500)), 
                        result => this._search(result));

    /*this.notificationS.countNotifications(this.auth.session.gid).subscribe(count => {
      this.countNotification = count;
    });*/


    //this.getNotifications(this.paginate);

    if (this.isReport) {
      return;
    }

  }

  getNotifications(paginate: Pageable): void {
    let last = null;
    let next = null;
    if (this.previousPageable) {
      if (this.previousPageable.page < paginate.page) {
        if (this.notifications) {
          last = this.notifications[(paginate.size * (paginate.page - 1)) - 1];
        }
      } else if (this.previousPageable.page > paginate.page) {
        if (this.notifications) {
          next = this.notifications[0];
        }
      }
    }
    this._subscribeSafe(this._notificationS.paginateNotifications(paginate, last, next),
    (result) => {
      this.loadingNoti = false;
      this.notifications.push(...result.items);
      this.countNotification = result.total;
      this.pagination = result;
      this.previousPageable = {size: result.per_page, page: result.page}
      this.loadingNotifications.next(false);
    });

  }

  getReportTypeString(input: string): string{
    const data  = {
      'keyword': 'Keyword',
      'qanda': 'Questions and Answers',
      'review': 'Review',
      'rollup': 'Performand Rollup',
      'revenue': 'Revenue',
      'performance-revenue': 'Performance Revenue',
      'performance-rollup': 'Performance Rollup',
      'performance-comparison': 'Performance Comparison',
      'comparison': 'Comparison',
      'grade': 'Grade',
    };
    
    return data[input] || input;
  }

  clearAllNotifications(): void {
    this._notificationS.deleteAll();
  }

  deleteNotification(n): void {
    this._notificationS.deleteNotification(n.id);
  }

// TODO: Unused, remove?
//   openFetchDialog(result, accountId, locationId, notifyErrors=false): void {
//     const dialogRef = this.dialog.open(ModalFetchComponent, {
//       width: '1000px',
//       data: {
//         difference: result.difference,
//         placeId: locationId,
//         history: null,
//         notifyErrors
//       }
//     });
// 
//     dialogRef.disableClose = true;
// 
//     dialogRef.afterClosed().subscribe(refresh => {
//       if (refresh) {
//         this.router.navigate(['/account', accountId, 'location', locationId, 'location-info']).then();
//       }
// 
//     });
//   }

  handleReload(e): void {
    e.preventDefault();
    if (!this.loadingNoti) {
      const $event: Pageable = {
        page: (this.paginate.page + 1),
        size: 5
      }
      this.loadingNoti = true;
      this.paginate = $event;
      //this.getNotifications($event);
    }

  }

  // TODO: referenced from a commented-out section on header.component.html, obsolete? if yes remove.
  // viewed(n): void {
  //   switch (n.type) {
  //     case 'protocols':
  //       this.router.navigate(['/review-assistant']).then();
  //       break;
  //     case 'warning':
  //       this.router.navigate([`/accounts/${n.accountId}/locations`]).then();
  //       break;
  //     case NOTIFICATION_ERROR_LOCATION:
  //       this.router.navigate([`/accounts/${n.accountId}/locations`]).then();
  //       break;
  //     case NOTIFICATION_ERROR_FETCH_DATA:
  //       this.fetchProgress = true;
  //       this.googleS.fetchDifference(n.accountId, n.location.locationId).take(1).subscribe(result => {
  //         this.fetchProgress = false;
  //         this.openFetchDialog(result, n.accountId, n.location.locationId, true);
  //       }, error => {
  //         this.fetchProgress = false;
  //         this.snack.openError('failed to fetch', 4000);
  //       });
  //       break;
  //     case NOTIFICATION_GENERAL:
  //       break;
  //     default:
  //       this.router.navigate(['/account', n.accountId, 'location', n.location.locationId, 'workshop']).then();
  //       break;
  //   }
  //   // this.notificationS.merge(this.auth.session.gid, n.id, {status: 'viewed'}).then(() => {
  //   //
  //   // });
  // }

  public onToggleSidenav() : void {
    this.sidenavToggle.emit();
  }

  handleChangeSearch($event: any) : void {
    this._searchSubject.next($event);
  }

  private _search($event: any) : void {
    if(!this._sessionS.hasSession())
      return
    
    const text = $event.target.value.toLowerCase();
    if(!text){
      this.results = [];
    } else {
      // TODO: This should be cancelled if this method is called again
      this._globalSearchService.search(text, this._sessionS.getSession().gid).subscribe(result =>{
        this.results = result;
      });
   }
  }

  // TODO: getter called from template, slow
  public display(value): string | null {
    if (!value) {
      return null;
    }

    switch (value.type) {
      case 'account':
        return `${value.accountName}`;
      case 'location':
        return `${value.locationName} `;
      case 'report':
        return `${value.type}: ${value.reportName}`;
    }
  }

  // TODO: Refactor against locations.component.ts
  private async _validateVerifiedStatus(location: any) : Promise<boolean> {
    if (location?.locationState?.isVerified == false) {
      await this._modalService.openErrorModal(
        "Verification required in Google Business Profile",
        `<div class='txt--left'>
          This location requires verification, which prevents access to your dashboard.
          <div>
            <br>Step 1: Login to your Google Business Profile and verify your location.
            <br>Step 2: Once verified, return to Map Labs and click the refresh button.
          </div>
        </div>`
      );
      return false;
    } else {
      return true;
    }
  }

  async selected(): Promise<void> {
    const value = this.selectedSearch.value;

    if (value?.type == "location" && !(await this._validateVerifiedStatus(value))){
      return
    }

    let url = '';
    switch (value.type) {
      case 'account':
        url = `/accounts/${value.accountId}/locations`;
        break;
      case 'location':
        url = `/account/${value.accountId}/location/${value.locationId}/insights`;
        break;
      case 'report':
        url = `/report/${value.gid}/${value.reportId}/${value.reportType}`;
        break;
    }
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.navigate([url])
  }

  async goToProfiles() : Promise<void> {
    if (this._router.url.includes('share')) {
      this._router.navigate(['/']);
    } else {
      const {gid} = this._sessionS.getSession()
      const accounts = (await this._accountS.getAccountPaginate(gid, {page: 1, size: 5}, [])).items
      this._router.navigate([accounts.length ? 
                             `accounts/${accounts[0]?.accountId}/locations` : 
                             'accounts'])
    }
  }

}
