// dep
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReplaySubject } from 'rxjs';

// app
import {ReportService} from '../../services/report.service';
import {DataPicker} from '../../constants/data-picker';
import {LocationService} from '../../services/location.service';
import { LoadingService } from '../../services/loading.service';
import { ModalService } from 'src/app/services/modal.service';
import SavedLocation from 'src/app/constants/firestore/saved-location';
import { SessionService } from 'src/app/services/session.service';
import { locationNameToRef } from 'src/app/constants/firestore/location-object';
// import { EventMessageService } from 'src/app/services/event-message.service';
import { REPORT_TYPE } from '../../constants/firestore/report';

@Component({
  selector: 'app-comparison-expansion',
  templateUrl: './comparison-expansion.component.html',
  styleUrls: ['./comparison-expansion.component.scss']
})
export class ComparisonExpansionComponent implements OnInit {
  @Input('dataPicker') reportDataPicker: DataPicker;
  @Input() metrics: any[];
  @Input() reportName: string;
  @Output() changeDataPicker = new EventEmitter<DataPicker>();
  @Input() isShared = false;
  @Input() lockDates = false;
  @Input() reportId = "";
  @Input() reportType: REPORT_TYPE;
  @Input() viewModeChecked = 'legacy';
  @Input() minDate = null;
  @Input() maxDate = null;
  @Input() isReport = false;
  @Input() report = null;

  filterRefresh = new ReplaySubject<DataPicker>(1);
  dataPicker: DataPicker;
  first = 0;
  selectDate: any;
  selectDateB: any;
  metricsNames: any;
  locations = null;
  panelExpand = false;
  isProgressCSV: boolean;
  public isDatePickerFirstOpened = true;
  public showTooltipsOnDisabledDates = false;

  constructor(
    private locationS: LocationService,
    private reportS: ReportService,
    private loadingService: LoadingService,
    private modalS: ModalService,
    private _sessionS : SessionService,
    // private eventMessageS: EventMessageService,
  ) {}

  ngOnInit() : void {
    if (this.metrics) {
      this.metricsNames = this.metrics.map(m => m.name);
    }
    if (this.first < 2) {
      this.dataPicker = this.reportDataPicker;
    }

    this.selectDate  = this.reportS.selectDatesFromDataPicker(this.dataPicker);
    this.selectDateB = this.reportS.selectDatesFromDataPicker(this.dataPicker, true);
    this.showTooltipsOnDisabledDates = this.isReport;

    const {locations} = this.reportDataPicker;
    
    (async () => {
       const r = await this.locationS.fetchMultipleLocations(this._sessionS.getSession().gid, locations)
       this.loadingService.reportAdvanced(1, "Profiles")
       this.locations = r
    })()

    if (this.isReport && this.report?.dynamic) {
      this.modalS.openDynamicReportReminderModal();
    }
  }

  handleDataPicker($event: DataPicker) : void {
    if (this.first < 1) {
      this.first++;
      return;
    }
    if($event.aggregation) 
      this.dataPicker.aggregation = $event.aggregation

    if($event.range)
      this.dataPicker.range = $event.range;
    
    if($event.rangeB)
      this.dataPicker.rangeB = $event.rangeB

    this.filterRefresh.next(this.dataPicker);
    this.dataPicker.locations = this.reportDataPicker.locations;
    this.changeDataPicker.emit(this.dataPicker);
  }

  expandPanel(event) : void {
    this.panelExpand = event;
  }

  showAll() : void {
    this.panelExpand = true;
  }

  closeAll() : void {
    this.panelExpand = false;
  }

  locationUrl(location : SavedLocation, destiny : string) : string | undefined {
    if (location) {
      const accountId  = location.accountId || locationNameToRef(location.location).accountId;
      const locationId  = location.locationId;
      return `/account/${accountId}/location/${locationId}/${destiny}`;
    }
  }

  async exportCSV(event) : Promise<void> {
    if (event != 'csv') 
      return;

    try {
      this.isProgressCSV = true;

      if (this.viewModeChecked == 'legacy') {
        await this.reportS.handleExportCsvFire(this.locations, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation.toLowerCase(), this.reportName);
      } else {
        await this.reportS.handleExportCsvMongo(this.reportId, null, null, null, this.dataPicker.range.start, this.dataPicker.range.end, this.dataPicker.aggregation);
      }
    } finally {
      this.isProgressCSV = false;
    }
  }

  showDatepickerModal() : void {
    if (this.isDatePickerFirstOpened) {
      this.isDatePickerFirstOpened = false;
      this.modalS.openDatepickerdates();
    }
  }

}
