import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-products-collection-edit',
  templateUrl: './modal-products-collection-edit.component.html',
  styleUrls: ['./modal-products-collection-edit.component.scss']
})
export class ModalProductsCollectionEditComponent implements OnInit {
  productName: string;
  productDescription: string;
  productCollectionNameInvalid: boolean;

  constructor( public dialogRef: MatDialogRef<ModalProductsCollectionEditComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any) {
    this.productCollectionNameInvalid = false;
    this.productName = data.productName;
    this.productDescription = data.productDescription;
  }

  ngOnInit() {
  }
  apply() {
    if (this.productName) {
      this.productCollectionNameInvalid = false;
      const data = { productName: this.productName, productDescription: this.productDescription };
      this.dialogRef.close(data);
    } else {
      this.productCollectionNameInvalid = true;
    }
  }

}
