<div class="box box--padding bg--white box--margin">
    <div class="row">
        <div class="col-12 col-lg-6 offset-lg-3 txt--center">
            <h2 class="txt--xl mb--20"><strong>Upgrade your listing!</strong></h2>
            <p class="txt--lg mb--60">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>
    </div>
    <div class="container--md">
        <div class="row no-gutters">
            <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0">
                <div class="panel panel--border panel--below">
                    <div class="panel__header">
                        <p class="txt--lg m--0">Essential
                            <span class="d-block"><strong><sup>$</sup><span class="txt--xxl">0</span>/mo</strong></span>
                        </p>
                    </div>
                    <div class="panel__content">
                        <ul class="list">
                            <li class="list__item list__item--success">No contracts, cancel anytime</li>
                            <li class="list__item list__item--success">Archived Insights and Reviews</li>
                            <li class="list__item list__item--success">Insights and Review Roll Up Reports</li>
                            <li class="list__item list__item--fail">Comparison Reports</li>
                            <li class="list__item list__item--fail">Revenue Projection Calculator</li>
                            <li class="list__item list__item--fail">Review Auto Responder</li>
                            <li class="list__item list__item--fail">Post Manager & Scheduling</li>
                            <li class="list__item list__item--fail">Review Website Widget</li>
                            <li class="list__item list__item--fail">Update Blocker</li>
                            <li class="list__item list__item--fail">Grader</li>
                            <li class="list__item list__item--fail">Export Data To CSV / PDF</li>
                            <li class="list__item list__item--fail">Priority Email Support</li>
                            <li class="list__item list__item--fail">Beta access to new features and tools</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0">
                <div class="panel panel--above">
                    <div class="panel__header panel__header--border bg--green">
                        <p class="txt--lg txt--white m--0">Ultimate per business listing
                            <span class="d-block"><strong><sup>$</sup><span class="txt--xxl">29.99</span>/mo</strong></span>
                        </p>
                    </div>
                    <div class="panel__content bg--gray">
                        <ul class="list">
                            <li class="list__item list__item--success">Upgrade to Ultimate at anytime</li>
                            <li class="list__item list__item--success">Unlimited Profiles</li>
                            <li class="list__item list__item--success">Archived Insights and Reviews</li>
                            <li class="list__item list__item--success">Insights and Review Roll Up Reports</li>
                            <li class="list__item list__item--success">Comparison Reports</li>
                            <li class="list__item list__item--success">Revenue Projection Calculator</li>
                            <li class="list__item list__item--success">Review Auto Responder</li>
                            <li class="list__item list__item--success">Post Manager & Scheduling</li>
                            <li class="list__item list__item--success">Review Website Widget</li>
                            <li class="list__item list__item--success">Update Blocker</li>
                            <li class="list__item list__item--success">Listing Grader</li>
                            <li class="list__item list__item--success">Export Data To CSV / PDF</li>
                            <li class="list__item list__item--success">Priority Email Support</li>
                            <li class="list__item list__item--success">Beta access to new features and tools</li>
                        </ul>
                        <p class="txt--center">Billed at $29.99/mo <strong>per listing.</strong>

                            Bulk pricing is available for businesses with more than 10
                            Profiles. <span class="txt--lightblue">Contact us</span> or <span class="txt--lightblue">schedule a demo</span> to learn more. </p>


                        <button mat-flat-button color="accent" class="btn btn--center btn--xl mb--20" (click)="openUpgradeDialog()"><strong>Yes, I want to upgrade</strong></button>
                        <button mat-button tabIndex="-1" class="d-block txt--lg txt--gray btn--center" (click)="decline()"><u>No, not today</u></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
