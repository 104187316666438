import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppChartsModule } from './../app-charts/app-charts.module';
import { SkeletonsModule } from './../skeletons/skeletons.module';
import { DatePickerModule } from './../date-picker/date-picker.module';
import { FormsModule } from '@angular/forms';

import { ReviewsNumberProtocolsComponent } from './reviews-number-protocols/reviews-number-protocols.component';
import { ReviewsNumberComponent } from './reviews-number/reviews-number.component';
import { ReviewsAverageRatingComponent } from './reviews-average-rating/reviews-average-rating.component';

@NgModule({
    declarations: [
        ReviewsAverageRatingComponent,
        ReviewsNumberComponent,
        ReviewsNumberProtocolsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SkeletonsModule,
        AppChartsModule,
        NgxSkeletonLoaderModule,
        MatTooltipModule,
        MatMenuModule,
        DatePickerModule
    ],
    exports: [
        ReviewsAverageRatingComponent,
        ReviewsNumberComponent,
        ReviewsNumberProtocolsComponent
    ]
})
export class ReviewsSharedModule { }