// dep
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

// app
import { LocationService } from './location.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuardService implements CanActivate {
  constructor(
    private _router: Router,
    private _locationService: LocationService,
    private _sessionS : SessionService
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot) : Promise<boolean> {
    const {gid, isTrial} = await this._sessionS.waitSession();

    const {locationId, accountId} = route.parent.params;

    const {subscriptionType} = (await this._locationService.fetchMultipleLocations(gid, [{accountId, locationId}], 
                                                                                   ['subscriptionType'] as ['subscriptionType']))[0];

    if (!isTrial && subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE) {
      await this._router.navigate(['/account', accountId , 'location', locationId, 'insights']);
      return false;
    }
    return true;
  }
}
