<div class="spinner-wrapper spinner-wrapper--fixed" *ngIf="showLoader">
    <mat-spinner></mat-spinner>
</div>

<app-modal-stepper
  [title]="['Create Bulk Update']"
  linear
  [finalAction]="'Submit'"
  [scan_action]="false"
  [step]="step"
  (step_activate)="verification($event)"
  (completed)="completeSteeper()"
>
    <cdk-step [completed]="completeStep1">
        <app-toggle-locations-in-account
            class="locations-no-padding mt--20"
            (selected)="selectedLocations($event)"
        ></app-toggle-locations-in-account>
    </cdk-step>

    <cdk-step [completed]="completeStep2">
        <div class="dialog__content dialog__content--height pt--0  mt--20" mat-dialog-content>
            <div class="dialog-row p-tb--0">
                <div class="spinner-wrapper" *ngIf="(loadingAttributes$ | async)" >
                    <mat-spinner></mat-spinner>
                </div>
                <div class="alert alert-warning" role="alert" *ngIf="!loadingAttributes$?.value && notSameCategory$?.value">
                    <h4>
                        Category Mismatch 
                        <span matTooltip="Some features and attributes available to businesses on Google Business Profiles are based on the type of business categories. This means that certain bulk updates can only be made for locations with the same exact categories."
                            matTooltipPosition="above"
                            matTooltipClass="mat-tooltip--override"
                            class="mat-tooltip tooltip tooltip--info">
                            <em class="fas fa-info"></em>
                        </span>
                    </h4>
                    <p> Please choose new locations or start with a bulk update to categories of your 
                        chosen locations to unlock all of the available bulk updates for your business type.
                    </p>
                    <mat-accordion>
                        <mat-expansion-panel hideToggle>
                          <mat-expansion-panel-header class="p--0 mt--5">
                            <mat-panel-title>
                              <span class="border--undefined">See locations</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <ng-container *ngFor="let item of locationsInfo">
                            <div>
                                <h3 class="company__title m--0 display-in-bl"><strong>{{ item?.locationName }}</strong> - </h3>
                                <p class="company__address m--0 display-in-bl"><gmb-address [address]="item?.locationAddress" [serviceAreas]="item?.serviceArea"></gmb-address></p>
                            </div>
                          </ng-container>
                        </mat-expansion-panel>
                        <mat-expansion-panel (opened)="categoriesViewDetails = true"
                                             (closed)="categoriesViewDetails = false">
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                

                <div class="alert alert-warning" role="alert" *ngIf="!loadingAttributes$?.value && notSameRegionCode$?.value">
                    <h4>
                        Region Code Mismatch
                        <span matTooltip="Some features and attributes available to businesses on Google Business Profiles are based on the location of your business, certain bulk updates can only be made for locations located in the same region as each other."
                            matTooltipPosition="above"
                            matTooltipClass="mat-tooltip--override"
                            class="mat-tooltip tooltip tooltip--info">
                            <em class="fas fa-info"></em>
                        </span>
                    </h4>
                    <p>Please choose new locations to unlock all of the available bulk updates for your business.</p>
                    <mat-accordion>
                        <mat-expansion-panel hideToggle>
                          <mat-expansion-panel-header class="p--0 mt--5">
                            <mat-panel-title>
                              <span class="border--undefined">See details</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <p>{{regionCodes}}</p>
                        </mat-expansion-panel>
                        <mat-expansion-panel (opened)="regionCodesdViewDetails = true"
                                             (closed)="regionCodesdViewDetails = false">
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>

                <mat-label class="d-block mb--10" *ngIf="!(loadingAttributes$ | async)">What would you like to update?</mat-label>
                <mat-form-field  appearance="fill" style="width: 100%" *ngIf="!(loadingAttributes$ | async)">
                    <mat-label>Select one</mat-label>
                    <mat-select [formControl]="componentToEdit" required>
                        <mat-optgroup label="Core Location Info">
                            <ng-container *ngFor="let core of listCoreComponents" >
                                <ng-container [ngSwitch]="core.key">
                                    <ng-container *ngSwitchCase="'primaryCategory'">
                                        <mat-option [value]="core" [disabled]="(notSameRegionCode$ | async)"> {{core.value}}
                                            <span *ngIf="(notSameRegionCode$ | async)"
                                                matTooltip="Region Code Mismatch"
                                                matTooltipPosition="above"
                                                matTooltipClass="mat-tooltip--override"
                                                class="mat-tooltip tooltip tooltip--info">
                                                <em class="fas fa-info"></em>
                                            </span>
                                        </mat-option>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'attributes'">
                                        <mat-option [value]="core" [disabled]="groupAttributes.length == 0 || (notSameCategory$ | async)"> {{core.value}}
                                            <span *ngIf="groupAttributes.length == 0 && !(notSameCategory$ | async)" 
                                                matTooltip="No common attributes are available to edit for the selected locations."
                                                matTooltipPosition="above"
                                                matTooltipClass="mat-tooltip--override"
                                                class="mat-tooltip tooltip tooltip--info">
                                                <em class="fas fa-info"></em>
                                            </span>
                                            <span *ngIf="(notSameCategory$ | async)" 
                                                matTooltip="Category Mismatch"
                                                matTooltipPosition="above"
                                                matTooltipClass="mat-tooltip--override"
                                                class="mat-tooltip tooltip tooltip--info">
                                                <em class="fas fa-info"></em>
                                            </span>
                                        </mat-option>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'service'" >
                                        <mat-option [value]="core" [disabled]="(notSameCategory$ | async) || (notAllowServices$ | async)">{{core.value}}
                                            <span *ngIf="(notSameCategory$ | async)" 
                                                matTooltip="Category Mismatch"
                                                matTooltipPosition="above"
                                                matTooltipClass="mat-tooltip--override"
                                                class="mat-tooltip tooltip tooltip--info">
                                                <em class="fas fa-info"></em>
                                            </span>
                                        </mat-option>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'menu'" >
                                        <mat-option [value]="core" [disabled]="notAllowedMenu$ | async">{{core.value}}
                                            <span *ngIf="(notAllowedMenu$ | async)" 
                                                matTooltip="There are one or more selected locations that have no food menu service"
                                                matTooltipPosition="above"
                                                matTooltipClass="mat-tooltip--override"
                                                class="mat-tooltip tooltip tooltip--info">
                                                <em class="fas fa-info"></em>
                                            </span>
                                        </mat-option>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <mat-option [value]="core"> {{core.value}} </mat-option>
                                    </ng-container>
                                </ng-container>

                            </ng-container>

                            <ng-container *ngIf="attributesUrl || (notSameCategory$ | async)">
                                <ng-container  *ngFor="let attributeUrl of attributesUrl">
                                    <ng-container *ngIf="attributeUrl.displayName; else noName">
                                        <mat-option [value]="'Secondary-'+attributeUrl.attributeId" >{{attributeUrl.displayName}}</mat-option>
                                    </ng-container>
                                    <ng-template #noName>
                                        <ng-container [ngSwitch]="attributeUrl.attributeId">
                                            <ng-container *ngSwitchCase="'url_menu'">
                                                <mat-option [value]="'Secondary-'+attributeUrl.attributeId">Menu link</mat-option>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'url_reservations'">
                                                <mat-option [value]="'Secondary-'+attributeUrl.attributeId"> Reservations links</mat-option>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'url_order_ahead'">
                                                <mat-option [value]="'Secondary-'+attributeUrl.attributeId"> Order ahead links</mat-option>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'url_covid_19_info_page'">
                                                <mat-option [value]="'Secondary-'+attributeUrl.attributeId"> COVID-19 links</mat-option>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'url_facility_telemedicine_page'">
                                                <mat-option [value]="'Secondary-'+attributeUrl.attributeId"> Virtual care links</mat-option>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                <mat-option  [value]="'Secondary-'+attributeUrl.attributeId"> Website</mat-option>
                                            </ng-container>
                                        </ng-container>
                                    </ng-template>
                                </ng-container>
                            </ng-container>
                        </mat-optgroup>

                        <mat-optgroup label="Action Links" *ngIf="listActionLink?.length > 0">
                            <mat-option *ngFor="let link of listActionLink" [value]="link"> {{link.value}}</mat-option>
                        </mat-optgroup>

                        <mat-optgroup label="Misc Location Info">
                            <mat-option *ngFor="let Misc of listMiscComponents" [disabled]="Misc.key === 'photos' && (notSameCategory$ | async) "  [value]="Misc"> {{Misc.value}}
                                <span *ngIf="Misc.key === 'photos' && (notSameCategory$ | async)" 
                                    matTooltip="Category Mismatch"
                                    matTooltipPosition="above"
                                    matTooltipClass="mat-tooltip--override"
                                    class="mat-tooltip tooltip tooltip--info">
                                    <em class="fas fa-info"></em>
                                </span>
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                    <mat-error *ngIf="componentToEdit.hasError('required')">Please choose an update</mat-error>
                </mat-form-field>
            </div>

        </div>
    </cdk-step>
    
    <cdk-step [completed]="completeStep3">
        <p class="txt--md m--0  mt--20">
            <strong>{{GetNameProperty(componentToEdit?.value)}}</strong>
        </p>
        <ng-container [ngSwitch]="componentToEdit.value?.key">
            <ng-container *ngSwitchCase="'locationName'">
                <app-modal-company-name #update [bulk]="true" (validResult)="validResult = $event" ></app-modal-company-name>
            </ng-container>
            <ng-container *ngSwitchCase="'primaryCategory'">
                <app-modal-category [regionCode]="regionCodes" #update [bulk]="true" (validResult)="validResult = $event" ></app-modal-category>
            </ng-container>
            <ng-container *ngSwitchCase="'regularHours'">
                <app-modal-regular-hours #update [bulk]="true" (validResult)="validResult = $event" ></app-modal-regular-hours>
            </ng-container>
            <ng-container *ngSwitchCase="'moreHours'">
              <app-modal-more-hours #update [bulk]="true" [bulkLocationSelected]="locationsSelection" (validResult)="validResult = $event" ></app-modal-more-hours>
            </ng-container>
            <ng-container *ngSwitchCase="'specialHours'">
                <div class="mt--5" style="width: fit-content;">
                    <mat-checkbox
                        class=" txt--sm fw--medium"
                        [checked]="Replace"
                        [disabled]="true"
                        matTooltip="When editing special hours, you can only replace the hours with a new set. Because of this, you cannot uncheck this option for Special Hours."
                        matTooltipPosition="right"
                        matTooltipClass="tooltip tooltip--white"
                        (change)="changeReplace($event)"
                        >
                        Replace With Data<i style="margin-left: 3px; color: #999ea2;" class="fas fa-info-circle"></i>
                    </mat-checkbox>
                </div>

                <app-modal-special-hours class="z-index--9" #update [bulk]="true" (validResult)="validResult = $event" ></app-modal-special-hours>
            </ng-container>
            <ng-container *ngSwitchCase="'primaryPhone'">
                <app-modal-phone #update [bulk]="true" (validResult)="validResult = $event" ></app-modal-phone>
            </ng-container>
            <ng-container *ngSwitchCase="'websiteUrl'">
                <app-modal-url #update [bulk]="true" (validResult)="validResult = $event" ></app-modal-url>
            </ng-container>
            <ng-container *ngSwitchCase="'service'">
                <mat-label class="d-block mb--10">Selet location to use as a template (Optional)</mat-label>
                <mat-form-field  appearance="fill" style="width: 100%">
                    <mat-label>Select one</mat-label>
                    <!--<mat-select (selectionChange)="changeSourceService($event)">
                        <ng-container *ngFor="let account of accountsReport" >
                            <mat-option style="height: unset; padding: 10px;" *ngFor="let location of account.locations"  [value]="account.gid+'/'+account.accountId+'/'+location.locationId">
                                <p style="font-weight: bold;  margin-bottom: 0px;">{{location.locationName}}</p>
                                <gmb-address mat-line [address]="location?.address"></gmb-address>
                            </mat-option>
                        </ng-container>
                    </mat-select>-->
                    <input type="text"
                        placeholder="Pick one"
                        aria-label="Number"
                        matInput
                        [formControl]="locationsTemplate"
                        [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
                        <mat-option style="height: unset; padding: 10px;" *ngFor="let location of filteredOptions | async" (click)="changeAutoCompleteSourceService(location.gid+'/'+location.accountId+'/'+location.locationId)"  [value]="location.locationName+' '+formatAddress(location?.address)">
                            <p style="font-weight: bold;  margin-bottom: 0px;">{{location.locationName}}</p>
                            <gmb-address mat-line [address]="location?.address" [serviceAreas]="location?.serviceArea"></gmb-address>
                        </mat-option>

                    </mat-autocomplete>
                </mat-form-field>
                <app-modal-service #update [bulk]="true" (validResult)="validResult = $event" [serviceList]="serviceListTemplate" [primaryCategory]="primaryCategories[0]" [aditionalCategories]="commonAdditionalCategories" ></app-modal-service>
            </ng-container>
            <ng-container *ngSwitchCase="'menu'">
                <mat-label class="d-block mb--10">Selet location to use as a template (Optional)</mat-label>
                <mat-form-field  appearance="fill" style="width: 100%">
                    <mat-label>Select one</mat-label>
                    <!--<mat-select (selectionChange)="changeSourceService($event)">
                        <ng-container *ngFor="let account of accountsReport" >
                            <mat-option style="height: unset; padding: 10px;" *ngFor="let location of account.locations"  [value]="account.gid+'/'+account.accountId+'/'+location.locationId">
                                <p style="font-weight: bold;  margin-bottom: 0px;">{{location.locationName}}</p>
                                <gmb-address mat-line [address]="location?.address"></gmb-address>
                            </mat-option>
                        </ng-container>
                    </mat-select>-->
                    <input type="text"
                        placeholder="Pick one"
                        aria-label="Number"
                        matInput
                        [formControl]="locationsTemplate"
                        [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
                        <mat-option style="height: unset; padding: 10px;" 
                                    *ngFor="let location of filteredOptions | async" 
                                    (click)="changeAutoCompleteSourceMenu(location.gid+'/'+location.accountId+'/'+location.locationId)"
                                    [value]="location.locationName+' '+formatAddress(location?.address)">
                            <p style="font-weight: bold;  margin-bottom: 0px;">{{location.locationName}}</p>
                            <gmb-address mat-line 
                                         [address]="location?.address" 
                                         [serviceAreas]="location?.serviceArea">
                            </gmb-address>
                        </mat-option>

                    </mat-autocomplete>
                </mat-form-field>
                <div class="dialog__content"  mat-dialog-content [style.height]="'300px'">

                    <app-menu-location #update [bulk]="true" (validResult)="validResult = $event"></app-menu-location>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'attributes'">
                <div class="spinner-wrapper" *ngIf="groupAttributes.length === 0" >
                    <mat-spinner></mat-spinner>
                </div>
                <app-modal-attributes *ngIf="groupAttributes.length > 0" 
                                      #update 
                                      [groups]="groupAttributes"
                                      [originalGroups]="groupAttributes"
                                      [excludedAtributes]="[]"
                                      [bulk]="true"
                                      (validResult)="validResult = $event">
                </app-modal-attributes>
            </ng-container>
            <ng-container *ngSwitchCase="'profile'">
                <app-modal-description #update [bulk]="true" (validResult)="validResult = $event"></app-modal-description>
            </ng-container>
            <ng-container *ngSwitchCase="'openInfo'">
                <app-modal-date #update [bulk]="true" (validResult)="validResult = $event"></app-modal-date>
            </ng-container>
            <ng-container *ngSwitchCase="'placeActionLinks'">
                <app-modal-action-links #update [bulk]="true" (validResult)="validResult = $event"></app-modal-action-links>
            </ng-container>
            <ng-container *ngSwitchCase="'labels'">
                <div class="mt--5" style="width: fit-content;">
                    <mat-checkbox
                        class=" txt--sm fw--medium"
                        [checked]="Replace"
                        [disabled]="true"
                        matTooltip="When editing labels, you can only replace the hours with a new set. Because of this, you cannot uncheck this option for Labels."
                        matTooltipPosition="right"
                        matTooltipClass="tooltip tooltip--white"
                        (change)="changeReplace($event)"
                        >
                        Replace With Data<i style="margin-left: 3px; color: #999ea2;" class="fas fa-info-circle"></i>
                    </mat-checkbox>
                </div>

                <app-modal-labels #update [bulk]="true" (validResult)="validResult = $event"></app-modal-labels>
            </ng-container>
            <ng-container *ngSwitchCase="'adWordsLocationExtensions'">
                <app-modal-google-ads #update [bulk]="true" (validResult)="validResult = $event"></app-modal-google-ads>
            </ng-container>
            <ng-container *ngSwitchCase="'photos'">
                <app-modal-photos #update [bulk]="true" (validResult)="validResult = $event" [primaryCategory]="primaryCategories[0]" ></app-modal-photos>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <app-modal-attribute-url *ngIf="attributesUrl" 
                                         [attributesUrl]="attributesUrl" 
                                         #update 
                                         [bulk]="true" 
                                         (validResult)="validResult = $event"
                                         [attributes]="getAttributes()"
                                         [attribute]="getAttribute()">
                </app-modal-attribute-url>
            </ng-container>
        </ng-container>

    </cdk-step>
</app-modal-stepper>
