// dep
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

// app
import { ObservationService } from '../../services/observation.service';
import { Pageable } from '../../constants/pageable';
import { Pagination } from '../../constants/api-response';
import { AccountService } from '../../services/account.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../../constants/firestore/account-location';
import { ModalService } from '../../services/modal.service';
import { LocationService } from '../../services/location.service';
import { SpinnerService } from '../../services/spinner.service';
import { GroupService } from '../../services/group.service';
import { ProfilesService } from '../../services/profiles/profiles.service';
import { AccountContainer, CreateProfileRequest, LocationModel, ProfileModel } from '../../lib/api-requests/create-profile-request';
import { GetProfilesRequest } from '../../lib/api-requests/get-profiles-request';
import { Profile } from '../../lib/api-responses/get-profiles-response';
import { GoogleAccountService } from '../../services/google-account.service';
import { GoogleService } from '../../services/google.service';
import { SnackbarService } from '../../services/snackbar.service';
import { GoogleLocationService } from '../../services/google-location.service';
import { SessionService } from 'src/app/services/session.service';
import SavedAccount from 'src/app/constants/firestore/saved-account';
import { BaseComponent } from 'src/app/components/base.component';
import { locationNameToRef } from 'src/app/constants/firestore/location-object';
import { markedPositions } from 'src/app/helpers/utils.helpers';
import SavedLocation from 'src/app/constants/firestore/saved-location';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent extends BaseComponent implements OnInit {
  
  @ViewChild('drawer', { static: true }) public drawer: MatDrawer;
  
  public DEFAULT_PAGE_SIZE = 10;
  public loadingTable = true;
  public allChecked = false;
  public displayedColumns = [];
  public locations = new MatTableDataSource([]);
  public paginate: Pageable = {size: this.DEFAULT_PAGE_SIZE, page: 1};
  public pagination: Pagination = {
    items: [],
    per_page: this.paginate.size,
    page: this.paginate.page,
    hasNext: false,
    hasPrev: false,
    pages: 0,
    total: 0
  };
  public sort = {
    sortBy: 'locationName',
    sortOrder: 'desc'
  };
  public filteredOptions: Observable<any>;
  public filterAccountsControl = new FormControl('');
  public addProfileFilterSelected: Observable<any>;
  public addProfileFilterAccountsControl = new FormControl('');
  public accountsOptions = [];
  public accounts;
  public labelAccountsFiltered = 'All';
  public filteredAccounts = [];
  public allLocationCheck = false;
  public selectedLocations = [];
  public dialog: MatDialog;
  public drawerOpened = false;
  public dl;
  public da;
  public googleAccounts: any[] = []
  public profilesOptions: any[] = []
  public accountProfilesOptions: any[] = []
  public addingProfile  = false;
  public profileStatus = {
    'verified': 'verified',
    'pending_edits': 'pending edits',
    'not_verified': 'not verified',
    'verified_locked': 'verified & locked',
  };

  public session$ = this._sessionS.session$;

  constructor(
    public router: Router,
    private _sessionS : SessionService,
    private obsService: ObservationService,
    private accountService: AccountService,
    private modalService: ModalService,
    private profilesService: ProfilesService,
    private locationService: LocationService,
    private spinnerService: SpinnerService,
    private groupService: GroupService,
    private googleAccountService: GoogleAccountService,
    private snackbarService: SnackbarService,
    private googleService: GoogleService,
    private _googleLocationS: GoogleLocationService,

  ) {
    super();
  }
    
  ngOnInit(): void {
    const {isAdmin} = this._sessionS.getSession();
    this.displayedColumns = ['check', 'company','storeCode', 'status', 
                             ... (isAdmin ? ['actions'] : []), 
                             'subscription'];

    this.loadAccounts();

    this.filteredOptions = this.filterAccountsControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.addProfileFilterSelected = this.addProfileFilterAccountsControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }


  async loadAccounts(): Promise<void> {
    const {gid} = this._sessionS.getSession();
    // Note, there is no pagination really used here, "1000" is a bad way to mean "get all accounts"
    this.accounts = (await this.accountService.getAccountPaginate(gid, {page: 1, size: 1000}, [])).items;
    for(const acc of this.accounts)
      acc.checked = false
    this.accountsOptions = [...this.accounts]
    this.loadProfilesTable(true)
  }

  loadProfilesTable(resetPaginator = false): void {
    this.loadingTable = true;

    if(resetPaginator){
      this.pagination = {
        items: [],
        per_page: this.DEFAULT_PAGE_SIZE,
        page: 1,
        hasNext: false,
        hasPrev: false,
        pages: 0,
        total: 0
      }
    }

    const accountsForSearch = this.filteredAccounts.length > 0 ? this.filteredAccounts : this.accounts

    // FIXME: This is wrong, must be an array of {gid,accountIds,locationIds}, also fix
    // on backend. Currently the response is too inclusive. 
    const profileRequest: GetProfilesRequest =  {
      gids:       [...new Set<string>(accountsForSearch.map(item => item.gid))],
      accountIds: [...new Set<string>(accountsForSearch.map(item => item.accountId))],
      locationIds: [],
      page: this.pagination.page,
      pageSize: this.pagination.per_page,
      sortKey: "locationName",
      reverse: false
    };

    if(accountsForSearch.length > 0){
      this.profilesService.getProfiles(profileRequest)
      .subscribe(
        (result) => {
          const d = result.data;

          this.locations = new MatTableDataSource<Profile>(d.items);
          this.pagination.items    = d.items;
          this.pagination.total    = d.total;
          this.pagination.hasNext  = d.hasNext;
          this.pagination.hasPrev  = d.hasPrev;
          this.pagination.page     = d.page;
          this.pagination.per_page = d.pageSize;
          this.pagination.pages    = d.totalPages;

          this.loadingTable = false;
        }
      );
    }
    
  }

  async onAddAccount(): Promise<void> {
    await this.router.navigate(['profiles'])
    this.obsService.sendAddAccount()
  }

  goLocation(location: any): void {
    this.router.navigate(['/account', location.accountId, 'location', location.locationId, 'insights'])
  }
  
  toggleCheckAll(event) {
    if(event.checked){
      this.selectAll();
    } else {
      this.clearAll();
    }
  }
  
  selectLocation(element: any, event): void {
    if(event.checked){
      this.selectedLocations.push(element);
    } else {
      this.selectedLocations = this.selectedLocations.filter((item) => item.locationId !== element.locationId);
    }
  }
  
  public selectAll(): void {
    this.locations.data.forEach(element => {
      element.isChecked = true;
      this.selectedLocations.push(element);
    });
  }

  clearAll(): void {
    this.locations.data.forEach(element => {
      element.isChecked = false;
    });
    this.selectedLocations = [];
  }
  
  refresh(profile: any): void {
    const {gid} = this._sessionS.getSession();
    this.locationService.locationRefreshAllDeps(profile.accountId, profile.locationId, gid);
  }
  
  lockSelectedLocations() : void {
    let anyIsAlreadyLocked = false;
    const observables = this.selectedLocations.map(profile =>
      this.locationService.isLocked(profile.locationId)
    );
    
    forkJoin(observables).subscribe(results => {
      for (let i = 0; i < results.length; i++) {
        const res = results[i];
        const profile = this.selectedLocations[i];
        if (res && res.locked) {
          anyIsAlreadyLocked = true;
        }
      }

      if(anyIsAlreadyLocked){
        this.modalService.openInfoModal(
          "Heads Up",
          "There is identical location in our network is already locked. Try a different selection or contact support."
        );
      } else {
        this.modalService.openConfirmModal(
          'Are you sure you want to lock these locations?',
          ('Locking a location will periodically update GBP with the locked data. '+
           'Any changes you make directly in GBP will be overwritten.'),
          res => {
            if (!res) {
              return;
            }
            
            const observables = this.selectedLocations.map(profile =>
              this.locationService.update(
                this._sessionS.getSession().gid,
                profile.accountId,
                profile.locationId,
                { lockedOn: new Date() }
              ).pipe(
                switchMap(loc => {
                  return this.googleService.saveLockHistory(
                    profile.accountId,
                    profile.locationId,
                    'locked',
                    'success'
                  );
                })
              )
            );
            
            forkJoin(observables).subscribe(
              () => {
                this.loadProfilesTable();
                this.snackbarService.openSuccess('Profiles successfully locked!', 2000);
              },
              err => {
                this.loadProfilesTable();
                this.snackbarService.openError(
                  'There was a problem locking these locations. Please try again later or contact support.',
                  2000
                );
                console.error('Error locking a location', err);
              }
            );
          }, 2);
        }
      });
  }

  lockLocation(profile: any) : void {
    this.locationService.isLocked(profile.locationId)
      .subscribe(res => {
        if(res.locked) {
          const dialogRef = this.modalService.openInfoModal(
            "Heads Up",
            "An identical location in our network is already locked. Please contact support.")
        } 

        if(res && !res.locked) {
          // this.progress = true;
        this.modalService.openConfirmModal(
          'Are you sure you want to lock this location?',
          'Locking a location will periodically update GBP with the locked data. Any changes you make directly in GBP will be overwritten.',
          res => {
            if (!res) {
              return;
            }
            this.locationService.update(
                this._sessionS.getSession().gid, 
                profile.accountId, 
                profile.locationId, 
                { lockedOn: new Date() }
            ).toPromise().then(loc => {
              this.googleService.saveLockHistory(
                profile.accountId, 
                profile.locationId, 
                'locked', 
                'success'
              ).subscribe(result => {
                  this.loadProfilesTable();
                  this.snackbarService.openSuccess('Location successfully locked!', 2000);
                });
            }, err => {
              this.snackbarService.openError('There was a problem locking this location. Please try again later or contact support.', 2000);
              console.error('Error locking a location', err);
            });
          }, 2);
        }
      })
  }

  unlockLocation(profile: any) {
    this.locationService.update(
      this._sessionS.getSession().gid,
      profile.accountId,
      profile.locationId,
      { lockedOn: null })
      .toPromise()
      .then(loc => {
        this.googleService.saveLockHistory(
          profile.accountId,
          profile.locationId,
          'unlock',
          'success')
          .subscribe(result => {
            this.loadProfilesTable();
            this.snackbarService.openSuccess('Location successfully unlocked!', 2000);
          });
    }, err => {
      this.snackbarService.openError('There was a problem unlocking this location. Please try again later or contact support.', 2000);
      console.error('Error unlocking a location', err);
    });
  }

  public async deleteBulk(): Promise<void> {
    if (!this.selectedLocations.length) {
      return;
    }

    try {
      this.spinnerService.loading$.next(true);
      const {gid} = this._sessionS.getSession();  
      await Promise.all(markedPositions(this.selectedLocations).map(
              e => this.locationService.deleteLocation(gid, e.value.accountId, e.value,
                                                       {notifyChange : e.isLast, deleteEmptyAccount: e.isLast})));
    } catch(err) {
      console.error('Error deleting locations', err);
    } finally {
      await this.loadAccounts();
      this.spinnerService.loading$.next(false);
    }

  }
  

  public async deleteLocationModal(location: SavedLocation) : Promise<void> {
    if(!location)
      return

    if(this.modalService.openConfirmModal(`Are you sure you want to delete ${location.locationName} ?`,
                                          'This action cannot be reverted.')) {
       try {
          this.spinnerService.loading$.next(true);
          const {gid} = this._sessionS.getSession();
          await this.locationService.deleteLocation(gid, location.accountId, location);
       } catch(err) {
          console.error("Error deleting location", err);
       } finally {
         await this.loadAccounts();
         this.spinnerService.loading$.next(false);
      }
    }
  }

  onMatDrawerOpenedChange(event: boolean): void{
    this.drawerOpened = event;
  }

  toggleDrawer() : void {
    this.drawer.toggle();
    this.drawerOpened = this.drawer.opened;
  }

  addPhotos() : void {
    this.router.navigate(['local-bulk'])
  }

  goToLocation(location: any): void {
    this.router.navigate(['/account', location.accountId, 'location', location.locationId, 'insights'])
  }

  goToLocationNewTab(location: any): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/account', location.accountId, 'location', location.locationId, 'insights'])
    );

    window.open(url, '_blank');
  }
  
  editLocation(element) {

  }
  
  handleReload($event: Pageable): void {
    this.paginate =  $event;
    this.loadProfilesTable();
  }
  
  filterAccountChanged(menu, clear) : void {
    if (clear || this.filteredAccounts.length === 0) {
      this.accounts.forEach(acc => acc.checked = false);
      this.labelAccountsFiltered = 'All';
      this.filteredAccounts = []
    }

    this.filterAccountsControl.setValue(null);
    menu.close.next(false);

    const resetPaginator = true;
    this.loadProfilesTable(resetPaginator);
  }

  _filter(value: string) : void {
    this.accountsOptions = [];
    const filterValue = value?.toLowerCase();
    if(value != ''){
      this.accounts?.forEach(acc => {
        const name = acc.accountName.toLowerCase();
        if (name.includes(filterValue)) {
          this.accountsOptions.push(acc)
        }
      });
    } else {
      this.accountsOptions = [...this.accounts];
    }
  }

  getLocationsFiltered(event, account) {
    this.filteredAccounts= [];
    this.labelAccountsFiltered = null;
    this.accounts.forEach(acc => {
      acc.checked = acc.accountId == account.account ? event.checked : acc.checked;
      if (acc.checked) {
        this.filteredAccounts.push(acc);
        this.labelAccountsFiltered = this.labelAccountsFiltered ? `${this.labelAccountsFiltered} - ${acc.accountName}`: acc.accountName; 
      }
    });
  }

  checkAllAccounts(event) : void {
    this.googleAccounts.forEach(element => {
      element.checked = event.checked;
    });
  }

  checkProfiles(event, locations) : void {
    locations.forEach(element => {
      element.checked = event.checked;
    });
  }

  hasAnySelectedAccount(){
    let result = false;
    this.googleAccounts.forEach(element => {
      if(element.checked){
        result = true;
      }
    });

    return result;
  }

  hasAnySelectedProfile() : boolean{
    let result = false;
    this.googleAccounts.forEach(element => {
      element?.profiles?.forEach(profile => {
        if(profile.checked){
          result = true;
        }
      });
    });
    return result;
  }

  async openAccountExpansionPanel(account : SavedAccount) : Promise<void> {
    const locations = await this.groupService.get_locations(this._sessionS.getSession().gid, account.accountId);
    if(!locations)
      return

    this.googleAccounts?.forEach(option => {
      if(!option.locations){
        option.locations = []
      }

      if(option.locations){
        locations.forEach( loc => {
          if(option.accountId === loc.accountId ){
            option.locations.push(loc);
          }
        });
      }
    });
  }

  async addNewProfiles() : Promise<void> {
    this.da = null;
    this.dl = null;

    this._subscribeSafe(this.googleAccountService.onLoadAll$,
       ev => {
        if (ev.success !== true) {
          this.snackbarService.openError(ev.message);
          return;
        }

        // Everything looks good...
        // Prevent multiple dialogs from being opened
        !this.da && this.toggleDrawer();

        this._subscribeSafe(this.googleAccountService.accounts$,
          accounts => {
          accounts.forEach((element: any) => {
            element.checked = false;
          });
          this.googleAccounts = accounts;
        })
      });

    // TODO: Must refactor as an high level flow against all callers of google.authenticate
    try {
      this.snackbarService.openInfo(`A tab to authenticate with Google will open. If you don't see it, check your pop-up blocker settings`, 2000);
      const {gid, uid} = this._sessionS.getSession();
      const url = await this.googleService.authenticate(gid, uid);
      const oauth = window.open(url, '_blank');
      // This popup ends up being a redirection so we cannot detect the real close event.
      // So we use an interval trick to overcome this.
      const popupTick = setInterval(() => {
        if (oauth?.closed) {
          clearInterval(popupTick);
          this.googleAccountService.loadAll();
        }
      }, 1000);
    } catch (e) {

      // This popup ends up being a redirection so we cannot detect the real close event.
      // So we use an interval trick to overcome this, with an event
      const message = 'There was an error with the GBP Authentication service';
      console.error(message, e);
      this.snackbarService.openError(message);
    }
  }

  loadStep2Profiles(): void {
    this.profilesOptions = [];

    this.googleAccounts.forEach(account => {
      if(account.checked){
        this._googleLocationS.loadAll(account.name);
      }
    });

    // FIXME: Multiple subscriptions, every click adds one
    this._subscribeSafe(this._googleLocationS.locations$,
      locations =>{
      let accountName = '';

      if (locations && locations.length > 0 && locations[0]?.name ) {
        const [firstPart, secondPart] = locations[0].name.split('/');

        if (firstPart && secondPart) {
          accountName = `${firstPart}/${secondPart}`;
        }
      }

      const account = this.googleAccounts.find(_=>_.name === accountName);
      if(account){
        account.profiles = locations;
      } 
    })
  }

  checkedGoogleAccounts() : any[] {
    if(this.googleAccounts?.length)
      return this.googleAccounts.filter(_=>_.checked)

    return []
  }

  addProfiles(): void{
    this.addingProfile = true;

    const request: CreateProfileRequest = {
      profiles: []
    }

    this.googleAccounts.forEach(account => {

      if(account.checked){
        const accountId = account.name.split('/')[1];
        const profileModel = new ProfileModel();
        const accountContainer = new AccountContainer();
        accountContainer.gid = this._sessionS.getSession().gid;
        accountContainer.accountId = accountId;
        accountContainer.accountName = account.accountName;
        accountContainer.account = 
          {
            name: account.name,
            accountName: account.accountName,
            type: account.type,
            role: account.role,
            verificationState: account.verificationState,
            vettedState: account.vettedState,
            accountNumber: account.accountNumber,
            permissionLevel: account.permissionLevel,
          }
        accountContainer.googleAuthEmailAddress = this._sessionS.getSession().user.email;
        accountContainer.gauthStatus = { isValid: true };
        accountContainer.googleAuth = this._sessionS.getSession().user.googleAuth;
        profileModel.account = accountContainer;

        profileModel.locations = [];
        account?.profiles?.forEach(profile => {
          if(profile.checked){
            const locationContainer = new LocationModel();
            locationContainer.accountId = accountId;
            locationContainer.gid = this._sessionS.getSession().gid;
            locationContainer.locationId = locationNameToRef(profile).locationId;
            locationContainer.locationName = profile.locationName;
            locationContainer.lockedOn = null;
            locationContainer.subscriptionType = LOCATION_SUBSCRIPTION_TYPE.FREE;
            profileModel.locations.push(locationContainer);
          }
        });

        request.profiles.push(profileModel);
      }
    });

    this.profilesService.createProfile(request)
      .subscribe(response => {
        this.addingProfile = false;
        this.toggleDrawer();
        this.loadAccounts();
    });
  }
}
