// dep
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

// app
import User from '../../../constants/firestore/user';
import {UserService} from '../../../services/user.service';
import {SnackbarService} from '../../../services/snackbar.service';
import { BaseComponent } from 'src/app/components/base.component';


/**
 * "Add User" dialog
 */
@Component({
  selector: 'app-user-add-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls:  ['./user-dialog.component.scss']
})
export class UserDialogComponent extends BaseComponent implements OnInit {
  static readonly OPTS_DEFAULT = { config: { width: '680px'} };
  RESULT_TYPE : {displayName: string, email: string, isActive: boolean, role: string} | false;

  user: User;
  userForm: FormGroup;
  isNew: boolean;
  users: User[];

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: {user : User},
    public dialogRef: MatDialogRef<UserDialogComponent>,
    private _userS: UserService,
    private snack: SnackbarService,
    private formBuilder: FormBuilder,
  ) {
    super();
    this.user = this.data.user;
    this.isNew = this.user === null;
  }

  get form() {
    return this.userForm;
  }

  ngOnInit() : void {
    this.buildForm();
    this._subscribeSafe(this._userS.users, users => this.users = users);
  }

  buildForm(): void {
    const readOnlyFields = this.user !== null;
    this.userForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      email: [{value: '', disabled: readOnlyFields}, [Validators.required, Validators.email]],
      active: [true],
      role: ['']
    });
    if (null !== this.user) {
      this.userForm.patchValue({
        name: this.user.displayName,
        email: this.user.email,
        active: this.user.isActive,
        role: this.user.role
      });
    }
  }

  save(): void {
    const userData = this.userForm.getRawValue();
    if (this.isNew) {
      if(this.users.some(user => user.displayName === userData.name || 
                                 user.email       === userData.email)) {
        this.snack.openError('The user already exists.');
        return;
      } 
    } 
    this.dialogRef.close({displayName: userData.name, email: userData.email, isActive: userData.active, role: userData.role});
  }

}
