// dep
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, interval } from 'rxjs';

// app
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-load-modal',
  templateUrl: './load-modal.component.html',
  styleUrls:  ['./load-modal.component.scss']
})
export class LoadModalComponent extends BaseComponent {

  steps  = 0;
  complete = 0;
  completed: string = null;
  progress  = 0
  loadingValue$ = new BehaviorSubject(0)

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { steps: number, complete: number, completed: string},
    public dialogRef: MatDialogRef<LoadModalComponent>
  ) {
    super();
    this.steps     = data.steps;
    this.complete  = data.complete;
    this.completed = data.completed;

    const sub = this._subscribeSafe(interval(500), 
    () => {
      if (this.progress >= 60) {
        sub.unsubscribe();
      } else {
        this.completed = `Stage 1 - Fetching Location Insights`;
        this.progress += Math.ceil(Math.random() * (30 - 10) + 10);
        this.loadingValue$.next(this.progress)
      }
    })
   }


  public updatedData(complete: number, completed: string) : void {
    this.complete += complete;
    //this.completed = `Stage ${this.complete} - Fetching ${completed}`;
    const progressCompleted = ((this.complete / this.steps) * 100) + 60
    if (progressCompleted >= 60 ) {
      this.progress = progressCompleted;
      if (this.complete === this.steps){
        this.completed = 'Stage 3 - Complete!';
        setTimeout(() => {
          this.dialogRef.close()
        }, 1000)
      }

      if (progressCompleted >= 100  || (this.complete - this.steps) < 3) this.loadingValue$.next(90)
      this.loadingValue$.next(progressCompleted)
      this.completed = `Stage 2 - Fetching Review Data`;
    }
  }

}
