<!--Apple Good to Know-->
<div class="box__item box__item--editable">
    <div class="d-flex w--100">
      <!-- <div class="icon icon--rounded icon--gray mr--20"><i class="far fa-info"></i></div> -->
      <div class="w--100" [class.txt--medium-gray]="isUnderReview">
        <h4 class="w--100 txt--md fw--700 mb--10">{{sectionName}}</h4>
        <ng-container *ngIf="attributesArray && attributesArray.length > 0">
          <div *ngFor="let att of attributesArray">
            <div class="mb--10">
              <div class="w--100 txt--md action-subtitle mb--10"><span>{{att.catName}}</span></div>
              <span *ngFor="let att of att.catAtts">{{att.name | titlecase }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
</div>