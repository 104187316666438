import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';
import { IReportFilterConfigData } from './../../../constants/report-filter-config-data';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class AbcInsightsReportDataService {

  private _insightsReportDataSubject = new Subject<any>();

  constructor(
    private _http: HttpClient,
  ) { }

  get insightsReportDataSubject$() {
    return this._insightsReportDataSubject.asObservable();
  }

  getInsightsData(filterConfig: IReportFilterConfigData) {

    const formattedStartDate = new Date(filterConfig.startDate).toISOString().split("T")[0] + "T00:00:00"
    const formattedEndDate = new Date(filterConfig.endDate).toISOString().split("T")[0] + "T00:00:00"

    const data = {
      gid: filterConfig.gid,
      // TODO: locationCanonId was set from google locationId, 
      // temporal fix: send the google locationId and make the backend resolve to locationCanonId
      // must change to the real locationCanonId
      // locationCanonId: filterConfig.locationCanonId, 
      locationId : filterConfig.locationId,
      locationCanonId: filterConfig.locationId,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      granularity: filterConfig.granularity?.toUpperCase()
    }

    return this._http.post(`${environment.coreApiUrl}/abc/locations/insights`, data)
    .pipe(
      map(data => {



        this._insightsReportDataSubject.next(data);
      }, 
      catchError(error => { // handle errors in a generic way, we can replace with single error handling in each request
        console.error(error)
        return of({error: true, message: 'failed when requesting data'})
    })
    ))
    .subscribe();
  
  }
}
