// dep
import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// app
import { ModalService } from '../services/modal.service';
import { LocationService } from '../services/location.service';
import { SubscriptionService } from '../services/subscription.service';
import { LOCATION_SUBSCRIPTION_TYPE } from '../constants/firestore/account-location';
import SavedLocation from '../constants/firestore/saved-location';
import { FeaturedComponent } from '../components/featured.component';
import { LocationRef } from '../constants/firestore/location-object';
import { SessionService } from '../services/session.service';

@Directive({})
export abstract class LocationClickToUpgradeDirective implements OnChanges, OnInit {
  @Output() denyAccess = new EventEmitter<boolean>();

  constructor(
    protected _sessionS : SessionService,
    protected _route: ActivatedRoute,
    protected _modalS: ModalService,
    protected _locationS: LocationService,
    protected _subscriptionS: SubscriptionService,
    protected _element: ElementRef,
  ) {
  }

  protected abstract _getInput() : '' | { location: SavedLocation, account: string, user?: boolean };
  protected abstract _inputName : string
  protected abstract _canUpgrade(subscriptionType : SavedLocation['subscriptionType']) : boolean

  ngOnInit() : void {
    this._element.nativeElement.addEventListener('click', this._clickHandler, { capture : true});
    this._fetchInfo()
  }

  private async _fetchInfo() : Promise<null | LocationRef> {
    let denyAccess = false;
    try {
      const {subscription, isTrial, gid, isMember} = this._sessionS.getSession();
      
      if(subscription.pricingVersion >= 3 || isTrial)
        return null;

      if (isMember) {
        await this._modalS.openWarningModalNoPermissions();
        return null
      }
  
      let {locationId, accountId} = this._route.snapshot.parent.params;
      let locationIsChecked = false;
      // let subscriptionType : SavedLocation['subscriptionType']

      const inp = this._getInput()
      if(typeof inp !== 'string') {
        locationId        = inp.location?.locationId || locationId
        accountId         = inp.account || accountId
        locationIsChecked = inp.location?.checked
        // subscriptionType  = inp.location?.subscriptionType
      }

      if (!locationId || !accountId || locationIsChecked) {
        return null;
      }

      const locationRef = { locationId, accountId }

      const {subscriptionType} = (await this._locationS.fetchMultipleLocations(gid, [locationRef],
                                                                               ['subscriptionType'] as 
                                                                               ['subscriptionType']))[0]
      if (!this._canUpgrade(subscriptionType)) {
        return null;
      }

      // Enable click-to-upgrade-location, disable all other click handlers
      denyAccess = true;
      return locationRef;

    } finally {
      this.denyAccess.emit(denyAccess);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes[this._inputName].firstChange) {
      this._fetchInfo()
    }
  }

  // TODO: Search #TODO-refactor-featured-model to find code to refactor against this 
  // code block. 
  private _clickHandler = async (event : Event) : Promise<void> => {
    const locationRef = await this._fetchInfo()

    if(!locationRef)
      // Next handlers will execute normally
      return
    
    if(!await this._modalS.openModal(FeaturedComponent, null) || 
       !await this._subscriptionS.flowChangeLocationsPlan([locationRef])) {
      // Don't execute normal handlers
      event.stopImmediatePropagation()
      event.stopPropagation()
      return;
    }
    
    // User upgraded
    // Location must have changed his subscriptionType
    await this._fetchInfo()
  }

}


/**
 * Directive to add a click handler to an element to trigger a Location upgrade
 * from an ESSENTIAL location (pricingVersion < 3)
 * 
 * Usages:
 *   - \<div gmbLGF/>  => will use the locationId/accountId from the route
 *   - \<div [gmbLGF]="{ location, account}"/>
 */
@Directive({
  exportAs: 'gmbLGF',
  selector: '[gmbLGF]'
})
export class LocationGatedFreeDirective extends LocationClickToUpgradeDirective {
  @Input() gmbLGF: '' | { location: SavedLocation, account: string };

  protected _getInput() : LocationGatedFreeDirective['gmbLGF'] { return this.gmbLGF }
  protected _inputName = 'gmbLGF'

  protected _canUpgrade(subscriptionType : SavedLocation['subscriptionType']) : boolean {
    return (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.FREE ||
            subscriptionType === LOCATION_SUBSCRIPTION_TYPE.ESSENTIAL);
  }

}


/**
 * Directive to add a click handler to an element to trigger a Location upgrade
 * from a BASIC location (pricingVersion < 3)
 * 
 * Usages:
 *   - \<div [gmbLGF]="{ location, account, user}"/>
 */
@Directive({
  exportAs: 'gmbLGB',
  selector: '[gmbLGB]'
})
export class LocationGatedBasicDirective extends LocationClickToUpgradeDirective {
  @Input() gmbLGB: { location: SavedLocation, account: string, user?: boolean };

  protected _getInput() : LocationGatedBasicDirective['gmbLGB'] { return this.gmbLGB }
  protected _inputName = 'gmbLGB'

  protected _canUpgrade(subscriptionType : SavedLocation['subscriptionType']) : boolean {
    return (subscriptionType === LOCATION_SUBSCRIPTION_TYPE.BASIC && !this.gmbLGB.user)
  }

}

