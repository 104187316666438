// dep
import {Directive, ElementRef, Input} from '@angular/core';
import {ModalService} from "../services/modal.service";

// app
import SavedLocation from "../constants/firestore/saved-location";
import {AlertType} from "../components/alert.component";

@Directive({
  selector: '[gmbRefreshLockCheck]'
})
export class RefreshLockCheckDirective {
  @Input() gmbRefreshLockCheck: Pick<SavedLocation, 'lastImpressions-fastTask' | 'lastKeywords-fastTask'>;

  constructor(private modalService: ModalService,
              private element: ElementRef) {
  }

  async ngOnInit() {
    if (this.gmbRefreshLockCheck['lastImpressions-fastTask']?.status ===  'COMPLETE' ||
        this.gmbRefreshLockCheck['lastKeywords-fastTask']?.status ===  'COMPLETE' 
    )
      return;

    this.element.nativeElement.removeAllListeners('click');
    this.element.nativeElement.addEventListener('click', this.handler.bind(this));
  }

  handler(e) {
    this.modalService.openAlertModal('Hang tight...', 'We are fetching the last 18 months of insights from Google and your all-time review data. This can take a while. Please try back in 10-15 minutes.', AlertType.INFO);
  }

}
