<!--Company Type-->
<div class="box__item box__item--editable">
  <div class="d-flex w--100">
    <div>
      <h4 class="w--100 txt--md fw--700 mb--10">Categories</h4>
      <div class="box__item box__item--sm box__item--editable" [class.align-items-start]="primaryCategory || additionalCategories">
        <div [class.txt--medium-gray]="isUnderReview">
          <p class="m--0" *ngIf="isEdit && !primaryCategory">Add Category</p>
          <div class="w--100 justify-content-between mb--5">
            <span class="m--0">{{primaryCategory?.displayName | titlecase}}</span>
            <span *ngIf="primaryCategory" class="selected-label ml--15">Primary</span>
          </div>
          <ng-container *ngIf="additionalCategories">
            <div *ngFor="let category of additionalCategories; let i = index">
              <ul [class]="i < additionalCategories.length - 1 ? 'mb--5' : ''">
                <li class="m--0">{{category?.displayName | titlecase}}</li>
              </ul>
            </div>
          </ng-container>
          <p class="under-review mt--5" *ngIf="isUnderReview" role="alert">Under review</p>
        </div>
        <button mat-button (click)="openCategoryDialog()" class="btn btn-icon" *ngIf="isEdit">
          <i class="fas fa-pencil m--0"></i>
        </button>
      </div>
    </div>
  </div>
</div>
 