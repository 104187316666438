// dep
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import moment from 'moment';

// app
import {environment as ENV} from '@environment';
import { ApiResponse, ApiResponse2, Pagination2} from '../constants/api-response';
import {DataPicker} from '../constants/data-picker';
import { Sort } from '../constants/sort';
import { Protocol } from '../constants/firestore/protocol';
import { IReviewList } from './../constants/review-list';
import { InsightsService } from './insights.service';
import { SnackbarService } from './snackbar.service';
import { LocationRef, locationRefsToPaths } from '../constants/firestore/location-object';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  constructor(
    private http: HttpClient,
    private _sessionS: SessionService,
    private insightS: InsightsService,
    private afsStorage: AngularFireStorage,
    private snackS: SnackbarService,
    ) { }


  aggregationRating(locations: LocationRef[], dataPicker: DataPicker) {
    const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const params = new HttpParams()
      .set('startDate', dataPicker.range.start)
      .set('endDate',   dataPicker.range.end)
      .set('aggregate', dataPicker.aggregation.toLowerCase())
      .set('timezoneId', timezoneId);

    const body = { locations: locationRefsToPaths(locations) }

    return this.http.post<ApiResponse>(`${ENV.apiUrl}/v2/reviews/aggregation/rating`, body, {
      params,
    }).pipe(
      map(value => value.data)
    );
  }

  // TODO: Unused (but referenced from commented code). Remove?
  // aggregationResponses(locations: LocationRef[], dataPicker: DataPicker) {
  //   const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //   let params = new HttpParams();
  //   if (dataPicker)
  //     params = (params.set('startDate',  dataPicker.range.start)
  //                     .set('endDate',    dataPicker.range.end)
  //                     .set('aggregate',  dataPicker.aggregation.toLowerCase())
  //                     .set('timezoneId', timezoneId))
  //
  //   const body = {locations: locationRefsToPaths(locations)}
  //
  //   return this.http.post<any>(`${ENV.apiUrl}/v2/reviews/aggregation/responses`, body, { params });
  // }

  aggregationNumber(locations: LocationRef[], dataPicker: DataPicker) {
    const timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const params = new HttpParams()
      .set('startDate', dataPicker?.range?.start)
      .set('endDate',   dataPicker?.range?.end)
      .set('aggregate', dataPicker?.aggregation.toLowerCase())
      .set('timezoneId', timezoneId);

   const body = {locations: locationRefsToPaths(locations)}

   return this.http.post<any>(`${ENV.apiUrl}/v2/reviews/aggregation/number`, body, {
      params,
    }).pipe(map(value => value.data));
  }

  getRatingsAndReviewsData(accountId: string, gid: string, locationId: any, dataPicker: DataPicker) {
    const body = {
      startDate: moment(dataPicker?.range?.start, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      endDate: moment(dataPicker?.range?.end).format('YYYY-MM-DD'),
      viewBy: (dataPicker?.aggregation)?.toLowerCase(),
      accountId,
      gid,
      locationId
    }

    return this.http.post<any>(`${ENV.apiUrl}/v2/reviews/ratings`, body)
  }

  getRatingsAndReviewsReportData(reportId: string, dataPicker: DataPicker) {
    const body = {
      reportId,
      startDate: moment(dataPicker.range.start, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      endDate:   moment(dataPicker.range.end,   'YYYY-MM-DD').format('YYYY-MM-DD'),
      viewBy: (dataPicker?.aggregation)?.toLowerCase(),
    }

    return this.http.post<any>(`${ENV.apiUrl}/v3/reports/reviews`, body)
  }

  reportReviewList(locations: LocationRef[], dataPicker: DataPicker, page: number, size: number, sort: Sort): Observable<Pagination2<IReviewList>> {
    let params = new HttpParams()
      .set('startDate', dataPicker.range.start)
      .set('endDate',   dataPicker.range.end);

    if (page && size) {
      params = params.append('page', page.toString());
      params = params.append('size', size.toString());
    }

    if (sort) {
      params = params.append('sortBy',    sort.sortBy.toString());
      params = params.append('direction', sort.direction.toString());
    }

    const body = {locations: locationRefsToPaths(locations)}

    return this.http.post<ApiResponse2<Pagination2<IReviewList>>>(`${ENV.apiUrl}/v2/reviews/report/list`, body, {
      params,
    }).pipe(map(value => value.data));
  }

  ratingDistribution(locations: LocationRef[]) {
    const body = {locations: locationRefsToPaths(locations)}

    return this.http.post<any>(`${ENV.apiUrl}/v2/reviews/ratingDistribution`, body);
  }


  keywordsByStat(locations: LocationRef[], dataPicker: DataPicker) {
    const params = (new HttpParams()
                    .set('startDate', dataPicker.range.start)
                    .set('endDate',   dataPicker.range.end))

    const body = {locations: locationRefsToPaths(locations)}

    return this.http.post<any>(`${ENV.apiUrl}/v2/reviews/keywords/status`, body, {params}).pipe(map(value => {
      return value.data;
    }));
  }

  buildDate(date : string) : string { 
    const d = date.includes('T') ? date.split('T') : date.split(' ');
    return `${d[0]} 00:00:00`;
  }

  filter(
    locations: any[],
    page: number = null,
    size: number = null,
    stars: number[] = null,
    filterStatus?: any[],
    hasResponse?: boolean,
    hasComment?: boolean,
    keyword?: string,
    sort?,
    gidExternal?: string,
    start?: string,
    end?: string
  ) {

    start = !start ? null : start.includes('T') || start.includes(' ') ? this.buildDate(start) : `${start} 00:00:00`;
    end   = !end   ? null : end.includes('T')   || end.includes(' ')   ? this.buildDate(end)   : `${end} 00:00:00`;

    const body = {
      "gids": [ gidExternal ],
      // TODO: Review backend endpoint implementation, maybe is mixing accountIds and locationIds, it must
      // be (locationRefs : [])       
      "accountIds": locations.map(l => l.accountId),
      "locationIds": locations.map(l => l.locationId),
      "filter": {
        "has_response": hasResponse,
        "has_comment": hasComment,
        "startDate": start,
        "endDate": end,
        "keyword": keyword,
        "status": filterStatus?.length ? filterStatus : null,
        "stars": stars?.length ? stars : null
      },
      "page": page,
      "pageSize": size,
      "sortDesc": (sort?.direction === 'asc'),
      "sortKey": sort?.sortBy
    };

    return this.http.post<any>(`${ENV.coreApiUrl}/reviews/list`, body , 
      //{ ... (gidExternal ? { headers : new HttpHeaders().set('gid', gidExternal)} : {})}
      ).pipe(map(value => value.data));
  }

  async protocolValidateReviews(gid: string, protocol: string) {
    return this.http.get<ApiResponse>(`${ENV.coreApiUrl}/protocols/check/${protocol}`)
    .toPromise().then(response => response.data);
  }

  protocolCheckCounterReview(protocol: Protocol): Promise<{Replied: 0, Total: 0, NotReplied: 0}> {
    return this.http.post<ApiResponse>(
      `${ENV.apiUrl}/v2/reviews/check_review`,
      {protocol}
      ).toPromise().then(response => response.data);
  }


  changeViewed(reviewId : string, viewed) {
    return this.http.put<ApiResponse>(`${ENV.apiUrl}/v2/reviews/viewed`, {
      reviewId,
      viewed
    }).pipe(map(response => response.data));
  }

  reply(accountId, locationId, reviewId, comment) {
    return this.http.put<ApiResponse>(`${ENV.apiUrl}/v2/reviews/${accountId}/${locationId}/${reviewId}/reply`, {
      comment,
    }).pipe(map(response => response.data));
  }

  reply_with_errors(accountId, locationId, reviewId, comment, protocol, reply) {
    return this.http.post<ApiResponse>(`${ENV.apiUrl}/v2/reviews/${accountId}/${locationId}/${reviewId}/reply`, {
      comment,
    }).pipe(map(response => response.data));
  }

  replyDelete(accountId : string, locationId : string, reviewId: any) {
    return this.http.delete<ApiResponse>(`${ENV.apiUrl}/v2/reviews/${accountId}/${locationId}/${reviewId}/reply`)
      .pipe(map(response => response.data));
  }


  saveReviews(accountId: string, locationId: string /*, isExternal = false*/) {
    const params = (new HttpParams().set('type','Reviews'))
    //const gid = isExternal ? this.auth.externalSession.gid : this.auth.session.gid
    const {gid} = this._sessionS.getSession();

    return this.http.get(`${ENV.coreApiUrl}/update/${gid}/${accountId}/${locationId}`,{params});
  }

  exportCsvData(filename: string, data: any[]) {
    return this.http.post(
      `${ENV.apiUrl}/v2/reviews/export-csv-data`,
      {
        file_name: filename,
        data
      });
  }



  async handleExportCsv(locations: {locationId: string, accountId: string}[], page: number = null, size: number = null, 
                        stars: number[] = null, filterStatus?: any[], hasResponse?: boolean,
                        hasComment?: boolean, keyword?: string, sort?, gidExternal?: string, start?: string, end?: string, 
                        filename?: string, reviewIds = null) : Promise<void> {

    const body = {
      "gids": [gidExternal],
      // TODO: Review backend endpoint implementation, maybe is mixing accountIds and locationIds, it must
      // be (locationRefs : []) 
      "accountIds": locations.map(l => l.accountId),
      "locationIds": locations.map(l => l.locationId),
      "filter": {
        "has_response": hasResponse,
        "has_comment": hasComment,
        "startDate": start,
        "endDate": end,
        "keyword": keyword,
        "status": filterStatus?.length ? filterStatus : null,
        "stars": stars?.length ? stars : null,
        "review_ids": reviewIds
      },
      "filename": filename,
      "page": page,
      "pageSize": size,
      "sortDesc": (sort?.direction === 'asc'),
      "sortKey": sort?.sortBy
    };

    try {
      const res = await this.downloadReviewCsv(body).toPromise()
      const filename2 = res?.data;
      const filename3 = await this.afsStorage.ref(filename2).getDownloadURL().toPromise()
      this.insightS.openDownloadInNewTab(filename3)
      this.snackS.openSuccess("The file exported successfully")
    } catch (err) {
      console.error(err)
      this.snackS.openError("There was an error in the export")
    }

  }

  downloadReviewCsv(body): Observable<any> {
    return this.http.post(`${ENV.coreApiUrl}/export/reviews`, body);
  }

  async getUpdatedData(gid : string, accountId : string, locationId: string, reviewId : string): Promise<any> {
    return await this.http.get(`${ENV.coreApiUrl}/reviews/difference/${gid}/${accountId}/${locationId}/${reviewId}`).toPromise()
  }
}
